import React from "react";
import { Box, Typography } from "@mui/material";
import BlinkoButton from "../Button/Button.component";
import { l } from "../../libs/localization";
import { Link } from "react-router-dom";

export default function LimitedFeatureBanner({ message }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 6.5,
      }}
    >
      <Typography>{message}</Typography>
      <a href="https://blinkogroup.com/it/piani-e-prezzi/" alt="Piani e Prezzi">
        <BlinkoButton thin sx={{ alignSelf: "center", px: 12.5, mt: 3 }}>
          {l("ECOMMERCE.CHOOSE_PLAN")}
        </BlinkoButton>
      </a>
    </Box>
  );
}
