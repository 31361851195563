import callApi from "../../libs/helpers/callApi";
import { getFlatEntityListApi } from "../../api/registry.api";

export const INTERNATIONAL_INVEST_LOADING = "INTERNATIONAL_INVEST_LOADING";
export const INTERNATIONAL_INVEST_LOADED = "INTERNATIONAL_INVEST_LOADED";
export const INTERNATIONAL_INVEST_LOAD_ERROR =
  "INTERNATIONAL_INVEST_LOAD_ERROR";

// EMPLOYER RANGE GET LIST
export const getInternationalInvestAction = callApi({
  apiCall: (p) =>
    getFlatEntityListApi({ ...p, entity: "international_invest" }),
  secured: true,
  startAction: INTERNATIONAL_INVEST_LOADING,
  successAction: INTERNATIONAL_INVEST_LOADED,
  errorAction: INTERNATIONAL_INVEST_LOAD_ERROR,
});
