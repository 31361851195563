export const BASE_MONTHLY_V1 = "BASE_MONTHLY_V1";

export const getLegacyPlanConfigurations = (tenant) => ({
  ...(["italy", "france"].includes(tenant) ? {} : {}),
  ...(tenant === "italy"
    ? {
        [BASE_MONTHLY_V1]: {
          id: BASE_MONTHLY_V1,
          priceId: {
            italy:
              window.config?.priceIds?.baseMonthly ||
              "price_1MlZp8G6CfP8S1wQVB8ISW9I",
          }[tenant],
          planName: "Blinko Base",
          price: 70,
          period: "month",
          companyCredits: 120,
          contactCredits: 120,
          prodId: {
            italy: "prod_N4Op3qWhrARLnC",
          }[tenant],
        },
      }
    : {}),
});
