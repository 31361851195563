import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import Config from "../../Config";
import Localize from "../Localized/Localize.comp";

import {
  getGMapsFormattedAddress,
  getGmapStringAddress,
} from "../../libs/utils";
import { reverseGeoCoding } from "../../api/common.api";
import { get } from "../../libs/helpers/ioc";

const APIKEY = Config.googleApiKey;

const CardAddress = (props) => {
  const { data, vertical = true } = props;
  const [results, setResults] = useState([]);
  const coordinates = { lat: null, lng: null };

  /* Fix dismount before async call finished. Cancelling the subscription to useEffect optimizes the app.*/
  const mountedRef = React.useRef(true);

  const marker = "../../assets/images/markerlefac.png"; //TODO caricare l'icon su server e prendere l url

  // // TODO GEO CODING

  const getReverseGeocoding = async (data) => {
    const address = encodeURI(getGmapStringAddress(data));
    try {
      const geo = await reverseGeoCoding(address);
      return geo;
    } catch (e) {
      console.error(e);
      return {};
    }
  };
  useEffect(() => {
    if (!data.position) {
      const geo = getReverseGeocoding(data).then((res) => {
        if (!mountedRef.current) return null;
        return setResults(res.results);
      });
    }
    return () => {
      mountedRef.current = false;
    };
  }, [data]);

  const getCoordinates = () => {
    if (results) {
      //const coordinates
      results.forEach((r) => {
        coordinates.lat = r.geometry.location.lat;
        coordinates.lng = r.geometry.location.lng;
      });
    }
  };

  getCoordinates();

  return (
    <Card
      title={
        <h2>
          <b>{Localize("PAGES.CONTACTS.DETAIL.SECTIONS.GENERAL.ADDRESS")}</b>
        </h2>
      }
      bordered={false}
      className="companyPage__body-card companyPage__body-card--custom"
    >
      <Row justify="space-between">
        <Col span={vertical && 24}>
          {getGMapsFormattedAddress(data)}
          {data.addressNotes && (
            <>
              <b>{Localize("COMMON.ADDRESS_NOTE")}:</b>
              <p>{data.addressNotes}</p>
            </>
          )}
        </Col>
        {/*//TODO MARKER IMAGE*/}
        <Col span={vertical && 24} id={`map-${data._id}`}>
          <a
            href={`https://www.google.it/maps?q=${encodeURI(
              getGmapStringAddress(data)
            )}`}
            target="_blank"
          >
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${
                data.address ? data.address : data.company?.address
              }+${
                data.city ? data.city : data.company?.city
              }&zoom=16&size=250x250&markers=color:red%7C${coordinates.lat},${
                coordinates.lng
              }&key=${APIKEY}`}
              width="100%"
              style={{ width: 200, height: 200 }}
            />
          </a>
        </Col>
      </Row>
    </Card>
  );
};

export default CardAddress;
