import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Row, Select, Spin } from "antd";
import { groupBy, orderBy } from "lodash";
import { SearchOutlined } from "@ant-design/icons";
import { getCompanyBrandsAction } from "../../actions/company.actions";
import Localize from "../../components/Localized/Localize.comp";
import { getFunctionAction } from "../../actions/registry/registry.function.actions";
import BrandList from "../../components/List/Brand.list";
import Label from "../../components/Label/Label";
import { translateRegistryOnView } from "../../libs/utils";
import Tree from "../../components/Tree/Tree";

const CompanyBrands = (props) => {
  const { companyId, disableDetail, sectors, isAgency = false } = props;

  const brands = useSelector((state) => state.company.brands);
  const dispatch = useDispatch();
  const [mainFunct, setMainFunct] = useState(" ");
  const [selectedSectorValue, setSelectedSectorValue] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);

  const functions = useSelector((state) => state.functionReg.data);
  const functionsLoading = useSelector((state) => state.functionReg.loading);

  useEffect(() => {
    searchRequest();
  }, []);

  useEffect(() => {
    if (functions.length == 0) {
      dispatch(getFunctionAction());
    }
  }, []);

  const handleOrder = (val) => {
    setMainFunct(val);
  };

  const searchRequest = () => {
    let sectorFilter = [...selectedSector];
    if (isAgency) sectorFilter = sectorFilter.map((sector) => ({ id: sector }));
    dispatch(
      getCompanyBrandsAction({
        id: companyId,
        sectors: sectorFilter,
        isAgency,
      })
    );
  };

  const filterLabel = (value) => {
    return (
      <Button
        type="link"
        className={`uppercase ${mainFunct == value ? "selected" : ""}`}
        onClick={() => handleOrder(value)}
        a
      >
        {value.length == 1 ? Localize("COMMON.ALL") : value}
      </Button>
    );
  };

  const parent = groupBy(functions.enum, "parent");

  return (
    <>
      <div className="animation fadein-up slow \" style={{ width: "100%" }}>
        <Card
          bordered={false}
          style={{ borderRadius: "10px" }}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          <Row
            gutter={16}
            justify="space-between"
            className="brand_search"
            align="bottom"
          >
            <Col span={8}>
              <Label
                className="filter-color"
                value={Localize("PAGES.SEARCH.ORDER.SECTOR")}
              />
              {isAgency ? (
                <Tree
                  translateEntity="Sector"
                  valueData={selectedSectorValue}
                  datatree={orderBy(sectors, ["key"], ["asc"])}
                  onSelect={(value, e) => {
                    setSelectedSectorValue(value);
                    setSelectedSector(value);
                  }}
                />
              ) : (
                <Select
                  mode="multiple"
                  allowClear={true}
                  style={{ width: "100%" }}
                  value={selectedSectorValue}
                  onChange={(value, opt) => {
                    setSelectedSectorValue(value);
                    setSelectedSector(opt);
                  }}
                  showSearch
                  options={
                    sectors &&
                    sectors.length > 0 &&
                    orderBy(sectors, ["key"], ["asc"]).map((e, j) => {
                      return {
                        value: e._id,
                        key: e._id,
                        label: translateRegistryOnView(e._id, "Sector")
                          ? translateRegistryOnView(e._id, "Sector")
                          : e.key,
                        id: e._id,
                      };
                    })
                  }
                />
              )}
            </Col>
            <Col>
              <Button type="primary" onClick={() => searchRequest()}>
                <SearchOutlined className="search-icn" />
                {Localize("COMMON.SEARCH")}
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
      {brands.loading ? (
        <div className="text-center mt-5">
          <Spin className="mt-5" />
        </div>
      ) : (
        <div className="animation fadein-right slow">
          {brands && brands.data && (
            <BrandList
              data={brands.data}
              disablePagination={true}
              disableDetail={disableDetail}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CompanyBrands;
