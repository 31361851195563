import React, { useState, useEffect } from "react";
import mergeDeep from "../../libs/helpers/mergeDeep";

// let values = null;
// let errors = {};
let completed = true;

export default ({
  name,
  children,
  onChange,
  required = [],
  initialValues = {},
}) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      setValues(values);
      setErrors(mergeDeep(values));
    } else if (!values || Object.keys(values).length === 0) {
      setValues({ ...initialValues });
      setErrors(errors);
    } else {
      setValues({ ...initialValues });
      setErrors(errors);
    }
  }, [initialValues]);

  completed = true;

  required.map((req) => {
    if (
      !Object.keys(values).includes(req) ||
      !values[req] ||
      /^\s*$/.test(values[req])
    ) {
      completed = false;
    }
    return req;
  });

  const updateValue = ({ key, value, error = false, regex }) => {
    completed = true;
    if (value && value.length > 0 && regex && !RegExp(regex).test(value)) {
      error = true;
    }
    if (!error) {
      values[key] = value;
      delete errors[key];
    } else {
      errors[key] = value;
      delete values[key];
    }
    setValues(values);
    setErrors(errors);

    required.map((req) => {
      if (!Object.keys(values).includes(req)) {
        completed = false;
      }
      return req;
    });
    typeof onChange === "function" && onChange({ values, errors, completed });
  };
  return (
    <form name={name}>
      {typeof children === "function"
        ? children({ values, errors, completed, updateValue })
        : children}
    </form>
  );
};
