import { Typography } from "@mui/material";
import React from "react";
import { l } from "../../libs/localization";
import Button from "../Button/Button.component";
import PhoneIcon from "../icons/PhoneIcon.component";
import Modal from "../Modal/Modal.component";

export const helpdeskEmails = {
  italy: "helpdesk.it@blinkogroup.com",
  france: "helpdesk.fr@blinkogroup.com",
  uk: "helpdesk.uk@blinkogroup.com",
  spain: "helpdesk.es@blinkogroup.com",
};

export default function ContactSalesModal({
  open,
  dismiss,
  children,
  mainMessage,
  ...props
}) {
  const tenant = localStorage.getItem("lefacWebTenant")?.data;
  return (
    <Modal open={open} onClose={dismiss} {...props}>
      <PhoneIcon sx={{ fontSize: 50 }} />
      <Typography sx={{ mt: 2.5 }} variant="h3">
        {l("ECOMMERCE.CONTACT_SALES")}
      </Typography>
      <Typography sx={{ alignSelf: "stretch", mb: 2.5 }}>
        {mainMessage}
      </Typography>
      <a href={`mailto:${helpdeskEmails[tenant]}`}>
        <Button>{l("ECOMMERCE.CONTACT")}</Button>
      </a>
      <Button color="gray" onClick={dismiss}>
        {l("ECOMMERCE.DISMISS")}
      </Button>
    </Modal>
  );
}
