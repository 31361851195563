import { getFlatV2EntityListApi } from "../api/registry.api";
import callApi from "../libs/helpers/callApi";

export const FlatV2InvestmentRoundFundingTypeEntity =
  "InvestmentRoundFundingType";

export const FLATV2_LIST_LOADING = "FLATV2_LIST_LOADING";
export const FLATV2_LIST_LOADED = "FLATV2_LIST_LOADED";
export const FLATV2_LIST_LOAD_ERROR = "FLATV2_LIST_LOAD_ERROR";

export const getFlatV2EntityAction = callApi({
  apiCall: (p) => getFlatV2EntityListApi(p),
  secured: true,
  startAction: FLATV2_LIST_LOADING,
  successAction: FLATV2_LIST_LOADED,
  errorAction: FLATV2_LIST_LOAD_ERROR,
  extraActionParams: (p) => ({ entity: p.entity }),
});
