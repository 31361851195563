import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";
import { logoutApi } from "./auth.api";
import { userLogout } from "../actions";
import { encodeURIFix } from "../libs/utils";

const APIKEY = Config.googleApiKey;

export const reverseGeoCoding = async (address) => {
  const res = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${APIKEY}`,
    {
      method: "GET",
    }
  );

  const response = res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const searchAutocompleteApi = async ({ headers, key }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/search?key=${encodeURIFix(
      key
    )}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = res.json();

  if (res.ok) {
    return response;
  }

  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  if (httpStatus === 401) {
    userLogout();
  }

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getWikipediaSummary = async (voice_url) => {
  const url = voice_url.replace("/wiki/", "/api/rest_v1/page/summary/");
  // Translate wikipedia url according to current locale:
  // url = url.replace(
  //   /https:\/\/[a-z][a-z].wikipedia.org/,
  //   `https://${getCurrentLocale()}.wikipedia.org`
  // );
  const res = await fetch(url, {
    method: "GET",
  });

  const response = res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};
