import React from "react";
import { Radio as MUIRadio, useTheme } from "@mui/material";

export default function Radio({ sx, ...props }) {
  const theme = useTheme();
  return (
    <MUIRadio
      {...props}
      sx={{
        "&.MuiRadio-root": {
          color: theme.palette.secondary.main,
          "&.Mui-checked": { color: theme.palette.secondary.main },
        },
        ...sx,
      }}
    />
  );
}
