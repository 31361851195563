import { reducerFromMap } from "../libs/helpers/reducers";

import {
  SET_SIDEBAR_COLLAPSED,
  SET_ERROR,
  SET_LANGUAGE,
} from "../actions/app.actions";
import { getFromLocalStorage } from "../libs/helpers/localstorage";
const initialState = {
  sidebarCollapsed: false,
  language:
    (getFromLocalStorage("lang") && getFromLocalStorage("lang").data) || "it",
};

const reducer = {
  [SET_SIDEBAR_COLLAPSED]: (state, actions) => {
    return {
      ...state,
      sidebarCollapsed: actions.collapsed,
    };
  },
  [SET_ERROR]: (state, actions) => ({
    ...state,
    error: actions.error,
  }),
  [SET_LANGUAGE]: (state, actions) => ({
    ...state,
    language: actions.language,
  }),
};

export default reducerFromMap(reducer, initialState);
