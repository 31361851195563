import moment from "moment";
import { getConsumptionApi } from "../api/ecommerce.api";
import getUserPlugins from "./getUserPlugins";

export default async function getPluginConsumption({ user, pluginName }) {
  const plugin = getUserPlugins(user).find((p) => p.name === pluginName);
  const firstDate = plugin.data.periodStartDateTime;
  const period = { month: 1, year: 12, none: 0 }[plugin.data.period];
  if (typeof period === "number" && firstDate) {
    const startDate = moment(firstDate);
    if (period > 0) {
      while (startDate.isBefore(moment().subtract(period, "months"))) {
        startDate.add(period, "months");
      }
    }
    const { consumption } = (
      await getConsumptionApi({ periodStartDate: startDate })
    ).data;
    return consumption;
  }
  throw new Error("Invalid plugin configuration or plugin not found.");
}
