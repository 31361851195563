/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @author davide.mantovani
 * @param Object objects - Objects or Arrays to merge
 *
 * @returns {object} New object with merged key/values
 */
const mergeDeep = (...objects) => {
  const isObject = (obj) => obj && typeof obj === "object";

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const prevVal = prev[key];
      const currVal = obj[key];

      if (Array.isArray(prevVal) && Array.isArray(currVal)) {
        // Checks if is array then concats values
        prev[key] = prevVal.concat(...currVal);
      } else if (isObject(prevVal) && isObject(currVal)) {
        // Checks if is objects on the same level of the previous one
        prev[key] = mergeDeep(prevVal, currVal);
      } else {
        // This object was empty
        prev[key] = currVal;
      }
      return key;
    });

    return prev;
  }, {});
};

export default mergeDeep;
