import {
  OTHER_CATEGORIES_LOADED,
  OTHER_CATEGORIES_LOADING,
  OTHER_CATEGORIES_LOAD_ERROR,
} from "../../actions/registry/registry.other_categories.actions";
import { reducerFromMap } from "../../libs/helpers/reducers";

const reducer = {
  /*
   * OTHER_CATEGORIES LIST
   */
  [OTHER_CATEGORIES_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [OTHER_CATEGORIES_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [OTHER_CATEGORIES_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
