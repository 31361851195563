import { push } from "redux-first-history";
export const APP_START = "@@APP_START";
export const ROUTER_LOCATION_CHANGE = "@@router/LOCATION_CHANGE";
export const SET_SIDEBAR_COLLAPSED = "SET_SIDEBAR_COLLAPSED";
export const LOGOUT = "LOGOUT";
export const SET_ERROR = "SET_ERROR";
export const SET_LANGUAGE = "SET_LANGUAGE";

export const goTo = (url) => (dispatch) => {
  dispatch(push(url));
};

export const setSidebarCollapsed = (collapsed) => {
  return {
    type: SET_SIDEBAR_COLLAPSED,
    collapsed,
  };
};

export const setErrorAction = ({ name, status, message, id, date, code }) => ({
  type: SET_ERROR,
  error: { name, status, message, id, date, code },
});

export const resetErrorAction = () => ({
  type: SET_ERROR,
  error: null,
});

export const setLanguageAction = ({ language }) => ({
  type: SET_LANGUAGE,
  language,
});
