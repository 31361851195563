import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Router } from "react-router";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore, { createReduxHistory } from "./store";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const store = configureStore();
const history = createReduxHistory(store);
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
