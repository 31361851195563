import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { AUTHORIZE_DONE, goTo, setLanguageAction } from "../../actions";
import { forgotPwdApi, loginApi } from "../../api/auth.api";
import BlinkoLogo from "../../design-library/BlinkoLogo";
import Button from "../../design-library/Button/Button.component";
import TextField from "../../design-library/TextField/TextField.component";
import Localize, { bareLocalize, l } from "../../libs/localization";

const TENANT_TO_LANG = {
  italy: "it",
  spain: "es",
  france: "fr",
  portugal: "en",
};

export default function ForgotPasswordPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [done, setDone] = useState(false);

  const [email, setEmail] = useState("");

  async function submit(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setDone(false);
    try {
      const res = await forgotPwdApi({ email });
      if (res) {
        setDone(true);
      } else {
        setError(bareLocalize("ERROR.DATA_ERROR"));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      if (err.response?.data?.clientType) {
        setError(err.response.data.clientType);
      } else {
        setError(err.message);
      }
    }
  }
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 8,
        py: 8,
        width: "100%",
        maxWidth: "none !important",
        minHeight: "100vh",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <Card sx={{ width: "680px" }}>
        <form onSubmit={submit}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              px: 5.5,
              py: 7.5,
              alignItems: "center",
              position: "relative",
            }}
          >
            <BlinkoLogo style={{ maxWidth: 220, alignSelf: "center" }} />
            <Typography sx={{ mt: 2.5 }} variant="h3">
              {l("RESET_PASSWORD.TITLE")}
            </Typography>
            {error ? (
              <Alert severity="error" sx={{ alignSelf: "stretch" }}>
                {error}
              </Alert>
            ) : null}
            {done ? (
              <Alert severity="success" sx={{ alignSelf: "stretch" }}>
                {Localize("LOGIN.SENT_RESET")}
              </Alert>
            ) : null}
            <TextField
              placeholder={l("LOGIN.EMAIL")}
              label={l("LOGIN.EMAIL")}
              type="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button type="submit">{l("RESET_PASSWORD.SUBMIT")}</Button>
            <Typography variant="body1">
              {l("RESET_PASSWORD.RETURN_TO")}{" "}
              <Link href="/login" alt="log in">
                {l("LOGIN.LOGIN")}
              </Link>
            </Typography>
            {loading ? (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",

                  zIndex: 5,
                }}
              >
                <CircularProgress />
              </Box>
            ) : null}
          </CardContent>
          <div />
        </form>
      </Card>
    </Container>
  );
}
