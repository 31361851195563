import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { List, Spin } from "antd";
import { useLocation } from "react-router";
import queryString from "query-string";
import Pitch from "../Pitch/Pitch";
import { searchPitchesAction } from "../../actions/search.action";

const PitchList = (props) => {
  const {
    pitchList,
    pageSize,
    total,
    onMoreBrandClick,
    loadingList,
    filters = "",
  } = props;
  const [pSize, setPSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const location = useLocation();

  const fetchData = (page, pageSize) => {
    if (!loadingList) {
      if (pSize !== pageSize) {
        setPSize(pageSize);
      }
      const query = queryString.parse(location.search);
      let params = "";

      if (filters) {
        params = {
          ...filters,
          pageNum: page,
          limit: pageSize,
        };
      }

      if (query.key && !params.name) {
        params = {
          name: query.key,
          pageNum: page,
          limit: pageSize,
        };
      }

      dispatch(
        searchPitchesAction({
          data: params,
        })
      );
    }
  };

  useEffect(() => {
    fetchData(1, pageSize);
  }, [location.key]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  return (
    <>
      {loadingList ? (
        <div className="text-center">
          <Spin className="mt-5" />
        </div>
      ) : (
        <List
          className="searchPage__list animation"
          itemLayout="horizontal"
          dataSource={pitchList}
          pagination={{
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              fetchData(page, pageSize);
            },
            current: currentPage,
            pageSize: pSize,
            total,
          }}
          renderItem={(item) => {
            return (
              <div className="searchPage__item-more animation fadein-right slow">
                <List.Item
                  className="searchPage__item pl-3"
                  style={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    marginBottom: 15,
                  }}
                >
                  <List.Item.Meta
                    size="small"
                    description={
                      <Pitch
                        isPitchPage
                        item={item}
                        onMoreBrandClick={onMoreBrandClick}
                      />
                    }
                  />
                </List.Item>
              </div>
            );
          }}
        />
      )}
    </>
  );
};

export default PitchList;
