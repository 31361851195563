import {
  Card,
  CardContent,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import BlinkoLogo from "../../design-library/BlinkoLogo";
import Button from "../../design-library/Button/Button.component";
import ThumbsUpIcon from "../../design-library/icons/ThumbsUpIcon.component";
import { l } from "../../libs/localization";

export default function PaymentSuccessfulPage() {
  const theme = useTheme();
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
        py: 8,
        width: "100%",
        maxWidth: "none !important",
        backgroundColor: theme.palette.primary.light,
        minHeight: "100vh",
      }}
    >
      <Card sx={{ width: "680px" }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            px: 5.5,
            py: 7.5,
            alignItems: "center",
            position: "relative",
          }}
        >
          <BlinkoLogo style={{ maxWidth: 180, alignSelf: "center" }} />
          <ThumbsUpIcon sx={{ fontSize: 60 }} />
          <Typography sx={{ mt: 2.5 }} variant="h3">
            Abbonamento attivato con successo
          </Typography>
          <Typography variant="body1">
            Ora puoi eseguire l&apos;accesso ed iniziare a consultare i dati di
            Blinko. <br />
            <br /> Vuoi vedere dei video tutorial? Visita il nostro canale{" "}
            <a href={l("ECOMMERCE.YOUTUBE_CHANNEL_URL")}>
              {l("ECOMMERCE.YOUTUBE")}
            </a>
            . <br />
            <br />
            Registrati alla nostra{" "}
            {
              <a href={l("ECOMMERCE.NEWSLETTER_URL")}>
                {l("ECOMMERCE.NEWSLETTER")}
              </a>
            }{" "}
            per ricevere ogni venerdi le news sulle Fusioni e Acquisizioni,
            Round di Investimenti e Nomine, clicchi qui.
          </Typography>
          <Link style={{ display: "block", alignSelf: "stretch" }} to="/">
            <Button sx={{ mt: 12, width: "100%" }} type="submit">
              vai alla home
            </Button>
          </Link>
        </CardContent>
        <div />
      </Card>
    </Container>
  );
}
