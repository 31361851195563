import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";

export const getNielsenEntityApi = async ({ headers, key }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.nielsen.api}/entity`,
    {
      method: "GET",
      headers,
    }
  );

  const response = res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;

    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const getNielsenYearsApi = async ({ headers, key }) => {
  const res = await fetch(`${Config.apiBaseurl}/${Config.apis.nielsen.api}`, {
    method: "GET",
    headers,
  });

  const response = res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;

    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};
