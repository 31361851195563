import axios from "axios";
import { getFromLocalStorage } from "../libs/helpers/localstorage";
import Config from "../Config";

export async function getSettingsApi({ key, entityType, entityId }) {
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  return axios.get(`${Config.apiBaseurl}/${Config.apis.v1}/settings`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-tenant": tenant,
    },
    params: {
      key,
      entityType,
      entityId,
    },
  });
}

export async function createSettingApi({ data }) {
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  return axios.post(`${Config.apiBaseurl}/${Config.apis.v1}/settings`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-tenant": tenant,
    },
  });
}

export async function editSettingApi({ id, data }) {
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  return axios.patch(
    `${Config.apiBaseurl}/${Config.apis.v1}/settings/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-tenant": tenant,
      },
    }
  );
}

export async function upsertSettingApi({ key, entityId, entityType, data }) {
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  return axios.put(
    `${Config.apiBaseurl}/${Config.apis.v1}/settings`,
    {
      key,
      entityId,
      entityType,
      data,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-tenant": tenant,
      },
    }
  );
}

export async function deleteSettingApi({ id }) {
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  return axios.delete(`${Config.apiBaseurl}/${Config.apis.v1}/settings/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-tenant": tenant,
    },
  });
}
