import axios from "axios";
import Config from "../Config";
import { getFromLocalStorage } from "../libs/helpers/localstorage";

export const getEntityNewsForEntityApi = async ({ entityType, entityId }) => {
  const ls = getFromLocalStorage();
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const token = ls && ls.token;
  const res = await axios.get(
    `${Config.apiBaseurl}/${Config.apis.entityNews.getForEntity}/${entityType}/${entityId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-tenant": tenant,
      },
    }
  );
  return res;
};
