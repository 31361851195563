import callApi from "../../libs/helpers/callApi";
import { getFlatEntityListApi } from "../../api/registry.api";

export const INCOME_RANGE_LOADING = "INCOME_RANGE_LOADING";
export const INCOME_RANGE_LOADED = "INCOME_RANGE_LOADED";
export const INCOME_RANGE_LOAD_ERROR = "INCOME_RANGE_LOAD_ERROR";

// INCOME RANGE GET LIST
export const getIncomeRangeAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "income_range" }),
  secured: true,
  startAction: INCOME_RANGE_LOADING,
  successAction: INCOME_RANGE_LOADED,
  errorAction: INCOME_RANGE_LOAD_ERROR,
});
