import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Col, List, Row, Spin, Tag } from "antd";
import StarOutlined from "@ant-design/icons/es/icons/StarOutlined";
import StarFilled from "@ant-design/icons/es/icons/StarFilled";
import { Box, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PhoneOutlined from "@ant-design/icons/es/icons/PhoneOutlined";
import _ from "lodash";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { MailOutlined, LinkedinOutlined } from "@ant-design/icons/es/icons";
import { searchContactsAction } from "../../actions/search.action";
import ContactModal from "../../pages/contact/contact.modal";
import Localize, { bareLocalize } from "../../libs/localization";

import { deleteAssociatedIdTagAction } from "../../actions/tag.actions";
import { toTitleCase, translateRegistryOnView } from "../../libs/utils";
import {
  CONTACT_DETAIL_MODAL_CLOSE,
  CONTACT_DETAIL_MODAL_OPEN,
  openContactDetailAction,
} from "../../actions/contact.actions";
import useListSelection from "../../hooks/useListSelection";
import usePermissions from "../../hooks/usePermissions";

const ContactList = (props) => {
  const {
    data,
    pageSize,
    setPageSize,
    title = "",
    total,
    filters = {},
    tagMode,
    setSelectedContact = () => {},
    isTagPage = false,
    loadingList,
    loadingTag,
    associatedTagsIds,
    setContactTagPage,
    onAssociateTag,
    onDeleteTag,
    resultsLimited = false,
    alreadyOpened = [],
    onContactOpen = () => {},
  } = props;

  const permissions = usePermissions();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [selection, setSelection] = useState([]);
  const {
    deselectAll,
    toggle,
    addToSelection,
    removeFromSelection,
  } = useListSelection({ selection, setSelection });

  const contactDetailData = useSelector((state) => state.contact.detail.data);
  const showContactModal = useSelector((s) => s.contact.detail.modalOpen);
  console.log(showContactModal);
  const setShowContactModal = (open) =>
    dispatch({
      type: open ? CONTACT_DETAIL_MODAL_OPEN : CONTACT_DETAIL_MODAL_CLOSE,
    });

  const [selectAll, setSelectAll] = useState(false); // attiva/disattiva checkbox

  const saveTags = [];

  const fetchData = (page, _pageSize) => {
    if (!isTagPage && !loadingList) {
      if (pageSize !== _pageSize) {
        setPageSize(_pageSize);
      }
      let params = {};

      params = {
        ...(filters || {}),
        pageNum: page,
        limit: _pageSize,
      };
      dispatch(
        searchContactsAction({
          data: params,
        })
      );
    }
  };

  useEffect(() => {
    setSelectedContact(selection);
  }, [selection]);

  const prevFilters = useRef();
  useEffect(() => {
    if (!prevFilters.current || !_.isEqual(prevFilters.current, filters)) {
      setCurrentPage(1);
    }
    prevFilters.current = filters;
  }, [filters]);

  useEffect(() => {
    if (contactDetailData) {
      onContactOpen(contactDetailData._id);
    }
  }, [contactDetailData]);

  const openContactDetail = (item) => {
    if (!tagMode) {
      dispatch(openContactDetailAction(item._id));
      // setDetailId(item._id);
      // setShowContactModal(true);
    }
  };

  useEffect(() => {
    deselectAll();
    setSelectAll(false);
  }, [tagMode]);

  const renderTag = (item) => {
    let renderedTags = [];
    associatedTagsIds &&
      associatedTagsIds.map((association) => {
        const founded = association._id === item._id;
        if (founded) {
          renderedTags = association.tags.map((tag) => {
            return (
              <Tag
                key={tag.tagId}
                color={`#${tag.color}`}
                className="searchPage__item-tag"
                closable
                onClose={() =>
                  dispatch(
                    deleteAssociatedIdTagAction({
                      tagId: tag.tagId,
                      associatedId: association._id,
                    })
                  )
                }
              >
                {tag.name}
              </Tag>
            );
          });
          const idTagsToSave = renderedTags.map((t) => t.key);
          saveTags.push({ id: item._id, tags: idTagsToSave });
        }
      });
    return renderedTags;
  };

  const renderStar = (item) => {
    const selected = selection.includes(item._id);
    const Component = selected ? StarFilled : StarOutlined;
    return <Component style={{ fontSize: 20 }} />;
  };

  return (
    <>
      {loadingList ? (
        <div className="text-center">
          <Spin className="mt-5" />
        </div>
      ) : (
        <div title={title}>
          {tagMode && (
            <Checkbox
              checked={selectAll}
              onChange={({ target }) => {
                setSelectAll(target.checked);
                if (target.checked) {
                  addToSelection(data.map((contact) => contact._id));
                } else {
                  removeFromSelection(data.map((contact) => contact._id));
                }
              }}
            >
              {Localize("COMMON.SELECT_ALL")}
            </Checkbox>
          )}
          <List
            className="searchPage__list animation"
            itemLayout="horizontal"
            dataSource={data}
            pagination={
              resultsLimited
                ? false
                : {
                    onChange: (page, _pageSize) => {
                      setSelectAll(false);
                      setCurrentPage(page);
                      setPageSize(_pageSize);
                      setContactTagPage && setContactTagPage(page);
                      fetchData(page, _pageSize);
                    },
                    current: currentPage,
                    pageSize,
                    total,
                    showSizeChanger: !isTagPage,
                  }
            }
            renderItem={(item) => {
              return (
                <div className="searchPage__item-more animation fadein-right slow">
                  <List.Item
                    className="searchPage__item pl-3"
                    onClick={() => openContactDetail(item)}
                  >
                    <List.Item.Meta
                      title={
                        <Row align="middle" justify="space-between">
                          <Col>
                            <h3 className="searchPage__item-title">
                              {tagMode && (
                                <Button
                                  type="text"
                                  shape="circle"
                                  size="small"
                                  className="mr-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    toggle(item._id);
                                  }}
                                >
                                  {renderStar(item)}
                                </Button>
                              )}
                              <b>{`${toTitleCase(item.name)} ${toTitleCase(
                                item.surname
                              )}`}</b>{" "}
                              | {item.title}
                              <span className="mr-2" />
                              {loadingTag ? (
                                <Spin size="small" className="ml-4" />
                              ) : (
                                renderTag(item)
                              )}
                            </h3>
                          </Col>
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            {item.linkedin && item.linkedin !== 0 && (
                              <a
                                onClick={
                                  permissions.ecommerce
                                    ? null
                                    : (e) => e.stopPropagation()
                                }
                                href={
                                  permissions.ecommerce ? null : item.linkedin
                                }
                                target="_blank"
                              >
                                <LinkedinOutlined className="searchPage__item-icon mr-3" />
                              </a>
                            )}
                            {item.email && (
                              <a
                                onClick={
                                  permissions.ecommerce
                                    ? null
                                    : (e) => e.stopPropagation()
                                }
                                href={
                                  permissions.ecommerce
                                    ? null
                                    : `mailto:${item.email}`
                                }
                              >
                                <MailOutlined className="searchPage__item-icon mr-3" />
                              </a>
                            )}
                            {alreadyOpened?.includes(item._id) ? (
                              <Tooltip
                                title={bareLocalize(
                                  "COMPANY_LIST.ALREADY_OPENED.TOOLTIP"
                                )}
                              >
                                <FileDownloadDoneIcon
                                  sx={{ marginRight: 1.5 }}
                                />
                              </Tooltip>
                            ) : null}
                          </Box>
                        </Row>
                      }
                      description={
                        <>
                          <Row justify="space-between" align="bottom">
                            <Col span={12}>
                              <Row align="top">
                                <p className="mr-3 m-0">
                                  {item.company?.name || item.companyName}
                                </p>
                                <Tag>
                                  {translateRegistryOnView(
                                    item.function,
                                    "function",
                                    true
                                  )
                                    ? translateRegistryOnView(
                                        item.function,
                                        "function",
                                        true
                                      )
                                    : item.function}
                                </Tag>
                              </Row>
                            </Col>
                            <Col span={8} className="mr-3">
                              <Row align="end">
                                <PhoneOutlined className="mr-2" />
                                {item.tel ? item.tel : item.company?.tel}{" "}
                              </Row>
                            </Col>
                          </Row>
                        </>
                      }
                    />
                  </List.Item>
                </div>
              );
            }}
          />
        </div>
      )}
      {contactDetailData && (
        <ContactModal
          onAssociateTag={onAssociateTag}
          onDeleteTag={onDeleteTag}
          visible={showContactModal}
          close={() => {
            setShowContactModal(false);
          }}
        />
      )}
    </>
  );
};

export default ContactList;
