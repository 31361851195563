import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Select, Tag, Tooltip } from "antd";
import {
  AlignLeftOutlined,
  EnvironmentOutlined,
  FormOutlined,
  LineOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Label from "../Label/Label";
import Localize from "../../libs/localization";

export default function JobOffersFiltersForm({ updateValue, formData }) {
  const [form, setForm] = useState({});
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    updateValue({ key: "jobOffers", value: [...filters] });
  }, [filters]);

  function addFilter(obj) {
    setFilters([...filters, obj]);
  }

  function removeFilter(i) {
    const newFilters = filters;
    newFilters.splice(i, 1);
    setFilters([...newFilters]);
  }

  return (
    <Row gutter={16}>
      <Col span={24} className="mt-3">
        {filters.map((filter, i) => (
          <>
            {i > 0 ? (
              <Row>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {Localize("COMMON.OR")}
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col span={20}>
                {["jobTitle", "contractType", "description", "location"].map(
                  (key) => {
                    const labelKey = {
                      jobTitle:
                        "PAGES.SEARCH.COMPANY.JOB_OFFERS.SEARCH_IN_TITLE",
                      contractType:
                        "PAGES.SEARCH.COMPANY.JOB_OFFERS.CONTRACT_TYPE",
                      description:
                        "PAGES.SEARCH.COMPANY.JOB_OFFERS.DESCRIPTION",
                      location: "PAGES.SEARCH.COMPANY.JOB_OFFERS.PLACE",
                    }[key];
                    const Icon = {
                      jobTitle: LineOutlined,
                      contractType: FormOutlined,
                      description: AlignLeftOutlined,
                      location: EnvironmentOutlined,
                    }[key];
                    if (filter[key] && filter[key].length > 0) {
                      return (
                        <Tooltip title={Localize(labelKey)}>
                          <Tag
                            icon={<Icon style={{ fontSize: "12px" }} />}
                            alt={Localize(labelKey)}
                          >
                            {Array.isArray(filter[key])
                              ? filter[key].join(" & ")
                              : filter[key]}
                          </Tag>
                        </Tooltip>
                      );
                    }
                    return null;
                  }
                )}
              </Col>
              <Col span={4}>
                <div
                  role="button"
                  onClick={() => {
                    removeFilter(i);
                  }}
                  style={{
                    padding: "5px 10px",
                    cursor: "pointer",
                    alignSelf: "flex-end",
                  }}
                >
                  X
                </div>
              </Col>
            </Row>
          </>
        ))}
      </Col>
      <Col span={12} className="mt-3">
        <Label
          className="filter-color"
          value={Localize("PAGES.SEARCH.COMPANY.JOB_OFFERS.SEARCH_IN_TITLE")}
        />
        <Select
          mode="tags"
          style={{ width: "100%" }}
          value={form.jobTitle}
          onChange={(e) => {
            setForm({ ...form, jobTitle: e });
          }}
        />
      </Col>
      <Col span={12} className="mt-3">
        <Label
          className="filter-color"
          value={Localize("PAGES.SEARCH.COMPANY.JOB_OFFERS.CONTRACT_TYPE")}
        />
        <Input
          value={form.contractType}
          onChange={(e) => {
            setForm({ ...form, contractType: e.target.value || undefined });
          }}
        />
      </Col>
      <Col span={24} className="mt-3">
        <Label
          className="filter-color"
          value={Localize("PAGES.SEARCH.COMPANY.JOB_OFFERS.DESCRIPTION")}
        />
        <Select
          mode="tags"
          style={{ width: "100%" }}
          value={form.description}
          onChange={(e) => {
            setForm({ ...form, description: e });
          }}
        />
      </Col>
      <Col span={12} className="mt-3">
        <Label
          className="filter-color"
          value={Localize("PAGES.SEARCH.COMPANY.JOB_OFFERS.PLACE")}
        />
        <Input
          value={form.location}
          onChange={(e) => {
            setForm({ ...form, location: e.target.value || undefined });
          }}
        />
      </Col>
      <Col span={12} className="mt-3">
        <Button
          // disabled={
          //   selectedYear === null ||
          //   type.length === 0 ||
          //   (min === null && max === null)
          // }
          type="primary"
          style={{ marginTop: "36px" }}
          disabled={
            !(
              form.location ||
              (form.description && form.description.length) ||
              form.contractType ||
              (form.jobTitle && form.jobTitle.length)
            )
          }
          onClick={() => {
            addFilter(form);
          }}
        >
          <PlusOutlined />
          {Localize("COMMON.ADD")}
        </Button>
      </Col>
    </Row>
  );
}
