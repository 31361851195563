import React, { useState, useEffect } from "react";
import { Button, Popconfirm, Row, Col, Spin } from "antd";
import { PlusOutlined, SaveOutlined, EditOutlined } from "@ant-design/icons";
import Localize from "../../components/Localized/Localize.comp";

import "./DetailPane.scss";
import { renderDetailItem, checkIfNeedsRender } from "./_DetailPane.helpers";
import { isEmpty, isEqual, toUpper, forEach, get, isNil } from "lodash";

import { ReactComponent as EmptyIcon } from "./empty.svg";
import { turnKeyIntoLabel } from "../../libs/utils";
import Form from "../Form/Form";
import Led from "../Led/Led";
import Stepper from "../Stepper/Stepper";
import Address from "../Address/Address";

const viewOptionsDefault = {
  cols: 3,
  displayAs: "GRID", // or MASONRY
  hideToolbar: false,
  noShadow: false,
  noBorder: false,
};

const Tile = ({ type = "GRID", children }) =>
  type === "GRID" ? (
    <Row>{children}</Row>
  ) : (
    <div className="masonry">{children}</div>
  );

export { Tile };

export default ({
  title,
  component,
  data = {},
  entitySchema = [],
  editing = false,
  creating = false,
  disableEditing = false,
  disableCreation = false,
  toggleEditMode,
  toggleCreateMode,
  loading = false,
  onSave = (data) => data,
  onEdit = (data) => data,
  onChange = (data) => data,
  onBlur = (key, value, data) => key && value && data,
  imageUploadOptions = {
    headers: null,
    url: null,
    callback: null,
    disabled: !editing,
    onDelete: null,
  },
  viewOptions = {
    ...viewOptionsDefault,
  },
  customRendererForType = {},
  extra,
  stepper = false,
  stepData,
  address = false,
  renderType = null,
}) => {
  const [dataForm, setDataForm] = useState(data);
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [formIsCompleted, setFormIsCompleted] = useState(!isEmpty(data));

  // compose viewOptions
  viewOptions = {
    ...viewOptionsDefault,
    ...viewOptions,
  };

  useEffect(() => {
    if (
      !creating &&
      ((!isEmpty(data) && isEmpty(dataForm)) || !isEqual(data, dataForm))
    ) {
      setDataForm(data);
      setFormIsCompleted(!isEmpty(data));
    }
  }, [data]);

  useEffect(() => setDataForm(data), [creating, editing]);

  const renderStepper = () => {
    const steps = [];
    forEach(stepData, (data) => {
      let isNeeded = false;
      forEach(data, (schema) => {
        if (checkIfNeedsRender(schema, renderType || "CREATE")) {
          isNeeded = true;
        }
      });

      if (data.length > 0 && isNeeded) {
        steps.push({
          title: Localize(
            `PAGES.COMPANIES.DETAIL.SECTIONS.GENERAL.${toUpper(
              data[0].section
            )}`
          ),
          content: (
            <>
              {data[0].section === "address" ? (
                <Col span={24} className="">
                  <Tile type={viewOptions.displayAs}>
                    <Address
                      style={
                        viewOptions.displayAs === "GRID" && {
                          padding: "10px",
                          minWidth: `${Math.floor(100 / viewOptions.cols)}%`,
                        }
                      }
                      searchStyle={
                        viewOptions.displayAs === "GRID" && {
                          padding: "10px",
                          minWidth: `${Math.floor(100 / viewOptions.cols)}%`,
                        }
                      }
                      dataForm={dataForm}
                      onChange={(newdata) => setDataForm(newdata)}
                      searchPlaceholder={Localize("COMMON.FIND_ADDRESS")}
                      editing={true}
                    />
                  </Tile>
                </Col>
              ) : (
                <Col span={24} className="">
                  <Form
                    id={`${data[0].section}-form`}
                    name={`${data[0].section}-form`}
                    initialValues={dataForm}
                    onChange={({ values, errors, completed }) => {
                      setDataForm({ ...dataForm, ...values, ...errors });
                      setFormHasErrors(!isEmpty(errors));
                      setFormIsCompleted(completed);
                      typeof onChange === "function" &&
                        onChange({ ...dataForm, ...values, ...errors });
                    }}
                  >
                    {({ values, errors, completed, updateValue }) => (
                      <Tile type={viewOptions.displayAs}>
                        {data.map(
                          (schema, i) =>
                            checkIfNeedsRender(
                              schema,
                              renderType || "CREATE"
                            ) && (
                              <Col
                                key={`${i}-${schema.key}`}
                                style={
                                  viewOptions.displayAs === "GRID" && {
                                    padding: 10,
                                    minWidth: `${Math.floor(
                                      100 / viewOptions.cols
                                    )}%`,
                                  }
                                }
                              >
                                <p>
                                  <b>
                                    {turnKeyIntoLabel(
                                      schema.label || schema.key
                                    )}
                                    :
                                  </b>
                                </p>
                                {renderDetailItem({
                                  idx: `${i}-${schema.key}`,
                                  item: dataForm[schema.key],
                                  error: errors && errors[schema.key],
                                  itemSchema: schema,
                                  disabled: !(creating && !disableCreation),
                                  onChange: (key, value, regex) => {
                                    console.log(key, value, regex);
                                    updateValue({ key, value, regex });
                                  },
                                  onBlur: (key, value) =>
                                    typeof onBlur === "function" &&
                                    key === schema.key &&
                                    onBlur(key, value, dataForm),
                                  imageUploadOptions,
                                  customRenderer:
                                    // !isNil(
                                    //   customRendererForType[schema.type]
                                    // ) &&
                                    // ((props) =>
                                    //   React.cloneElement(
                                    //     customRendererForType[schema.type],
                                    //     props
                                    //   )),
                                    typeof customRendererForType[
                                      schema.type
                                    ] === "function"
                                      ? (props) =>
                                          customRendererForType[schema.type](
                                            props
                                          )
                                      : null,
                                })}
                              </Col>
                            )
                        )}
                      </Tile>
                    )}
                  </Form>
                </Col>
              )}
            </>
          ),
        });
      }
    });

    return (
      <Stepper
        steps={steps}
        status={formHasErrors ? "error" : ""}
        save={() => onSave(dataForm)}
      />
    );
  };

  return (
    <Spin spinning={loading}>
      <Row
        className={`detail-pane ${
          viewOptions.noShadow ? "" : "box-shadow-small"
        } ${viewOptions.noBorder ? "" : "soft-border"}`}
      >
        {!isEmpty(data) || component ? (
          <>
            {!viewOptions.hideToolbar && (
              <Col span={24}>
                <Row justify="space-between" align="middle" className="toolbar">
                  <Col span="auto">
                    <h2>
                      <b>{title || ""}</b>
                      {(editing || creating) && formHasErrors && (
                        <span className="form-error-led">
                          <Led on={true} type="error" />
                          {Localize("FORMS.HAS_ERRORS")}
                        </span>
                      )}
                    </h2>
                  </Col>
                  <Col span="auto">
                    {extra}
                    {editing || creating ? (
                      <>
                        <Button
                          type="link"
                          onClick={() => {
                            setDataForm(data);
                            setFormHasErrors(false);
                            setFormIsCompleted(!isEmpty(data));
                            if (
                              typeof toggleEditMode === "function" &&
                              editing
                            ) {
                              toggleEditMode(!editing);
                            } else if (
                              typeof toggleCreateMode === "function" &&
                              creating
                            ) {
                              toggleCreateMode(!creating);
                            }
                          }}
                        >
                          {Localize("COMMON.CANCEL")}
                        </Button>
                        {!stepper && (
                          <Popconfirm
                            placement="left"
                            title={Localize("CONFIRM.SAVE_EDIT")}
                            onConfirm={() => {
                              if (formIsCompleted && !formHasErrors) {
                                if (editing) {
                                  if (typeof toggleEditMode === "function") {
                                    toggleEditMode(!editing);
                                  }
                                  if (typeof onEdit === "function") {
                                    onEdit(dataForm);
                                  }
                                } else if (creating) {
                                  if (typeof toggleCreateMode === "function") {
                                    toggleCreateMode(!editing);
                                  }
                                  if (typeof onSave === "function") {
                                    onSave(dataForm);
                                  }
                                }
                              }
                            }}
                            disabled={
                              !formIsCompleted || formHasErrors || loading
                            }
                            okText={Localize("COMMON.YES")}
                            cancelText={Localize("COMMON.NO")}
                          >
                            <Button
                              type="primary"
                              disabled={
                                !formIsCompleted || formHasErrors || loading
                              }
                            >
                              <SaveOutlined />
                              {Localize("COMMON.SAVE")}
                            </Button>
                          </Popconfirm>
                        )}
                      </>
                    ) : (
                      <>
                        {!disableEditing && (
                          <Button
                            onClick={() => {
                              typeof toggleEditMode === "function" &&
                                toggleEditMode(!editing) &&
                                setDataForm(data);
                            }}
                            disabled={loading}
                          >
                            <EditOutlined />
                            {Localize("COMMON.EDIT")}
                          </Button>
                        )}
                        {!disableCreation && (
                          <Button
                            type="primary"
                            disabled={loading}
                            onClick={() => {
                              typeof toggleCreateMode === "function" &&
                                toggleCreateMode(!creating);
                            }}
                          >
                            <PlusOutlined />
                            {Localize("COMMON.CREATE_NEW")}
                          </Button>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            )}
            {creating && !stepper && (
              <Col span={24} className="">
                <Form
                  name="form-create"
                  initialValues={dataForm}
                  onChange={({ values, errors, completed }) => {
                    setDataForm({
                      ...dataForm,
                      ...values,
                      ...errors,
                    });
                    // console.log(values, !isEmpty(errors), completed, dataForm);
                    setFormHasErrors(!isEmpty(errors));
                    setFormIsCompleted(completed);
                    typeof onChange === "function" &&
                      onChange({ ...dataForm, ...values, ...errors });
                  }}
                >
                  {({ values, errors, completed, updateValue }) => (
                    <Row
                      className={[
                        "creation-box",
                        [creating ? "open" : "closed"],
                      ].join(" ")}
                    >
                      {entitySchema.map((schema, i) => {
                        if (address && schema.key === "address") {
                          return (
                            <Address
                              style={
                                viewOptions.displayAs === "GRID" && {
                                  padding: "10px",
                                  minWidth: `${Math.floor(
                                    100 / viewOptions.cols
                                  )}%`,
                                }
                              }
                              searchStyle={
                                viewOptions.displayAs === "GRID" && {
                                  padding: "10px",
                                  minWidth: `${Math.floor(
                                    100 / viewOptions.cols
                                  )}%`,
                                }
                              }
                              dataForm={dataForm}
                              onChange={(newdata) => setDataForm(newdata)}
                              searchPlaceholder={Localize(
                                "COMMON.FIND_ADDRESS"
                              )}
                              editing={true}
                              showNote={false}
                            />
                          );
                        }
                        return (
                          checkIfNeedsRender(schema, renderType) && (
                            <Col
                              key={i}
                              style={{
                                padding: 10,
                                minWidth: `${Math.floor(
                                  100 / viewOptions.cols
                                )}%`,
                              }}
                            >
                              <p>
                                <b>
                                  {turnKeyIntoLabel(schema.label || schema.key)}
                                  :
                                </b>
                              </p>
                              {renderDetailItem({
                                idx: i,
                                item: dataForm[schema.key],
                                error: errors && errors[schema.key],
                                itemSchema: schema,
                                disabled: !(creating && !disableCreation),
                                onChange: (key, value, regex) =>
                                  updateValue({ key, value, regex }),
                                onBlur: (key, value) =>
                                  typeof onBlur === "function" &&
                                  key === schema.key &&
                                  onBlur(key, value, dataForm),
                                imageUploadOptions,
                                customRenderer:
                                  // !isNil(customRendererForType[schema.type]) &&
                                  // ((props) =>
                                  //   React.cloneElement(
                                  //     customRendererForType[schema.type],
                                  //     props
                                  //   )),
                                  typeof customRendererForType[schema.type] ===
                                  "function"
                                    ? (props) =>
                                        customRendererForType[schema.type](
                                          props
                                        )
                                    : null,
                              })}
                            </Col>
                          )
                        );
                      })}
                    </Row>
                  )}
                </Form>
              </Col>
            )}
            {creating && stepper && <Col span={24}>{renderStepper()}</Col>}
            {!creating &&
              (!component ? (
                <Col span={24}>
                  <Form
                    name="form-edit"
                    initialValues={dataForm}
                    onChange={({ values, errors, completed }) => {
                      setDataForm({ ...dataForm, ...values, ...errors });
                      setFormHasErrors(!isEmpty(errors));
                      setFormIsCompleted(completed);
                      typeof onChange === "function" &&
                        onChange({ ...dataForm, ...values, ...errors });
                      // console.log(values, !isEmpty(errors), completed);
                    }}
                  >
                    {({ values, errors, completed, updateValue }) => (
                      <Tile type={viewOptions.displayAs}>
                        {entitySchema.map((schema, i) => {
                          if (address && schema.key === "address") {
                            return (
                              <Address
                                style={
                                  viewOptions.displayAs === "GRID" && {
                                    padding: "10px",
                                    minWidth: `${Math.floor(
                                      100 / viewOptions.cols
                                    )}%`,
                                  }
                                }
                                searchStyle={
                                  viewOptions.displayAs === "GRID" && {
                                    padding: "10px",
                                    minWidth: `${Math.floor(
                                      100 / viewOptions.cols
                                    )}%`,
                                  }
                                }
                                dataForm={dataForm}
                                onChange={(newdata) => setDataForm(newdata)}
                                searchPlaceholder={Localize(
                                  "COMMON.FIND_ADDRESS"
                                )}
                                editing={editing}
                                showNote={false}
                              />
                            );
                          }

                          return (
                            <>
                              {checkIfNeedsRender(
                                schema,
                                renderType || "CREATE"
                              ) && (
                                <Col
                                  key={i}
                                  style={
                                    viewOptions.displayAs === "GRID" && {
                                      padding: 10,
                                      minWidth: `${Math.floor(
                                        100 / viewOptions.cols
                                      )}%`,
                                    }
                                  }
                                >
                                  <p>
                                    <b>
                                      {turnKeyIntoLabel(
                                        schema.label || schema.key
                                      )}
                                      :
                                    </b>
                                  </p>
                                  {renderDetailItem({
                                    idx: i,
                                    item: dataForm[schema.key],
                                    // item: dataForm[schema.key] || data[schema.key],
                                    error: errors && errors[schema.key],
                                    itemSchema: schema,
                                    disabled: !(editing && !disableEditing),
                                    onChange: (key, value, regex) =>
                                      updateValue({ key, value, regex }),
                                    onBlur: (key, value) =>
                                      typeof onBlur === "function" &&
                                      key === schema.key &&
                                      onBlur(key, value, dataForm),
                                    imageUploadOptions,
                                    customRenderer:
                                      // !isNil(customRendererForType[schema.type]) &&
                                      // ((props) =>
                                      //   React.cloneElement(
                                      //     customRendererForType[schema.type],
                                      //     props
                                      //   )),
                                      typeof customRendererForType[
                                        schema.type
                                      ] === "function"
                                        ? (props) =>
                                            customRendererForType[schema.type](
                                              props
                                            )
                                        : null,
                                  })}
                                </Col>
                              )}
                            </>
                          );
                        })}
                      </Tile>
                    )}
                  </Form>
                </Col>
              ) : (
                <Col span={24} style={{ padding: 10 }}>
                  {component}
                </Col>
              ))}
          </>
        ) : (
          <>
            <Col span={24} className="toolbar">
              <h2>
                <b>{title || ""}</b>
              </h2>
            </Col>
            <Col span={24}>
              <div style={{ textAlign: "center", opacity: 0.8 }}>
                <EmptyIcon />
                {!loading && (
                  <p style={{ opacity: 0.6 }}>{Localize("COMMON.NO_DATA")}</p>
                )}
              </div>
            </Col>
          </>
        )}
      </Row>
    </Spin>
  );
};
