import axios from "axios";
import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";
import { getFromLocalStorage } from "../libs/helpers/localstorage";

export const searchAutocompleteApi = async ({ headers, key }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/search?key=${key}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const searchCompaniesApi = async ({ headers, data }) => {
  if (data.creation_year) data.creation_year = parseInt(data.creation_year, 10);
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/company`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

let abortController;

export const searchContactsApi = async ({ headers, data }) => {
  if (data.company_filters?.creation_year)
    data.company_filters.creation_year = parseInt(
      data.company_filters.creation_year,
      10
    );
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();

  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/contact`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
      signal: (abortController || {}).signal,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const searchBrandsApi = async ({ headers, params = "" }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.brand.list}${params}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const searchPitchesApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/pitch`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }

  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const searchWorkRelationApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/clc`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getSavedSearchList = async ({ headers, params = "" }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.savedSearch.api}?${params}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getSingleSavedSearch = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.savedSearch.api}/${id}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const createSearch = async ({ headers, body }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.savedSearch.api}`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const deleteSearch = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.savedSearch.api}/${id}`,
    {
      method: "DELETE",
      headers,
    }
  );

  if (res.ok) {
    return;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getPlaceListApi = async ({
  headers,
  type,
  isContactsList = false,
}) => {
  const ls = getFromLocalStorage();
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const token = ls && ls.token;
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/${
      isContactsList ? "contact" : "company"
    }/${type}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "x-tenant": tenant,
        ...headers,
      },
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getValuesApi = async ({ headers, entity, field }) => {
  const ls = getFromLocalStorage();
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const token = ls && ls.token;
  try {
    const res = await axios.get(
      `${Config.apiBaseurl}/${Config.apis.frontend.api}/${entity}/${field}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "x-tenant": tenant,
          ...headers,
        },
      }
    );
    return res.data;
  } catch (e) {
    if (e.response) {
      const { statusText, status } = e.response;
      const { httpStatus, clientType, description } = e.response.data;
      throw new ErrorMessage(
        statusText,
        description,
        httpStatus,
        status,
        clientType
      );
    } else throw e;
  }
};
