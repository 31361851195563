import callApi from "../libs/helpers/callApi";

import {
  getContactListApi,
  getContactDetailApi,
  getContactCompanyApi,
  getContactBrandsApi,
  exportContactListApi,
  exportWorkRelationsApi,
  getFunctionsContactsSummaryApi,
} from "../api/contact.api";
import { getUserInfoAction } from ".";

export const CONTACT_LIST_LOADING = "CONTACT_LIST_LOADING";
export const CONTACT_LIST_LOADED = "CONTACT_LIST_LOADED";
export const CONTACT_LIST_LOAD_ERROR = "CONTACT_LIST_LOAD_ERROR";

export const getContactListAction = callApi({
  apiCall: (p) => getContactListApi(p),
  secured: true,
  startAction: CONTACT_LIST_LOADING,
  successAction: CONTACT_LIST_LOADED,
  errorAction: CONTACT_LIST_LOAD_ERROR,
});

export const CONTACT_DETAIL_LOADING = "CONTACT_DETAIL_LOADING";
export const CONTACT_DETAIL_LOADED = "CONTACT_DETAIL_LOADED";
export const CONTACT_DETAIL_LOAD_ERROR = "CONTACT_DETAIL_LOAD_ERROR";

export const getContactDetailAction = callApi({
  apiCall: (p) => getContactDetailApi(p),
  secured: true,
  startAction: CONTACT_DETAIL_LOADING,
  successAction: CONTACT_DETAIL_LOADED,
  errorAction: CONTACT_DETAIL_LOAD_ERROR,
});

export const CONTACT_COMPANY_LOADING = "CONTACT_COMPANY_LOADING";
export const CONTACT_COMPANY_LOADED = "CONTACT_COMPANY_LOADED";
export const CONTACT_COMPANY_LOAD_ERROR = "CONTACT_COMPANY_LOAD_ERROR";

export const getContactCompanyAction = callApi({
  apiCall: (p) => getContactCompanyApi(p),
  secured: true,
  startAction: CONTACT_COMPANY_LOADING,
  successAction: CONTACT_COMPANY_LOADED,
  errorAction: CONTACT_COMPANY_LOAD_ERROR,
});

export const CONTACT_BRAND_LOADING = "CONTACT_BRAND_LOADING";
export const CONTACT_BRAND_LOADED = "CONTACT_BRAND_LOADED";
export const CONTACT_BRAND_LOAD_ERROR = "CONTACT_BRAND_LOAD_ERROR";

export const getContactBrandsAction = callApi({
  apiCall: (p) => getContactBrandsApi(p),
  secured: true,
  startAction: CONTACT_BRAND_LOADING,
  successAction: CONTACT_BRAND_LOADED,
  errorAction: CONTACT_BRAND_LOAD_ERROR,
});

export const EXPORT_CONTACTS_LOADING = "EXPORT_CONTACTS_LOADING";
export const EXPORT_CONTACTS_LOADED = "EXPORT_CONTACTS_LOADED";
export const EXPORT_CONTACTS_LOAD_ERROR = "EXPORT_CONTACTS_LOAD_ERROR";

export const exportContactsAction = callApi({
  apiCall: (p) => exportContactListApi(p),
  secured: true,
  startAction: EXPORT_CONTACTS_LOADING,
  successAction: EXPORT_CONTACTS_LOADED,
  errorAction: EXPORT_CONTACTS_LOAD_ERROR,
  afterSuccessAction: ({ result, dispatch }) => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(result);
    a.download = "contact-list.xlsx";
    a.click();
    dispatch(getUserInfoAction());
  },
});

export const EXPORT_WORKRELATIONS_LOADING = "EXPORT_WORKRELATIONS_LOADING";
export const EXPORT_WORKRELATIONS_LOADED = "EXPORT_WORKRELATIONS_LOADED";
export const EXPORT_WORKRELATIONS_LOAD_ERROR =
  "EXPORT_WORKRELATIONS_LOAD_ERROR";

export const exportWorkRelationsAction = callApi({
  apiCall: (p) => exportWorkRelationsApi(p),
  secured: true,
  startAction: EXPORT_WORKRELATIONS_LOADING,
  successAction: EXPORT_WORKRELATIONS_LOADED,
  errorAction: EXPORT_WORKRELATIONS_LOAD_ERROR,
  afterSuccessAction: ({ result, dispatch }) => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(result);
    a.download = "work-relations.xlsx";
    a.click();
    dispatch(getUserInfoAction());
  },
});

export const FUNCTIONS_CONTACTS_SUMMARY_LOADING =
  "FUNCTIONS_CONTACTS_SUMMARY_LOADING";
export const FUNCTIONS_CONTACTS_SUMMARY_LOADED =
  "FUNCTIONS_CONTACTS_SUMMARY_LOADED";
export const FUNCTIONS_CONTACTS_SUMMARY_LOAD_ERROR =
  "FUNCTIONS_CONTACTS_SUMMARY_LOAD_ERROR";

export const getFunctionsContactsSummaryAction = callApi({
  apiCall: (p) => getFunctionsContactsSummaryApi(p),
  secured: true,
  startAction: FUNCTIONS_CONTACTS_SUMMARY_LOADING,
  successAction: FUNCTIONS_CONTACTS_SUMMARY_LOADED,
  errorAction: FUNCTIONS_CONTACTS_SUMMARY_LOAD_ERROR,
});

export const CONTACT_DETAIL_MODAL_OPEN = "CONTACT_DETAIL_MODAL_OPEN";
export const CONTACT_DETAIL_MODAL_CLOSE = "CONTACT_DETAIL_MODAL_CLOSE";
export const openContactDetailAction = (id) => async (dispatch, getState) => {
  await getContactDetailAction({ id })(dispatch, getState);
  const detailState = getState().contact.detail;
  if (!detailState.error) {
    dispatch({ type: CONTACT_DETAIL_MODAL_OPEN });
  }
};
