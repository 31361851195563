import {
  Card,
  CardContent,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { getCustomerPortalUrlApi } from "../../api/ecommerce.api";
import BlinkoLogo from "../../design-library/BlinkoLogo";
import Button from "../../design-library/Button/Button.component";
import ThumbsDownIcon from "../../design-library/icons/ThumbsDownIcon.component";
import { l } from "../../libs/localization";

export default function SubscriptionOverduePage() {
  const theme = useTheme();

  async function openCustomerPortal() {
    const res = await getCustomerPortalUrlApi({
      returnTo: "/profile/subscription-info",
    });
    window.location.href = res.data?.url;
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
        py: 8,
        width: "100%",
        maxWidth: "none !important",
        backgroundColor: theme.palette.primary.light,
        minHeight: "100vh",
      }}
    >
      <Card sx={{ width: "680px" }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            px: 5.5,
            py: 7.5,
            alignItems: "center",
            position: "relative",
          }}
        >
          <BlinkoLogo style={{ maxWidth: 180, alignSelf: "center" }} />
          <ThumbsDownIcon sx={{ fontSize: 60 }} />
          <Typography color="error" sx={{ mt: 2.5 }} variant="h3">
            Il tuo abbonamento è sospeso perchè il rinnovo è fallito
          </Typography>
          <Typography variant="body1">
            Correggi i tuoi dati di pagamento per riprendere ad utilizzare il
            tuo abbonamento.
          </Typography>
          <Button onClick={openCustomerPortal}>
            {l("ECOMMERCE.MANAGE_SUBSCRIPTION")}
          </Button>
        </CardContent>
        <div />
      </Card>
    </Container>
  );
}
