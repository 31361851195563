import callApi from "../../libs/helpers/callApi";
import { getTreeDataApi } from "../../api/registry.api";

export const CATEGORY_LOADING = "CATEGORY_LOADING";
export const CATEGORY_LOADED = "CATEGORY_LOADED";
export const CATEGORY_LOAD_ERROR = "CATEGORY_LOAD_ERROR";

// CATEGORY
export const getTreeCategoryAction = callApi({
  apiCall: (p) => getTreeDataApi({ ...p, entity: "Category" }),
  secured: true,
  startAction: CATEGORY_LOADING,
  successAction: CATEGORY_LOADED,
  errorAction: CATEGORY_LOAD_ERROR,
});
