import React from "react";

import "./Led.scss";

export default ({ on = true, size = 10, style, type }) => (
  <span
    style={{ width: size, height: size, ...style }}
    className={["led", on ? "on" : "off", type || "standard"].join(" ")}
  />
);
