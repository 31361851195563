import React from "react";
import { SvgIcon } from "@mui/material";

export default function PhoneIcon(props) {
  return (
    <SvgIcon width="50" height="50" viewBox="0 0 50 50" fill="none" {...props}>
      <g clipPath="url(#clip0_160_1529)">
        <path
          d="M28.4609 0C27.9219 0 27.4844 0.437585 27.4844 0.976753C27.4844 1.51592 27.9219 1.95351 28.4609 1.95351C39.2607 1.95351 48.0469 10.7414 48.0469 21.5433C48.0469 22.0824 48.4844 22.52 49.0234 22.52C49.5625 22.52 50 22.0824 50 21.5433C50 9.66497 40.3379 0 28.4609 0Z"
          fill="#565551"
        />
        <path
          d="M43.1484 22.5199C43.6875 22.5199 44.125 22.0823 44.125 21.5431C44.125 12.9037 37.0977 5.875 28.4609 5.875C27.9219 5.875 27.4844 6.31259 27.4844 6.85175C27.4844 7.39092 27.9219 7.82851 28.4609 7.82851C36.0215 7.82851 42.1719 13.9801 42.1719 21.5431C42.1719 22.0823 42.6094 22.5199 43.1484 22.5199V22.5199Z"
          fill="#565551"
        />
        <path
          d="M36.2969 21.5429C36.2969 22.0821 36.7344 22.5197 37.2734 22.5197C37.8125 22.5197 38.25 22.0821 38.25 21.5429C38.25 16.1444 33.8594 11.7529 28.4619 11.7529C27.9229 11.7529 27.4854 12.1905 27.4854 12.7297C27.4854 13.2689 27.9229 13.7064 28.4619 13.7064C32.7822 13.7064 36.2969 17.2218 36.2969 21.5439V21.5429Z"
          fill="#565551"
        />
        <path
          d="M27.4844 18.6057C27.4844 19.1448 27.9219 19.5824 28.4609 19.5824C29.542 19.5824 30.4219 20.4625 30.4219 21.5437C30.4219 22.0829 30.8594 22.5205 31.3984 22.5205C31.9375 22.5205 32.375 22.0829 32.375 21.5437C32.375 19.3851 30.6191 17.6289 28.4609 17.6289C27.9219 17.6289 27.4844 18.0665 27.4844 18.6057V18.6057Z"
          fill="#565551"
        />
        <path
          d="M18.3037 31.7131C18.3037 32.2522 17.8662 32.6898 17.3271 32.6898C16.7881 32.6898 16.3506 32.2522 16.3506 31.7131C16.3506 31.1739 16.7881 30.7363 17.3271 30.7363C17.8662 30.7363 18.3037 31.1739 18.3037 31.7131Z"
          fill="#565551"
        />
        <mask id="path-6-inside-1_160_1529" fill="white">
          <path d="M26.7606 48.8238C31.2079 51.0039 36.5575 50.0965 40.0731 46.5793L42.0926 44.5593C43.1893 43.4644 43.1893 41.6896 42.0926 40.5927L34.2909 32.7904C33.1961 31.6945 31.4217 31.6926 30.326 32.7904L27.0106 36.1065C26.3612 36.7561 25.3592 36.8879 24.6288 36.422C23.4373 35.6601 22.2782 34.8201 21.1834 33.9244C20.7655 33.5826 20.1512 33.6451 19.8094 34.0622C19.4676 34.4802 19.5301 35.0956 19.9471 35.4365C21.1004 36.38 22.3211 37.2649 23.578 38.0678C25.075 39.0241 27.1004 38.7809 28.3924 37.4876L31.7079 34.1716C32.0389 33.8395 32.576 33.8385 32.909 34.1716L40.7108 41.9748C41.0428 42.3079 41.0438 42.8451 40.7108 43.1782L38.6913 45.1981C35.7616 48.1284 31.3123 48.8805 27.619 47.0696C14.0887 40.4325 6.72641 29.909 2.93247 22.2493C1.11801 18.5865 1.87094 14.153 4.80747 11.2169L6.78305 9.24189C7.11411 8.90979 7.65122 8.90881 7.98422 9.24189L15.787 17.0461C16.117 17.3763 16.1209 17.9145 15.787 18.2476L12.4715 21.5636C11.1795 22.8559 10.9354 24.8817 11.8915 26.379C12.4549 27.261 13.0623 28.1303 13.6971 28.9645C14.0233 29.3933 14.6366 29.4763 15.0653 29.1501C15.494 28.8238 15.578 28.2104 15.2508 27.7807C14.6493 26.9895 14.0721 26.1641 13.537 25.3271C13.0702 24.5955 13.203 23.5933 13.8524 22.9438L17.1668 19.6287C18.2625 18.5337 18.2635 16.759 17.1668 15.6631L9.36508 7.86076C8.27133 6.76484 6.49594 6.76289 5.40122 7.86076L3.42661 9.83575C-0.102689 13.3638 -1.00406 18.7017 1.18247 23.1167C5.1102 31.0479 12.7362 41.9455 26.7606 48.8238V48.8238Z" />
        </mask>
        <path
          d="M26.7606 48.8238C31.2079 51.0039 36.5575 50.0965 40.0731 46.5793L42.0926 44.5593C43.1893 43.4644 43.1893 41.6896 42.0926 40.5927L34.2909 32.7904C33.1961 31.6945 31.4217 31.6926 30.326 32.7904L27.0106 36.1065C26.3612 36.7561 25.3592 36.8879 24.6288 36.422C23.4373 35.6601 22.2782 34.8201 21.1834 33.9244C20.7655 33.5826 20.1512 33.6451 19.8094 34.0622C19.4676 34.4802 19.5301 35.0956 19.9471 35.4365C21.1004 36.38 22.3211 37.2649 23.578 38.0678C25.075 39.0241 27.1004 38.7809 28.3924 37.4876L31.7079 34.1716C32.0389 33.8395 32.576 33.8385 32.909 34.1716L40.7108 41.9748C41.0428 42.3079 41.0438 42.8451 40.7108 43.1782L38.6913 45.1981C35.7616 48.1284 31.3123 48.8805 27.619 47.0696C14.0887 40.4325 6.72641 29.909 2.93247 22.2493C1.11801 18.5865 1.87094 14.153 4.80747 11.2169L6.78305 9.24189C7.11411 8.90979 7.65122 8.90881 7.98422 9.24189L15.787 17.0461C16.117 17.3763 16.1209 17.9145 15.787 18.2476L12.4715 21.5636C11.1795 22.8559 10.9354 24.8817 11.8915 26.379C12.4549 27.261 13.0623 28.1303 13.6971 28.9645C14.0233 29.3933 14.6366 29.4763 15.0653 29.1501C15.494 28.8238 15.578 28.2104 15.2508 27.7807C14.6493 26.9895 14.0721 26.1641 13.537 25.3271C13.0702 24.5955 13.203 23.5933 13.8524 22.9438L17.1668 19.6287C18.2625 18.5337 18.2635 16.759 17.1668 15.6631L9.36508 7.86076C8.27133 6.76484 6.49594 6.76289 5.40122 7.86076L3.42661 9.83575C-0.102689 13.3638 -1.00406 18.7017 1.18247 23.1167C5.1102 31.0479 12.7362 41.9455 26.7606 48.8238V48.8238Z"
          fill="#565551"
          stroke="#565551"
          strokeWidth="4"
          mask="url(#path-6-inside-1_160_1529)"
        />
      </g>
      <defs>
        <clipPath id="clip0_160_1529">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
