import { Paper } from "@mui/material";
import MUIModal from "@mui/material/Modal";
import React from "react";

export default function Modal({ open, onClose, children, ...props }) {
  return (
    <MUIModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...props}
    >
      <Paper
        sx={{
          px: 5.5,
          py: 7.5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2.5,
          maxWidth: 678,
        }}
      >
        {children}
      </Paper>
    </MUIModal>
  );
}
