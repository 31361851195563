import callApi from "../libs/helpers/callApi";

import {
  getCompanyEntityApi,
  getCompanyListApi_old,
  getCompanyDetailApi,
  getCompanyBrandsApi,
  getCompanyNielsenApi,
  getCompanyCreaApi,
  getCompanySocialMediaApi,
  getFinancialsYearsApi,
  getCompanyGroupApi,
  getCompanyContactsSummaryApi,
  exportCompanyListApi,
  getInvestmentRoundApi,
} from "../api/company.api";
import { searchContactsApi, searchPitchesApi } from "../api/search.api";
import { getUserInfoAction, goTo } from ".";

export const COMPANY_LIST_LOADING = "COMPANY_LIST_LOADING";
export const COMPANY_LIST_LOADED = "COMPANY_LIST_LOADED";
export const COMPANY_LIST_LOAD_ERROR = "COMPANY_LIST_LOAD_ERROR";

export const getCompanyListAction = callApi({
  apiCall: (p) => getCompanyListApi_old(p),
  secured: true,
  startAction: COMPANY_LIST_LOADING,
  successAction: COMPANY_LIST_LOADED,
  errorAction: COMPANY_LIST_LOAD_ERROR,
});

export const COMPANY_DETAIL_LOADING = "COMPANY_DETAIL_LOADING";
export const COMPANY_DETAIL_LOADED = "COMPANY_DETAIL_LOADED";
export const COMPANY_DETAIL_LOAD_ERROR = "COMPANY_DETAIL_LOAD_ERROR";

export const getCompanyDetailAction = callApi({
  apiCall: (p) => getCompanyDetailApi(p),
  secured: true,
  startAction: COMPANY_DETAIL_LOADING,
  successAction: COMPANY_DETAIL_LOADED,
  errorAction: COMPANY_DETAIL_LOAD_ERROR,
});

export const COMPANY_CONTACTS_LOADING = "COMPANY_CONTACTS_LOADING";
export const COMPANY_CONTACTS_LOADED = "COMPANY_CONTACTS_LOADED";
export const COMPANY_CONTACTS_LOAD_ERROR = "COMPANY_CONTACTS_LOAD_ERROR";

/*export const getCompanyContactsAction = callApi({
  apiCall: (p) => getCompanyContactsApi(p),
  secured: true,
  startAction: COMPANY_CONTACTS_LOADING,
  successAction: COMPANY_CONTACTS_LOADED,
  errorAction: COMPANY_CONTACTS_LOAD_ERROR,
});*/

export const getCompanyContactsAction = callApi({
  apiCall: (p) => searchContactsApi(p),
  secured: true,
  startAction: COMPANY_CONTACTS_LOADING,
  successAction: COMPANY_CONTACTS_LOADED,
  errorAction: COMPANY_CONTACTS_LOAD_ERROR,
});

export const COMPANY_CONTACTS_SUMMARY_LOADING =
  "COMPANY_CONTACTS_SUMMARY_LOADING";
export const COMPANY_CONTACTS_SUMMARY_LOADED =
  "COMPANY_CONTACTS_SUMMARY_LOADED";
export const COMPANY_CONTACTS_SUMMARY_LOAD_ERROR =
  "COMPANY_CONTACTS_SUMMARY_LOAD_ERROR";

export const getCompanyContactsSummaryAction = callApi({
  apiCall: (p) => getCompanyContactsSummaryApi(p),
  secured: true,
  startAction: COMPANY_CONTACTS_SUMMARY_LOADING,
  successAction: COMPANY_CONTACTS_SUMMARY_LOADED,
  errorAction: COMPANY_CONTACTS_SUMMARY_LOAD_ERROR,
});

export const COMPANY_BRANDS_LOADING = "COMPANY_BRANDS_LOADING";
export const COMPANY_BRANDS_LOADED = "COMPANY_BRANDS_LOADED";
export const COMPANY_BRANDS_LOAD_ERROR = "COMPANY_BRANDS_LOAD_ERROR";

export const getCompanyBrandsAction = callApi({
  apiCall: (p) => getCompanyBrandsApi(p),
  secured: true,
  startAction: COMPANY_BRANDS_LOADING,
  successAction: COMPANY_BRANDS_LOADED,
  errorAction: COMPANY_BRANDS_LOAD_ERROR,
});

export const COMPANY_NIELSEN_LOADING = "COMPANY_NIELSEN_LOADING";
export const COMPANY_NIELSEN_LOADED = "COMPANY_NIELSEN_LOADED";
export const COMPANY_NIELSEN_LOAD_ERROR = "COMPANY_NIELSEN_LOAD_ERROR";

export const getCompanyNielsenAction = callApi({
  apiCall: (p) => getCompanyNielsenApi(p),
  secured: true,
  startAction: COMPANY_NIELSEN_LOADING,
  successAction: COMPANY_NIELSEN_LOADED,
  errorAction: COMPANY_NIELSEN_LOAD_ERROR,
});

export const COMPANY_PITCH_LOADING = "COMPANY_PITCH_LOADING";
export const COMPANY_PITCH_LOADED = "COMPANY_PITCH_LOADED";
export const COMPANY_PITCH_LOAD_ERROR = "COMPANY_PITCH_LOAD_ERROR";

export const getCompanyPitchAction = callApi({
  apiCall: (p) => searchPitchesApi(p),
  secured: true,
  startAction: COMPANY_PITCH_LOADING,
  successAction: COMPANY_PITCH_LOADED,
  errorAction: COMPANY_PITCH_LOAD_ERROR,
});

export const COMPANY_CREA_LOADING = "COMPANY_CREA_LOADING";
export const COMPANY_CREA_LOADED = "COMPANY_CREA_LOADED";
export const COMPANY_CREA_LOAD_ERROR = "COMPANY_CREA_LOAD_ERROR";

export const getCompanyCreaAction = callApi({
  apiCall: (p) => getCompanyCreaApi(p),
  secured: true,
  startAction: COMPANY_CREA_LOADING,
  successAction: COMPANY_CREA_LOADED,
  errorAction: COMPANY_CREA_LOAD_ERROR,
});

export const SOCIAL_MEDIA_LOADING = "SOCIAL_MEDIA_LOADING";
export const SOCIAL_MEDIA_LOADED = "SOCIAL_MEDIA_LOADED";
export const SOCIAL_MEDIA_LOAD_ERROR = "SOCIAL_MEDIA_LOAD_ERROR";

export const getCompanySocialMediaAction = callApi({
  apiCall: (p) => getCompanySocialMediaApi(p),
  secured: true,
  startAction: SOCIAL_MEDIA_LOADING,
  successAction: SOCIAL_MEDIA_LOADED,
  errorAction: SOCIAL_MEDIA_LOAD_ERROR,
});

export const FINANCIALS_YEARS_LOADING = "FINANCIALS_YEARS_LOADING";
export const FINANCIALS_YEARS_LOADED = "FINANCIALS_YEARS_LOADED";
export const FINANCIALS_YEARS_LOAD_ERROR = "FINANCIALS_YEARS_LOAD_ERROR";

export const getFinancialsYearsAction = callApi({
  apiCall: (p) => getFinancialsYearsApi(p),
  secured: true,
  startAction: FINANCIALS_YEARS_LOADING,
  successAction: FINANCIALS_YEARS_LOADED,
  errorAction: FINANCIALS_YEARS_LOAD_ERROR,
});

export const COMPANY_INVESTMENT_ROUND_LOADING =
  "COMPANY_INVESTMENT_ROUND_LOADING";
export const COMPANY_INVESTMENT_ROUND_LOADED =
  "COMPANY_INVESTMENT_ROUND_LOADED";
export const COMPANY_INVESTMENT_ROUND_LOAD_ERROR =
  "COMPANY_INVESTMENT_ROUND_LOAD_ERROR";

export const getCompanyInvestmentRoundAction = callApi({
  apiCall: (p) => getInvestmentRoundApi(p),
  secured: true,
  startAction: COMPANY_INVESTMENT_ROUND_LOADING,
  successAction: COMPANY_INVESTMENT_ROUND_LOADED,
  errorAction: COMPANY_INVESTMENT_ROUND_LOAD_ERROR,
});

export const COMPANY_ENTITY_LOADING = "COMPANY_ENTITY_LOADING";
export const COMPANY_ENTITY_LOADED = "COMPANY_ENTITY_LOADED";
export const COMPANY_ENTITY_LOAD_ERROR = "COMPANY_ENTITY_LOAD_ERROR";

export const getCompanyEntityAction = callApi({
  apiCall: (p) => getCompanyEntityApi(p),
  secured: true,
  startAction: COMPANY_ENTITY_LOADING,
  successAction: COMPANY_ENTITY_LOADED,
  errorAction: COMPANY_ENTITY_LOAD_ERROR,
});

export const COMPANY_GROUP_LOADING = "COMPANY_GROUP_LOADING";
export const COMPANY_GROUP_LOADED = "COMPANY_GROUP_LOADED";
export const COMPANY_GROUP_LOAD_ERROR = "COMPANY_GROUP_LOAD_ERROR";

export const getCompanyGroupAction = callApi({
  apiCall: (p) => getCompanyGroupApi(p),
  secured: true,
  startAction: COMPANY_GROUP_LOADING,
  successAction: COMPANY_GROUP_LOADED,
  errorAction: COMPANY_GROUP_LOAD_ERROR,
});

export const EXPORT_COMPANIES_LOADING = "EXPORT_COMPANIES_LOADING";
export const EXPORT_COMPANIES_LOADED = "EXPORT_COMPANIES_LOADED";
export const EXPORT_COMPANIES_LOAD_ERROR = "EXPORT_COMPANIES_LOAD_ERROR";

export const exportCompaniesAction = callApi({
  apiCall: (p) => exportCompanyListApi(p),
  secured: true,
  startAction: EXPORT_COMPANIES_LOADING,
  successAction: EXPORT_COMPANIES_LOADED,
  errorAction: EXPORT_COMPANIES_LOAD_ERROR,
  afterSuccessAction: ({ result, dispatch }) => {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(result);
    a.download = "company-list.xlsx";
    a.click();
    dispatch(getUserInfoAction());
  },
});

export const openCompanyDetailAction = (id) => async (dispatch, getState) => {
  await getCompanyDetailAction({ id })(dispatch, getState);
  const detailState = getState().company.detail;
  if (detailState.data) {
    dispatch(goTo(`/companies/${id}`));
  }
};
