import React, { useEffect } from "react";
import { Row, Col, Card, Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { orderBy } from "lodash";
import Tree from "../../components/Tree/Tree";
import Localize from "../../libs/localization";
import BannerTop from "../../components/BannerTop/BannerTop";
import Label from "../../components/Label/Label";
import InvestmentForm from "../form/InvestmentForm";
import { getTreeCategoryAction } from "../../actions/registry/registry.category.actions";
import { resetSearchAction } from "../../actions/search.action";
import PitchScoreList from "../../components/List/PitchScore.list";
import PlaceAutocomplete from "../../components/PlaceAutocomplete/PlaceAutocomplete";
import { getTreeSectorAction } from "../../actions/registry/registry.sector.actions";
import useFieldValues from "../../hooks/useFieldValues";
import usePitchScores from "../../hooks/usePitchScores";

const { Panel } = Collapse;

const PitchScoresSearch = () => {
  const dispatch = useDispatch();

  const category = useSelector((state) => state.pitch_category.data);
  const sector = useSelector((state) => state.sector.data);

  const {
    data: pitchScoresData,
    loading: pitchScoresLoading,
    options,
    setOptions,
    filters,
    setFilters,
  } = usePitchScores({
    baseFilters: {
      minPitchScore: 1,
    },
  });

  const [regions, regionsLoading] = useFieldValues({
    entity: "company",
    field: "regions",
  });
  const [scopes, scopesLoading] = useFieldValues({
    entity: "pitch",
    field: "scope",
  });

  useEffect(() => {
    if (sector.length === 0) {
      dispatch(getTreeSectorAction());
    }
  }, [sector]);

  useEffect(() => {
    if (category.length === 0) {
      dispatch(getTreeCategoryAction());
    }
  }, [category]);

  useEffect(() => {
    dispatch(resetSearchAction());
  }, []);

  return (
    <>
      <BannerTop small>
        <h1 className="mt-4 orange-title">
          {Localize("PAGES.PITCH_SCORES.TITLE")}
        </h1>
      </BannerTop>
      <div className="searchPage__filters animation fadein-up slow \">
        <Card
          bordered={false}
          style={{ borderRadius: "10px" }}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          <Row align="middle" justify="start" gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <Label
                className="filter-color"
                value={Localize("PAGES.SEARCH.ORDER.CATEGORY")}
              />
              <Tree
                valueData={filters.pitchCategory}
                translateEntity="Category"
                datatree={orderBy(category, ["key"], ["asc"])}
                onChange={(value) =>
                  setFilters({ ...filters, pitchCategory: value })
                }
                onSelect={(value) =>
                  setFilters({ ...filters, pitchCategory: value })
                }
              />
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Label
                className="filter-color"
                value={Localize("COMMON.REGION")}
              />
              <PlaceAutocomplete
                disableOptions={regionsLoading}
                data={regions?.sort().filter((el) => el !== "")}
                value={filters.companyRegion}
                onChange={(value) => {
                  setFilters({ ...filters, companyRegion: value });
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Label
                className="filter-color"
                value={Localize("PAGES.SEARCH.ORDER.SECTOR")}
              />
              <Tree
                translateEntity="Sector"
                valueData={filters.sector}
                datatree={orderBy(sector, ["key"], ["asc"])}
                onChange={(value) => {
                  setFilters({ ...filters, companySector: value });
                }}
                onSelect={(value) => {
                  setFilters({ ...filters, companySector: value });
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Label
                className="filter-color"
                value={Localize("COMMON.REGION")}
              />
              <PlaceAutocomplete
                disableOptions={scopesLoading}
                data={scopes?.sort().filter((el) => el !== "")}
                value={filters.pitchScope}
                onChange={(value) => {
                  setFilters({ ...filters, pitchScope: value });
                }}
              />
            </Col>
          </Row>
          <Collapse ghost={true} style={{ marginLeft: -15 }}>
            <Panel
              key="1"
              header={Localize("PAGES.SEARCH.ORDER.ADEVERTISING_INVESTMENTS")}
            >
              <InvestmentForm
                onChange={(_, value) =>
                  setFilters({ ...filters, companyNielsen: value })
                }
                dataForm={{ nielsen_filters: filters.companyNielsen }}
              />
            </Panel>
          </Collapse>
        </Card>
      </div>
      <div>
        <Row
          align="middle"
          className="searchPage__orders"
          style={{
            minHeight: 60,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div />
          <div>
            <h2
              style={{ fontWeight: "bold" }}
              className="p-0 m-0 searchPage__orders-button orange-title"
            >
              {Localize("PAGES.PITCH_SCORES.FOUND_RESULTS")}{" "}
              <span className="orange-title">
                {pitchScoresData && pitchScoresData?.pageInfo?.totalResults > 0
                  ? pitchScoresData?.pageInfo?.totalResults
                  : "0"}
              </span>
            </h2>
          </div>
        </Row>
      </div>
      <PitchScoreList
        data={pitchScoresData?.results || []}
        total={pitchScoresData?.pageInfo?.totalResults || 0}
        options={options}
        setOptions={setOptions}
        loadingList={pitchScoresLoading}
      />
    </>
  );
};

export default PitchScoresSearch;
