import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, Button, Collapse, Input, Select } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import DownOutlined from "@ant-design/icons/es/icons/DownOutlined";
import UpOutlined from "@ant-design/icons/es/icons/UpOutlined";
import { isEmpty, orderBy } from "lodash";
import BannerTop from "../../components/BannerTop/BannerTop";
import InvestmentForm from "../form/InvestmentForm";
import Form from "../../components/Form/Form";
import { getTreeSectorAction } from "../../actions/registry/registry.sector.actions";
import Tree from "../../components/Tree/Tree";
import Label from "../../components/Label/Label";
import {
  resetSearchAction,
  searchWorkRelationAction,
} from "../../actions/search.action";
import WorkRelationList from "../../components/List/WorkRelation.list";
import { FilterOutlined } from "@ant-design/icons/es/icons";
import { getCompanyEntityAction } from "../../actions/company.actions";
import { translateCompanyType } from "../../libs/utils";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import { DownloadOutlined } from "@ant-design/icons";
import { exportWorkRelationsAction } from "../../actions/contact.actions";
import { Modal } from "antd";
import Localize from "../../libs/localization";
import { EXPORT_WORKRELATION_PLUGIN } from "../../api/user.api";
import debounce from "lodash/debounce";

const { Panel } = Collapse;
const PAGE_SIZE = 20;
const { confirm } = Modal;
const DEBOUNCE_DELAY = 700;

const WorkRelationSearch = (props) => {
  const {} = props;
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const dispatch = useDispatch();

  const [showOther, setShowOther] = useState(false);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [dataForm, setDataForm] = useState({ company_filters: {} });
  const [companyFilters, setCompanyFilters] = useState({});
  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState("company");
  const [orderDir, setOrderDir] = useState("asc");
  const [allowDownload, setAllowDownload] = useState(false);
  const sector = useSelector((state) => state.sector.data);
  const resultSearch = useSelector((state) => state.search.results.data);
  const loadingSearch = useSelector((state) => state.search.results.loading);
  const companyEntity = useSelector((state) => state.company.entity.schema);
  const loadingExport = useSelector(
    (state) => state.contact.exportList.loading
  );

  useEffect(() => {
    if (sector.length === 0) {
      dispatch(getTreeSectorAction());
    }
  }, [sector]);

  useEffect(() => {
    if (companyEntity && companyEntity.length > 0) {
      companyEntity.forEach((el) => {
        if (el.key === "type")
          setCompanyTypes(
            el.enum.map((type) => ({
              key: type.id,
              value: type.id,
              label: translateCompanyType(type.id),
            }))
          );
      });
    }
  }, [companyEntity]);

  useEffect(() => {
    dispatch(resetSearchAction());
    dispatch(getCompanyEntityAction());
    setAllowDownload(
      props.user.group === "SUPER-ADMIN" ||
        props.user.group === "ADMIN" ||
        props.user.scopes[
          getFromLocalStorage("lefacWebTenant")?.data
        ]?.includes("p_workingRelationsExport:any")
    );
  }, []);

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const debouncedChangeHandler = useMemo(
    () => debounce((formData) => searchRequest(formData), DEBOUNCE_DELAY),
    []
  );

  function handleFormValueChange(formData) {
    setDataForm(formData);
    debouncedChangeHandler(formData);
  }

  const onExportWorkRelations = () => {
    const dataParams = { ...filters };
    delete dataParams.pageNum;
    delete dataParams.limit;
    delete dataParams.sort_direction;
    delete dataParams.sort_key;
    if (props.user.group === "SUPER-ADMIN" || props.user.group === "ADMIN")
      dispatch(exportWorkRelationsAction({ data: dataParams }));
    else {
      const creditData = Object.values(
        props?.user?.licence?.tenants[tenant]?.plugins
      )?.find((plugin) => plugin.name === EXPORT_WORKRELATION_PLUGIN)?.data;
      const numberOfCredits =
        creditData?.contactLimit - parseInt(creditData?.exportedCount);
      const content =
        numberOfCredits > 0
          ? `${Localize(
              "TAG.EXPORT_MODAL_DESCRIPTION"
            )} ${numberOfCredits}. ${Localize("TAG.EXPORT_CONTINUE")}`
          : Localize("ERROR.ERROR_EXPORT_CONTACTS");
      return confirm({
        title: Localize("TAG.EXPORT_MODAL_TITLE"),
        content,
        okButtonProps: { disabled: numberOfCredits <= 0 },
        cancelText: Localize("COMMON.CANCEL"),
        onOk() {
          dispatch(exportWorkRelationsAction({ data: dataParams }));
        },
      });
    }
  };

  const searchRequest = (formInfo) => {
    const dataParams = {};
    Object.keys(formInfo).forEach((p) => {
      if (Array.isArray(formInfo[p])) {
        if (formInfo[p].length > 0) {
          dataParams[p] = formInfo[p];
        }
      } else if (formInfo[p] && formInfo[p].length > 0) {
        dataParams[p] = formInfo[p];
      } else if (formInfo[p] === true) {
        dataParams[p] = formInfo[p];
      }
      delete dataParams.type;
    });

    if (order) {
      dataParams.sort_key = order;
      dataParams.sort_direction = orderDir;
    }

    dataParams.company_filters = {};
    if (!isEmpty(companyFilters)) {
      delete companyFilters.brand_company_agency;
      delete companyFilters.sector;
      delete companyFilters.nielsen_filters;
      const compFilt = {};
      Object.keys(companyFilters).forEach((p) => {
        if (Array.isArray(companyFilters[p])) {
          if (companyFilters[p].length > 0) {
            compFilt[p] = companyFilters[p];
          }
        } else if (companyFilters[p] && companyFilters[p].length > 0) {
          compFilt[p] = companyFilters[p];
        }
      });

      dataParams.company_filters = { ...compFilt };
    }
    setFilters(dataParams);
    dataParams.pageNum = 1;
    dataParams.limit = PAGE_SIZE;
    dispatch(searchWorkRelationAction({ data: dataParams }));
  };

  const handleOrder = (val) => {
    if (val === order) {
      setOrderDir(orderDir === "asc" ? "desc" : "asc");
    } else {
      setOrderDir("asc");
    }
    setOrder(val);
  };

  useEffect(() => {
    searchRequest(dataForm);
  }, [orderDir, order]);

  const orderLabel = (value, key) => {
    return (
      <Button
        type="link"
        className={`${
          order === value ? "selected" : ""
        } searchPage__orders-button`}
        onClick={() => handleOrder(value)}
      >
        {key}
        {order === value && (
          <span className="searchPage__orders-icon">
            {orderDir === "asc" ? <UpOutlined /> : <DownOutlined />}
          </span>
        )}
      </Button>
    );
  };

  return (
    <>
      <BannerTop small>
        <h1 className="mt-4 orange-title">
          {Localize("PAGES.WORK_RELATION.TITLE")}
        </h1>
      </BannerTop>
      <div className="searchPage__filters animation fadein-up slow \">
        <Card
          bordered={false}
          style={{ borderRadius: "10px" }}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          <Form
            initialValues={dataForm}
            onChange={({ values, errors }) => {
              handleFormValueChange({
                ...dataForm,
                ...values,
                ...errors,
              });
              setCompanyFilters({
                ...companyFilters,
                ...values,
                ...errors,
              });
            }}
          >
            {({ updateValue: updateValueParent }) => (
              <>
                <Row gutter={16}>
                  <Col span={8}>
                    <Label
                      className="filter-color"
                      value={Localize("COMMON.SEARCH")}
                    />
                    <Input
                      value={dataForm.brand_company_agency}
                      onChange={(e) =>
                        updateValueParent({
                          key: "brand_company_agency",
                          value: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <Label
                      className="filter-color"
                      value={Localize("PAGES.SEARCH.ORDER.SECTOR")}
                    />
                    <Tree
                      translateEntity="Sector"
                      valueData={dataForm.sector}
                      datatree={orderBy(sector, ["key"], ["asc"])}
                      onChange={(value) => {
                        updateValueParent({ key: "sector", value });
                      }}
                      onSelect={(value, e) => {
                        updateValueParent({ key: "sector", value });
                      }}
                    />
                  </Col>
                  {tenant !== "spain" && (
                    <Col span={8}>
                      <Label
                        className="filter-color"
                        value={Localize("PAGES.SEARCH.ORDER.COMPANY_TYPE")}
                      />
                      <Select
                        style={{ width: "100%" }}
                        allowClear
                        options={orderBy(companyTypes, "label")}
                        defaultValue={dataForm.company_filters.type}
                        onChange={(value, option) => {
                          if (value) {
                            updateValueParent({
                              key: "type",
                              value: option.key,
                            });
                          } else {
                            updateValueParent({ key: "type", value: "" });
                          }
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <Row justify="end" className="mt-3" align="middle">
                  <Col>
                    <Button
                      type="link"
                      onClick={() => setShowOther(!showOther)}
                      className="secondary"
                    >
                      <FilterOutlined className="mr-1" />
                      {Localize(
                        `COMMON.${!showOther ? "OTHER_FILTERS" : "CLOSE"}`
                      )}
                    </Button>
                  </Col>
                </Row>
                {showOther && (
                  <div className="animation fadein-right slow">
                    <Form
                      initialValues={dataForm}
                      onChange={({ values, errors }) => {
                        handleFormValueChange({
                          ...dataForm,
                          ...values,
                          ...errors,
                        });
                      }}
                    >
                      {({ updateValue }) => (
                        <Collapse ghost={true} style={{ marginLeft: "-15px" }}>
                          <Panel
                            key="1"
                            header={Localize(
                              "PAGES.SEARCH.ORDER.ADEVERTISING_INVESTMENTS"
                            )}
                          >
                            <InvestmentForm
                              onChange={(key, value) =>
                                updateValue({ key, value })
                              }
                              dataForm={dataForm}
                            />
                          </Panel>
                        </Collapse>
                      )}
                    </Form>
                  </div>
                )}
              </>
            )}
          </Form>
        </Card>
      </div>
      <Row align="middle" className="searchPage__orders">
        <Col className="orange-title" style={{ fontWeight: "normal" }}>
          {Localize("COMMON.ORDER_BY")}
        </Col>
        <Col>{orderLabel("company", Localize("PAGES.SEARCH.ORDER.NAME"))}</Col>
        <Col flex="auto">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <h2
              style={{ fontWeight: "bold" }}
              className="p-0 m-0 searchPage__orders-button orange-title"
            >
              {Localize("PAGES.SEARCH.WORKRELATIONS_FOUND")}:{" "}
              <span className="orange-title">
                {resultSearch?.workRelations?.totalResults
                  ? resultSearch?.workRelations?.totalResults
                  : 0}
              </span>
            </h2>
            {resultSearch?.workRelations?.totalResults > 0 && allowDownload && (
              <Button
                type="primary"
                className="ml-3 orange-title"
                onClick={onExportWorkRelations}
                loading={loadingExport}
              >
                <DownloadOutlined className="mr-1" />
                {Localize("TAG.EXPORT_WORKRELATIONS")}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <div>
        <WorkRelationList
          data={
            (resultSearch.workRelations && resultSearch.workRelations.data) ||
            []
          }
          total={
            resultSearch.workRelations &&
            resultSearch.workRelations.totalResults
          }
          loadingList={loadingSearch}
          pageSize={PAGE_SIZE}
          filters={filters}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, {})(WorkRelationSearch);
