import callApi from "../../libs/helpers/callApi";
import { getFlatEntityListApi } from "../../api/registry.api";

export const MEDIUM_PLANNING_LOADING = "MEDIUM_PLANNING_LOADING";
export const MEDIUM_PLANNING_LOADED = "MEDIUM_PLANNING_LOADED";
export const MEDIUM_PLANNING_LOAD_ERROR = "MEDIUM_PLANNING_LOAD_ERROR";

// EMPLOYER RANGE GET LIST
export const getMediumPlanningAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "medium_planning" }),
  secured: true,
  startAction: MEDIUM_PLANNING_LOADING,
  successAction: MEDIUM_PLANNING_LOADED,
  errorAction: MEDIUM_PLANNING_LOAD_ERROR,
});
