import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Box, Card, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import BannerTop from "../../components/BannerTop/BannerTop";
import { l } from "../../libs/localization";
import "./profile.style.scss";
import Button from "../../design-library/Button/Button.component";
import { ProfileStep2 } from "../billingAddress/completeBillingInfo.page";
import { editProfileApi } from "../../api/user.api";
import { getUserInfoAction } from "../../actions";

function ProfileBillingInfoPage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);

  const [data, setData] = useState({
    isCompany: false,
    address: {},
    countrySpecificInfo: {
      italy: {},
    },
  });

  useEffect(() => {
    if (user.billingInfo) {
      setData({
        ...data,
        ...user.billingInfo,
        birthDate: user.billingInfo?.birthDate
          ? moment(user.billingInfo.birthDate).format("YYYY-MM-DD")
          : "",
      });
    }
  }, [user]);

  async function submit(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await editProfileApi({
        name: data.firstName,
        surname: data.lastName,
        billingInfo: data,
      });
      dispatch(getUserInfoAction());
      setLoading(false);
      setEditing(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      if (err.response?.data?.clientType) {
        setError(err.response.data.clientType);
      } else {
        setError(err.message);
      }
    }
  }

  return (
    <div className="profilePage">
      <BannerTop small variant="profile">
        <h1 className="mt-4 title">{l("PROFILE.PROFILE")}</h1>
      </BannerTop>
      <form onSubmit={submit}>
        <Card
          sx={{
            p: 3,
            mt: -6,
            position: "relative",
            boxShadow: "0px 4px 10px 0px #00000026",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
          }}
        >
          {error ? (
            <Alert severity="error" sx={{ alignSelf: "stretch" }}>
              {error}
            </Alert>
          ) : null}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 3,
            }}
          >
            <ProfileStep2
              {...{ data, setData }}
              disabled={!editing}
              fieldProps={{ sx: { width: "calc(50% - 12px)" } }}
            />
          </Box>
          <Button
            sx={{ alignSelf: "center" }}
            type="submit"
            onClick={
              editing
                ? () => {}
                : (e) => {
                    e.preventDefault();
                    setEditing(true);
                  }
            }
          >
            {editing ? l("PROFILE.SAVE") : l("PROFILE.EDIT")}
          </Button>
          {loading ? (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.7)",

                zIndex: 5,
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
        </Card>
      </form>
    </div>
  );
}

export default ProfileBillingInfoPage;
