import React, { useEffect, useState } from "react";
import { Card, Box, Alert, CircularProgress, Typography } from "@mui/material";
import { Select, Switch } from "antd";
import { useSelector } from "react-redux";
import BannerTop from "../../components/BannerTop/BannerTop";
import { l } from "../../libs/localization";
import "./profile.style.scss";
import Button from "../../design-library/Button/Button.component";
import { editProfileApi } from "../../api/user.api";
import usePermissions from "../../hooks/usePermissions";
import { getSettingsApi, upsertSettingApi } from "../../api/settings.api";

function CommunicationsSettingsPage({}) {
  const profile = useSelector((state) => state.auth.user);
  const [data, setData] = useState(profile.weeklyUpdateEmailSettings);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const permissions = usePermissions();

  const dataOrDefault = data ||
    profile.weeklyUpdateEmailSettings || { sections: {} };

  const [emailAlertsSettings, setEmailAlertsSettings] = useState(null);

  useEffect(() => {
    async function load() {
      const settings = await getSettingsApi({
        key: "email-alerts.general.",
      });
      const map = {};
      for (let i = 0; i < settings.data.length; i++) {
        map[settings.data[i].key] = settings.data[i];
      }
      const obj = {
        enabled:
          map["email-alerts.general.enabled"]?.data === true ||
          typeof map["email-alerts.general.enabled"] === "undefined",
        frequency: map["email-alerts.general.frequency"]?.data || "weekly",
      };
      setEmailAlertsSettings(obj);
    }
    if (emailAlertsSettings === null && permissions.emailAlerts) {
      load();
    }
  }, [emailAlertsSettings, permissions]);

  async function submit(e) {
    e.preventDefault();
    setLoading(true);
    setError(false);
    async function saveSettings() {
      Object.keys(emailAlertsSettings).forEach((key) => {
        upsertSettingApi({
          key: `email-alerts.general.${key}`,
          data: emailAlertsSettings[key],
        });
      });
    }
    try {
      saveSettings();
      await editProfileApi({
        weeklyUpdateEmailSettings: data,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      if (err.response?.data?.clientType) {
        setError(
          l(
            `PROFILE.MAILER_INTEGRATION.ERROR.${err.response.data.clientType}`
          ) || err.response.data.clientType
        );
      } else {
        setError(err.message);
      }
    }
  }

  return (
    <div className="profilePage">
      <BannerTop small variant="profile">
        <h1 className="mt-4 title">{l("PROFILE.COMMUNICATIONS")}</h1>
      </BannerTop>
      <form onSubmit={submit}>
        <Card
          sx={{
            p: 8,
            mt: "-190px",
            position: "relative",
            boxShadow: "0px 4px 10px 0px #00000026",
            display: "flex",
            gap: 4,
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          {error ? (
            <Alert
              severity="error"
              sx={{
                alignSelf: "stretch",
              }}
            >
              {error}
            </Alert>
          ) : null}

          {permissions.weeklyUpdateEmail ? (
            <Box>
              <Typography
                sx={{
                  fontSize: 22,
                  fontWeight: 500,
                  marginBottom: "40px",
                }}
              >
                {l("PROFILE.COMMUNICATIONS.WEEKLY_UPDATES")}
              </Typography>
              <Box>
                <Typography>
                  {dataOrDefault.enabled
                    ? l("PROFILE.COMMUNICATIONS.WEEKLY_UPDATES.ENABLED")
                    : l("PROFILE.COMMUNICATIONS.WEEKLY_UPDATES.DISABLED")}
                </Typography>
                <Switch
                  checked={dataOrDefault.enabled}
                  onChange={(checked) =>
                    setData({
                      ...dataOrDefault,
                      enabled: checked,
                    })
                  }
                />
              </Box>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  marginBottom: "40px",
                  marginTop: "20px",
                }}
              >
                {l("PROFILE.COMMUNICATIONS.WEEKLY_UPDATES.SECTIONS_TITLE")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                {[
                  "pitches",
                  "creas",
                  "appointments",
                  "companies",
                  "investmentRounds",
                  "acquisitions",
                ].map((section) => (
                  <Box
                    sx={{
                      width: {
                        xs: "60%",
                        sm: "calc(50% - 12px)",
                      },
                    }}
                  >
                    <Typography>
                      {l(
                        `PROFILE.COMMUNICATIONS.WEEKLY_UPDATES.SECTION.${section.toUpperCase()}`
                      )}
                    </Typography>
                    <Switch
                      checked={dataOrDefault.sections[section]}
                      onChange={(checked) =>
                        setData({
                          ...dataOrDefault,
                          sections: {
                            ...dataOrDefault.sections,
                            [section]: checked,
                          },
                        })
                      }
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          ) : null}
          <hr style={{ margin: "40px 0" }} />
          {permissions.emailAlerts ? (
            <Box>
              <Typography
                sx={{
                  fontSize: 22,
                  fontWeight: 500,
                  marginBottom: "40px",
                }}
              >
                {l("PROFILE.COMMUNICATIONS.SPECIFIC_ALERTS")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "60%",
                      sm: "calc(50% - 12px)",
                    },
                  }}
                >
                  <Typography>
                    {dataOrDefault.enabled
                      ? l("COMMON.ENABLED")
                      : l("COMMON.DISABLED")}
                  </Typography>
                  <Switch
                    checked={emailAlertsSettings?.enabled}
                    onChange={(checked) =>
                      setEmailAlertsSettings({
                        ...setEmailAlertsSettings,
                        enabled: checked,
                      })
                    }
                  />
                </Box>
                <Box
                  sx={{
                    width: {
                      xs: "60%",
                      sm: "calc(50% - 12px)",
                    },
                  }}
                >
                  <Typography>
                    {l("PROFILE.COMMUNICATIONS.FREQUENCY")}
                  </Typography>
                  <Select
                    style={{
                      width: 200,
                    }}
                    value={emailAlertsSettings?.frequency}
                    onChange={(v) =>
                      setEmailAlertsSettings({
                        ...emailAlertsSettings,
                        frequency: v,
                      })
                    }
                    options={[
                      {
                        value: "daily",
                        label: l("COMMON.DAILY"),
                      },
                      {
                        value: "weekly",
                        label: l("COMMON.WEEKLY"),
                      },
                    ]}
                  />
                </Box>
              </Box>
            </Box>
          ) : null}

          <Button sx={{ alignSelf: "flex-end" }} type="submit">
            {l("PROFILE.SAVE")}
          </Button>
          {loading ? (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.7)",

                zIndex: 5,
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
        </Card>
      </form>
    </div>
  );
}

export default CommunicationsSettingsPage;
