import log from "loglevel";
import prefixer from "loglevel-plugin-prefix";
import moment from "moment";

import Config from "../../Config";

prefixer.reg(log);
prefixer.apply(log, {
  template: "[%t] %l:",
  timestampFormatter(date) {
    return moment(date).format("YYYY-MM-DD, hh:mm:ss");
  },
});

if (Config.logger) {
  log.enableAll();
  log.info("All log level enabled");
} else {
  log.setLevel("error");
}
log.noConflict();

export default log;
