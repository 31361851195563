import React from "react";
import { Card, Row, Tag, Col } from "antd";
import { Link } from "react-router-dom";
import Localize from "../Localized/Localize.comp";
import "./Card.scss";
import { toUpper } from "lodash";
import { translateRegistryOnView } from "../../libs/utils";

const CardAgencies = (props) => {
  const { data } = props;

  return (
    <Card
      title={
        <h2>
          <b>{Localize("PAGES.COMPANIES.DETAIL.SECTIONS.AGENCIES")}</b>
        </h2>
      }
      bordered={false}
      className="companyPage__body-card companyPage__body-card--custom"
    >
      {data.agencies.map((ag, index) => (
        <Row
          gutter={10}
          key={index}
          style={{ borderBottom: "1px solid #CCC" }}
          align="middle"
        >
          <Col span={12}>
            <Link to={`/companies/${ag.id}`}>{toUpper(ag.name)}</Link>
          </Col>
          <Col span={12}>
            {ag.categories.map((c, i) => (
              <Tag className="my-2" key={c.categoryId}>
                {translateRegistryOnView(c.categoryId, "Category") ||
                  c.categoryName}
              </Tag>
            ))}
          </Col>
        </Row>
      ))}
    </Card>
  );
};

export default CardAgencies;
