import React, { useEffect, useRef, useState } from "react";
import { TreeSelect } from "antd";
import { forEach, isNil, isEqual, includes } from "lodash";
import { translateRegistryOnView } from "../../libs/utils";

const { SHOW_PARENT } = TreeSelect;

const Tree = (props) => {
  const {
    datatree,
    disabled,
    valueData,
    onChange,
    translateEntity,
    placeholder = "__",
    treeCheckable = true,
    onSelect = false,
  } = props;

  const [value, setValue] = useState(undefined);
  const [data, setData] = useState([]);
  const [disabledEl, setDisabledEl] = useState(null);

  const onTreeChange = (value, e) => {
    setValue(value);
    if (typeof onSelect === "function") {
      onSelect(value, e);
    } else {
      onChange(e);
    }
  };

  const recursiveData = (array) => {
    const result = [];
    forEach(array, (el, k) => {
      const { key: title, _id: value, path, children } = el;
      if (el.active) {
        result.push({
          title: translateRegistryOnView(value, translateEntity)
            ? translateRegistryOnView(value, translateEntity)
            : title,
          value,
          key: value,
          path,
          children:
            children && children.length > 0 ? recursiveData(children) : [],
        });
      }
    });
    return result;
  };

  const disabledList = [];
  /* const checkDisabled = (array) => {
    if (array.length > 0) {
      forEach(array, (el, k) => {
        const { _id: value, children } = el;
        if (!el.active) {
          disabledList.push(value);
        }
        if (children && children.length > 0) {
          checkDisabled(children);
        }
      });
      return disabledList;
    }
    return null;
  };*/

  useEffect(() => {
    if (datatree && data && data.length == 0) {
      const result = recursiveData(datatree);
      if (result.length > 0) {
        setData(result);
      }
    }
  }, [datatree]);

  // const prevValue = useRef();
  /*  useEffect(() => {
    if (
      (!isNil(valueData) && !value) ||
      (!isEqual(prevValue.current, valueData) )
    ) {
      const finalVal = [];
      forEach(valueData, (v) => {
        finalVal.push(v);
      });
      prevValue.current = valueData;

      onTreeChange(finalVal);
    }
  }, [valueData, value]);*/

  const tProps = {
    treeData: data,
    value: valueData,
    onChange: onTreeChange,
    treeCheckable,
    showCheckedStrategy: SHOW_PARENT,
    placeholder,
    style: {
      width: "100%",
    },
  };

  return (
    <>
      {data.length > 0 ? <TreeSelect {...tProps} disabled={disabled} /> : null}
    </>
  );
};

export default Tree;
