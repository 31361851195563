import callApi from "../libs/helpers/callApi";
import {
  createCommentApi,
  deleteCommentApi,
  editCommentApi,
  getCommentListApi,
} from "../api/comment.api";

export const COMMENT_LIST_LOADING = "COMMENT_LIST_LOADING";
export const COMMENT_LIST_LOADED = "COMMENT_LIST_LOADED";
export const COMMENT_LIST_LOAD_ERROR = "COMMENT_LIST_LOAD_ERROR";

export const getCommentListAction = callApi({
  apiCall: (p) => getCommentListApi(p),
  secured: true,
  startAction: COMMENT_LIST_LOADING,
  successAction: COMMENT_LIST_LOADED,
  errorAction: COMMENT_LIST_LOAD_ERROR,
});

export const CREATE_COMMENT_LOADING = "CREATE_COMMENT_LOADING";
export const CREATE_COMMENT_LOADED = "CREATE_COMMENT_LOADED";
export const CREATE_COMMENT_LOAD_ERROR = "CREATE_COMMENT_LOAD_ERROR";

export const createCommentAction = callApi({
  apiCall: (p) => createCommentApi(p),
  secured: true,
  startAction: CREATE_COMMENT_LOADING,
  successAction: CREATE_COMMENT_LOADED,
  errorAction: CREATE_COMMENT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params }) => {
    const { entityId, entityType } = params.data;
    dispatch(
      getCommentListAction({
        params: { entityId, entityType },
      })
    );
  },
});

export const EDIT_COMMENT_LOADING = "EDIT_COMMENT_LOADING";
export const EDIT_COMMENT_LOADED = "EDIT_COMMENT_LOADED";
export const EDIT_COMMENT_LOAD_ERROR = "EDIT_COMMENT_LOAD_ERROR";

export const editCommentAction = callApi({
  apiCall: (p) => editCommentApi(p),
  secured: true,
  startAction: EDIT_COMMENT_LOADING,
  successAction: EDIT_COMMENT_LOADED,
  errorAction: EDIT_COMMENT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params }) => {
    const { entityId, entityType } = params.data;
    dispatch(
      getCommentListAction({
        params: { entityId, entityType },
      })
    );
  },
});

export const DELETE_COMMENT_LOADING = "DELETE_COMMENT_LOADING";
export const DELETE_COMMENT_LOADED = "DELETE_COMMENT_LOADED";
export const DELETE_COMMENT_LOAD_ERROR = "DELETE_COMMENT_LOAD_ERROR";

export const deleteCommentAction = callApi({
  apiCall: (p) => deleteCommentApi(p),
  secured: true,
  startAction: DELETE_COMMENT_LOADING,
  successAction: DELETE_COMMENT_LOADED,
  errorAction: DELETE_COMMENT_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params }) => {
    const { entityId, entityType } = params;
    dispatch(
      getCommentListAction({
        params: { entityId, entityType },
      })
    );
  },
});
