import React from "react";
import { SvgIcon } from "@mui/material";

export default function EmailSentIcon(props) {
  return (
    <SvgIcon width="60" height="50" viewBox="0 0 60 50" fill="none" {...props}>
      <g clipPath="url(#clip0_65_1440)">
        <path
          d="M8.04258 24.375H1.17188C0.525 24.375 0 24.9 0 25.5469C0 26.1938 0.525 26.7188 1.17188 26.7188H8.04258C8.68945 26.7188 9.21445 26.1938 9.21445 25.5469C9.21445 24.9 8.68945 24.375 8.04258 24.375Z"
          fill="#565551"
        />
        <path
          d="M11.5371 24.375H11.5195C10.8727 24.375 10.3477 24.9 10.3477 25.5469C10.3477 26.1938 10.8727 26.7188 11.5195 26.7188H11.5371C12.184 26.7188 12.709 26.1938 12.709 25.5469C12.709 24.9 12.184 24.375 11.5371 24.375Z"
          fill="#565551"
        />
        <path
          d="M4.92427 12.0703H1.1731C0.526221 12.0703 0.0012207 12.5953 0.0012207 13.2422C0.0012207 13.8891 0.526221 14.4141 1.1731 14.4141H4.92427C5.57114 14.4141 6.09614 13.8891 6.09614 13.2422C6.09614 12.5953 5.57114 12.0703 4.92427 12.0703Z"
          fill="#565551"
        />
        <path
          d="M13.1848 12.0703H8.75159C8.10471 12.0703 7.57971 12.5953 7.57971 13.2422C7.57971 13.8891 8.10471 14.4141 8.75159 14.4141H13.1848C13.8317 14.4141 14.3567 13.8891 14.3567 13.2422C14.3567 12.5953 13.8317 12.0703 13.1848 12.0703Z"
          fill="#565551"
        />
        <path
          d="M15.736 30.8191H9.17346C8.52659 30.8191 8.00159 31.3441 8.00159 31.991C8.00159 32.6379 8.52659 33.1629 9.17346 33.1629H15.736C16.3828 33.1629 16.9078 32.6379 16.9078 31.991C16.9078 31.3441 16.3828 30.8191 15.736 30.8191Z"
          fill="#565551"
        />
        <path
          d="M20.8606 38.3191H4.33716C3.69028 38.3191 3.16528 38.8441 3.16528 39.491C3.16528 40.1379 3.69028 40.6629 4.33716 40.6629H20.8606C21.5075 40.6629 22.0325 40.1379 22.0325 39.491C22.0325 38.8441 21.5075 38.3191 20.8606 38.3191Z"
          fill="#565551"
        />
        <path
          d="M59.6566 28.5352L48.8566 17.7352L42.0503 10.9289L31.4648 0.343361C31.0078 -0.11367 30.2648 -0.11367 29.8078 0.343361L11.1257 19.0254C10.6687 19.4836 10.6687 20.2254 11.1257 20.6824L39.3175 48.8742C39.5378 49.0945 39.8355 49.2176 40.146 49.2176C40.4566 49.2176 40.7554 49.0945 40.9746 48.8742L59.6566 30.1922C59.8769 29.9719 59.9999 29.6742 59.9999 29.3637C59.9999 29.0531 59.8769 28.7543 59.6566 28.5352ZM29.7082 3.7582V18.6832H14.7832L29.7082 3.7582ZM38.9742 45.2168L14.7832 21.0258H29.7082V24.8484C29.7082 27.8496 32.1503 30.2918 35.1515 30.2918H38.9742V45.2168ZM35.1515 27.9492C33.4417 27.9492 32.0519 26.5582 32.0519 24.8496V4.24453L39.7253 11.918L47.1152 19.3078L47.3917 19.5844L47.6683 19.8609L55.7542 27.9469H35.1492L35.1515 27.9492ZM41.3179 45.218V30.293H56.2417L41.3179 45.218Z"
          fill="#565551"
        />
      </g>
      <defs>
        <clipPath id="clip0_65_1440">
          <rect width="60" height="49.2176" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
