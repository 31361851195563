import React, { useEffect } from "react";
import { Col, DatePicker, InputNumber, Row, Select } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { orderBy } from "lodash";
import Label from "../../components/Label/Label";
import { getIncomeRangeAction } from "../../actions/registry/registry.income_range.actions";
import { getEmployerRangeAction } from "../../actions/registry/registry.employer_range.actions";
import { getInternationalInvestAction } from "../../actions/registry/registry.international_invest.actions";
import { getMediumPlanningAction } from "../../actions/registry/registry.medium_planning.actions";
import Localize from "../../components/Localized/Localize.comp";
import { translateRegistryOnView } from "../../libs/utils";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import country from "../../assets/json/country.json";
import usePermissions from "../../hooks/usePermissions";

const tenant = getFromLocalStorage("lefacWebTenant")?.data;

const InfoForm = (props) => {
  const { dataForm, onChange, isHR } = props;

  const incomeRange = useSelector((state) => state.income_range.data);
  const employerRange = useSelector((state) => state.employer_range.data);
  const internationalInvest = useSelector(
    (state) => state.international_invest.data
  );
  const mediumPlanning = useSelector((state) => state.medium_planning.data);
  const dispatch = useDispatch();

  const permissions = usePermissions();

  useEffect(() => {
    if (incomeRange.length === 0) {
      dispatch(getIncomeRangeAction());
    }
  }, [incomeRange]);

  useEffect(() => {
    if (employerRange.length === 0) {
      dispatch(getEmployerRangeAction());
    }
  }, [employerRange]);

  useEffect(() => {
    if (internationalInvest.length === 0) {
      dispatch(getInternationalInvestAction());
    }
  }, [internationalInvest]);

  useEffect(() => {
    if (mediumPlanning.length === 0) {
      dispatch(getMediumPlanningAction());
    }
  }, [mediumPlanning]);

  const internationalInvestOptions = orderBy(internationalInvest.set, "id")
    .filter((e) => e.id.length > 0)
    .map((o) => {
      return {
        value: o.id,
        label: translateRegistryOnView(o.id, "international_invest", true)
          ? translateRegistryOnView(o.id, "international_invest", true)
          : o.id,
        key: o.id,
      };
    });

  return (
    <Row gutter={16}>
      <Col span={8}>
        <Label
          className="filter-color"
          value={Localize("PAGES.SEARCH.ORDER.CREATION_YEAR")}
        />
        <DatePicker
          style={{ width: "100%" }}
          placeholder=""
          value={dataForm.creation_year && moment(dataForm.creation_year)}
          onChange={(value) => {
            onChange("creation_year", value?.format("YYYY"));
          }}
          picker="year"
        />
      </Col>
      <Col span={8}>
        <Label
          className="filter-color"
          value={Localize("PAGES.SEARCH.ORDER.NATION_ORIGIN")}
        />
        <Select
          mode="multiple"
          allowClear={true}
          style={{ width: "100%" }}
          value={dataForm.nation_orig}
          onChange={(value) => onChange("nation_orig", value)}
          showSearch
          filterOption={(input, option) =>
            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={
            country &&
            country.map((c) => {
              return {
                value: c.code,
                label: c.name,
              };
            })
          }
        />
      </Col>
      {tenant !== "spain" && (
        <Col span={8}>
          <Label
            className="filter-color"
            value={Localize("PAGES.SEARCH.ORDER.STORE_NUMBER")}
          />
          <Row>
            <Row align="bottom">
              <b className="mr-2 filter-color">{Localize("COMMON.FROM")}</b>
              <InputNumber
                value={dataForm.min_store_number}
                onChange={(value) => onChange("min_store_number", value)}
              />
            </Row>
            <Row align="bottom">
              <b className="mx-2 filter-color">{Localize("COMMON.TO")}</b>
              <InputNumber
                value={dataForm.max_store_number}
                onChange={(value) => onChange("max_store_number", value)}
              />
            </Row>
          </Row>
        </Col>
      )}
      <Col span={8} className={tenant !== "spain" ? "mt-3" : ""}>
        <Label
          className="filter-color"
          value={Localize("PAGES.SEARCH.ORDER.INCOME_RANGE")}
        />
        <Select
          mode="multiple"
          allowClear={true}
          style={{ width: "100%" }}
          value={dataForm.income_range}
          onChange={(value) => onChange("income_range", value)}
          showSearch
          options={
            incomeRange.enum &&
            orderBy(incomeRange.enum, "id")
              .filter((e) => e.id.length > 0)
              .map((c) => {
                return {
                  value: c.id,
                  label: translateRegistryOnView(c.id, "income_range", true)
                    ? translateRegistryOnView(c.id, "income_range", true)
                    : c.id,
                  key: c.id,
                };
              })
          }
        />
      </Col>
      <Col span={8} className="mt-3">
        <Label
          className="filter-color"
          value={Localize("PAGES.SEARCH.ORDER.EMPLOYER_RANGE")}
        />
        <Select
          mode="multiple"
          allowClear={true}
          style={{ width: "100%" }}
          value={dataForm.employer_range}
          onChange={(value) => onChange("employer_range", value)}
          showSearch
          options={
            employerRange.enum &&
            orderBy(employerRange.enum, "id")
              .filter((e) => e.id.length > 0)
              .map((o) => {
                return {
                  value: o.id,
                  label: translateRegistryOnView(o.id, "employer_range", true)
                    ? translateRegistryOnView(o.id, "employer_range", true)
                    : o.id,
                  key: o.id,
                };
              })
          }
        />
      </Col>
      {tenant !== "spain" && (
        <Col span={8} className="mt-3">
          <Label
            className="filter-color"
            value={Localize("PAGES.SEARCH.ORDER.INTERNATIONAL_INVEST")}
          />
          <Select
            mode="multiple"
            allowClear={true}
            style={{ width: "100%" }}
            value={dataForm.international_invest}
            onChange={(value) => {
              let obj;
              if (value.includes("all")) {
                obj = internationalInvestOptions.map((o) => {
                  return o.value;
                });
              } else if (value.includes("none")) {
                obj = [];
              } else {
                obj = value;
              }
              onChange("international_invest", obj);
            }}
            showSearch
            options={[
              {
                label:
                  dataForm.international_invest?.length ===
                  internationalInvestOptions?.length
                    ? "---DESELECT ALL---"
                    : "---SELECT ALL---",
                value:
                  dataForm.international_invest?.length ===
                  internationalInvestOptions?.length
                    ? "none"
                    : "all",
              },
              ...(internationalInvest.set ? internationalInvestOptions : []),
            ]}
          />
        </Col>
      )}

      {permissions.mediaPlanning && (
        <Col span={8} className="mt-3">
          <Label
            className="filter-color"
            value={Localize("PAGES.SEARCH.ORDER.MEDIUM_PLANNIG")}
          />
          <Select
            mode="multiple"
            allowClear={true}
            style={{ width: "100%" }}
            value={dataForm.medium_planning}
            onChange={(value) => onChange("medium_planning", value)}
            showSearch
            options={
              mediumPlanning.enum &&
              orderBy(mediumPlanning.enum, "id")
                .filter((e) => e.id.length > 0)
                .map((o) => {
                  return {
                    value: o.id,
                    label: translateRegistryOnView(
                      o.id,
                      "medium_planning",
                      true
                    )
                      ? translateRegistryOnView(o.id, "medium_planning", true)
                      : o.id,
                    key: o.id,
                  };
                })
            }
          />
        </Col>
      )}
    </Row>
  );
};

export default InfoForm;
