import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  FUNCTION_LOADING,
  FUNCTION_LOADED,
  FUNCTION_LOAD_ERROR,
  FUNCTION_CREATE_LOADING,
  FUNCTION_CREATE_LOAD_ERROR,
  FUNCTION_EDIT_LOADING,
  FUNCTION_EDIT_COMPLETE,
  FUNCTION_EDIT_LOAD_ERROR,
  FUNCTION_DELETE_LOADING,
  FUNCTION_DELETE_COMPLETE,
  FUNCTION_DELETE_LOAD_ERROR,
  FUNCTION_CREATE_COMPLETE,
} from "../../actions/registry/registry.function.actions";

const reducer = {
  /*
   * FUNCTION LIST
   */
  [FUNCTION_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [FUNCTION_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [FUNCTION_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
