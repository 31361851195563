import { getLegacyPlanConfigurations } from "./ecommerce/legacyPlans";

export const ES_ADVANCE = "ES_ADVANCE";
export const ES_PRO = "ES_PRO";
export const PLAN_ADVANCE_MONTHLY_V2 = "PLAN_ADVANCE_MONTHLY_V2";
export const PLAN_ADVANCE_YEARLY_V2 = "PLAN_ADVANCE_YEARLY_V2";
export const PLAN_PRO_MONTHLY_V2 = "PLAN_PRO_MONTHLY_V2";
export const PLAN_PRO_YEARLY_V2 = "PLAN_PRO_YEARLY_V2";
export const PLAN_ADV_MONTHLY_V2 = "PLAN_ADV_MONTHLY_V2";
export const PLAN_ADV_YEARLY_V2 = "PLAN_ADV_YEARLY_V2";

export const getPlanConfigurations = (tenant) => ({
  ...(["italy", "france", "uk"].includes(tenant)
    ? {
        [PLAN_ADVANCE_MONTHLY_V2]: {
          id: PLAN_ADVANCE_MONTHLY_V2,
          priceId: window.config?.priceIds?.advanceMonthlyV2,
          planName: "Blinko Advance",
          price: 99,
          period: "month",
          companyCredits: 120,
          contactCredits: 120,
        },
        [PLAN_ADVANCE_YEARLY_V2]: {
          id: PLAN_ADVANCE_YEARLY_V2,
          priceId: window.config?.priceIds?.advanceYearlyV2,
          planName: "Blinko Advance",
          price: 1000,
          period: "year",
          companyCredits: 120 * 12,
          contactCredits: 120 * 12,
        },
        [PLAN_PRO_MONTHLY_V2]: {
          id: PLAN_PRO_MONTHLY_V2,
          priceId: window.config?.priceIds?.proMonthlyV2,
          planName: "Blinko Pro",
          price: 159,
          period: "month",
          companyCredits: 250,
          contactCredits: 250,
        },
        [PLAN_PRO_YEARLY_V2]: {
          id: PLAN_PRO_YEARLY_V2,
          priceId: window.config?.priceIds?.proYearlyV2,
          planName: "Blinko Pro",
          price: 1500,
          period: "year",
          companyCredits: 250 * 12,
          contactCredits: 250 * 12,
        },
        [PLAN_ADV_MONTHLY_V2]: {
          id: PLAN_ADV_MONTHLY_V2,
          priceId: window.config?.priceIds?.advMonthly,
          planName: "Blinko Adv",
          price: 249,
          period: "month",
          companyCredits: 250,
          contactCredits: 250,
        },
        [PLAN_ADV_YEARLY_V2]: {
          id: PLAN_ADV_YEARLY_V2,
          priceId: window.config?.priceIds?.advYearly,
          planName: "Blinko Adv",
          price: 2390,
          period: "year",
          companyCredits: 250 * 12,
          contactCredits: 250 * 12,
        },
      }
    : {}),
  ...(tenant === "spain"
    ? {
        [ES_ADVANCE]: {
          id: ES_ADVANCE,
          priceId: "TODO",
          planName: "Blinko Advance",
          price: 1000,
          period: "year",
          companyCredits: 1500,
          contactCredits: 1500,
          prodId: "TODO",
        },
        [ES_PRO]: {
          id: ES_PRO,
          priceId: "TODO",
          planName: "Blinko Pro",
          price: 1500,
          period: "year",
          companyCredits: 2000,
          contactCredits: 2000,
          prodId: "TODO",
        },
      }
    : {}),
  ...getLegacyPlanConfigurations(tenant),
});

export const PLAN_CONFIGURATIONS = getPlanConfigurations("italy");

export function isBillingInfoComplete(billingInfo = {}) {
  const incompleteFields = [];
  if (!billingInfo.firstName) incompleteFields.push("firstName");
  if (!billingInfo.lastName) incompleteFields.push("lastName");
  if (!billingInfo.birthDate) incompleteFields.push("birthDate");
  if (!billingInfo.phoneNumber) incompleteFields.push("phoneNumber");

  if (billingInfo.isCompany) {
    if (!billingInfo.administrationEmail)
      incompleteFields.push("administrationEmail");
    if (!billingInfo.countrySpecificInfo?.italy?.codiceFiscale)
      incompleteFields.push("countrySpecificInfo.italy.codiceFiscale");
    if (!billingInfo.address) incompleteFields.push("address");
    else {
      if (!billingInfo.address.street) incompleteFields.push("address.street");
      if (!billingInfo.address.city) incompleteFields.push("address.city");
      if (!billingInfo.address.province)
        incompleteFields.push("address.province");
      if (!billingInfo.address.region) incompleteFields.push("address.region");
      if (!billingInfo.address.country)
        incompleteFields.push("address.country");
    }
    // if (!billingInfo.countrySpecificInfo?.italy?.pec)
    //   incompleteFields.push("countrySpecificInfo.italy.pec");

    // if (!billingInfo.countrySpecificInfo?.italy?.sdi)
    //   incompleteFields.push("countrySpecificInfo.italy.sdi");
    if (!billingInfo.function) incompleteFields.push("function");
  }
  return {
    complete: incompleteFields.length === 0,
    incompleteFields,
  };
}
