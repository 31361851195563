import React, { useEffect } from "react";
import { Avatar, Row, Col, Card } from "antd";

import "./contact.scss";
import LinkedinOutlined from "@ant-design/icons/es/icons/LinkedinOutlined";

import { groupBy, orderBy, toUpper, omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Localize from "../../components/Localized/Localize.comp";
import CardAddress from "../../components/Cards/Card.address";
import DetailPane from "../../components/DetailPane/DetailPane";
import Tabs from "../../components/Tabs/Tabs";
import CardContacts from "../../components/Cards/Card.contacts";

import BannerTop from "../../components/BannerTop/BannerTop";
import CompanyAutocomplete from "../../components/CompanyAutocomplete/CompanyAutocomplete";

import { ReactComponent as Xing } from "../../assets/icons/icons8-xing.svg";
import { getContactDetailAction } from "../../actions/contact.actions";

const mock = require("../../mock/contact");

const xingIconStyle = {
  width: "28px",
  border: "2px solid white",
  borderRadius: "3px",
  marginTop: "4px",
  height: "28px",
};

const Contact = (props) => {
  const { match } = props;

  const data = useSelector((state) => state.contact.detail.data);
  const schema = useSelector((state) => state.contact.detail.schema);
  const dispatch = useDispatch();

  useEffect(() => {
    if (match.params.id) {
      dispatch(getContactDetailAction({ id: match.params.id }));
    }
  }, [match.params.id]);

  const sections = schema ? groupBy(orderBy(schema, "order"), "section") : {};

  const omitKey = ["active", "title", "company"];

  return (
    <div className="contactPage">
      {data && (
        <>
          <div className="contactPage__head profile__container">
            <BannerTop>
              <Row className="profile__container--left" align="middle">
                {data.profile_img && (
                  <div className="profile__avatar">
                    <Avatar size={90} src={data.profile_img} />
                  </div>
                )}

                <div className="profile__data">
                  <h2>
                    {data.surname} {data.name}
                  </h2>
                  <div>
                    {data.function} presso {data.company.name}
                  </div>
                </div>
              </Row>
              <Row className="profile__container--right" align="middle">
                {data.linkedin && data.linkedin.length > 0 && (
                  <a href={data.linkedin} target="_blank">
                    <LinkedinOutlined />
                  </a>
                )}
                {data.xing && data.xing.length > 0 && (
                  <a href={data.xing} target="_blank">
                    <Xing style={xingIconStyle} />
                  </a>
                )}
              </Row>
            </BannerTop>
          </div>
          <Col span={24} className="contactPage__body">
            <Tabs
              options={{
                onTabClick: () => {
                  window.scrollTo({
                    behavior: "smooth",
                    left: 0,
                    top: 0,
                  });
                },
                animated: true,
              }}
            >
              <div title="Generale">
                <Row gutter={16}>
                  {/*left col*/}
                  <Col span={16}>
                    {Object.keys(sections).map((s, i) => {
                      if (
                        s === "address" ||
                        s === "db_info" ||
                        s === "contact"
                      ) {
                        return null;
                      }
                      return (
                        <span className="contactPage__body-card">
                          <DetailPane
                            key={i}
                            title={Localize(
                              `PAGES.CONTACTS.DETAIL.SECTIONS.GENERAL.${toUpper(
                                s
                              )}`
                            )}
                            data={omit(data, omitKey)}
                            entitySchema={sections[s]}
                            loading={false}
                            editing={false}
                            disableEditing={true}
                            disableCreation={true}
                            viewOptions={false}
                            address={false}
                            renderType="CONTACT"
                            customRendererForType={{
                              Company: (props, token) =>
                                CompanyAutocomplete({ ...props, token }),
                            }}
                          />
                        </span>
                      );
                    })}
                  </Col>
                  <Col span={8}>
                    <CardContacts data={data} />
                    <CardAddress data={omit(data, omitKey)} />;
                  </Col>
                </Row>
              </div>
            </Tabs>
          </Col>
        </>
      )}
    </div>
  );
};

export default Contact;
