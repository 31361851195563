import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ResponsiveLine } from "@nivo/line";
import { Col, Row } from "antd";
import Localize from "../../libs/localization";

var SI_SYMBOL = ["€", "k€", "M€", "B€", "T€", "P€", "E€"];

function abbreviateNumber(number) {
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  // if (tier == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1).replace(".", ",") + suffix;
}

const FinancialGraph = ({ data, showPersonnelCost }) => {
  const [dataSet, setDataSet] = useState();
  const [employeesDataSet, setEmployeesDataSet] = useState();
  const [personnelCostDataSet, setPersonnelCostDataSet] = useState();

  useEffect(() => {
    let revenueData = [];
    let incomeData = [];
    let employeesData = [];
    let personnelCostData = [];
    data &&
      data.forEach((element) => {
        revenueData.push({
          x: element.year,
          y: element.revenue ? element.revenue : 0,
        });
        incomeData.push({
          x: element.year,
          y: element.operatingResult ? element.operatingResult : 0,
        });
        employeesData.push({
          x: element.year,
          y: element.employees ? element.employees : 0,
        });
        personnelCostData.push({
          x: element.year,
          y: element.personnelCost ? element.personnelCost : 0,
        });
      });
    const completeArray = [
      {
        id: Localize("COMMON.REVENUE"),
        data: revenueData,
      },
      {
        id: Localize("COMMON.INCOME"),
        data: incomeData,
      },
    ];
    setDataSet(completeArray);
    setEmployeesDataSet([
      { id: Localize("COMMON.EMPLOYEES"), data: employeesData },
    ]);
    setPersonnelCostDataSet([
      { id: Localize("COMMON.PERSONNEL_COST"), data: personnelCostData },
    ]);
  }, [data]);

  return (
    <>
      <Row style={{ marginTop: 24 }}>
        <Col>
          <h3 style={{ fontWeight: "bold" }}>
            {Localize("COMMON.REVENUE")} {Localize("COMMON.AND")}{" "}
            {Localize("COMMON.INCOME")}
          </h3>
        </Col>
      </Row>
      <Row style={{ height: "300px", width: "100%" }}>
        {dataSet && (
          <ResponsiveLine
            data={dataSet}
            margin={{ top: 10, right: 180, bottom: 50, left: 100 }}
            xScale={{ type: "point" }}
            yScale={{ type: "linear", min: "auto", max: "auto" }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: Localize("COMMON.YEAR"),
              legendOffset: 36,
              legendPosition: "middle",
            }}
            yFormat={(e) => abbreviateNumber(e)}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              format: (e) => abbreviateNumber(e),
            }}
            useMesh={true}
            colors={{ scheme: "spectral" }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                itemWidth: 10,
                itemHeight: 20,
                itemTextColor: "#999",
                symbolSize: 18,
                symbolShape: "circle",
                translateX: 30,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        )}
      </Row>
      <Row style={{ marginTop: 24 }}>
        <Col>
          <h3 style={{ fontWeight: "bold" }}>{Localize("COMMON.EMPLOYEES")}</h3>
        </Col>
      </Row>
      <Row style={{ height: "300px", width: "100%" }}>
        {employeesDataSet && (
          <ResponsiveLine
            data={employeesDataSet}
            margin={{ top: 10, right: 180, bottom: 50, left: 50 }}
            xScale={{ type: "point" }}
            yScale={{ type: "linear" }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: Localize("COMMON.YEAR"),
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: "middle",
              format: (e) => Math.floor(e) === e && e,
            }}
            useMesh={true}
            colors={{ scheme: "spectral" }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                itemWidth: 10,
                itemHeight: 20,
                itemTextColor: "#999",
                symbolSize: 18,
                symbolShape: "circle",
                translateX: 30,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        )}
      </Row>
      {showPersonnelCost && (
        <>
          <Row style={{ marginTop: 24 }}>
            <Col>
              <h3 style={{ fontWeight: "bold" }}>
                {Localize("COMMON.PERSONNEL_COST")}
              </h3>
            </Col>
          </Row>
          <Row style={{ height: "300px", width: "100%" }}>
            {personnelCostDataSet && (
              <ResponsiveLine
                data={personnelCostDataSet}
                margin={{ top: 10, right: 180, bottom: 50, left: 100 }}
                xScale={{ type: "point" }}
                yScale={{ type: "linear", min: "auto", max: "auto" }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: Localize("COMMON.YEAR"),
                  legendOffset: 36,
                  legendPosition: "middle",
                }}
                yFormat={(e) => abbreviateNumber(e)}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legendPosition: "middle",
                  format: (e) => abbreviateNumber(e),
                }}
                useMesh={true}
                colors={{ scheme: "spectral" }}
                pointSize={10}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                legends={[
                  {
                    anchor: "top-right",
                    direction: "column",
                    itemWidth: 10,
                    itemHeight: 20,
                    itemTextColor: "#999",
                    symbolSize: 18,
                    symbolShape: "circle",
                    translateX: 30,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]}
              />
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default FinancialGraph;
