import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { orderBy } from "lodash";
import BannerTop from "../../components/BannerTop/BannerTop";
import Tabs from "../../components/Tabs/Tabs";
import CompanyList from "../../components/List/Company.list";
import ContactList from "../../components/List/Contact.list";
import TagOptions from "../../components/Tag/TagOptions";
import {
  associationsTagIdCompanyAction,
  associationsTagIdContactAction,
  deleteTagAction,
  exportTagContactsAction,
  getTagAction,
  getTagCompanyDetailAction,
  getTagContactDetailAction,
  resetTagDetailAction,
} from "../../actions/tag.actions";
import "./tag.style.scss";
import Localize, { l } from "../../libs/localization";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import { get } from "../../libs/helpers/ioc";
import { EXPORT_CONTACTS_PLUGIN } from "../../api/user.api";
import usePermissions from "../../hooks/usePermissions";
import LimitedFeatureBanner from "../../design-library/LimitedFeatureBanner/LimitedFeatureBanner.component";
import getPluginConsumption from "../../libs/getPluginConsumption";
import getUserPlugins from "../../libs/getUserPlugins";

const PAGE_SIZE = 50;
const { confirm } = Modal;

const TagPage = ({ location, user }) => {
  const dispatch = useDispatch();
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const [allowDownloadContacts, setAllowDownloadContacts] = useState(false);

  const permissions_ = get("scopes");
  const permissions = usePermissions();

  const selectedTagWidget = location.data; // route param
  const tags = useSelector((state) => state.tag.tagsList);

  const [selectedTag, setSelectedTag] = useState(
    selectedTagWidget && selectedTagWidget.name
  );

  const companiesDetail = useSelector((state) => state.tag.companiesTagDetail);
  const tagDetailLoading = useSelector((state) => state.tag.loading);
  const contactsDetail = useSelector((state) => state.tag.contactsTagDetail);
  const loadingExport = useSelector(
    (state) => state.tag.exportContactList.loading
  );

  const companiesDetailData = useSelector(
    (state) => state.tag.companiesTagDetailData
  );
  const contactsDetailData = useSelector(
    (state) => state.tag.contactsTagDetailData
  );

  // Associated tag - id COMPANY
  const loadingTagsIdsCompany = useSelector(
    (state) => state.tag.tagsForIdsCompany.loading
  );
  const associatedTagsIdsCompany = useSelector(
    (state) => state.tag.tagsForIdsCompany.data
  );

  // Associated tag - id CONTACT
  const loadingTagsIdsContact = useSelector(
    (state) => state.tag.tagsForIdsContact.loading
  );
  const associatedTagsIdsContact = useSelector(
    (state) => state.tag.tagsForIdsContact.data
  );

  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const companyIds = [];
  const contactIds = [];

  useEffect(() => {
    if (tags.length === 0 && permissions.favourites) {
      dispatch(getTagAction());
    }
    setAllowDownloadContacts(
      user.group === "SUPER-ADMIN" ||
        user.group === "ADMIN" ||
        user.scopes[getFromLocalStorage("lefacWebTenant")?.data]?.includes(
          "p_contactsExport:any"
        )
    );
  }, [permissions]);

  const [contactTagPage, setContactTagPage] = useState(1);
  const [companyTagPage, setCompanyTagPage] = useState(1);

  const searchRequest = () => {
    if (selectedTag) {
      let id = "";

      tags &&
        tags.forEach((tag) => {
          if (tag.name === selectedTag) {
            id = tag._id;
          }
        });

      if (id) {
        dispatch(
          getTagCompanyDetailAction({
            id,
            pageNum: companyTagPage,
          })
        );
        dispatch(
          getTagContactDetailAction({
            id,
            pageNum: contactTagPage,
          })
        );
      }
    } else {
      setSelectedTag("");
      dispatch(resetTagDetailAction());
    }
  };

  const setContactPage = (val) => {
    if (contactsDetailData && contactsDetailData.totalPages) {
      if (contactsDetailData.totalPages === 1) {
        return;
      }
      setContactTagPage(val);
    }
  };

  const setCompanyPage = (val) => {
    if (companiesDetailData && companiesDetailData.totalPages) {
      if (companiesDetailData.totalPages === 1) {
        return;
      }
      setCompanyTagPage(val);
    }
  };

  useEffect(() => {
    searchRequest();
  }, [contactTagPage, companyTagPage]);

  useEffect(() => {
    searchRequest();
  }, [selectedTag]);

  const deleteTag = () => {
    let id = "";

    tags &&
      tags.forEach((tag) => {
        if (tag.name === selectedTag) {
          id = tag._id;
        }
      });

    return confirm({
      title: Localize("TAG.CONFIRM_DELETE_TITLE"),
      content: Localize("TAG.CONFIRM_DELETE_DESC"),
      cancelText: Localize("COMMON.CANCEL"),
      onOk() {
        dispatch(deleteTagAction({ id }));
        setSelectedTag("");
      },
    });
  };

  const getCompanyPageIds = () => {
    if (companiesDetail) {
      companiesDetail.forEach((company) => {
        companyIds.push(company._id);
      });
      dispatch(associationsTagIdCompanyAction({ data: { id: companyIds } }));
    }
  };

  const getContactPageIds = () => {
    if (contactsDetail) {
      contactsDetail.forEach((contact) => {
        contactIds.push(contact._id);
      });
      dispatch(associationsTagIdContactAction({ data: { id: contactIds } }));
    }
  };

  const exportContacts = async () => {
    const id = tags.find((tag) => tag.name === selectedTag)._id;
    if (user.group === "SUPER-ADMIN" || user.group === "ADMIN")
      dispatch(exportTagContactsAction({ id }));
    else {
      let creditsLeft = 0;
      if (permissions.exportContactsV2) {
        const pluginName = "ExportContactsV2";
        const plugin = getUserPlugins(user).find((p) => p.name === pluginName);
        creditsLeft =
          (plugin?.data?.contactLimit || 0) -
          (
            await getPluginConsumption({
              user,
              pluginName,
            })
          )?.contactExport?.count;
      } else {
        const creditData = Object.values(
          user?.licence?.tenants[tenant]?.plugins
        )?.find((plugin) => plugin.name === EXPORT_CONTACTS_PLUGIN)?.data;
        creditsLeft =
          creditData?.contactLimit - parseInt(creditData?.exportedCount, 10);
      }
      const content =
        creditsLeft > 0
          ? `${Localize(
              "TAG.EXPORT_MODAL_DESCRIPTION"
            )} ${creditsLeft}. ${Localize("TAG.EXPORT_CONTINUE")}`
          : Localize("ERROR.ERROR_EXPORT_CONTACTS");
      return confirm({
        title: Localize("TAG.EXPORT_MODAL_TITLE"),
        content,
        okButtonProps: { disabled: creditsLeft <= 0 },
        cancelText: Localize("COMMON.CANCEL"),
        onOk() {
          dispatch(exportTagContactsAction({ id }));
        },
      });
    }
  };

  useEffect(() => {
    if (permissions.favourites) {
      getCompanyPageIds();
    }
  }, [companiesDetail]);

  useEffect(() => {
    if (permissions.favourites) {
      getContactPageIds();
    }
  }, [contactsDetail]);

  const renderContacts = () => {
    if (Object.keys(permissions_ || {})?.includes("contact")) {
      return (
        <ContactList
          data={contactsDetail || []}
          title={
            <>
              {Localize("PAGES.CONTACTS.TITLE")} (
              {(contactsDetailData && contactsDetailData.totalResults) || 0})
            </>
          }
          total={contactsDetailData && contactsDetailData.totalResults}
          pageSize={pageSize}
          setPageSize={setPageSize}
          isTagPage
          loadingList={tagDetailLoading}
          loadingTag={loadingTagsIdsContact}
          associatedTagsIds={
            associatedTagsIdsContact && associatedTagsIdsContact
          }
          setContactTagPage={(val) => setContactPage(val)}
        />
      );
    }
    return null;
  };

  const renderCompanies = () => {
    if (Object.keys(permissions_ || {})?.includes("company")) {
      return (
        <CompanyList
          data={(companiesDetail && companiesDetail) || []}
          title={
            <>
              {Localize("PAGES.COMPANIES.TITLE")} (
              {(companiesDetailData && companiesDetailData.totalResults) || 0})
            </>
          }
          total={companiesDetailData && companiesDetailData.totalResults}
          pageSize={pageSize}
          setPageSize={setPageSize}
          isTagPage
          loadingList={tagDetailLoading}
          loadingTag={loadingTagsIdsCompany}
          associatedTagsIds={
            associatedTagsIdsCompany && associatedTagsIdsCompany
          }
          setCompanyTagPage={(val) => setCompanyPage(val)}
        />
      );
    }
    return null;
  };

  return (
    <>
      <BannerTop small>
        <h1 className="mt-4 orange-title">{Localize("PAGES.TAG.TITLE")}</h1>
      </BannerTop>
      {}
      {permissions.favourites ? (
        <>
          <div className="searchPage__filters animation fadein-up slow">
            <TagOptions
              isForm
              value={selectedTag}
              tagList={orderBy(tags, "created_at", "desc")}
              onTagChange={(value) => setSelectedTag(value)}
              onTagSelect={searchRequest}
              onDeleteClick={deleteTag}
              deleteDisabled={!selectedTag}
              loadingExport={loadingExport}
              showExport={
                selectedTag &&
                contactsDetailData?.totalResults > 0 &&
                allowDownloadContacts
              }
              onExportContacts={exportContacts}
            />
          </div>
          <Tabs
            containerClass="tagPage__tabs"
            options={{
              onTabClick: () => {
                window.scrollTo({
                  behavior: "smooth",
                  left: 0,
                  top: 0,
                });
              },
              animated: true,
            }}
          >
            {renderCompanies()}
            {renderContacts()}
          </Tabs>
        </>
      ) : (
        <LimitedFeatureBanner message={l("ECOMMERCE.FAVOURITES_LIMIT_TEXT")} />
      )}
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, {})(TagPage);
