import React, { useEffect, useState } from "react";
import { Col } from "antd";

import "./search.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import queryString from "query-string";
import Tabs from "../../components/Tabs/Tabs";
import Localize from "../../components/Localized/Localize.comp";
import CompanyList from "../../components/List/Company.list";
import ContactList from "../../components/List/Contact.list";
import BrandList from "../../components/List/Brand.list";
import BannerTop from "../../components/BannerTop/BannerTop";
import {
  searchBrandsAction,
  searchCompaniesAction,
  searchContactsAction,
} from "../../actions/search.action";
import { ReactComponent as EmptyIcon } from "../../components/DetailPane/empty.svg";
import { encodeURIFix } from "../../libs/utils";

const PAGE_SIZE = 20;

const SearchPage = (props) => {
  const { match } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const [filters, setFilters] = useState(null);

  const resultSearch = useSelector((state) => state.search.results.data);

  useEffect(() => {
    const query = queryString.parse(location.search);
    const params = {};
    if (query.key) {
      const data = {
        name: query.key,
        pageNum: 1,
        limit: PAGE_SIZE,
      };

      const dataContact = {
        surname: query.key,
        pageNum: 1,
        limit: PAGE_SIZE,
      };

      dispatch(
        searchCompaniesAction({
          data,
        })
      );
      dispatch(
        searchContactsAction({
          data: dataContact,
        })
      );
      dispatch(
        searchBrandsAction({
          params: `?name=${encodeURIFix(
            query.key
          )}&pageNum=${1}&limit=${PAGE_SIZE}`,
        })
      );
    }
  }, [location]);

  return (
    <>
      <div className="searchPage">
        <BannerTop>
          <h1 className="mt-4">
            {Localize("COMMON.SEARCH_RESULT")}: "
            {queryString.parse(location.search).key}"
          </h1>
        </BannerTop>
        {resultSearch ? (
          <Tabs
            containerClass="searchPage__tabs"
            options={{
              onTabClick: () => {
                window.scrollTo({
                  behavior: "smooth",
                  left: 0,
                  top: 0,
                });
              },
              animated: true,
            }}
          >
            <CompanyList
              filters={{
                name: queryString.parse(location?.search)?.key,
              }}
              data={
                (resultSearch.companies && resultSearch.companies.data) || []
              }
              pageSize={pageSize}
              setPageSize={setPageSize}
              total={
                resultSearch.companies && resultSearch.companies.totalResults
              }
              title={
                <>
                  {Localize("PAGES.COMPANIES.TITLE")} (
                  {resultSearch.companies &&
                    resultSearch.companies.totalResults}
                  )
                </>
              }
            />
            <ContactList
              data={(resultSearch.contacts && resultSearch.contacts.data) || []}
              filters={{
                surname: queryString.parse(location?.search)?.key,
              }}
              pageSize={pageSize}
              setPageSize={setPageSize}
              total={
                resultSearch.contacts && resultSearch.contacts.totalResults
              }
              title={
                <>
                  {Localize("PAGES.CONTACTS.TITLE")} (
                  {resultSearch.contacts && resultSearch.contacts.totalResults})
                </>
              }
            />
            <BrandList
              data={(resultSearch.brands && resultSearch.brands.data) || []}
              pageSize={PAGE_SIZE}
              total={resultSearch.brands && resultSearch.brands.totalResults}
              title={
                <>
                  {Localize("PAGES.BRANDS.TITLE")}({" "}
                  {resultSearch.brands && resultSearch.brands.totalResults})
                </>
              }
            />
          </Tabs>
        ) : (
          <Col span={24}>
            <div style={{ textAlign: "center", opacity: 0.8 }}>
              <EmptyIcon />
              <p style={{ opacity: 0.6 }}>{Localize("COMMON.NO_DATA")}</p>
            </div>
          </Col>
        )}
      </div>
    </>
  );
};

export default SearchPage;
