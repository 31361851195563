import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyCreaAction } from "../../actions/company.actions";
import { Alert, Button, Col, List, Row, Spin, Tag } from "antd";

import PlaySquareOutlined from "@ant-design/icons/es/icons/PlaySquareOutlined";
import moment from "moment";
import { Link } from "react-router-dom";
import { orderBy, toUpper } from "lodash";
import { truncate } from "../../libs/utils";
import Truncate from "../../components/Truncate/Truncate";
import Localize from "../../libs/localization";
import BrandModal from "../brand/brand.modal";

const CompanyCrea = (props) => {
  const { companyId } = props;

  const crea = useSelector((state) => state.company.crea);
  const dispatch = useDispatch();

  const [showBrandModal, setShowBrandModal] = useState(false);
  const [brandId, setBrandId] = useState(null);

  useEffect(() => {
    searchRequest();
  }, []);

  const searchRequest = () => {
    dispatch(getCompanyCreaAction({ id: companyId }));
  };

  return (
    <>
      {crea?.loading ? (
        <div className="text-center mt-5" style={{ width: "100%" }}>
          <Spin className="mt-5" />
        </div>
      ) : (
        <Col span={24} className="animation fadein-right slow">
          {crea?.data && (
            <List
              className="searchPage__list animation"
              itemLayout="horizontal"
              dataSource={orderBy(crea.data, "date", "desc")}
              renderItem={(c) => (
                <div className="searchPage__item-more animation fadein-right slow">
                  <List.Item className="searchPage__item">
                    <List.Item.Meta
                      style={{ height: "200px" }}
                      avatar={
                        c.snapshot_url ? (
                          <Row
                            align="middle"
                            justify="center"
                            className="customCard__crea-image customCard__crea-image--detail"
                            style={{
                              backgroundImage: `url(${c.snapshot_url})`,
                            }}
                          >
                            <a href={c.url} target="_blank">
                              <PlaySquareOutlined className="customCard__crea-image--play" />
                            </a>
                          </Row>
                        ) : (
                          <Row
                            align="middle"
                            justify="center"
                            className="customCard__crea-placeholder customCard__crea-placeholder--detail"
                          >
                            <a href={c.url} target="_blank">
                              <PlaySquareOutlined className="customCard__crea-placeholder--play" />
                            </a>
                          </Row>
                        )
                      }
                      title={
                        <Row justify="space-between" className="px-3">
                          <h3 className="searchPage__item-title ">
                            <b>{c.title}</b>
                          </h3>
                          <p>{c.date && moment(c.date).format("DD/MM/YYYY")}</p>
                        </Row>
                      }
                      description={
                        <div className="px-3">
                          <div className="mb-2">
                            {c.crea_category &&
                              c.crea_category.map((cat) => <Tag>{cat}</Tag>)}
                          </div>
                          <p>
                            <Truncate string={c.infos} truncateVal={300} />
                          </p>
                          {c.agency && (
                            <p>
                              <b className="secondary">
                                {Localize(
                                  "PAGES.COMPANIES.DETAIL.SECTIONS.AGENCIES"
                                )}
                                :
                              </b>{" "}
                              <Link to={`/companies/${c.agency.id}`}>
                                {toUpper(c.agency.name)}
                              </Link>
                            </p>
                          )}
                          {c.brand && (
                            <p>
                              <b className="secondary">{"Brands: "}</b>{" "}
                              <a
                                onClick={() => {
                                  setBrandId(c.brand.id);
                                  setShowBrandModal(true);
                                }}
                                className="pointer"
                              >
                                {toUpper(c.brand.name)}
                              </a>
                            </p>
                          )}
                        </div>
                      }
                    />
                  </List.Item>
                </div>
              )}
            />
          )}

          {brandId && (
            <BrandModal
              id={brandId}
              visible={showBrandModal}
              close={() => {
                setBrandId(null);
                setShowBrandModal(false);
              }}
            />
          )}
        </Col>
      )}
    </>
  );
};

export default CompanyCrea;
