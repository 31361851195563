import React, { useState } from "react";
import { List, Row, Spin, Tag, Col, Card, Button } from "antd";
import LinkOutlined from "@ant-design/icons/es/icons/LinkOutlined";
import moment from "moment";
import { truncate } from "../../libs/utils";
import BannerTop from "../BannerTop/BannerTop";
import Modal from "antd/lib/modal/Modal";
import Localize from "../../libs/localization";

const NewsList = ({ data: newsList, loadingScroll }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  return (
    <>
      {newsList && newsList.length > 0 && (
        <List
          className="homePage__newsSection-newsCard"
          dataSource={newsList}
          itemLayout="vertical"
          renderItem={(item) => {
            return (
              <List.Item key={item._id}>
                <List.Item.Meta
                  title={
                    <>
                      <Row align="top" className="ml-3 mt-2">
                        <Col span={1} className="mt-1">
                          <a href={item.link} target="_blank" rel="noreferrer">
                            {item.feedLogo ? (
                              <img
                                src={`${item.feedLogo}`}
                                alt="news_logo"
                                style={{ width: "100%" }}
                              />
                            ) : (
                              <LinkOutlined style={{ fontSize: 20 }} />
                            )}
                          </a>
                        </Col>
                        <Col span={20} className="ml-3 mb-0 pb-0">
                          <a href={item.link} target="_blank" rel="noreferrer">
                            <h3 style={{ cursor: "pointer" }}>{item.title}</h3>
                          </a>
                          <p style={{ fontSize: 12 }} className="m-0">
                            {moment(item.pubDate).format("DD/MM/YYYY")}
                          </p>
                        </Col>
                      </Row>
                      <div className="ml-3">
                        {item.categories &&
                          Array.isArray(item.categories) &&
                          item.categories.map((c) => (
                            <Tag key={c} className="mt-2">
                              {c}
                            </Tag>
                          ))}
                      </div>
                    </>
                  }
                  description={
                    <Row
                      className="mx-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowModal(true);
                        setModalContent(item);
                      }}
                    >
                      {truncate(item.contentSnippet, 300)}
                    </Row>
                  }
                />
              </List.Item>
            );
          }}
        >
          {loadingScroll && (
            <div
              style={{
                height: 50,
                width: "100%",
                textAlign: "center",
              }}
            >
              <Spin />
            </div>
          )}
        </List>
      )}

      <Modal
        wrapClassName="contactPage__modal"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        title={
          <div className="contactPage__head profile__container">
            <BannerTop>
              <Row className="profile__container--left" align="middle">
                <Row className="profile__data">
                  <Col span={20}>
                    <h2>{modalContent.title}</h2>
                  </Col>
                </Row>
              </Row>
            </BannerTop>
          </div>
        }
      >
        <Col className="contactPage__body">
          <Row>
            <Row gutter={16}>
              <Col span={24}>
                <Card
                  bordered={false}
                  className="companyPage__body-card companyPage__body-card--custom customCard"
                >
                  {modalContent.contentSnippet && (
                    <p>{modalContent.contentSnippet}</p>
                  )}
                  <Row justify="center" align="bottom">
                    <a href={modalContent.link} target="_blank">
                      <Button type="primary">
                        <LinkOutlined style={{ fontSize: "1rem" }} />
                        {Localize("PAGES.HOME.GO_TO_NEWS")}
                      </Button>
                    </a>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Row>
        </Col>
      </Modal>
    </>
  );
};

export default NewsList;
