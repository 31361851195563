import React, { useEffect, useRef } from "react";
import { Input } from "antd";

const SearchPlaces = ({ onChange, placeholder, type = ["address"], value }) => {
  const placeInputRef = useRef(null);

  useEffect(() => {
    initPlaceAPI();
  }, []);

  // initialize the google place autocomplete
  const initPlaceAPI = () => {
    if (window.google.maps) {
      let autocomplete = new window.google.maps.places.Autocomplete(
        placeInputRef.current,
        { types: type }
      );
      new window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          let place = autocomplete.getPlace();
          onChange(place);
        }
      );
    }
  };

  return (
    <>
      <input
        type="text"
        value={value}
        ref={placeInputRef}
        className="ant-input"
        placeholder={placeholder}
        onChange={onChange}
      />
    </>
  );
};

export default SearchPlaces;
