import React from "react";
import LogoColored from "../../assets/images/blinko-azul.png";
import LogoWhite from "../../assets/images/blinko-blanco.png";
import LogoBlack from "../../assets/images/blinko-black.png";

const images = {
  colored: LogoColored,
  white: LogoWhite,
  black: LogoBlack,
};

export default function BlinkoLogo({ variant = "colored", ...props }) {
  return (
    <img
      src={images[variant]}
      alt="Blinko logo"
      {...props}
      style={{ alignSelf: "stretch", ...(props.style || {}) }}
    />
  );
}
