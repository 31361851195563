export function shouldShowProfilePage({ permissions }) {
  return (
    permissions.ecommerce ||
    permissions.activemailer ||
    permissions.companyQuota ||
    permissions.contactQuota ||
    permissions.weeklyUpdateEmail
  );
}

export function getDefaultProfilePage({ permissions }) {
  if (permissions.ecommerce) return "/profile/basic-info";
  if (permissions.activemailer) return "/profile/mailer-integration";
  if (permissions.companyQuota || permissions.contactQuota)
    return "/profile/quotas";
  if (permissions.weeklyUpdateEmail) return "/profile/communications";
  return "/";
}
