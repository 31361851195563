import React, { useEffect, useState } from "react";
import { AutoComplete, Select } from "antd";
import { toUpper } from "lodash";
import { getCompanyListApi } from "../../api/company.api";

const { Option } = AutoComplete;

const CompanyAutocomplete = ({
  onChange,
  disabled,
  value,
  singleValue = false,
  style = {},
  freeForm = false,
}) => {
  const [companyList, setCompanyList] = useState([]);
  const [currValue, setCurrValue] = useState(value || []);
  const [loading, setLoading] = useState(false);

  useEffect(() => setCurrValue(value), [value]);
  let mode = "";
  if (!singleValue) mode = freeForm ? "tags" : "multiple";
  return (
    <Select
      mode={mode}
      showSearch
      value={currValue?.map((el) => el?.id || el.toString())}
      filterOption={false}
      style={{ width: "100%", ...style }}
      disabled={disabled}
      loading={loading}
      onClear={() => {
        setCurrValue([]);
        if (typeof onChange === "function" && !disabled) {
          onChange([]);
        }
      }}
      onSelect={(v, opt) => {
        const newVal = [...(currValue || []), opt?.obj || v];
        setCurrValue(newVal);
        if (typeof onChange === "function" && !disabled) {
          onChange(newVal);
        }
      }}
      allowClear
      onChange={(v, opt) => {
        let newVal;
        if (!v && !opt) {
          newVal = [];
          setCurrValue(newVal);
        } else {
          newVal = Array.isArray(opt) ? opt.map((o) => o?.obj) : [opt?.obj];
          setCurrValue(newVal);
        }
        if (typeof onChange === "function" && !disabled) {
          onChange(newVal);
        }
      }}
      onDeselect={(v) => {
        const newValue = (currValue || []).filter((el) => el !== v);
        setCurrValue(newValue);
        if (typeof onChange === "function" && !disabled) {
          onChange(newValue, null);
        }
      }}
      onSearch={async (val) => {
        if (val && val.length > 2) {
          setLoading(true);
          const res = await getCompanyListApi({
            params: {
              name: val,
            },
            abortChannel: "COMPANY_AUTOCOMPLETE",
          });
          if (!res.canceled) {
            setLoading(false);
            setCompanyList(
              (res.data || []).map((r) => ({
                label: r.name,
                value: r._id,
                obj: { id: r._id, name: r.name },
              }))
            );
          }
        }
      }}
      placeholder={disabled ? "—" : ""}
    >
      {companyList.map((el, key) => (
        <Option key={key} value={el.value} obj={el.obj}>
          {toUpper(el.label)}
        </Option>
      ))}
    </Select>
  );
};

export default CompanyAutocomplete;
