import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";

export const searchNewsApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/news`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }

  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getCreaListApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/crea`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );
  const response = await res.json();

  if (res.ok) {
    return response;
  }

  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getPitchListApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/pitch`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );
  const response = await res.json();

  if (res.ok) {
    return response;
  }

  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};
