import { reducerFromMap } from "../libs/helpers/reducers";
import {
  PITCH_GRAPH_LOAD_ERROR,
  PITCH_GRAPH_LOADED,
  PITCH_GRAPH_LOADING,
} from "../actions/pitch.actions";

const reducer = {
  /*
   * PITCH GRAPH
   */
  [PITCH_GRAPH_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [PITCH_GRAPH_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    graph: data,
  }),
  [PITCH_GRAPH_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, {});
