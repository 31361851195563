import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { resetErrorAction, goTo } from "../actions/app.actions";
import { removeFromLocalStorage } from "./helpers/localstorage";
import { refreshPage } from "./utils";
import ErrorPage from "../pages/system/error.page";
import OutOfQuotaModal from "../design-library/OutOfQuotaModal/OutOfQuotaModal.component";

const errorMap = {
  OUT_OF_QUOTA_ERROR: OutOfQuotaModal,
};
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    window.setError = (error) => {
      this.setState({ error });
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    // if (prevProps.error && !error) {
    //   goTo("/");
    // }
    if (!_.isEqual(error, prevProps.error)) {
      this.setState({ error });
    }
  }

  componentWillUnmount() {}

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({ error });
  }

  render() {
    const { resetError } = this.props;
    const { error } = this.state;
    if (error) {
      const { id, name, status, message, stack, date, code } = error;
      const ErrorComponent = errorMap[id] || ErrorPage;
      return (
        <>
          {this.props.children}
          <ErrorComponent
            error={{ id, name, status, message, stack, date, code }}
            dismiss={resetError}
            forceLogin={() => {
              removeFromLocalStorage(null, "general");
              refreshPage();
            }}
          />
        </>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = ({ app }) => ({
  error: app.error,
});

export default connect(mapStateToProps, { resetError: resetErrorAction, goTo })(
  ErrorBoundary
);
