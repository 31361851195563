import callApi from "../../libs/helpers/callApi";
import { getFlatEntityListApi } from "../../api/registry.api";

export const MAIN_CATEGORY_LOADING = "MAIN_CATEGORY_LOADING";
export const MAIN_CATEGORY_LOADED = "MAIN_CATEGORY_LOADED";
export const MAIN_CATEGORY_LOAD_ERROR = "MAIN_CATEGORY_LOAD_ERROR";

// INCOME RANGE GET LIST
export const getMainCategoryAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "main_category" }),
  secured: true,
  startAction: MAIN_CATEGORY_LOADING,
  successAction: MAIN_CATEGORY_LOADED,
  errorAction: MAIN_CATEGORY_LOAD_ERROR,
});
