import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  EMPLOYER_RANGE_LOADING,
  EMPLOYER_RANGE_LOADED,
  EMPLOYER_RANGE_LOAD_ERROR,
} from "../../actions/registry/registry.employer_range.actions";

const reducer = {
  /*
   * EMPLOYER_RANGE LIST
   */
  [EMPLOYER_RANGE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [EMPLOYER_RANGE_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [EMPLOYER_RANGE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
