import React from "react";
import FacebookOutlined from "@ant-design/icons/es/icons/FacebookOutlined";
import LinkedinOutlined from "@ant-design/icons/es/icons/LinkedinOutlined";
import InstagramOutlined from "@ant-design/icons/es/icons/InstagramOutlined";
import YoutubeOutlined from "@ant-design/icons/es/icons/YoutubeOutlined";
import { Tag } from "antd";
import { capitalize } from "lodash";
import { getSocialIcon, turnKeyIntoLabel } from "../../libs/utils";

const Social = (props) => {
  const { external } = props;
  const getIcon = (social) => {
    let icon = null;
    if (social.link != 0 && social.link.length > 0 && !social.disabled) {
      switch (social.label) {
        case "linkedin":
          icon = <LinkedinOutlined />;
          break;
      }

      return (
        icon && (
          <a
            className="grey-light-color"
            href={social.link}
            target="_blank"
            key={social.label}
          >
            {icon}
          </a>
        )
      );
    }
  };

  return <>{external && external.map((obj) => getIcon(obj))}</>;
};
const OtherSocial = (props) => {
  const { external } = props;

  const getTag = (social) => {
    if (social.link != 0 && social.link.length > 0 && !social.disabled) {
      return (
        <Tag
          icon={getSocialIcon(social)}
          className="card__tagLinks"
          key={social.label}
        >
          <a href={social.link} target="_blank">
            {turnKeyIntoLabel(social.label)}
          </a>
        </Tag>
      );
    }
  };

  return <>{external && external.map((obj) => getTag(obj))}</>;
};

export { Social, OtherSocial };
