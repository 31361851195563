import { reducerFromMap } from "../libs/helpers/reducers";
import {
  COMMENT_LIST_LOADED,
  COMMENT_LIST_LOADING,
  COMMENT_LIST_LOAD_ERROR,
  CREATE_COMMENT_LOADED,
  CREATE_COMMENT_LOADING,
  CREATE_COMMENT_LOAD_ERROR,
  DELETE_COMMENT_LOADED,
  DELETE_COMMENT_LOADING,
  DELETE_COMMENT_LOAD_ERROR,
} from "../actions/comment.actions";

const reducer = {
  /*
   * COMMENT LIST
   */
  [COMMENT_LIST_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [COMMENT_LIST_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    commentList: data,
  }),
  [COMMENT_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * COMMENT CREATION
   */
  [CREATE_COMMENT_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [CREATE_COMMENT_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [CREATE_COMMENT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * DELETE COMMENT
   */
  [DELETE_COMMENT_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [DELETE_COMMENT_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [DELETE_COMMENT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  commentList: [],
});
