import React, { useEffect, useState } from "react";
import { Avatar, Row, Col, Modal, Spin, Tag, Button } from "antd";
import "./contact.scss";
import LinkedinOutlined from "@ant-design/icons/es/icons/LinkedinOutlined";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toUpper } from "lodash";
import Localize from "../../components/Localized/Localize.comp";
import CardAddress from "../../components/Cards/Card.address";
import CardContacts from "../../components/Cards/Card.contacts";
import BannerTop from "../../components/BannerTop/BannerTop";
import { ReactComponent as SalesNavigator } from "../../assets/icons/sales-navigator-logo.svg";
import {
  getContactBrandsAction,
  getContactDetailAction,
} from "../../actions/contact.actions";
import {
  getSingleContactTagsAction,
  getTagAction,
} from "../../actions/tag.actions";
import CardNomina from "../../components/Cards/Card.nomina";
import CardBrands from "../../components/Cards/Card.brands";
import { getEmailHelp, toTitleCase } from "../../libs/utils";
import PersonalComments from "../../components/Cards/PersonalComments";
import TagManage from "../../components/Tag/TagManage";
import usePermissions from "../../hooks/usePermissions";
import { getFeatures } from "../../libs/features";

const salesNavigatorIconStyle = {
  width: "28px",
  border: "2px solid white",
  borderRadius: "3px",
  marginTop: "4px",
  height: "28px",
};

const ContactModal = (props) => {
  const {
    visible,
    close,
    onAssociateTag,
    onDeleteTag,
    showAssociateTag,
    id,
  } = props;
  const features = getFeatures();

  const data = useSelector((state) => state.contact.detail.data);
  const loading = useSelector((state) => state.contact.detail.loading);
  const contactBrands = useSelector((state) => state.contact.brands.data);
  const tags = useSelector((state) => state.tag.tagsList);
  const singleContactTags = useSelector((state) => state.tag.singleContactTags);

  const permissions = usePermissions();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?._id && (data._id === id || !id)) {
      dispatch(getContactBrandsAction({ id: data._id }));
      if (permissions.favourites) {
        dispatch(getSingleContactTagsAction({ data: { id: [data._id] } }));
      }
    } else {
      dispatch(getContactDetailAction({ id }));
    }
  }, [data, id]);

  useEffect(() => {
    if (tags.length === 0 && permissions.favourites) {
      dispatch(getTagAction());
    }
  }, []);

  const showContactCard =
    data?.website ||
    data?.e_commerce ||
    data?.tel ||
    data?.phone ||
    data?.fax ||
    data?.email ||
    data?.company?.tel ||
    data?.company?.fax;

  return (
    <div className="contactPage">
      {data?._id ? (
        <Modal
          wrapClassName="contactPage__modal"
          visible={visible}
          onCancel={() => close()}
          title={
            <div className="contactPage__head profile__container">
              <BannerTop height="350px">
                <Row className="profile__container--left" align="middle">
                  <div className="profile__avatar pt-3 pb-3">
                    <Avatar size={90} src={data?.profile_img} />
                  </div>
                  <Row className="profile__data" style={{ width: "100%" }}>
                    <Col span={20}>
                      <h2 className="grey-light-color">
                        {data?.prefix} {toTitleCase(data?.name)}{" "}
                        {toTitleCase(data?.surname)}
                      </h2>
                    </Col>
                    <Col
                      span={4}
                      style={{
                        textAlign: "right",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {data?.xing?.length > 0 && (
                        <div style={{ marginRight: 2 }}>
                          <a href={data.xing} target="_blank">
                            <SalesNavigator style={salesNavigatorIconStyle} />
                          </a>
                        </div>
                      )}
                      {data?.linkedin?.length > 0 && (
                        <div style={{ marginTop: 2 }}>
                          <a
                            style={{ zIndex: 1 }}
                            href={data?.linkedin}
                            target="_blank"
                          >
                            <LinkedinOutlined
                              style={{ fontSize: "2rem", color: "#fff" }}
                            />
                          </a>
                        </div>
                      )}
                    </Col>
                    <Col span={18}>
                      <div className="profile__data-function grey-light-color">
                        {data?.title} {Localize("COMMON.AT")}
                        <br />
                        <Link
                          className="grey-light-color"
                          to={`/companies/${data?.company?.id}`}
                        >
                          {toUpper(data?.company?.name)}
                        </Link>
                        <div className="pt-3" style={{ zIndex: 1 }}>
                          {permissions.favourites ? (
                            <TagManage
                              showAssociateTag={showAssociateTag}
                              tagInfo={singleContactTags}
                              entityId={data._id}
                              entity="contact"
                              onAssociateTag={() => {
                                dispatch(
                                  getSingleContactTagsAction({
                                    data: { id: [data._id] },
                                  })
                                );
                                onAssociateTag();
                              }}
                              onDeleteTag={onDeleteTag}
                            />
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    {data && (
                      <Col
                        span={6}
                        style={{
                          textAlign: "right",
                          paddingTop: 8,
                          zIndex: 1,
                          lineHeight: "0.75rem",
                        }}
                      >
                        <a
                          className="help-btn grey-light-color"
                          style={{ zIndex: 1 }}
                          href={`mailto:${getEmailHelp()}?subject=Contact ${toTitleCase(
                            data?.name
                          )} ${toTitleCase(data?.surname)}`}
                        >
                          {Localize("COMMON.HELP")}
                        </a>
                      </Col>
                    )}
                  </Row>
                </Row>
              </BannerTop>
            </div>
          }
          footer={
            <Col span={24} className="contactPage__footer">
              <Row className="profile__container--right" align="middle"></Row>
            </Col>
          }
        >
          <Col className="contactPage__body">
            <Row gutter={16}>
              {showContactCard && (
                <Col span={24}>
                  <CardContacts data={data} />
                </Col>
              )}
              {(data?.address || data?.company?.address) && (
                <Col span={24}>
                  <CardAddress data={data} vertical={false} />
                </Col>
              )}
              {(data?.nomination_date || data?.nomination_comment) && (
                <Col span={24}>
                  <CardNomina data={data} />
                </Col>
              )}
              {contactBrands && contactBrands.length > 0 && (
                <Col span={24}>
                  <CardBrands data={contactBrands} />
                </Col>
              )}
              {features.comments && permissions.comments && !loading && (
                <Col span={24}>
                  <PersonalComments entityType="contact" entityId={data._id} />
                </Col>
              )}
            </Row>
          </Col>
        </Modal>
      ) : null}
    </div>
  );
};

export default ContactModal;
