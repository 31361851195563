import { getFlatV2EntityListApi } from "../../api/registry.api";
import callApi from "../../libs/helpers/callApi";

export const FUNDING_TYPE_LOADING = "FUNDING_TYPE_LOADING";
export const FUNDING_TYPE_LOADED = "FUNDING_TYPE_LOADED";
export const FUNDING_TYPE_LOAD_ERROR = "FUNDING_TYPE_LOAD_ERROR";

// GET FUNDING TYPE LIST
export const getFundingTypeAction = callApi({
  apiCall: (p) =>
    getFlatV2EntityListApi({ ...p, entity: "InvestmentRoundFundingType" }),
  secured: true,
  startAction: FUNDING_TYPE_LOADING,
  successAction: FUNDING_TYPE_LOADED,
  errorAction: FUNDING_TYPE_LOAD_ERROR,
});
