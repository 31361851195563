import React, { useEffect } from "react";
import { Card } from "antd";
import Localize from "../../libs/localization";

const CardComment = (props) => {
  const { comment } = props;

  return (
    <Card
      title={
        <h2>
          <b>{Localize("PAGES.COMPANIES.DETAIL.SECTIONS.PUBLIC_COMMENT")}</b>
        </h2>
      }
      bordered={false}
      className="companyPage__body-card companyPage__body-card--custom"
    >
      <p>{comment}</p>
    </Card>
  );
};

export default CardComment;
