import firebase from "firebase";
import axios from "axios";
import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";
import { headersBuilder } from "../libs/helpers/callApi";
import { getFromLocalStorage } from "../libs/helpers/localstorage";

export const authorizeApi = async ({ code }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.oauth.callback}?authorization_code=${code}&redirect_uri=${Config.appBaseurl}/authorize`,
    {
      method: "GET",
    }
  );
  const response = await res.json();
  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const loginApi = async ({ email, password }) => {
  if (!(firebase.apps || []).length) {
    firebase.initializeApp(Config.firebaseConfig);
  }
  try {
    const resFirebase = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const { user } = resFirebase;
    const token = await user.getIdToken();
    const res = await fetch(`${Config.apiBaseurl}/${Config.apis.oauth.login}`, {
      method: "POST",
      headers: headersBuilder(),
      body: JSON.stringify({ token, context: "CUSTOMER" }),
    });
    const json = await res.json();
    return { ...json, user, token: json.licence, error: !res.ok };
  } catch ({ message, code }) {
    return { description: message, clientType: code, error: true };
  }
};

export const getUserInfoApi = async ({ headers }) => {
  const res = await fetch(`${Config.apiBaseurl}/${Config.apis.oauth.me}`, {
    method: "GET",
    headers,
  });

  const response = await res.json();

  if (res.ok) return response;
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const forgotPwdApi = async ({ email }) => {
  if (!(firebase.apps || []).length) {
    firebase.initializeApp(Config.firebaseConfig);
  }
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const logoutApi = async () => {
  if (!(firebase.apps || []).length) {
    firebase.initializeApp(Config.firebaseConfig);
  }
  await firebase.auth().signOut();
};

export async function registerApi({ email, password }) {
  if (!(firebase.apps || []).length) {
    firebase.initializeApp(Config.firebaseConfig);
  }
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const res = await axios.post(
    `${Config.apiBaseurl}/${Config.apis.auth.register}`,
    {
      email,
      password,
    },
    {
      headers: {
        "x-tenant": tenant,
      },
    }
  );
  (
    await firebase.auth().signInWithEmailAndPassword(email, password)
  ).user.sendEmailVerification({
    url: `${window.location.origin}/login?email_confirmation=true`,
  });
  return res.data;
}
