import axios from "axios";
import Config from "../Config";
import { getFromLocalStorage } from "../libs/helpers/localstorage";

export async function checkoutApi({ priceId, ...data }) {
  const ls = getFromLocalStorage();
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const token = ls && ls.token;
  return axios.get(`${Config.apiBaseurl}/${Config.apis.ecommerce.checkout}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-tenant": tenant,
    },
    params: {
      priceId,
      ...data,
    },
  });
}

export async function getActivePlansApi() {
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  return axios.get(
    `${Config.apiBaseurl}/${Config.apis.ecommerce.getActivePlans}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-tenant": tenant,
      },
    }
  );
}

export async function getCustomerPortalUrlApi({ returnTo } = {}) {
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  return axios.get(
    `${Config.apiBaseurl}/${Config.apis.ecommerce.getCustomerPortalUrl}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-tenant": tenant,
      },
      params: {
        returnTo,
      },
    }
  );
}

export async function getConsumptionApi({ periodStartDate } = {}) {
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  return axios.get(`${Config.apiBaseurl}/${Config.apis.consumption.api}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-tenant": tenant,
    },
    params: {
      periodStartDate,
    },
  });
}
