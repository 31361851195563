import { getActivePlansApi } from "../api/ecommerce.api";
import callApi from "../libs/helpers/callApi";

export const GET_ACTIVE_PLANS_LOADING = "GET_ACTIVE_PLANS_LOADING";
export const GET_ACTIVE_PLANS_LOADED = "GET_ACTIVE_PLANS_LOADED";
export const GET_ACTIVE_PLANS_LOAD_ERROR = "GET_ACTIVE_PLANS_LOAD_ERROR";

export const getActivePlansAction = callApi({
  apiCall: (p) => getActivePlansApi(p),
  secured: true,
  startAction: GET_ACTIVE_PLANS_LOADING,
  successAction: GET_ACTIVE_PLANS_LOADED,
  errorAction: GET_ACTIVE_PLANS_LOAD_ERROR,
});
