import React from "react";
import { SvgIcon } from "@mui/material";

export default function ProfileIcon(props) {
  return (
    <SvgIcon width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        d="M10.2427 1.75734C9.10945 0.624141 7.60266 0 6 0C4.39734 0 2.89055 0.624141 1.75734 1.75734C0.624141 2.89055 0 4.39734 0 6C0 7.60266 0.624141 9.10945 1.75734 10.2427C2.89055 11.3759 4.39734 12 6 12C7.60266 12 9.10945 11.3759 10.2427 10.2427C11.3759 9.10945 12 7.60266 12 6C12 4.39734 11.3759 2.89055 10.2427 1.75734ZM2.60414 10.0617C2.80219 8.35711 4.26797 7.04086 6 7.04086C6.91313 7.04086 7.77187 7.39664 8.41781 8.04234C8.96344 8.5882 9.3075 9.3007 9.39609 10.0615C8.47594 10.8321 7.29141 11.2969 6 11.2969C4.70859 11.2969 3.52406 10.8323 2.60414 10.0617ZM6 6.31664C4.99523 6.31664 4.17773 5.49914 4.17773 4.49437C4.17773 3.48961 4.99523 2.67211 6 2.67211C7.00477 2.67211 7.82227 3.48961 7.82227 4.49437C7.82227 5.49914 7.00477 6.31664 6 6.31664ZM10.0013 9.46734C9.82219 8.745 9.44836 8.07891 8.91492 7.54547C8.48273 7.11328 7.97156 6.78914 7.41398 6.58594C8.0843 6.13148 8.52563 5.36344 8.52563 4.49437C8.52563 3.10195 7.39266 1.96898 6.00023 1.96898C4.60781 1.96898 3.47484 3.10195 3.47484 4.49437C3.47484 5.36391 3.91641 6.13219 4.58719 6.58664C4.07414 6.77367 3.59953 7.06266 3.19102 7.44305C2.59969 7.99383 2.18883 8.69602 1.99852 9.46688C1.19203 8.53711 0.703359 7.32469 0.703359 6.00023C0.703359 3.07945 3.07945 0.703359 6.00023 0.703359C8.92102 0.703359 11.2971 3.07945 11.2971 6.00023C11.2971 7.32492 10.8082 8.53781 10.0015 9.46734H10.0013Z"
        fill="#565551"
      />
    </SvgIcon>
  );
}
