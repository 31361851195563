import React from "react";
import { SvgIcon } from "@mui/material";

export default function SadIcon(props) {
  return (
    <SvgIcon width="50" height="50" viewBox="0 0 50 50" fill="none" {...props}>
      <g clipPath="url(#clip0_65_1697)">
        <path
          d="M25 0C11.2145 0 0 11.2145 0 25C0 38.7855 11.2145 50 25 50C38.7855 50 50 38.7855 50 25C50 11.2145 38.7838 0 25 0ZM25 47.2973C18.875 47.2973 13.3209 44.8142 9.28716 40.8041C7.67568 39.2027 6.30912 37.3564 5.24493 35.3277C3.62331 32.2382 2.70439 28.7247 2.70439 25C2.7027 12.7044 12.7044 2.7027 25 2.7027C30.8311 2.7027 36.147 4.95439 40.1233 8.63176C42.1858 10.5389 43.8885 12.8277 45.1166 15.3885C46.5135 18.3007 47.2973 21.5608 47.2973 25C47.2973 37.2939 37.2956 47.2973 25 47.2973Z"
          fill="#565551"
        />
        <path
          d="M27.6416 31.6909C30.6061 32.3108 33.27 33.9781 35.1416 36.3852L37.275 34.7264C35.0115 31.8142 31.7868 29.7973 28.1939 29.0456C22.4169 27.8379 16.3443 30.0676 12.7227 34.7264L14.8561 36.3852C17.8493 32.5355 22.8662 30.6926 27.6399 31.6909H27.6416Z"
          fill="#565551"
        />
        <path
          d="M16.6675 22.1281C18.1602 22.1281 19.3702 20.918 19.3702 19.4254C19.3702 17.9327 18.1602 16.7227 16.6675 16.7227C15.1749 16.7227 13.9648 17.9327 13.9648 19.4254C13.9648 20.918 15.1749 22.1281 16.6675 22.1281Z"
          fill="#565551"
        />
        <path
          d="M33.3902 22.1281C34.8829 22.1281 36.0929 20.918 36.0929 19.4254C36.0929 17.9327 34.8829 16.7227 33.3902 16.7227C31.8975 16.7227 30.6875 17.9327 30.6875 19.4254C30.6875 20.918 31.8975 22.1281 33.3902 22.1281Z"
          fill="#565551"
        />
      </g>
      <defs>
        <clipPath id="clip0_65_1697">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
