import { SearchOutlined } from "@ant-design/icons";
import { Box } from "@mui/material";
import { Button, Card, Col, Input, Space, Table } from "antd";
import moment from "moment";
import React, { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import IndeedIcon from "../../components/Icons/indeed";
import Label from "../../components/Label/Label";
import Localize, { bareLocalize, l } from "../../libs/localization";

const textSorter = (key) => (a, b) => {
  if (a[key] < b[key]) return -1;
  if (a[key] > b[key]) return 1;
  return 0;
};

export default function CompanyJobOffers({ data, updatedAt }) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [searchDesc, setSearchDesc] = useState("");
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`${l(
            "PAGES.COMPANIES.DETAIL.JOB_OFFERS.SEARCH_IN"
          )} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 120,
            }}
          >
            {l("PAGES.COMPANIES.DETAIL.JOB_OFFERS.SEARCH")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 120,
            }}
          >
            {l("PAGES.COMPANIES.DETAIL.JOB_OFFERS.RESET")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "publishedAt",
      key: "publishedAt",
      sorter: textSorter("publishedAt"),
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      sorter: textSorter("jobTitle"),
      ...getColumnSearchProps("jobTitle"),
    },
    {
      title: "Contract Type",
      dataIndex: "contractType",
      key: "contractType",
      sorter: textSorter("contractType"),
      ...getColumnSearchProps("contractType"),
    },
    {
      title: "Place",
      dataIndex: "location",
      key: "location",
      sorter: textSorter("location"),
      ...getColumnSearchProps("location"),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: "Link",
      dataIndex: "url",
      key: "url",
      render(url) {
        return (
          <a href={url} rel="noreferrer" target="_blank">
            <IndeedIcon width="32" height="32" />
          </a>
        );
      },
    },
  ];

  return (
    <Card
      title={
        <h2>
          <b>{Localize("PAGES.COMPANIES.DETAIL.SECTIONS.JOB_OFFERS")}</b>
        </h2>
      }
      bordered={false}
      className="companyPage__body-card companyPage__body-card--custom customCard"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Col span={12} className="mt-3">
          <Label
            className="filter-color"
            value={Localize(
              "PAGES.COMPANIES.DETAIL.JOB_OFFERS.SEARCH_DESCRIPTION"
            )}
          />
          <Input
            value={searchDesc}
            onChange={(e) => {
              setSearchDesc(e.target.value);
            }}
          />
        </Col>
        <Col span={12} className="mt-3">
          <p>
            <Label
              className="filter-color"
              value={
                bareLocalize("COMMON.RESULTS")?.charAt(0).toUpperCase() +
                bareLocalize("COMMON.RESULTS")?.slice(1) +
                ":"
              }
            />
            &nbsp;
            {
              data.filter(
                searchDesc
                  ? (r) => r.description.toLowerCase().includes(searchDesc)
                  : () => true
              ).length
            }
          </p>
          {updatedAt ? (
            <p>
              <Label
                className="filter-color"
                value={
                  bareLocalize("COMMON.UPDATED_AT")?.charAt(0).toUpperCase() +
                  bareLocalize("COMMON.UPDATED_AT")?.slice(1) +
                  ":"
                }
              />
              &nbsp;
              {moment(updatedAt).format("DD/MM/YYYY HH:mm")}
            </p>
          ) : null}
        </Col>
      </Box>
      <Table
        dataSource={data
          .filter(
            searchDesc
              ? (r) => r.description.toLowerCase().includes(searchDesc)
              : () => true
          )
          .map((r) => ({ ...r, key: r._id }))}
        columns={columns}
        expandable={{
          expandedRowRender: (row) => (
            <div dangerouslySetInnerHTML={{ __html: row.description }} />
          ),
          rowExpandable: (row) => true,
        }}
      />
      ;
    </Card>
  );
}
