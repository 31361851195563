import React, { useEffect, useState } from "react";
import { Card, Typography, useTheme, Box } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import BannerTop from "../../components/BannerTop/BannerTop";
import { l } from "../../libs/localization";
import "./profile.style.scss";
import Button from "../../design-library/Button/Button.component";
import { getConsumptionApi } from "../../api/ecommerce.api";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";

export const pricingPageUrls = {
  italy: "https://blinkogroup.com/it/piani-e-prezzi/",
  uk: "https://blinkogroup.com/en/plans-pricing/",
  france: "https://blinkogroup.com/fr/forfaits-et-tarifs/",
};

function ProfileQuotasPage() {
  const theme = useTheme();
  const [consumption, setCompanyConsumption] = useState(null);
  const profile = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);

  const tenant = getFromLocalStorage("lefacWebTenant")?.data;

  const consumptionPlugin =
    profile.licence.tenants[tenant]?.plugins?.["6538ca30695c4015d3daeb7c"];
  let periodStartDate = null;
  let period = 1;
  if (consumptionPlugin) {
    period = consumptionPlugin.data.period === "month" ? 1 : 12;
    const firstDate = consumptionPlugin.data.periodStartDateTime;
    periodStartDate = moment(firstDate);
    while (periodStartDate.isBefore(moment().subtract(period, "months"))) {
      periodStartDate.add(period, "months");
    }
  }
  const periodStartDateMillis = periodStartDate.valueOf();
  async function loadInfo() {
    setLoading(true);
    const res = await getConsumptionApi({
      periodStartDate: periodStartDateMillis,
    });
    setCompanyConsumption(res.data?.consumption);
    setLoading(false);
  }

  useEffect(() => {
    loadInfo();
  }, []);

  return (
    <div className="profilePage">
      <BannerTop small variant="profile">
        <h1 className="mt-4 title">{l("PROFILE.QUOTAS")}</h1>
      </BannerTop>
      <Card
        sx={{
          p: 8,
          mt: -6,
          position: "relative",
          boxShadow: "0px 4px 10px 0px #00000026",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
        }}
      >
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                {l("ECOMMERCE.REMAINING_COMPANY_CREDITS")}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                <Typography
                  sx={{
                    display: "inline",
                    fontSize: 24,
                    color: theme.palette.primary.main,
                  }}
                >
                  {typeof consumption?.companies?.count === "number"
                    ? (consumptionPlugin.data.companyCredits || 0) -
                      consumption?.companies?.count
                    : "..."}
                </Typography>
                /{consumptionPlugin.data.companyCredits || 0}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                {l("ECOMMERCE.REMAINING_CONTACT_CREDITS")}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                <Typography
                  sx={{
                    display: "inline",
                    fontSize: 24,
                    color: theme.palette.primary.main,
                  }}
                >
                  {typeof consumption?.contacts?.count === "number"
                    ? (consumptionPlugin.data.contactCredits || 0) -
                      consumption?.contacts?.count
                    : "..."}
                </Typography>
                /{consumptionPlugin.data.contactCredits || 0}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                {l("PROFILE.QUOTAS.RENEWAL_DATE")}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  display: "inline",
                  fontSize: 24,
                  color: theme.palette.primary.main,
                }}
              >
                {periodStartDate.add(period, "months").format("DD/MM/YYYY")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ flex: { xs: 0, md: 0.15, lg: 0.5 } }} />
      </Card>
    </div>
  );
}

export default ProfileQuotasPage;
