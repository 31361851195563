import React, { useEffect } from "react";
import { Row, Col, Modal, Spin, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toUpper } from "lodash";
import { Link } from "react-router-dom";
import BannerTop from "../../components/BannerTop/BannerTop";
import { getBrandDetailAction } from "../../actions/brand.actions";
import CardAgencies from "../../components/Cards/Card.agency";
import CardCreas from "../../components/Cards/Card.creas";
import CardPitch from "../../components/Cards/Card.pitch";
import {
  getSocialIcon,
  translateRegistryOnView,
  turnKeyIntoLabel,
} from "../../libs/utils";
import ContactsBrandList from "../../components/List/Contacts.brand.list";
import { get } from "../../libs/helpers/ioc";
import "./brand.scss";

const BrandModal = (props) => {
  const { visible, close, id } = props;
  const permissions = get("scopes");
  const data = useSelector((state) => state.brand.detail);
  const loading = useSelector((state) => state.brand.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBrandDetailAction({ id }));
  }, [id]);

  if (data && data.data) {
    const { brand, pitchs, creas, contacts } = data.data;
    if (brand.data._id !== id) return null;
    return (
      <div className="brandPage">
        <>
          {loading || brand.loading ? (
            <Spin
              style={{ marginTop: 20, marginBottom: 20, marginRight: 10 }}
            />
          ) : (
            <>
              <Modal
                key={brand.data._id}
                wrapClassName="brandPage__modal"
                visible={visible}
                onCancel={() => close()}
                width="900px"
                title={
                  <div className="brandPage__head profile__container">
                    <BannerTop>
                      <div
                        className="profile__container--left"
                        style={{
                          width: "100%",
                          zIndex: 1,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {brand.data.logo && (
                          <div
                            style={{
                              backgroundImage: `url("${brand.data.logo}")`,
                              backgroundPositionX: "left",
                            }}
                            className="brandPage__head-logo"
                          />
                        )}

                        <div className="profile__data">
                          <h2>{toUpper(brand.data.name)}</h2>
                          <div>
                            <Link
                              style={{
                                color: "white",
                                pointerEvents: Object.keys(
                                  permissions
                                )?.includes("company")
                                  ? ""
                                  : "none",
                              }}
                              to={`/companies/${brand.data.company.id}`}
                            >
                              {toUpper(brand.data.company.name)}
                            </Link>
                          </div>
                          {brand.data.website && (
                            <div className="profile__data-function">
                              <a href={brand.data.website} target="_blank">
                                {brand.data.website}
                              </a>
                            </div>
                          )}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            {brand.data?.sector?.length > 0 &&
                              brand.data.sector.map((sector, i) => (
                                <Tag className="my-2" key={sector._id}>
                                  {translateRegistryOnView(sector.key, "Sector")
                                    ? translateRegistryOnView(
                                        sector.key,
                                        "Sector"
                                      )
                                    : sector.key}
                                </Tag>
                              ))}
                          </div>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            {brand.data?.external_links?.length > 0 &&
                              brand.data.external_links.map(
                                (external_link, i) =>
                                  external_link.link && (
                                    <Tag
                                      icon={getSocialIcon(external_link)}
                                      className="my-2 filled tag-bg"
                                      key={external_link._id}
                                    >
                                      <a
                                        href={external_link.link}
                                        target="_Blank"
                                      >
                                        {external_link.label
                                          ? turnKeyIntoLabel(
                                              external_link.label
                                            )
                                          : i.link}
                                      </a>
                                    </Tag>
                                  )
                              )}
                          </div>
                        </div>
                      </div>
                    </BannerTop>
                  </div>
                }
              >
                <Col className="brandPage__body">
                  <Row style={{ width: "100%" }}>
                    <Col span={24}>
                      <Row gutter={16}>
                        {brand.data?.agencies &&
                          brand.data?.agencies?.length > 0 && (
                            <Col span={24}>
                              <CardAgencies data={brand.data} />
                            </Col>
                          )}
                        {creas?.data && creas?.data?.length > 0 && (
                          <Col span={24}>
                            <CardCreas data={creas?.data} />
                          </Col>
                        )}
                        {pitchs?.data && pitchs?.data?.length > 0 && (
                          <Col span={24}>
                            <CardPitch data={pitchs?.data} />
                          </Col>
                        )}
                        {contacts?.data && contacts?.data?.length > 0 && (
                          <Col span={24}>
                            <ContactsBrandList data={contacts.data} />
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Modal>
            </>
          )}
        </>
      </div>
    );
  }
  return null;
};

export default BrandModal;
