import {
  Card,
  CardContent,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import BlinkoLogo from "../../design-library/BlinkoLogo";
import Button from "../../design-library/Button/Button.component";
import EmailSentIcon from "../../design-library/icons/EmailSentIcon.component";
import ThumbsDownIcon from "../../design-library/icons/ThumbsDownIcon.component";

export default function PaymentFailedPage() {
  const theme = useTheme();
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
        py: 8,
        width: "100%",
        maxWidth: "none !important",
        backgroundColor: theme.palette.primary.light,
        minHeight: "100vh",
      }}
    >
      <Card sx={{ width: "680px" }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            px: 5.5,
            py: 7.5,
            alignItems: "center",
            position: "relative",
          }}
        >
          <BlinkoLogo style={{ maxWidth: 180, alignSelf: "center" }} />
          <ThumbsDownIcon sx={{ fontSize: 60 }} />
          <Typography color="error" sx={{ mt: 2.5 }} variant="h3">
            Spiacenti, qualcosa è andato storto
          </Typography>
          <Typography variant="body1">
            Esegui di nuovo il processo di pagamento e assicurati di avere fondi
            a disposizione sulla carta di credito utilizzata.
          </Typography>
          <Link style={{ display: "block", alignSelf: "stretch" }} to="/">
            <Button sx={{ mt: 12, width: "100%" }} type="submit">
              vai alla home
            </Button>
          </Link>
        </CardContent>
        <div />
      </Card>
    </Container>
  );
}
