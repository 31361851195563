import { ButtonBase, useTheme } from "@mui/material";
import React from "react";

export default function Button({
  children,
  color = "primary",
  thin = false,
  ...props
}) {
  const theme = useTheme();
  const backgroundColor = {
    primary: theme.palette.primary.main,
    gray: "#C0BEB3",
  }[color];
  const color_ = {
    primary: theme.palette.primary.contrastText,
    gray: "#fff",
  }[color];
  return (
    <ButtonBase
      {...props}
      sx={{
        px: 2.75,
        py: thin ? 1 : 2.5,
        alignSelf: "stretch",
        backgroundColor,
        color: color_,
        textTransform: "uppercase",
        borderRadius: "10px",
        "&.Mui-disabled": {
          opacity: 0.5,
        },
        ...props.sx,
      }}
    >
      {children}
    </ButtonBase>
  );
}
