import React from "react";
import { Tooltip } from "antd";
import { truncate } from "../../libs/utils";

const Truncate = (props) => {
  const { string, truncateVal = 30, placement = "top" } = props;

  return (
    <Tooltip title={string} placement={placement}>
      <span>{truncate(string, truncateVal)}</span>
    </Tooltip>
  );
};

export default Truncate;
