import callApi from "../libs/helpers/callApi";
import { getBrandListApi } from "../api/brand.api";
import { searchAutocompleteApi } from "../api/common.api";
import {
  createSearch,
  deleteSearch,
  getPlaceListApi,
  getSavedSearchList,
  getSingleSavedSearch,
  searchBrandsApi,
  searchCompaniesApi,
  searchContactsApi,
  searchPitchesApi,
  searchWorkRelationApi,
} from "../api/search.api";
import { SET_LANGUAGE } from "./app.actions";

export const SEARCH_LOADING = "SEARCH_LOADING";
export const SEARCH_LOADED = "SEARCH_LOADED";
export const SEARCH_LOAD_ERROR = "SEARCH_LOAD_ERROR";

export const fastSearchAction = callApi({
  apiCall: (p) => searchAutocompleteApi(p),
  secured: true,
  startAction: SEARCH_LOADING,
  successAction: SEARCH_LOADED,
  errorAction: SEARCH_LOAD_ERROR,
});

export const SEARCH_CONTACT_LOADING = "SEARCH_CONTACT_LOADING";
export const SEARCH_CONTACT_LOADED = "SEARCH_CONTACT_LOADED";
export const SEARCH_CONTACT_LOAD_ERROR = "SEARCH_CONTACT_LOAD_ERROR";

export const searchContactsAction = callApi({
  apiCall: (p) => searchContactsApi(p),
  secured: true,
  startAction: SEARCH_CONTACT_LOADING,
  successAction: SEARCH_CONTACT_LOADED,
  errorAction: SEARCH_CONTACT_LOAD_ERROR,
});

export const SEARCH_COMPANY_LOADING = "SEARCH_COMPANY_LOADING";
export const SEARCH_COMPANY_LOADED = "SEARCH_COMPANY_LOADED";
export const SEARCH_COMPANY_LOAD_ERROR = "SEARCH_COMPANY_LOAD_ERROR";

export const searchCompaniesAction = callApi({
  apiCall: (p) => searchCompaniesApi(p),
  secured: true,
  startAction: SEARCH_COMPANY_LOADING,
  successAction: SEARCH_COMPANY_LOADED,
  errorAction: SEARCH_COMPANY_LOAD_ERROR,
});

export const SEARCH_BRAND_LOADING = "SEARCH_BRAND_LOADING";
export const SEARCH_BRAND_LOADED = "SEARCH_BRAND_LOADED";
export const SEARCH_BRAND_LOAD_ERROR = "SEARCH_BRAND_LOAD_ERROR";

export const searchBrandsAction = callApi({
  apiCall: (p) => searchBrandsApi(p),
  secured: true,
  startAction: SEARCH_BRAND_LOADING,
  successAction: SEARCH_BRAND_LOADED,
  errorAction: SEARCH_BRAND_LOAD_ERROR,
});

export const SEARCH_PITCH_LOADING = "SEARCH_PITCH_LOADING";
export const SEARCH_PITCH_LOADED = "SEARCH_PITCH_LOADED";
export const SEARCH_PITCH_LOAD_ERROR = "SEARCH_PITCH_LOAD_ERROR";

export const searchPitchesAction = callApi({
  apiCall: (p) => searchPitchesApi(p),
  secured: true,
  startAction: SEARCH_PITCH_LOADING,
  successAction: SEARCH_PITCH_LOADED,
  errorAction: SEARCH_PITCH_LOAD_ERROR,
});

export const SEARCH_WORK_RELATION_LOADING = "SEARCH_WORK_RELATION_LOADING";
export const SEARCH_WORK_RELATION_LOADED = "SEARCH_WORK_RELATION_LOADED";
export const SEARCH_WORK_RELATION_LOAD_ERROR =
  "SEARCH_WORK_RELATION_LOAD_ERROR";

export const searchWorkRelationAction = callApi({
  apiCall: (p) => searchWorkRelationApi(p),
  secured: true,
  startAction: SEARCH_WORK_RELATION_LOADING,
  successAction: SEARCH_WORK_RELATION_LOADED,
  errorAction: SEARCH_WORK_RELATION_LOAD_ERROR,
});

export const SEARCH_RESET = "SEARCH_RESET";

export const resetSearchAction = () => ({
  type: SEARCH_RESET,
});

export const SEARCH_LIST_LOADING = "SEARCH_LIST_LOADING";
export const SEARCH_LIST_LOADED = "SEARCH_LIST_LOADED";
export const SEARCH_LIST_LOAD_ERROR = "SEARCH_LIST_LOAD_ERROR";

export const getSearchListAction = callApi({
  apiCall: (p) => getSavedSearchList(p),
  secured: true,
  startAction: SEARCH_LIST_LOADING,
  successAction: SEARCH_LIST_LOADED,
  errorAction: SEARCH_LIST_LOAD_ERROR,
});

export const CREATE_SEARCH_LOADING = "CREATE_SEARCH_LOADING";
export const CREATE_SEARCH_LOADED = "CREATE_SEARCH_LOADED";
export const CREATE_SEARCH_LOAD_ERROR = "CREATE_SEARCH_LOAD_ERROR";

export const createSearchAction = callApi({
  apiCall: (p) => createSearch(p),
  secured: true,
  startAction: CREATE_SEARCH_LOADING,
  successAction: CREATE_SEARCH_LOADED,
  errorAction: CREATE_SEARCH_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params, result }) => {
    // params?.body?.type === "contact" &&
    //   dispatch(getSearchListByIdAction({ id: result?.data?._id }));
    dispatch(getSearchListAction({ params: `entity=${params?.body?.type}` }));
  },
});

export const DELETE_SEARCH_LOADING = "DELETE_SEARCH_LOADING";
export const DELETE_SEARCH_LOADED = "DELETE_SEARCH_LOADED";
export const DELETE_SEARCH_LOAD_ERROR = "DELETE_SEARCH_LOAD_ERROR";

export const deleteSearchAction = callApi({
  apiCall: (p) => deleteSearch(p),
  secured: true,
  startAction: DELETE_SEARCH_LOADING,
  successAction: DELETE_SEARCH_LOADED,
  errorAction: DELETE_SEARCH_LOAD_ERROR,
  afterSuccessAction: ({ dispatch, params }) => {
    dispatch(getSearchListAction({ params: `entity=${params?.type}` }));
  },
});

export const SEARCH_LIST_BY_ID_LOADING = "SEARCH_LIST_BY_ID_LOADING";
export const SEARCH_LIST_BY_ID_LOADED = "SEARCH_LIST_BY_ID_LOADED";
export const SEARCH_LIST_BY_ID_LOAD_ERROR =
  "SEARCH_LIST_BY_ID_BY_ID_LOAD_ERROR";

export const getSearchListByIdAction = callApi({
  apiCall: (p) => getSingleSavedSearch(p),
  secured: true,
  startAction: SEARCH_LIST_BY_ID_LOADING,
  successAction: SEARCH_LIST_BY_ID_LOADED,
  errorAction: SEARCH_LIST_BY_ID_LOAD_ERROR,
});

export const CITIES_LIST_LOADING = "CITIES_LIST_LOADING";
export const CITIES_LIST_LOADED = "CITIES_LIST_LOADED";
export const CITIES_LIST_LOAD_ERROR = "CITIES_LIST_LOAD_ERROR";

export const getCitiesListAction = callApi({
  apiCall: (p) => getPlaceListApi(p),
  secured: true,
  startAction: CITIES_LIST_LOADING,
  successAction: CITIES_LIST_LOADED,
  errorAction: CITIES_LIST_LOAD_ERROR,
});

export const PROVINCES_LIST_LOADING = "PROVINCES_LIST_LOADING";
export const PROVINCES_LIST_LOADED = "PROVINCES_LIST_LOADED";
export const PROVINCES_LIST_LOAD_ERROR = "PROVINCES_LIST_LOAD_ERROR";

export const getProvincesListAction = callApi({
  apiCall: (p) => getPlaceListApi(p),
  secured: true,
  startAction: PROVINCES_LIST_LOADING,
  successAction: PROVINCES_LIST_LOADED,
  errorAction: PROVINCES_LIST_LOAD_ERROR,
});

export const REGIONS_LIST_LOADING = "REGIONS_LIST_LOADING";
export const REGIONS_LIST_LOADED = "REGIONS_LIST_LOADED";
export const REGIONS_LIST_LOAD_ERROR = "REGIONS_LIST_LOAD_ERROR";

export const getRegionsListAction = callApi({
  apiCall: (p) => getPlaceListApi(p),
  secured: true,
  startAction: REGIONS_LIST_LOADING,
  successAction: REGIONS_LIST_LOADED,
  errorAction: REGIONS_LIST_LOAD_ERROR,
});
