import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Row, Select, Tooltip } from "antd";
import { groupBy, orderBy } from "lodash";
import StarOutlined from "@ant-design/icons/es/icons/StarOutlined";
import StarFilled from "@ant-design/icons/es/icons/StarFilled";
import { v4 as uuidv4 } from "uuid";
import { SearchOutlined } from "@ant-design/icons";
import { Box } from "@mui/material";
import Localize from "../../components/Localized/Localize.comp";
import { getFunctionAction } from "../../actions/registry/registry.function.actions";
import { searchContactsAction } from "../../actions/search.action";
import ContactList from "../../components/List/Contact.list";
import TagOptions from "../../components/Tag/TagOptions";
import CreateTagModal from "../../components/CreateTagModal/CreateTagModal";
import {
  associateTagAction,
  associationsTagIdContactAction,
  createTagAction,
  getTagAction,
} from "../../actions/tag.actions";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import Label from "../../components/Label/Label";
import { translateRegistryOnView } from "../../libs/utils";
import usePermissions from "../../hooks/usePermissions";
import { getConsumptionApi } from "../../api/ecommerce.api";
import LimitedFeatureBanner from "../../design-library/LimitedFeatureBanner/LimitedFeatureBanner.component";
import { l } from "../../libs/localization";

const CompanyContacts = (props) => {
  const { companyId } = props;
  const dispatch = useDispatch();
  const permissions = usePermissions();
  const [mainFunct, setMainFunct] = useState(null);

  // TAG
  const [tagMode, setTagMode] = useState(false);
  const [tagAddModal, setAddTagModal] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [colorValue, setColorValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [parent, setParent] = useState();
  const [selectedFunctionValue, setSelectedFunctionValue] = useState([]);
  const [functionChild, setFunctionChild] = useState([]);
  const [filters, setFilters] = useState({});
  const [consumption, setConsumption] = useState(null);
  const [contactsPageSize, setContactsPageSize] = useState(20);

  const contacts = useSelector((state) => state.search.results.data);
  const loadingContacts = useSelector((state) => state.search.results.loading);
  const functions = useSelector((state) => state.functionReg.data);
  const tags = useSelector((state) => state.tag.tagsList);
  const contactSummary = useSelector(
    (state) => state.company.contactSummary.data
  );
  const user = useSelector((state) => state.auth.user);
  const tenant = getFromLocalStorage("lefacWebTenant");

  const ids = [];

  const loadingTagsIdsContact = useSelector(
    (state) => state.tag.tagsForIdsContact.loading
  );
  const associatedTagsIdsContact = useSelector(
    (state) => state.tag.tagsForIdsContact.data
  );

  async function loadConsumption() {
    try {
      const res = await getConsumptionApi();
      setConsumption(res.data?.consumption);
    } catch (e) {}
  }

  useEffect(() => {
    loadConsumption();
  }, []);

  useEffect(() => {
    searchRequest();
  }, []);

  useEffect(() => {
    if (functions.length === 0) {
      dispatch(getFunctionAction());
    }
  }, []);

  useEffect(() => {
    const userProfiles = user?.licence?.tenants?.[tenant?.data]?.profiles;
    const groupedFunctions = groupBy(functions.enum, "parent");
    let newGroupedFunctions = {};
    contactSummary.forEach((element) => {
      Object.keys(groupedFunctions).forEach((key) => {
        if (key === element.functionParent)
          newGroupedFunctions[key] = groupedFunctions[key];
      });
    });
    let sortedNewGroupedFunctions = Object.keys(newGroupedFunctions)
      .sort()
      .reduce((acc, currValue) => {
        acc[currValue] = newGroupedFunctions[currValue];
        return acc;
      }, {});
    setParent(sortedNewGroupedFunctions);
  }, [user, functions]);

  useEffect(() => {
    if (permissions.favourites) {
      dispatch(getTagAction());
    }
  }, [tagAddModal]);

  useEffect(() => {
    const groupedFunctions = groupBy(functions.enum, "parent");
    setFunctionChild(groupedFunctions[mainFunct]);
  }, [mainFunct]);

  const handleOrder = (val) => {
    setMainFunct(val);
    setSelectedFunctionValue([]);
  };

  const searchRequest = () => {
    const params = {
      company_filters: {
        id: companyId,
      },
      pageNum: 1,
      limit: contactsPageSize,
    };

    if (mainFunct && selectedFunctionValue.length === 0) {
      params.function_parent = [mainFunct];
    }

    if (selectedFunctionValue?.length > 0) {
      params.function = selectedFunctionValue;
    }

    setFilters(params);

    dispatch(
      searchContactsAction({
        data: params,
      })
    );
  };

  const getContactPageIds = () => {
    if (contacts.contacts && contacts.contacts.data) {
      contacts.contacts.data.forEach((contact) => {
        ids.push(contact._id);
      });
      if (permissions.favourites) {
        dispatch(associationsTagIdContactAction({ data: { id: ids } }));
      }
    }
  };

  useEffect(() => {
    getContactPageIds();
  }, [contacts.contacts.data, tagMode]);

  useEffect(() => {
    searchRequest();
  }, [mainFunct]);

  const clearTagFields = () => {
    setTagValue("");
    setColorValue("");
    setErrorMessage("");
  };

  const createTag = () => {
    const params = {
      name: tagValue,
      color: colorValue.hex.replace("#", "").toUpperCase(),
    };

    dispatch(createTagAction({ data: params }));
    clearTagFields();
  };

  const associateTagContact = () => {
    const dataParams = {};
    let id = "";

    tags.forEach((tag) => {
      if (tag.name === selectedTag) {
        id = tag._id;
      }
    });

    dataParams.name = selectedTag;
    dataParams.contacts = selectedContact;
    dispatch(associateTagAction({ id, data: dataParams }));
    setTagMode(false);
    setSelectedTag("");
  };

  const filterLabel = (value) => {
    return (
      <Button
        type="link"
        key={uuidv4()}
        className={`uppercase ${mainFunct === value ? "selected" : ""}`}
        onClick={() => handleOrder(value)}
      >
        {!value ? Localize("COMMON.ALL") : value}
      </Button>
    );
  };

  return (
    <>
      {contacts && contacts.contacts.data && (
        <div className="animation fadein-right slow">
          <Row gutter={0} align="middle" className="searchPage__lineFilters">
            {permissions.favourites ? (
              <Tooltip title="Associa Tag">
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => setTagMode(!tagMode)}
                  icon={
                    tagMode ? (
                      <StarFilled className="mr-2" style={{ fontSize: 20 }} />
                    ) : (
                      <StarOutlined className="mr-2" style={{ fontSize: 20 }} />
                    )
                  }
                />
              </Tooltip>
            ) : null}
            <Col>
              {filterLabel(null)}
              {parent && Object.keys(parent).map((val) => filterLabel(val))}
            </Col>
            <CreateTagModal
              visible={tagAddModal}
              onCancel={() => {
                clearTagFields();
                setAddTagModal(false);
              }}
              onOk={() => {
                createTag();
                setAddTagModal(false);
              }}
              okDisabled={!tagValue || !colorValue || errorMessage}
              tagValue={tagValue}
              colorValue={colorValue}
              onTagChange={(e) => {
                const foundedTag = tags.find(
                  (tag) =>
                    tag.name.toLowerCase() === e.target.value.toLowerCase()
                );
                if (foundedTag) {
                  setErrorMessage(Localize("TAG.ERROR_MESSAGE"));
                } else {
                  setErrorMessage("");
                }
                setTagValue(e.target.value);
              }}
              onColorChange={(color) => setColorValue(color)}
              errorMessage={errorMessage}
            />
          </Row>
          {tagMode && (
            <TagOptions
              onAddTagClick={() => setAddTagModal(true)}
              tagList={tags}
              value={selectedTag}
              onTagChange={(value) => setSelectedTag(value)}
              onAssociateClick={associateTagContact}
              associateDisabled={!selectedTag || selectedContact.length === 0}
            />
          )}
          {mainFunct && (
            <Card
              bordered={false}
              style={{ borderRadius: "10px", marginTop: 12 }}
              className="companyPage__body-card companyPage__body-card--custom customCard"
            >
              <Row
                gutter={16}
                justify="space-between"
                className="contact_search"
                align="bottom"
              >
                <Col span={8}>
                  <Label
                    className="filter-color"
                    value={Localize("SCHEMA.DEFAULT.CONTACT.FUNCTION")}
                  />
                  <Select
                    mode="multiple"
                    allowClear={true}
                    style={{ width: "100%" }}
                    value={selectedFunctionValue}
                    onChange={(value, opt) => {
                      setSelectedFunctionValue(value);
                    }}
                    showSearch
                    options={
                      functionChild &&
                      functionChild.length > 0 &&
                      orderBy(functionChild, ["id"], ["asc"]).map((e, j) => {
                        return {
                          value: e.id,
                          key: e.id,
                          label: translateRegistryOnView(e.id, "function", true)
                            ? translateRegistryOnView(e._id, "function", true)
                            : e.id,
                          id: e.id,
                        };
                      })
                    }
                  />
                </Col>
                <Col>
                  <Button type="primary" onClick={() => searchRequest()}>
                    <SearchOutlined className="search-icn" />
                    {Localize("COMMON.SEARCH")}
                  </Button>
                </Col>
              </Row>
            </Card>
          )}
          <ContactList
            data={contacts.contacts.data}
            pageSize={contactsPageSize}
            setPageSize={setContactsPageSize}
            total={contacts.contacts.totalResults}
            tagMode={tagMode}
            resultsLimited={contacts.contacts.resultsLimited}
            setSelectedContact={setSelectedContact}
            filters={filters}
            loadingList={loadingContacts}
            loadingTag={loadingTagsIdsContact}
            associatedTagsIds={
              associatedTagsIdsContact && associatedTagsIdsContact
            }
            alreadyOpened={consumption?.contacts?.ids}
            consumption={consumption}
            setConsumption={setConsumption}
            onContactOpen={(id) => {
              if (consumption && !consumption?.contacts?.ids?.includes(id)) {
                setConsumption({
                  ...(consumption || {}),
                  contacts: {
                    ...(consumption?.contacts || {}),
                    ids: [...(consumption?.contacts?.ids || []), id],
                  },
                });
              }
            }}
          />
          <Box sx={{ color: "black" }}>
            {contacts?.contacts?.resultsLimited ? (
              <LimitedFeatureBanner
                message={l("ECOMMERCE.SEARCH_RESULTS_LIMIT_TEXT")}
              />
            ) : null}
          </Box>
        </div>
      )}
    </>
  );
};

export default CompanyContacts;
