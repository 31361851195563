import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFromLocalStorage } from "../libs/helpers/localstorage";

export default function usePermissions() {
  const user = useSelector((state) => state.auth.user);
  const [permissions, setPermissions] = useState({
    companyInvestments: false,
    downloadContacts: false,
    downloadCompanies: false,
    investmentRounds: false,
    comments: false,
    collectiveAgreements: false,
    jobOffers: false,
    ecommerce: false,
    favourites: false,
    contactNomination: false,
    savedSearch: false,
    entityNews: false,
    companyFinancials: false,
    activemailer: false,
    mediaPlanning: false,
    pitchScores: false,
    companyQuota: false,
    contactQuota: false,
    weeklyUpdateEmail: false,
    exportContactsV2: false,
    exportCompaniesV2: false,
    emailAlerts: false,
  });

  useEffect(() => {
    function hasPermission(permission) {
      return user.scopes?.[
        getFromLocalStorage("lefacWebTenant")?.data
      ]?.includes(permission);
    }
    if (user) {
      const delta = {};
      const updated = {
        companyInvestments: hasPermission("companyInvestments"),
        investmentRounds: hasPermission("investmentRounds"),
        downloadContacts: hasPermission("p_contactsExport:any"),
        downloadCompanies: hasPermission("p_companyExport:any"),
        comments: hasPermission("comments"),
        collectiveAgreements: hasPermission("collectiveAgreements"),
        ecommerce: hasPermission("ecommerce"),
        favourites: hasPermission("favourites"),
        contactNomination: hasPermission("contactNomination"),
        savedSearch: hasPermission("savedSearch"),
        entityNews: hasPermission("entityNews"),
        companyFinancials: hasPermission("companyFinancials"),
        jobOffers: hasPermission("jobOffers"),
        activemailer: hasPermission("activemailer"),
        mediaPlanning: hasPermission("mediaPlanning"),
        pitchScores: hasPermission("pitchScores"),
        companyQuota: hasPermission("companyQuota"),
        contactQuota: hasPermission("contactQuota"),
        weeklyUpdateEmail: hasPermission("weeklyUpdateEmail"),
        exportContactsV2: hasPermission("exportContactsV2"),
        exportCompaniesV2: hasPermission("exportCompaniesV2"),
        emailAlerts: hasPermission("emailAlerts"),
      };
      Object.keys(updated).forEach((key) => {
        const value = updated[key];
        if (permissions[key] !== value) delta[key] = value;
      });
      if (Object.keys(delta).length > 0) {
        setPermissions({
          ...permissions,
          ...delta,
        });
      }
    }
  }, [user]);
  return permissions;
}
