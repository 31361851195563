import React, { Component } from "react";
import { bool, array } from "prop-types";

import Localize from "../../components/Localized/Localize.comp";
import { Button, Table as AntdTable, Spin, Col, Row } from "antd";
import { isEmpty, size, map, cloneDeep } from "lodash";
import { generateColumns, EmptySet } from "./_Table.helpers";
import { ReloadOutlined } from "@ant-design/icons";

import "./Table.scss";
import mergeDeep from "../../libs/helpers/mergeDeep";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = { filtering: false };
  }
  static propTypes = {
    loading: bool.isRequired,
    columns: array,
    data: array.isRequired,
    enableRowSelection: bool,
  };

  static defaultProps = {
    loading: false,
    data: [],
    enableRowSelection: false,
  };

  render() {
    const { filtering = false } = this.state;
    const {
      pageSize,
      current = 1,
      total,
      loading,
      data,
      size,
      bordered = true,
      entitySchema,
      columns = generateColumns({
        entity: entitySchema,
        data,
        parent: this,
      }),
      rowExtra,
      onChange,
      onDataReload,
      enableRowSelection,
      selectedRowKeys,
      onRowSelection,
      onRowClick,
    } = this.props;

    const tableData = cloneDeep(data);

    return (
      <>
        {typeof onDataReload === "function" && (
          <Row style={{ marginBottom: 20 }} justify="end">
            <Col span="auto">
              <Button
                onClick={onDataReload}
                disabled={loading || filtering}
                size="small"
              >
                <ReloadOutlined /> {Localize("COMMON.RELOAD_DATA")}
              </Button>
            </Col>
          </Row>
        )}
        <div className="table-component">
          <Spin spinning={loading} className="table-component">
            {
              //!isEmpty(data) || filtering ? (
              !isEmpty(data) || columns ? (
                <AntdTable
                  /* getPopupContainer is workaround to let the dropdown stick on table headers */
                  // getPopupContainer={(trigger) => trigger.parentNode}
                  className={[
                    ...(typeof onRowClick === "function"
                      ? ["clickable-rows"]
                      : []),
                  ].join(" ")}
                  rowSelection={
                    enableRowSelection && {
                      selectedRowKeys,
                      onChange: onRowSelection,
                    }
                  }
                  onRow={(record, rowIndex) => ({
                    onClick: (e) =>
                      typeof onRowClick === "function" &&
                      onRowClick(record, rowIndex, e),
                  })}
                  columns={columns}
                  dataSource={(tableData || []).map((d, i) => {
                    if (!d.key) {
                      d.key = i;
                    }
                    map(d, (v, k) => {
                      if (typeof v === "object") {
                        v = v.name || v.label || v.value;
                      }
                      d[k] = v;
                    });
                    return d;
                  })}
                  defaultExpandAllRows={!!rowExtra}
                  expandable={
                    !!rowExtra &&
                    typeof rowExtra === "function" && {
                      expandedRowRender: rowExtra,
                      expandIconColumnIndex: -1,
                    }
                  }
                  onChange={typeof onChange === "function" && onChange}
                  pagination={
                    pageSize
                      ? {
                          total,
                          pageSize,
                          current,
                          size: "small",
                          showSizeChanger: false,
                          showLessItems: true,
                          position: [/* "topRight",*/ "bottomRight"],
                        }
                      : false
                  }
                  scroll={{ x: "100%" }}
                  bordered={bordered}
                  size={size}
                  locale={{
                    emptyText: <EmptySet />,
                  }}
                  showSorterTooltip={false}
                />
              ) : (
                <div style={{ textAlign: "center", opacity: 0.8 }}>
                  {!loading && <EmptySet />}
                </div>
              )
            }
          </Spin>
        </div>
      </>
    );
  }
}

export default Table;
