import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import Table from "../../components/Table/Table";
import FinancialGraph from "../../components/FinancialGraph/FinancialGraph";
import { orderBy } from "lodash";
import Localize from "../../libs/localization";
import { currencyFormatter } from "../../libs/utils";

const renderData = (data, sort = "desc", format = false) => {
  if (!data) return [];
  const tableData = [];
  Object.keys(data).map((year, i) => {
    if (
      data[year].revenue ||
      data[year].operatingResult ||
      data[year].employees ||
      data[year].personnelCost
    ) {
      const tempData = {};
      tempData.year = year;
      tempData.key = i;
      tempData.revenue = data[year].revenue ? data[year].revenue : 0;
      tempData.operatingResult = data[year].operatingResult
        ? data[year].operatingResult
        : 0;
      tempData.employees = data[year].employees ? data[year].employees : 0;
      tempData.personnelCost = data[year].personnelCost;
      if (format) {
        tempData.revenue = currencyFormatter.format(tempData.revenue);
        tempData.operatingResult = currencyFormatter.format(
          tempData.operatingResult
        );
        if (tempData.personnelCost)
          tempData.personnelCost = currencyFormatter.format(
            tempData.personnelCost
          );
      }
      tableData.push(tempData);
    }
  });
  return orderBy(tableData, "year", sort);
};

const CompanyFinancial = (props) => {
  const { data } = props;
  const [showPersonnelCost, setShowPersonnelCost] = useState(false);

  const columns = [
    {
      title: (
        <div style={{ fontWeight: "bold" }}>{Localize("COMMON.YEAR")}</div>
      ),
      dataIndex: "year",
    },
    {
      title: (
        <div style={{ fontWeight: "bold" }}>{Localize("COMMON.REVENUE")}</div>
      ),
      dataIndex: "revenue",
    },
    {
      title: (
        <div style={{ fontWeight: "bold" }}>{Localize("COMMON.INCOME")}</div>
      ),
      dataIndex: "operatingResult",
    },
    {
      title: (
        <div style={{ fontWeight: "bold" }}>{Localize("COMMON.EMPLOYEES")}</div>
      ),
      dataIndex: "employees",
    },
  ];

  useEffect(() => {
    Object.keys(data).forEach((year) => {
      if (!!data[year].personnelCost) setShowPersonnelCost(true);
    });
  }, [data]);

  return (
    <Card
      title={
        <h2>
          <b>{Localize("PAGES.COMPANIES.DETAIL.SECTIONS.FINANCIALS")}</b>
        </h2>
      }
      bordered={false}
      className="companyPage__body-card companyPage__body-card--custom customCard"
    >
      {data && (
        <Table
          data={renderData(data, undefined, true)}
          columns={
            showPersonnelCost
              ? [
                  ...columns,
                  {
                    title: (
                      <div style={{ fontWeight: "bold" }}>
                        {Localize("COMMON.PERSONNEL_COST")}
                      </div>
                    ),
                    dataIndex: "personnelCost",
                  },
                ]
              : columns
          }
          size="small"
          bordered={false}
        />
      )}
      {data && (
        <div style={{ marginTop: 50 }}>
          <FinancialGraph
            data={renderData(data, "asc")}
            showPersonnelCost={showPersonnelCost}
          />
        </div>
      )}
    </Card>
  );
};

export default CompanyFinancial;
