import React from "react";

import "./Placeholders.scss";

export default ({ height = 44, width = "40%" }) => (
  <div
    className="placeholders placeholder-title"
    style={{ height, width }}
  ></div>
);
