import React, { useEffect, useState } from "react";
import { Card, Row, Col, Spin } from "antd";
import Localize from "../../libs/localization";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyGroupAction } from "../../actions/company.actions";
import { Link, useHistory } from "react-router-dom";

const CompanyGroup = (props) => {
  const { companyId, companyName } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [fullGroup, setFullGroup] = useState({});

  const group = useSelector((state) => state.company.group);
  useEffect(() => {
    dispatch(getCompanyGroupAction({ id: companyId }));
  }, [companyId]);

  useEffect(() => {
    if (group.data) {
      let reversedList = reverse(group.data, undefined);

      setFullGroup(reversedList);
    }
  }, [group]);

  const goToCompany = (id) => {
    history.push(`/companies/${id}`);
  };

  function reverse(list, prev) {
    // if this is the last node, switch it with previous and return
    if (list.parent == undefined) {
      list.parent = prev;
      return list;
    }

    // otherwise, switch it with the reverse of what is parent
    var ret = reverse(list.parent, list);
    list.parent = prev;
    return ret;
  }

  const recursiveRender = (parentId, list) => {
    return (
      list.parent &&
      parentId === list.parent?.id &&
      list.parent?.children?.length > 0 && (
        <ul>
          {list.parent.children.map((ch) => (
            <li className="m-1" key={`${parentId}-ch-${ch.id}`}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  goToCompany(ch.id);
                }}
              >
                <p
                  className="company-group-link mb-0"
                  style={{
                    fontWeight: ch.id === companyId ? "bold" : "",
                    pointerEvents: ch.id === companyId ? "none" : "",
                  }}
                >
                  {ch.name}
                </p>
              </div>
              {recursiveRender(ch.id, list.parent)}
            </li>
          ))}
        </ul>
      )
    );
  };

  return (
    <>
      {group?.loading ? (
        <div className="text-center mt-5" style={{ width: "100%" }}>
          <Spin className="mt-5" />
        </div>
      ) : (
        <Card
          title={
            <h2>
              <b>{Localize("PAGES.COMPANIES.DETAIL.SECTIONS.GROUP")}</b>
            </h2>
          }
          bordered={false}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          {fullGroup?.id ? (
            <p className="mb-0">
              <Link
                className="company-parent-group-link"
                to={`/companies/${fullGroup?.id}`}
                style={{
                  fontWeight: fullGroup?.id === companyId ? "bold" : "",
                  pointerEvents: fullGroup?.id === companyId ? "none" : "",
                }}
              >
                {fullGroup?.name}
              </Link>
            </p>
          ) : (
            <p className="mb-0">
              <Link
                className="company-parent-group-link"
                to={`/companies/${companyId}`}
                style={{ fontWeight: "bold", pointerEvents: "none" }}
              >
                {companyName}
              </Link>
            </p>
          )}

          {fullGroup?.children?.length > 0 && (
            <ul>
              {fullGroup.children.map((child) => (
                <li className="m-1" key={child.id}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      goToCompany(child.id);
                    }}
                  >
                    <p
                      className="company-group-link mb-0"
                      style={{
                        fontWeight: child.id === companyId ? "bold" : "",
                        pointerEvents: child.id === companyId ? "none" : "",
                      }}
                    >
                      {child.name}
                    </p>
                    {recursiveRender(child.id, fullGroup)}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </Card>
      )}
    </>
  );
};

export default CompanyGroup;
