import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { LocalizeFunc } from "../../libs/localization";
import { setOnLocalStorage } from "../../libs/helpers/localstorage";

const Lc = (
  search,
  params = null,
  markdown = false,
  array = false,
  returnObject = false
) => {
  const props = {
    search,
    params,
    markdown,
    array,
    returnObject,
  };

  return <Localized {...props} />;
};

const Localized = (props) => {
  const language = useSelector((state) => state.app.language);
  const [current, setCurrent] = useState(language);

  useEffect(() => {
    setOnLocalStorage(language, "lang");
    setCurrent(language);
  }, [language]);

  const { search, params, markdown, array, returnObject } = props;

  const loc = LocalizeFunc(
    search,
    params,
    markdown,
    array,
    returnObject,
    current
  );

  return loc || null;
};

export default Lc;
