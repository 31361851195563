import callApi from "../libs/helpers/callApi";
import { getPitchGraphApi } from "../api/pitch.api";

export const PITCH_GRAPH_LOADING = "PITCH_GRAPH_LOADING";
export const PITCH_GRAPH_LOADED = "PITCH_GRAPH_LOADED";
export const PITCH_GRAPH_LOAD_ERROR = "PITCH_GRAPH_LOAD_ERROR";

export const getPitchGraphAction = callApi({
  apiCall: (p) => getPitchGraphApi(p),
  secured: true,
  startAction: PITCH_GRAPH_LOADING,
  successAction: PITCH_GRAPH_LOADED,
  errorAction: PITCH_GRAPH_LOAD_ERROR,
});
