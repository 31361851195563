import { Typography } from "@mui/material";
import { Divider, Tag } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { LinkItUrl, UrlComponent } from "react-linkify-it";
import { l } from "../../libs/localization";

function EntityNews({ news }) {
  return (
    <div>
      <div>
        <Typography color="primary" component="span">
          {moment(news.date).format("DD/MM/YYYY")}
        </Typography>{" "}
        <LinkItUrl
          component={(match, key) => (
            <UrlComponent match={match} key={key} target="_blank" />
          )}
        >
          {news.text}
        </LinkItUrl>
      </div>
      {news.referencedEntities.length ? (
        <div>
          {l("ENTITY_NEWS.REFERENCED_COMPANIES")}:{" "}
          {news.referencedEntities.map((re) => (
            <Link
              to={
                re.entityType === "company"
                  ? `/companies/${re.entityId}`
                  : `/contacts/${re.entityId}`
              }
            >
              <Tag style={{ cursor: "pointer" }}>
                {re.metadata?.name || re.entityId}
              </Tag>
            </Link>
          ))}
        </div>
      ) : null}
      {news.tags.length ? (
        <div>
          Tag:{" "}
          {news.tags.map((tag) => (
            <Tag>{l(`ENTITY_NEWS.TAGS.${tag}`) || tag}</Tag>
          ))}
        </div>
      ) : null}
      <Divider />
    </div>
  );
}

export default EntityNews;
