import axios from "axios";
import Config from "../Config";
import { getFromLocalStorage } from "../libs/helpers/localstorage";

export const searchBrandAdvertisersApi = async ({ filters }) => {
  const ls = getFromLocalStorage();
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const token = ls && ls.token;
  const res = await axios.post(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/brand-advertisers`,
    {
      ...filters,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-tenant": tenant,
      },
    },
  );
  return res.data;
};
