import React from "react";
import { List, Row, Tag } from "antd";
import { toUpper } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
import Localize from "../../libs/localization";
import { get } from "../../libs/helpers/ioc";
import { toTitleCase, translateRegistryOnView } from "../../libs/utils";
import "../../pages/home/home.styles.scss";

const PitchHomeList = ({ data: pitchList, pitchCardHeight }) => {
  const permissions = get("scopes");
  return (
    <>
      <List
        className="homePage__pitchContainer__pitchCard"
        style={{ height: pitchCardHeight }}
        dataSource={pitchList}
        renderItem={(item) => {
          return (
            <List.Item key={item._id}>
              <List.Item.Meta
                title={
                  <Row align="middle" className="mx-3">
                    <h3 className="mb-0 pb-0">
                      {toTitleCase(item.title)}
                      <span>
                        {item.category.map((c) => (
                          <Tag className="ml-3" key={c._id}>
                            {translateRegistryOnView(c._id, "Category")
                              ? translateRegistryOnView(c._id, "Category")
                              : c.key}
                          </Tag>
                        ))}
                      </span>
                    </h3>
                  </Row>
                }
                description={
                  <>
                    <Row>
                      <p className="mx-3 p-0" style={{ fontSize: 12 }}>
                        <b>
                          {Localize("COMMON.START")}:{" "}
                          {moment(item.start_date).format("DD/MM/YYYY")}
                          {item.end_date ? (
                            <span>
                              {" "}
                              - {Localize("COMMON.END")}:{" "}
                              {moment(item.end_date).format("DD/MM/YYYY")}
                            </span>
                          ) : (
                            <span> - {Localize("COMMON.IN_PROGRESS")}</span>
                          )}
                        </b>
                      </p>
                    </Row>
                    {item.company && (
                      <p className="mx-3 p-0 mb-0">
                        <b className="secondary">
                          {Localize("PAGES.HOME.CARDS.COMPANY")}:
                        </b>{" "}
                        <Link
                          style={{
                            pointerEvents: Object.keys(
                              permissions || {}
                            )?.includes("company")
                              ? ""
                              : "none",
                          }}
                          to={`/companies/${item.company.id}`}
                        >
                          {toUpper(item.company?.name)}
                        </Link>
                      </p>
                    )}
                    {/* item.brands.length > 0 && (
                      <Col
                        span={24}
                        className="ml-3 mt-0 p-0 align-content-center"
                      >
                        <Row align="middle">
                          <b className="secondary">
                            {Localize(
                              "PAGES.COMPANIES.DETAIL.SECTIONS.MODAL.BRANDS"
                            )}
                          </b>
                          <span className="mx-1">:</span>
                          {item.brands.slice(0, 5).map((b) => (
                            <Tag key={b.id}>{b.name}</Tag>
                          ))}
                          {onMoreBrandClick && item.brands.length > 5 && (
                            <Tag
                              className="pointer"
                              onClick={() =>
                                onMoreBrandClick(
                                  <Label
                                    value={Localize(
                                      "PAGES.COMPANIES.DETAIL.SECTIONS.MODAL.BRANDS"
                                    )}
                                  />,
                                  <ul>
                                    {item.brands.map((b) => (
                                      <li>{b.name}</li>
                                    ))}
                                  </ul>
                                )
                              }
                            >
                              + {item.brands.length - 5}{" "}
                            </Tag>
                          )}
                        </Row>
                      </Col>
                    ) */}
                    {/* item.winnerCompany && (
                      <p className="mx-3 mt-2 mb-0">
                        <b className="secondary">
                          {Localize("PAGES.HOME.CARDS.WINNER")}:
                        </b>{" "}
                        <Link to={`/companies/${item.winnerCompany.id}`}>
                          {item.winnerCompany.name}
                        </Link>
                      </p>
                    ) */}
                  </>
                }
              />
            </List.Item>
          );
        }}
      />
    </>
  );
};

export default PitchHomeList;
