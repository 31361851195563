import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  INCOME_RANGE_LOADING,
  INCOME_RANGE_LOADED,
  INCOME_RANGE_LOAD_ERROR,
} from "../../actions/registry/registry.income_range.actions";

const reducer = {
  /*
   * INCOME_RANGE LIST
   */
  [INCOME_RANGE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [INCOME_RANGE_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [INCOME_RANGE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
