import React, { lazy } from "react";
import {
  HomeOutlined,
  ShopOutlined,
  ContactsOutlined,
  AuditOutlined,
  TeamOutlined,
  StarOutlined,
  PercentageOutlined,
  NotificationOutlined,
} from "@ant-design/icons";

// npm libs
// ---

// internal libs
import Config from "./Config";
import { type } from "./libs/router";
import { SiteLayout } from "./layouts";
import { removeFromLocalStorage } from "./libs/helpers/localstorage";
import Localize from "./components/Localized/Localize.comp";

// components
import Loading from "./components/Loading/Loading";
import {
  CompanyDetail,
  CompanySearch,
  HomePage,
  Contact,
  WorkRelationSearch,
  TagPage,
} from "./pages";
import SearchPage from "./pages/search/search.page";
import ContactSearch from "./pages/contact/contact.search";
import PitchSearch from "./pages/pitch/pitch.search";
import { refreshPage } from "./libs/utils";
import RegistrationPage from "./pages/registration/registration.page";
import ConfirmationEmailSentPage from "./pages/registration/confirmationEmailSent.page";
import CompleteBillingInfoPage from "./pages/billingAddress/completeBillingInfo.page";
import LoginPage from "./pages/registration/login.page";
import ForgotPasswordPage from "./pages/registration/forgotPassword.page";
import ConfirmPlanPage from "./pages/registration/confirmPlan.page";
import PaymentSuccessfulPage from "./pages/registration/paymentSuccessful.page";
import PaymentFailedPage from "./pages/registration/paymentFailed.page";
import ProfileLayout from "./layouts/site/profile.layout";
import ProfileBasicInfoPage from "./pages/profile/basicInfo.page";
import ProfileBillingInfoPage from "./pages/profile/billingInfo.page";
import ProfileSubscriptionInfoPage from "./pages/profile/subscriptionInfo.page";
import ProfileQuotasPage from "./pages/profile/quotas.page";
import { l } from "./libs/localization";
import SubscriptionOverduePage from "./pages/registration/subscriptionOverdue.page";
import ProfileMailerIntegrationPage from "./pages/profile/mailerIntegration.page";
import PitchScoresSearch from "./pages/pitch/pitch-scores.search";
import CommunicationsSettingsPage from "./pages/profile/communications.page";
import BrandAdvertisersSearch from "./pages/brandAdvertisers/brandAdvertisers.search";

const AuthPage = lazy(() => import("./pages/system/auth.page"));
const Forbidden = lazy(() => import("./pages/system/forbidden.page"));

export default [
  {
    id: "login",
    path: "/login",
    title: Localize("PAGES.LOGIN.TITLE"),
    noFollow: true,
    showInMenu: false,
    type: type.AUTHENTICATION,
    component: LoginPage,
  },
  {
    id: "forgotPassword",
    path: "/forgotPassword",
    title: Localize("PAGES.LOGIN.TITLE"),
    noFollow: true,
    showInMenu: false,
    type: type.AUTHENTICATION,
    component: ForgotPasswordPage,
  },
  {
    id: "register",
    path: "/register",
    title: Localize("PAGES.REGISTER.TITLE"),
    noFollow: false,
    showInMenu: false,
    type: type.AUTHENTICATION,
    component: RegistrationPage,
  },
  {
    id: "confirmationEmailSent",
    path: "/confirmationEmailSent",
    title: Localize("PAGES.REGISTER.TITLE"),
    noFollow: true,
    showInMenu: false,
    type: type.AUTHENTICATION,
    component: ConfirmationEmailSentPage,
  },
  {
    id: "paymentSuccessful",
    path: "/paymentSuccessful",
    title: Localize("PAGES.REGISTER.TITLE"),
    noFollow: true,
    showInMenu: false,
    type: type.SECURED,
    component: PaymentSuccessfulPage,
  },
  {
    id: "paymentFailed",
    path: "/paymentFailed",
    title: Localize("PAGES.REGISTER.TITLE"),
    noFollow: true,
    showInMenu: false,
    type: type.SECURED,
    component: PaymentFailedPage,
  },
  {
    id: "paymentFailed",
    path: "/past-due",
    title: Localize("PAGES.REGISTER.TITLE"),
    noFollow: true,
    showInMenu: false,
    type: type.SECURED,
    component: SubscriptionOverduePage,
  },
  {
    id: "completeBillingInfo",
    path: "/completeBillingInfo",
    title: Localize("PAGES.REGISTER.TITLE"),
    noFollow: true,
    showInMenu: false,
    type: type.SECURED,
    component: CompleteBillingInfoPage,
  },
  {
    id: "confirmPlan",
    path: "/confirmPlan",
    title: Localize("PAGES.REGISTER.TITLE"),
    noFollow: true,
    showInMenu: false,
    type: type.SECURED,
    component: ConfirmPlanPage,
  },
  {
    id: "authorize",
    path: "/authorize",
    title: Localize("PAGES.AUTHORIZE.TITLE"),
    noFollow: true,
    component: AuthPage,
    showInMenu: false,
    type: type.AUTHENTICATION,
  },
  {
    id: "logout",
    path: "/logout/do",
    showInMenu: false,
    noFollow: true,
    type: type.SECURED,
    component: () => {
      removeFromLocalStorage(null, "general");
      // window.location = `${Config.apiBaseurl}/${Config.apis.oauth.logout}`;
      removeFromLocalStorage(null, "lefacWebTenant");
      window.location.href = "/";
      return Loading({ coverBack: true });
    },
  },
  {
    id: "home",
    title: Localize("PAGES.HOME.TITLE"),
    path: "/",
    component: HomePage,
    showInMenu: true,
    icon: <HomeOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
  },
  {
    id: "tag",
    title: Localize("PAGES.TAG.TITLE"),
    path: "/tag",
    component: TagPage,
    showInMenu: true,
    icon: <StarOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["favourites", "ecommerce"],
  },
  {
    id: "companySearch",
    title: Localize("PAGES.COMPANIES.TITLE"),
    path: "/companies",
    component: CompanySearch,
    showInMenu: true,
    icon: <ShopOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["company"],
  },
  {
    id: "company",
    title: Localize("PAGES.COMPANIES.TITLE"),
    path: "/companies/:id",
    component: CompanyDetail,
    showInMenu: false,
    icon: <ShopOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["company"],
  },
  {
    id: "contactSearch",
    title: Localize("PAGES.CONTACTS.TITLE"),
    path: "/contacts",
    component: ContactSearch,
    showInMenu: true,
    icon: <ContactsOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["contact"],
  },
  {
    id: "contact",
    title: Localize("PAGES.CONTACTS.TITLE"),
    path: "/contacts/:id",
    component: Contact,
    showInMenu: false,
    icon: <ContactsOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["contact"],
  },
  {
    id: "pitchSearch",
    title: Localize("PAGES.PITCH.TITLE"),
    path: "/pitch",
    component: PitchSearch,
    showInMenu: true,
    icon: <AuditOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["pitch"],
  },
  {
    id: "workRelation",
    title: Localize("PAGES.WORK_RELATION.TITLE"),
    path: "/workRelation",
    component: WorkRelationSearch,
    showInMenu: true,
    icon: <TeamOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["pitch"],
  },
  {
    id: "brandAdvertisers",
    title: Localize("PAGES.BRAND_ADVERTISERS.TITLE"),
    path: "/brandAdvertisers",
    component: BrandAdvertisersSearch,
    showInMenu: true,
    icon: <TeamOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["brandAdvertisers"],
  },
  {
    id: "pitchScores",
    title: Localize("PAGES.PITCH_SCORES.TITLE"),
    path: "/pitch-scores",
    component: PitchScoresSearch,
    showInMenu: true,
    icon: <PercentageOutlined />,
    layout: SiteLayout,
    type: type.SECURED,
    scopes: ["pitchScores"],
  },
  {
    id: "searchPage",
    title: "searchPage",
    path: "/searchPage",
    component: SearchPage,
    showInMenu: false,
    layout: SiteLayout,
    type: type.SECURED,
  },
  {
    id: "profileBasicInfo",
    title: l("REGISTER.PERSONAL_INFO"),
    path: "/profile/basic-info",
    component: ProfileBasicInfoPage,
    showInMenu: false,
    icon: <AuditOutlined />,
    layout: ProfileLayout,
    profile: true,
    type: type.SECURED,
    scopes: ["ecommerce"],
  },
  {
    id: "profileBillingInfo",
    title: l("REGISTER.BILLING_INFO"),
    path: "/profile/billing-info",
    component: ProfileBillingInfoPage,
    showInMenu: false,
    icon: <AuditOutlined />,
    layout: ProfileLayout,
    profile: true,
    type: type.SECURED,
    scopes: ["ecommerce"],
  },
  {
    id: "profileSubscriptionInfo",
    title: l("ECOMMERCE.SUBSCRIPTION"),
    path: "/profile/subscription-info",
    component: ProfileSubscriptionInfoPage,
    showInMenu: false,
    icon: <AuditOutlined />,
    layout: ProfileLayout,
    profile: true,
    type: type.SECURED,
    scopes: ["ecommerce"],
  },
  {
    id: "profileQuotas",
    title: l("PROFILE.QUOTAS"),
    path: "/profile/quotas",
    component: ProfileQuotasPage,
    showInMenu: false,
    icon: <AuditOutlined />,
    layout: ProfileLayout,
    profile: true,
    type: type.SECURED,
    scopes: ["companyQuota", "contactQuota"],
  },
  {
    id: "profileMailerIntegration",
    title: l("PROFILE.MAILER_INTEGRATION"),
    path: "/profile/mailer-integration",
    component: ProfileMailerIntegrationPage,
    showInMenu: false,
    icon: <AuditOutlined />,
    layout: ProfileLayout,
    profile: true,
    type: type.SECURED,
    scopes: ["activemailer"],
  },
  {
    id: "profileCommunications",
    title: l("PROFILE.COMMUNICATIONS"),
    path: "/profile/communications",
    component: CommunicationsSettingsPage,
    showInMenu: false,
    icon: <NotificationOutlined />,
    layout: ProfileLayout,
    profile: true,
    type: type.SECURED,
    scopes: ["weeklyUpdateEmail"],
  },
  {
    id: "forbidden",
    title: Localize("LOGIN.FORBIDDEN"),
    path: "/forbidden",
    component: Forbidden,
    showInMenu: false,
    // layout: AdminLayout,
    layoutOptions: { boxShadow: false, noPadding: true },
    type: type.PUBLIC,
  },
];
