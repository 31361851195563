import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Input, Modal, Row, Select, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { orderBy } from "lodash";
import { CirclePicker } from "react-color";
import {
  createTagAction,
  deleteAssociatedIdTagAction,
  getTagAction,
} from "../../actions/tag.actions";
import "./Tag.scss";
import Localize from "../../libs/localization";
import Label from "../Label/Label";
import { associateTagApi } from "../../api/tag.api";
import { headersBuilder } from "../../libs/helpers/callApi";
import usePermissions from "../../hooks/usePermissions";

const { Option } = Select;

const TagManage = ({
  tagInfo,
  entityId,
  entity = "company",
  onAssociateTag,
  onDeleteTag,
  showAssociateTag = true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const [selectedTag, setSelectedTag] = useState();
  const [newTagValue, setNewTagValue] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [colorValue, setColorValue] = useState("");
  const dispatch = useDispatch();
  const permissions = usePermissions();

  const tags = useSelector((state) => state.tag.tagsList);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (permissions.favourites) {
      dispatch(getTagAction());
    }
  }, []);

  const associateTag = () => {
    const dataParams = {};
    let id = "";

    tags.forEach((tag) => {
      if (tag.name === selectedTag) {
        id = tag._id;
      }
    });

    dataParams.name = selectedTag;
    entity === "company"
      ? (dataParams.companies = [entityId])
      : (dataParams.contacts = [entityId]);
    associateTagApi({
      headers: {
        ...headersBuilder(),
        Authorization: `Bearer ${token}`,
      },
      id,
      data: dataParams,
    })
      .then(() => onAssociateTag())
      .catch(() => null);
    setSelectedTag(null);
    setShowModal(false);
  };

  const createTag = () => {
    const params = {
      name: newTagValue,
      color: colorValue.hex.replace("#", "").toUpperCase(),
    };

    dispatch(createTagAction({ data: params }));
    setShowAddNew(false);
    setColorValue("");
    setNewTagValue(null);
  };

  const renderTag = () => {
    let renderedTags = [];
    tagInfo.data &&
      tagInfo.data.map((association) => {
        const founded = association._id === entityId;
        if (founded) {
          renderedTags = association.tags.map((tag) => (
            <Tag
              key={tag.tagId}
              color={`#${tag.color}`}
              className="searchPage__item-tag"
              closable={showAssociateTag}
              onClose={() => {
                dispatch(
                  deleteAssociatedIdTagAction({
                    tagId: tag.tagId,
                    associatedId: association._id,
                  })
                );
                onDeleteTag && onDeleteTag();
              }}
            >
              {tag.name}
            </Tag>
          ));
        }
      });
    return renderedTags;
  };

  return (
    <>
      {renderTag()}
      {showAssociateTag && (
        <Tag className="tag-plus" onClick={() => setShowModal(true)}>
          <PlusOutlined /> {Localize("TAG.ASSOCIATE_ONE")}
        </Tag>
      )}
      <Modal
        title={Localize("TAG.ADD_TAG")}
        visible={showModal}
        onCancel={() => {
          setShowAddNew(false);
          setColorValue("");
          setNewTagValue(null);
          setSelectedTag(null);
          setShowModal(false);
        }}
        footer={null}
      >
        <Row align="bottom" justify="start">
          <Col span={12} className="pr-3">
            <Select
              value={selectedTag}
              allowClear
              style={{ width: "100%" }}
              onChange={(val) => setSelectedTag(val)}
              onSelect={(val) => setSelectedTag(val)}
              disabled={showAddNew}
            >
              {tags &&
                orderBy(tags, "created_at", "desc").map((tag) => {
                  return (
                    <Option value={tag.name} label={tag.name} key={tag._id}>
                      <Row align="middle">
                        <Col span={24}>
                          <Row align="middle">
                            <div
                              className="mr-2"
                              style={{
                                width: 15,
                                height: 15,
                                borderRadius: 50,
                                backgroundColor: `#${tag.color}`,
                              }}
                            />
                            {tag.name}
                          </Row>
                        </Col>
                      </Row>
                    </Option>
                  );
                })}
            </Select>
          </Col>
          <Col className="mt-2" span={12}>
            <Button
              disabled={showAddNew}
              icon={<PlusOutlined />}
              onClick={() => setShowAddNew(true)}
              className="mr-3"
            >
              {Localize("TAG.CREATE")}
            </Button>
            <Button
              type="primary"
              onClick={associateTag}
              disabled={!selectedTag || showAddNew}
            >
              {Localize("TAG.ASSOCIATE")}
            </Button>
          </Col>
        </Row>

        {showAddNew && (
          <Row align="bottom" justify="start">
            <Divider />
            <Col span={24}>
              <Label value={Localize("TAG.INSERT_NEW_TAG")} />
              <Input
                value={newTagValue}
                onChange={(e) => {
                  const foundedTag = tags.find(
                    (tag) =>
                      tag.name.toLowerCase() === e.target.value.toLowerCase()
                  );
                  if (foundedTag) {
                    setErrorMessage(Localize("TAG.ERROR_MESSAGE"));
                  } else {
                    setErrorMessage("");
                  }
                  setNewTagValue(e.target.value);
                }}
              />
              <p className="mt-1" style={{ color: "red" }}>
                {errorMessage}
              </p>
              <Label value={Localize("TAG.SELECT_COLOR")} className="mt-3" />
              <CirclePicker
                color={colorValue}
                onChange={(color) => setColorValue(color)}
              />
            </Col>
            <Col span={24} className="mt-2 text-right">
              <Button
                onClick={() => {
                  setShowAddNew(false);
                  setColorValue("");
                  setNewTagValue(null);
                }}
                className="mr-3"
              >
                {Localize("COMMON.CANCEL")}
              </Button>
              <Button
                icon={<SaveOutlined />}
                type="primary"
                onClick={createTag}
                disabled={!colorValue || !newTagValue}
              >
                {Localize("COMMON.CREATE_NEW")}
              </Button>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default TagManage;
