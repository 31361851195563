import React, { Fragment } from "react";
import moment from "moment";
import {
  Input,
  Tag,
  DatePicker,
  Select,
  Radio,
  Image,
  Upload,
  Tooltip,
  Button,
  Popover,
  Switch,
  AutoComplete,
} from "antd";
import { find } from "lodash";

import {
  LinkOutlined,
  PlusOutlined,
  CloseOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  StopOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Localize from "../../components/Localized/Localize.comp";
import { turnKeyIntoLabel } from "../../libs/utils";
import Led from "../Led/Led";
import Placeholders from "../Placeholders";

const { TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;

export const renderDetailItem = ({
  idx = Math.round(Math.random() * 1000),
  item,
  itemSchema,
  disabled,
  error,
  datasource,
  onChange,
  onBlur = (key, value) => key && value,
  imageUploadOptions = {
    headers: null,
    url: null,
    callback: null,
    disabled,
    onDelete: null,
    readOnly: false,
  },
  customRenderer,
}) => {
  if (
    ["id", "_id", "created_at", "modified_at", "deleted_at"].indexOf(
      itemSchema.key
    ) >= 0
  ) {
    disabled = true;
  }
  if (customRenderer && typeof customRenderer === "function") {
    return customRenderer({ item, onChange, disabled, itemSchema });
  }
  switch (itemSchema.type) {
    case "Boolean":
      return disabled ? (
        <span
          style={{
            height: 32,
            marginBottom: 5,
            display: "table",
          }}
        >
          <span
            style={{
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            <Led on={item} type="success" style={{ marginRight: 10 }} />
          </span>
          <span
            style={{
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            {item ? Localize("COMMON.YES") : Localize("COMMON.NO")}
          </span>
        </span>
      ) : (
        <Switch
          style={{ marginTop: 5 }}
          checked={item}
          onChange={(value) => {
            if (typeof onChange === "function") {
              onChange(itemSchema.key, value);
            }
          }}
        />
      );
    case "Link":
      return (
        <>
          {Array.isArray(item) &&
            item.map((i, k) =>
              disabled ? (
                i &&
                (i.link || !disabled) && (
                  <Tag
                    key={k}
                    className={i.link ? "filled tag-bg" : "empty tag-bg"}
                  >
                    <LinkOutlined />
                    <a href={i.link} target="_Blank" style={{ paddingLeft: 5 }}>
                      {i.label ? turnKeyIntoLabel(i.label) : i.link}
                    </a>
                    {!disabled && <CloseOutlined />}
                  </Tag>
                )
              ) : (
                <div
                  style={{
                    float: "left",
                    width: "33%",
                    padding: "10px 20px",
                  }}
                >
                  <LinkOutlined />{" "}
                  {i.label ? turnKeyIntoLabel(i.label) : i.link}
                  <Input
                    style={{ width: "100%" }}
                    value={i.link}
                    size="small"
                    allowClear
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      if (typeof onChange === "function" && !disabled) {
                        item[k].link = value
                          .trim()
                          .replace(
                            /\f\n\r\t\v​\u00a0\u1680​\u2000​-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff/g,
                            ""
                          );
                        onChange(itemSchema.key, item);
                      }
                    }}
                  />
                </div>
              )
            )}
        </>
      );
    case "Image":
      return (
        <Upload
          name="file"
          listType="picture-card"
          disabled={imageUploadOptions.disabled || item}
          showUploadList={false}
          action={
            imageUploadOptions.url &&
            `${imageUploadOptions.url}/${itemSchema.key}`
          }
          headers={imageUploadOptions.headers}
          onChange={(info) => {
            if (info.file.status === "uploading") {
              return;
            }
            if (info.file.status === "done") {
              const { response } = info.file;
              typeof imageUploadOptions.callback === "function" &&
                imageUploadOptions.callback(response);
            }
          }}
        >
          {item ? (
            <Popover
              trigger={imageUploadOptions.readOnly ? [] : "click"}
              placement="left"
              content={
                !imageUploadOptions.readOnly && (
                  <Button
                    disabled={!item || !disabled}
                    size="small"
                    onClick={() => imageUploadOptions.onDelete(itemSchema.key)}
                  >
                    <DeleteOutlined />
                    {Localize("COMMON.DELETE")}
                  </Button>
                )
              }
            >
              <img
                src={`${imageUploadOptions.url}/${itemSchema.key}`}
                alt={itemSchema.key}
                style={{ width: "100%", opacity: disabled ? 1 : 0.2 }}
              />
            </Popover>
          ) : (
            <Placeholders.ImgPlaceholder
              style={{ width: "100%", opacity: disabled ? 1 : 0.4 }}
            />
          )}
        </Upload>
      );
    case "Enum":
      return (
        <Select
          value={item}
          style={{ width: "100%" }}
          disabled={disabled}
          placeholder={disabled ? "—" : ""}
          onChange={(value) =>
            typeof onChange === "function" &&
            !disabled &&
            onChange(itemSchema.key, value)
          }
          showSearch
          options={(itemSchema.enum || []).map((e, j) => ({
            value: e,
            key: j,
          }))}
        />
      );
    case "Set":
      return (
        <>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder={disabled ? "—" : ""}
            disabled={disabled}
            value={item}
            onChange={(value) =>
              typeof onChange === "function" &&
              !disabled &&
              onChange(itemSchema.key, value)
            }
            options={(itemSchema.set || []).map((s, j) => ({
              value: s,
              key: j,
            }))}
          />
        </>
      );
    case "Date":
      return (
        <DatePicker
          placeholder={disabled ? "—" : ""}
          onChange={onChange}
          value={item && moment(item)}
          format="DD-MM-YYYY, \hHH:mm"
          disabled={disabled}
          style={{ marginBottom: 5 }}
          onChange={(value) => {
            if (typeof onChange === "function" && !disabled) {
              onChange(itemSchema.key, value);
            }
          }}
        />
      );
    default:
      if (Array.isArray(item)) {
        return item.map((i, k) => (
          <Fragment key={k}>
            {renderDetailItem({
              idx: k,
              item: i,
              itemSchema,
              disabled,
              error,
              onChange,
              onBlur,
              repeating: item.length,
            })}
          </Fragment>
        ));
      }
      return (
        // togliere gli "a capo" all'onchange
        <TextArea
          // <Input
          className={error && "input-error"}
          value={item}
          placeholder={disabled ? "—" : ""}
          style={{
            marginBottom: 5,
            textAlign: item && item.length > 100 ? "justify" : "left",
          }}
          disabled={disabled}
          cols={item && item.length > 300 ? 100 : null}
          onChange={(e) => {
            const {
              target: { value },
            } = e;
            if (typeof onChange === "function" && !disabled) {
              onChange(
                itemSchema.key,
                value
                  // .trim()
                  .replace(
                    /\f\n\r\t\v​\u00a0\u1680​\u2000​-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff/g,
                    ""
                  ),
                itemSchema.type === "Number" && /^\d+$/g
              );
            }
          }}
          onBlur={(e) => {
            const {
              target: { value },
            } = e;
            onBlur(itemSchema.key, value);
          }}
          autoSize
        />
        // />
      );
  }
};

export const checkIfNeedsRender = (schema, type) => {
  if (type === "CREATE") {
    return (
      schema.type !== "Image" &&
      schema.type !== "Link" &&
      schema.key !== "created_at" &&
      schema.key !== "modified_at" &&
      schema.key !== "deleted_at"
    );
  }
  if (type === "COMPANY") {
    return (
      schema.type !== "Image" &&
      schema.type !== "Link" &&
      schema.key !== "created_at" &&
      schema.key !== "modified_at" &&
      schema.key !== "deleted_at" &&
      schema.key !== "tel" &&
      schema.key !== "fax" &&
      schema.key !== "website" &&
      schema.key !== "name" &&
      schema.key !== "wikipedia" &&
      schema.key !== "active" &&
      schema.key !== "nielsen"
    );
  }
  if (type === "CONTACT") {
    return (
      schema.type !== "Image" &&
      schema.type !== "Link" &&
      schema.key !== "created_at" &&
      schema.key !== "modified_at" &&
      schema.key !== "deleted_at" &&
      schema.key !== "prefix" &&
      schema.key !== "active"
    );
  }
};

export const googlePlacesParser = (place) => {
  const keyParse = [
    "street_number",
    "route",
    "locality",
    "postal_town",
    "country",
    "administrative_area_level_2",
    "administrative_area_level_1",
    "postal_code",
  ];

  const obj = {};

  keyParse.forEach((val) => {
    const _place = find(place, (p) => {
      return p.types.includes(val);
    });
    obj[val] = _place ? _place.short_name : null;
  });

  return obj;
};
