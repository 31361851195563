import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getFromLocalStorage,
  setOnLocalStorage,
} from "../../libs/helpers/localstorage";
import LocalizedFlag from "../Localized/LocalizedFlag";
import Localize from "../Localized/Localize.comp";
import { getTenantListAction } from "../../actions/user.actions";
import { flagMap } from "../../libs/utils";

const SmallTenantSelector = (props) => {
  const { onSelect = null } = props;

  const dispatch = useDispatch();

  const [currentTenant, setCurrentTenant] = useState(null);
  const tenantList = useSelector((state) => state.user.tenant);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const tenant = getFromLocalStorage("lefacWebTenant");
    if (currentTenant && currentTenant !== tenant.data) {
      setCurrentTenant(tenant);
    }
    if (tenant && !currentTenant) {
      setCurrentTenant(tenant.data);
    }
  });

  useEffect(() => {
    if (tenantList.data.length == 0) {
      dispatch(getTenantListAction());
    }
  }, []);

  const handleChange = (value) => {
    setOnLocalStorage(value, "lefacWebTenant");
    if (typeof onSelect === "function") {
      onSelect(value);
    }
    setCurrentTenant(value);
  };
  return (
    <>
      {tenantList && tenantList.data.length > 0 && (
        <Select
          value={currentTenant}
          style={{ width: "100%" }}
          onChange={handleChange}
          placeholder={Localize("LOGIN.CHOOSE_TENANT")}
        >
          {tenantList.data
            .filter(
              (t) =>
                t.hidden !== true ||
                user?.group === "ADMIN" ||
                user?.group === "SUPER-ADMIN"
            )
            // .filter(
            //   ({ _id }) =>
            //     user?.licence?.tenants?.[_id]?.exp &&
            //     moment(user.licence.tenants[_id].exp * 1000).isSameOrAfter(
            //       moment()
            //     )
            // )
            .map((t) => (
              <Select.Option
                disabled={
                  !user?.licence?.tenants?.[t._id]?.exp ||
                  moment(user.licence.tenants[t._id].exp * 1000).isBefore(
                    moment()
                  )
                }
                value={t._id}
                key={t._id}
              >
                <LocalizedFlag locale={flagMap[t._id]} square={true} /> {t.name}
              </Select.Option>
            ))}
        </Select>
      )}
    </>
  );
};

export default SmallTenantSelector;
