import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, orderBy, remove, isEqual } from "lodash";
import { Button, Col, DatePicker, InputNumber, Row, Select } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import { getNielsenYearsAction } from "../../actions/nielsen.action";

import Label from "../../components/Label/Label";
import Localize from "../../libs/localization";
import {
  FlatV2InvestmentRoundFundingTypeEntity,
  getFlatV2EntityAction,
} from "../../actions/flatV2.actions";
import { translateRegistryKeyFlatV2 } from "../../libs/utils";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import CompanyAutocomplete from "../../components/CompanyAutocomplete/CompanyAutocomplete";

const InvestmentRoundsForm = (props) => {
  const { dataForm, onChange } = props;
  const filter = dataForm.investment_round_filters || [];

  const roundTypeEntity = useSelector(
    (state) => state.flatV2.entities[FlatV2InvestmentRoundFundingTypeEntity]
  );
  const roundTypeEntityMap = {};
  if (roundTypeEntity?.data) {
    roundTypeEntity.data.forEach((type) => {
      roundTypeEntityMap[type._id] = type;
    });
  }
  const years = useSelector((state) => state.nielsen.years);

  const [type, setType] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [investorNames, setInvestorNames] = useState([]);
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(roundTypeEntity)) {
      dispatch(
        getFlatV2EntityAction({
          entity: FlatV2InvestmentRoundFundingTypeEntity,
        })
      );
    }
  }, [roundTypeEntity]);

  useEffect(() => {
    if (isEmpty(years)) {
      dispatch(getNielsenYearsAction());
    }
  }, [years]);

  const clear = () => {
    setType([]);
    setFromDate(null);
    setToDate(null);
    setInvestorNames([]);
    setMin(null);
    setMax(null);
  };

  const generateFilter = () => {
    const obj = {
      type: type?.length > 0 ? type : undefined,
      fromDate: fromDate ? fromDate.toISOString() : undefined,
      toDate: toDate ? toDate.toISOString() : undefined,
      investorName: investorNames?.length > 0 ? investorNames : undefined,
      min: min || undefined,
      max: max || undefined,
    };
    const total = [...filter, obj];
    clear();
    onChange("investment_round_filters", total);
  };

  const removeFromFilterList = (f) => {
    remove(filter, (c) => {
      return isEqual(f, c);
    });
    onChange(
      "investment_round_filters",
      filter.length > 0 ? filter : undefined
    );
  };

  return (
    <>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {filter.map((f, i) => {
          const conditions = [];
          if (f.type) {
            conditions.push(
              <p>
                {Localize("COMMON.TYPE")} ={" "}
                {f.type
                  .map((tId) => roundTypeEntityMap[tId]?.key)
                  .join(` ${Localize("COMMON.OR")} `)}
              </p>
            );
          }
          if (f.fromDate || f.toDate) {
            const sections = [];
            if (f.fromDate) {
              sections.push(
                <>
                  {Localize("COMMON.FROM")}{" "}
                  {moment(f.fromDate).format("YYYY-MM-DD")}
                </>
              );
            }
            if (f.toDate) {
              sections.push(
                <>
                  {" "}
                  {Localize("COMMON.TO")}{" "}
                  {moment(f.toDate).format("YYYY-MM-DD")}
                </>
              );
            }
            conditions.push(
              <p>
                {Localize("COMMON.DATE")}: {sections}
              </p>
            );
          }
          if (f.investorName) {
            conditions.push(
              <p>
                {Localize("PAGES.SEARCH.ORDER.INVESTOR_NOT_ON_BLINKO")} ={" "}
                {f.investorName.join(` ${Localize("COMMON.OR")} `)}
              </p>
            );
          }
          if (f.min || f.max) {
            conditions.push(
              <p>
                {f.min ? `${f.min} >= ` : null}
                {Localize("PAGES.SEARCH.ORDER.INVESTMENT_AMOUNT")}
                {f.max ? ` <= ${f.max}` : null}
              </p>
            );
          }
          return (
            <li key={i} style={{ display: "flex", flexDirection: "row" }}>
              <CloseOutlined onClick={() => removeFromFilterList(f)} />
              {conditions.map((el, i) =>
                i === conditions.length - 1 ? (
                  el
                ) : (
                  <>
                    {el}
                    <p>&nbsp;|&nbsp;</p>
                  </>
                )
              )}
            </li>
          );
        })}
      </ul>
      <Row gutter={[16, 16]}>
        <Col span={24} md={6} style={{ minWidth: 150, alignSelf: "stretch" }}>
          <Label className="filter-color" value={Localize("COMMON.TYPE")} />
          <Select
            mode="multiple"
            allowClear={true}
            style={{ width: "100%" }}
            value={type}
            onChange={(value) => setType(value)}
            showSearch
            options={
              roundTypeEntity?.data &&
              roundTypeEntity.data
                .sort((a, b) => {
                  if (a.key > b.key) {
                    return 1;
                  }
                  if (b.key > a.key) {
                    return -1;
                  }
                  return 0;
                })
                .map((el, j) => {
                  return {
                    label:
                      translateRegistryKeyFlatV2(
                        el.key,
                        FlatV2InvestmentRoundFundingTypeEntity,
                        getFromLocalStorage("lang")?.data
                      ) || el.key,
                    value: el._id,
                    key: el.key,
                  };
                })
            }
          />
        </Col>
        <Col span={24} md={9} style={{ minWidth: 250 }}>
          <Label className="filter-color" value={Localize("COMMON.DATE")} />
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            format="DD-MM-YYYY"
            placeholder={[Localize("COMMON.START"), Localize("COMMON.END")]}
            className="mr-5"
            allowEmpty={[false, true]}
            defaultValue={[
              fromDate && moment(fromDate),
              toDate && moment(toDate),
            ]}
            onChange={(dates) => {
              const [dateStart, dateEnd] = dates || [];
              if (dateStart) {
                setFromDate(dateStart);
              } else {
                setFromDate(null);
              }
              if (dateEnd) {
                setToDate(dateEnd);
              } else {
                setToDate(null);
              }
            }}
          />
        </Col>
        <Col span={24} md={9} style={{ paddingTop: "42px" }}>
          <Row justify="space-between">
            <b className="mr-2 filter-color">{Localize("COMMON.FROM")}</b>
            <InputNumber
              style={{ height: 32 }}
              min={0}
              value={min}
              onChange={(value) => setMin(value)}
            />
            <b className="mx-2 filter-color">{Localize("COMMON.TO")}</b>
            <InputNumber
              style={{ height: 32 }}
              min={0}
              value={max}
              onChange={(value) => setMax(value)}
            />
          </Row>
        </Col>
        <Col span={24}>
          <Row style={{ gap: 16 }}>
            <div style={{ flex: 1, minWidth: 200 }}>
              <Label
                className="filter-color"
                value={Localize("PAGES.SEARCH.ORDER.INVESTOR_NOT_ON_BLINKO")}
              />
              <CompanyAutocomplete
                style={{ minHeight: 32 }}
                onChange={(arr) =>
                  setInvestorNames(arr.map((el) => el?.name || el))
                }
                freeForm
              />
            </div>
            <Button
              // disabled={
              //   selectedYear === null ||
              //   type.length === 0 ||
              //   (min === null && max === null)
              // }
              type="primary"
              style={{ marginTop: "36px" }}
              onClick={() => generateFilter()}
            >
              <PlusOutlined />
              {Localize("COMMON.ADD")}
            </Button>
          </Row>
        </Col>
        {/* <Col span={4} style={{ paddingTop: "42px", minWidth: "130px" }}></Col> */}
      </Row>
    </>
  );
};

export default InvestmentRoundsForm;
