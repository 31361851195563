import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";

export const getBrandListApi = async ({ headers, page, params = null }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.brand.list}?pageNum=${page || 1}${
      params ? `&${params.join("&")}` : ""
    }`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

// export const getBrandDetailApi = async ({ headers, id }) => {
//   const res = await fetch(
//     `${Config.apiBaseurl}/${Config.apis.brand.detail}/${id}`,
//     {
//       method: "GET",
//       headers,
//     }
//   );
//
//   const response = await res.json();
//
//   if (res.ok) {
//     return response;
//   } else {
//     const { statusText, status } = res;
//     const { httpStatus, clientType, description } = response;
//
//     throw new ErrorMessage(
//       statusText,
//       description,
//       httpStatus,
//       status,
//       clientType
//     );
//   }
// };

export const getBrandDetailApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/brand/${id}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;

    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};

export const getBrandCompanyApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  } else {
    const { statusText, status } = res;
    const { httpStatus, clientType, description } = response;
    throw new ErrorMessage(
      statusText,
      description,
      httpStatus,
      status,
      clientType
    );
  }
};
