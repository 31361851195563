import callApi from "../../libs/helpers/callApi";
import { getTreeDataApi } from "../../api/registry.api";

export const SECTOR_LOADING = "SECTOR_LOADING";
export const SECTOR_LOADED = "SECTOR_LOADED";
export const SECTOR_LOAD_ERROR = "SECTOR_LOAD_ERROR";

// SECTOR
export const getTreeSectorAction = callApi({
  apiCall: (p) => getTreeDataApi({ ...p, entity: "Sector" }),
  secured: true,
  startAction: SECTOR_LOADING,
  successAction: SECTOR_LOADED,
  errorAction: SECTOR_LOAD_ERROR,
});
