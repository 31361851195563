import React, { useEffect, useState } from "react";
import { Card, Row, Col, Typography, Table } from "antd";
import { orderBy, toUpper } from "lodash";
import Localize from "../../libs/localization";
import InvestmentRoundGraph from "../../components/InvestmentRoundGraph/InvestmentRoundGraph";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyInvestmentRoundAction } from "../../actions/company.actions";
import moment from "moment";
import { getFundingTypeAction } from "../../actions/registry/registry.funding_type.actions";
import { Link } from "react-router-dom";
import Label from "../../components/Label/Label";
import {
  currencyFormatter,
  translateRegistryKeyFlatV2,
} from "../../libs/utils";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import { v4 as uuidv4 } from "uuid";

const fundingTypeEntity = "InvestmentRoundFundingType";

const CompanyInvestmentRound = (props) => {
  const { companyId } = props;
  const dispatch = useDispatch();

  const data = useSelector((state) => state.company.investmentRound.data);
  const fundingTypes = useSelector((state) => state.funding_type.data);

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyInvestmentRoundAction({ id: companyId }));
      dispatch(getFundingTypeAction());
    }
  }, [companyId]);

  const columns = [
    {
      title: Localize("PAGES.COMPANIES.DETAIL.SECTIONS.FUNDING_TYPE"),
      dataIndex: "fundingType",
      render: (type) =>
        fundingTypes?.map((fundingType) =>
          fundingType._id === type
            ? translateRegistryKeyFlatV2(
                fundingType.key,
                fundingTypeEntity,
                getFromLocalStorage("lang")?.data
              ) || fundingType.key
            : null
        ),
    },
    {
      title: Localize("PAGES.COMPANIES.DETAIL.SECTIONS.FUNDING_DATE"),
      dataIndex: "fundingDate",
      render: (fundingDate) => moment(fundingDate).format("DD/MM/YY"),
    },
    {
      title: Localize("PAGES.COMPANIES.DETAIL.SECTIONS.FUNDING_AMOUNT"),
      dataIndex: "totalAmountInvested",
      render: (totalAmountInvested) =>
        currencyFormatter.format(totalAmountInvested),
    },
  ];

  const investorsColumns = [
    {
      title: Localize("PAGES.COMPANIES.DETAIL.SECTIONS.FUNDING_COMPANY_NAME"),
      render: (data) => (
        <Link
          style={{
            pointerEvents: data.companyId ? "" : "none",
            color: data.companyId ? "" : "rgba(0, 0, 0, 0.65)",
          }}
          to={`/companies/${data.companyId}`}
        >
          {data.companyName}
        </Link>
      ),
      width: "66%",
    },
    {
      title: Localize(
        "PAGES.COMPANIES.DETAIL.SECTIONS.FUNDING_COMPANY_INVESTED"
      ),
      dataIndex: "amountInvested",
      render: (amountInvested) =>
        amountInvested ? currencyFormatter.format(amountInvested) : "-",
    },
  ];

  return (
    <>
      <Card
        title={
          <h2>
            <b>
              {Localize(
                "PAGES.COMPANIES.DETAIL.SECTIONS.INVESTMENT_ROUND_OVERVIEW.TITLE"
              )}
            </b>
          </h2>
        }
        bordered={false}
        className="companyPage__body-card companyPage__body-card--custom customCard"
      >
        {data?.length && (
          <Row>
            <Col span={6} className="mb-3">
              <Row>
                <Label
                  value={Localize(
                    "PAGES.COMPANIES.DETAIL.SECTIONS.INVESTMENT_ROUND_OVERVIEW.LAST_ROUND_TYPE"
                  )}
                />
              </Row>
              {fundingTypes?.map((fundingType) =>
                fundingType._id ===
                orderBy(data, "fundingDate", "desc")[0].fundingType
                  ? translateRegistryKeyFlatV2(
                      fundingType.key,
                      fundingTypeEntity,
                      getFromLocalStorage("lang")?.data
                    ) || fundingType.key
                  : null
              )}
            </Col>
            <Col span={6} className="mb-3">
              <Row>
                <Label
                  value={Localize(
                    "PAGES.COMPANIES.DETAIL.SECTIONS.INVESTMENT_ROUND_OVERVIEW.LAST_ROUND_DATE"
                  )}
                />
              </Row>
              {moment(
                orderBy(data, "fundingDate", "desc")[0].fundingDate
              ).format("DD/MM/YY")}
            </Col>
            <Col span={6} className="mb-3">
              <Row>
                <Label
                  value={Localize(
                    "PAGES.COMPANIES.DETAIL.SECTIONS.INVESTMENT_ROUND_OVERVIEW.TOTAL_INVESTMENT"
                  )}
                />
              </Row>
              {currencyFormatter.format(
                data.reduce(
                  (partialSum, a) => partialSum + a.totalAmountInvested,
                  0
                )
              )}
            </Col>
            <Col span={6} className="mb-3">
              <Row>
                <Label
                  value={Localize(
                    "PAGES.COMPANIES.DETAIL.SECTIONS.INVESTMENT_ROUND_OVERVIEW.TOTAL_ROUND_RECIVED"
                  )}
                />
              </Row>
              {data.length}
            </Col>
          </Row>
        )}
      </Card>
      <Card
        title={
          <h2>
            <b>
              {Localize(
                "PAGES.COMPANIES.DETAIL.SECTIONS.INVESTMENT_ROUND_INFO"
              )}
            </b>
          </h2>
        }
        bordered={false}
        className="companyPage__body-card companyPage__body-card--custom customCard"
      >
        {data && (
          <div style={{ marginTop: 50 }}>
            <InvestmentRoundGraph data={orderBy(data, "fundingDate", "asc")} />
          </div>
        )}
        {data && (
          <div style={{ marginTop: 50 }}>
            <Table
              dataSource={orderBy(data, "fundingDate", "desc")}
              columns={columns}
              size="small"
              bordered={false}
              pagination={false}
              rowKey={(record) => record._id}
              expandable={{
                expandedRowRender: (record) => (
                  <div style={{ margin: "20px 0 30px 20px" }}>
                    <Table
                      columns={investorsColumns}
                      dataSource={record.investors}
                      rowKey={() => uuidv4()}
                      pagination={false}
                      size="small"
                    />
                  </div>
                ),
                rowExpandable: (record) => record.investors?.length > 0,
              }}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default CompanyInvestmentRound;
