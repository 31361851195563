import {
  Alert,
  Box,
  Button as MUIButton,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { registerApi } from "../../api/auth.api";
import SelectTenant from "../../components/SelectTenant/SelectTenant.component";
import BlinkoLogo from "../../design-library/BlinkoLogo";
import Button from "../../design-library/Button/Button.component";
import Checkbox from "../../design-library/Checkbox/Checkbox.component";
import TextField from "../../design-library/TextField/TextField.component";
import { l } from "../../libs/localization";

export default function RegistrationPage() {
  const theme = useTheme();
  const [data, setData] = useState(null);
  const [tenant, setTenant] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user) {
      history.push(`/${history.location.search}`);
    }
  }, [user]);

  async function submit(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      setData(await registerApi({ email, password }));
      setLoading(false);
      history.push("/confirmationEmailSent");
    } catch (err) {
      setLoading(false);
      console.error(err);
      if (err.response?.data?.clientType) {
        setError(
          l(`REGISTRATION.ERROR.${err.response.data.clientType}`) ||
            err.response.data.clientType
        );
      } else {
        setError(err.message);
      }
    }
  }
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 8,
        py: 8,
        width: "100%",
        maxWidth: "none !important",
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <Card sx={{ width: { xs: "100%", sm: "600px", md: "680px" } }}>
        <form onSubmit={submit}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              px: 5.5,
              py: 7.5,
              alignItems: "center",
              position: "relative",
            }}
          >
            <BlinkoLogo style={{ maxWidth: 220, alignSelf: "center" }} />
            <Typography sx={{ mt: 2.5 }} variant="h3">
              {l("REGISTER.INSERT_YOUR_INFO")}
            </Typography>
            {error ? (
              <Alert severity="error" sx={{ alignSelf: "stretch" }}>
                {error}
              </Alert>
            ) : null}
            <TextField
              placeholder={l("LOGIN.EMAIL")}
              label={l("LOGIN.EMAIL")}
              type="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              placeholder={l("LOGIN.PASSWORD")}
              label={l("LOGIN.PASSWORD")}
              type="password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <SelectTenant
              filterTenants={(t) => {
                return t.freeTrialEnabled;
              }}
              currentTenant={tenant}
              setCurrentTenant={setTenant}
            />
            <Box
              sx={{
                mt: 1,
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Checkbox
                label={
                  <Typography sx={{ color: "#565551", fontSize: "0.875rem" }}>
                    {l("REGISTER.PRIVACY_TEXT")}&nbsp;
                    <Link href="#" alt="asd" underline="none">
                      {l("REGISTER.PRIVACY")}
                    </Link>
                  </Typography>
                }
                required
              />
              <Checkbox
                label={
                  <Typography sx={{ color: "#565551", fontSize: "0.875rem" }}>
                    {l("REGISTER.TERMS_TEXT")}&nbsp;
                    <Link href="#" alt="asd" underline="none">
                      {l("REGISTER.TERMS")}
                    </Link>
                  </Typography>
                }
                required
              />
            </Box>
            <Button type="submit">{l("REGISTER.REGISTER")}</Button>
            <Typography variant="body1">
              {l("REGISTER.HAVE_AN_ACCOUNT_ALREADY")}{" "}
              <Link href="/login" alt="login">
                {l("REGISTER.LOGIN")}
              </Link>
            </Typography>
            {loading ? (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",

                  zIndex: 5,
                }}
              >
                <CircularProgress />
              </Box>
            ) : null}
          </CardContent>
          <div />
        </form>
      </Card>
    </Container>
  );
}
