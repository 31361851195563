import callApi from "../../libs/helpers/callApi";
import {
  getFlatEntityListApi,
  createFlatEntityApi,
  editFlatEntityApi,
  deleteFlatEntityApi,
} from "../../api/registry.api";

export const FUNCTION_LOADING = "FUNCTION_LOADING";
export const FUNCTION_LOADED = "FUNCTION_LOADED";
export const FUNCTION_LOAD_ERROR = "FUNCTION_LOAD_ERROR";

// FUNCTION GET LIST
export const getFunctionAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "function" }),
  secured: true,
  startAction: FUNCTION_LOADING,
  successAction: FUNCTION_LOADED,
  errorAction: FUNCTION_LOAD_ERROR,
});
