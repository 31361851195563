import React from "react";
import { Select, Card, Row, Button, Col, Tooltip } from "antd";
import PlusOutlined from "@ant-design/icons/es/icons/PlusOutlined";
import SearchOutlined from "@ant-design/icons/es/icons/SearchOutlined";
import DeleteOutlined from "@ant-design/icons/es/icons/DeleteOutlined";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { orderBy } from "lodash";
import Label from "../Label/Label";
import Localize from "../../libs/localization";

const { Option } = Select;

const TagOptions = ({
  tagList,
  onAddTagClick,
  onAssociateClick,
  onAssociateAllClick,
  tooManyResultsToAssociateAll,
  onSearchClick,
  onExportContacts,
  associateDisabled,
  associateAllDisabled,
  deleteDisabled,
  showExport,
  onTagChange,
  onDeleteClick,
  loadingExport,
  value,
  isForm = false,
}) => {
  const associateAllButton = (
    <Button
      type="primary"
      onClick={onAssociateAllClick}
      disabled={tooManyResultsToAssociateAll || associateAllDisabled}
      style={{ width: "100%" }}
    >
      {Localize("TAG.ASSOCIATE_ALL")}
    </Button>
  );
  return (
    <Card className="companyPage__body-card mt-3" style={{ borderRadius: 10 }}>
      <Row align="bottom" justify="center" gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}>
          <Label className="filter-color" value={Localize("TAG.SELECT")} />
          <Select
            value={value}
            allowClear
            style={{ width: "100%", minWidth: 200 }}
            onChange={(val) => onTagChange(val)}
            onSelect={(val) => onTagChange(val)}
          >
            {tagList &&
              orderBy(tagList, "created_at", "desc").map((tag) => {
                return (
                  <Option value={tag.name} label={tag.name} key={tag._id}>
                    <Row align="middle">
                      <Col span={12}>
                        <Row align="middle">
                          <div
                            className="mr-2"
                            style={{
                              width: 15,
                              height: 15,
                              borderRadius: 50,
                              backgroundColor: `#${tag.color}`,
                            }}
                          />
                          {tag.name}
                        </Row>
                      </Col>
                      <Col span={12} align="center">
                        {tag.created_at &&
                          moment(tag.created_at).format("DD-MM-YYYY")}
                      </Col>
                    </Row>
                  </Option>
                );
              })}
          </Select>
        </Col>
        <>
          {isForm ? (
            <>
              <Col>
                <Button
                  className="mr-3 filter-color"
                  disabled={deleteDisabled}
                  onClick={onDeleteClick}
                >
                  <DeleteOutlined />
                  {Localize("TAG.DELETE_TAG")}
                </Button>
                {showExport && (
                  <Button
                    className="mr-3 filter-color"
                    onClick={onExportContacts}
                    loading={loadingExport}
                  >
                    <DownloadOutlined />
                    {Localize("TAG.EXPORT_CONTACTS")}
                  </Button>
                )}
              </Col>
            </>
          ) : (
            <>
              <Col className="mt-2">
                <Button icon={<PlusOutlined />} onClick={onAddTagClick}>
                  {Localize("TAG.CREATE")}
                </Button>
              </Col>
              <Col
                className="mt-2"
                style={{
                  minWidth: 200,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
              >
                <Button
                  type="primary"
                  onClick={onAssociateClick}
                  disabled={associateDisabled}
                >
                  {Localize("TAG.ASSOCIATE")}
                </Button>
              </Col>
              <Col
                className="mt-2"
                style={{
                  minWidth: 200,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
              >
                {tooManyResultsToAssociateAll ? (
                  <Tooltip title={Localize("TAG.TOO_MANY_RESULTS")}>
                    {associateAllButton}
                  </Tooltip>
                ) : (
                  associateAllButton
                )}
              </Col>
            </>
          )}
        </>
      </Row>
    </Card>
  );
};

export default TagOptions;
