import React from "react";
import { Select, Card, Row, Button, Col, Popconfirm } from "antd";
import Localize from "../../libs/localization";

const { Option } = Select;

const SearchManagement = ({
  type,
  searchList,
  onSearchElementChange,
  onDeleteSearch,
  value,
}) => {
  return (
    <Row align="bottom" className={!value ? "mr-4" : "mr-5"}>
      {value && (
        <Col span={8}>
          <Popconfirm
            title={Localize("CONFIRM.DELETE")}
            onConfirm={onDeleteSearch}
            okText={Localize("COMMON.YES")}
            cancelText={Localize("COMMON.NO")}
          >
            <Button type="link" className="secondary">
              {Localize("PAGES.SEARCH.DELETE_SEARCH")}
            </Button>
          </Popconfirm>
        </Col>
      )}
      <Col span={8}>
        <Select
          value={value}
          allowClear={value}
          style={{ width: "300px" }}
          optionLabelProp="label"
          onChange={(id) =>
            onSearchElementChange(
              id,
              id
                ? searchList[
                    searchList.findIndex((search) => search._id === id)
                  ].data
                : null
            )
          }
          onSelect={(id) =>
            onSearchElementChange(
              id,
              id
                ? searchList[
                    searchList.findIndex((search) => search._id === id)
                  ].data
                : null
            )
          }
          placeholder={Localize("PAGES.SEARCH.SELECT_SEARCH")}
        >
          {searchList &&
            searchList.map((search, i) => {
              return (
                <Option
                  value={search._id}
                  label={search.name}
                  key={search._id ? search._id : i}
                >
                  <Row>
                    <Col span={12}>{search.name}</Col>
                    {search.cache?.resultsCount !== undefined &&
                      type === "contacts" && (
                        <Col span={12} style={{ textAlign: "right" }}>
                          {search.cache?.resultsCount}{" "}
                          {Localize("PAGES.SEARCH.CONTACTS").toLowerCase()}
                        </Col>
                      )}
                  </Row>
                </Option>
              );
            })}
        </Select>
      </Col>
    </Row>
  );
};

export default SearchManagement;
