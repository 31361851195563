import callApi from "../../libs/helpers/callApi";
import { getFlatEntityListApi } from "../../api/registry.api";

export const OTHER_CATEGORIES_LOADING = "OTHER_CATEGORIES_LOADING";
export const OTHER_CATEGORIES_LOADED = "OTHER_CATEGORIES_LOADED";
export const OTHER_CATEGORIES_LOAD_ERROR = "OTHER_CATEGORIES_LOAD_ERROR";

// GET OTHER CATEGORIES GET LIST
export const getOtherCategoriesAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "other_categories" }),
  secured: true,
  startAction: OTHER_CATEGORIES_LOADING,
  successAction: OTHER_CATEGORIES_LOADED,
  errorAction: OTHER_CATEGORIES_LOAD_ERROR,
});
