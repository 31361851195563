import {
  Card,
  CardContent,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import BlinkoLogo from "../../design-library/BlinkoLogo";
import EmailSentIcon from "../../design-library/icons/EmailSentIcon.component";
import { l } from "../../libs/localization";

export default function ConfirmationEmailSentPage() {
  const theme = useTheme();
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
        py: 8,
        width: "100%",
        maxWidth: "none !important",
        backgroundColor: theme.palette.primary.light,
        minHeight: "100vh",
      }}
    >
      <Card sx={{ width: { xs: "100%", sm: "600px", md: "680px" } }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            px: 5.5,
            py: 7.5,
            alignItems: "center",
            position: "relative",
          }}
        >
          <BlinkoLogo style={{ maxWidth: 180, alignSelf: "center" }} />
          <EmailSentIcon sx={{ fontSize: 60 }} />
          <Typography sx={{ mt: 2.5 }} variant="h3">
            {l("REGISTER.CONFIRM_EMAIL_ADDRESS_TITLE")}
          </Typography>
          <Typography variant="body1">
            {l("REGISTER.CONFIRM_EMAIL_ADDRESS_BODY")}
          </Typography>
        </CardContent>
        <div />
      </Card>
    </Container>
  );
}
