import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ResponsiveLine } from "@nivo/line";
import { Row } from "antd";

const PitchGraph = ({ data }) => {
  const [dataSet, setDataSet] = useState();
  const orderDataByYear = (data) => {
    let uniqDataInfo = getOnlyUniqIds(data);
    let dates = [];
    uniqDataInfo &&
      uniqDataInfo.map((graphArray) => {
        // prendo tutte le date, ordinate e uniche
        graphArray.data.map((g) => dates.push(g.x));
        dates.sort((a, b) => a - b).filter((val) => val !== undefined);
        dates = _.uniq(dates);
        setDataSet(uniqDataInfo);
        //return data;
      });
    // cerco se l'array del grafico contiene tutte le date, altrimenti le aggiungo
    uniqDataInfo.map((grapArray) => {
      dates.forEach((date) => {
        const founded = grapArray.data
          .sort((a, b) => a.x - b.x)
          .find((g) => g.x === date);
        if (!founded) {
          grapArray.data.push({ x: date, y: 0 });
          grapArray.data.sort((a, b) => a.x - b.x);
        }
      });
      setDataSet(uniqDataInfo);
    });
  };

  useEffect(() => {
    orderDataByYear(data);
  }, [data]);

  /**
   * Sometimes the pitch graph data have the same id (like "Agenzia Eventi")
   * So we need to merge the info from duplicates on one uniq element
   * @param {*} response
   * @returns
   */
  const getOnlyUniqIds = (response = []) => {
    let newResponse = [];
    if (response?.length > 0) {
      newResponse = response.reduce((accumulator, cur) => {
        var id = cur.id;
        var found = accumulator.find((elem) => {
          return elem.id == id;
        });
        if (found) cur.data.forEach((curObj) => found.data.push(curObj));
        else accumulator.push(cur);
        return accumulator;
      }, []);
    }
    return newResponse;
  };

  return (
    <Row style={{ height: "400px", width: "100%" }}>
      <ResponsiveLine
        data={dataSet}
        margin={{ top: 10, right: 180, bottom: 50, left: 50 }}
        xScale={{ type: "point" }}
        yScale={{ type: "linear" }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "year",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          format: (e) => Math.floor(e) === e && e,
        }}
        useMesh={true}
        colors={{ scheme: "spectral" }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        legends={[
          {
            anchor: "top-right",
            direction: "column",
            itemWidth: 10,
            itemHeight: 20,
            itemTextColor: "#999",
            symbolSize: 18,
            symbolShape: "circle",
            translateX: 30,
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
      />
    </Row>
  );
};

export default PitchGraph;
