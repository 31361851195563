/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import {
  FormControlLabel,
  Checkbox as MUICheckbox,
  useTheme,
} from "@mui/material";

export default function Checkbox({ label, ...props }) {
  const theme = useTheme();
  return (
    <label
      style={{
        display: "flex",
        alignItems: "center",
        zIndex: "3",
        alignSelf: "stretch",
      }}
    >
      <MUICheckbox
        id="checkbox"
        color="secondary"
        sx={{
          color: theme.palette.secondary.main,
          ml: "-13px",
        }}
        {...props}
      />
      {label}
    </label>
  );
}
