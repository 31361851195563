export const TO_EMAIL_ALERTS_TYPES_MAP = {
  0: "new_contact",
  1: "contact_change",
  2: "new_pitch",
  3: "new_creativity",
  4: "new_fundraiser",
  5: "new_mergers_and_acquisitions",
};

export const FROM_EMAIL_ALERTS_TYPES_MAP = {
  new_contact: "0",
  contact_change: "1",
  new_pitch: "2",
  new_creativity: "3",
  new_fundraiser: "4",
  new_mergers_and_acquisitions: "5",
};
