import ErrorMessage from "../libs/helpers/ErrorMessage";
import Config from "../Config";

export const getCommentListApi = async ({ headers, params }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.comment.api}?entityId=${params.entityId}&entityType=${params.entityType}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const createCommentApi = async ({ headers, data }) => {
  const res = await fetch(`${Config.apiBaseurl}/${Config.apis.comment.api}`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });

  if (res.ok) {
    return;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const editCommentApi = async ({ headers, data, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.comment.api}/${id}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    }
  );

  if (res.ok) {
    return;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const deleteCommentApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.comment.api}/${id}`,
    {
      method: "DELETE",
      headers,
    }
  );

  if (res.ok) {
    return;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};
