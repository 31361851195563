import callApi from "../libs/helpers/callApi";
import { getNielsenEntityApi, getNielsenYearsApi } from "../api/nielsen.api";

export const NIELSEN_LIST_LOADING = "NIELSEN_LIST_LOADING";
export const NIELSEN_LIST_LOADED = "NIELSEN_LIST_LOADED";
export const NIELSEN_LIST_LOAD_ERROR = "NIELSEN_LIST_LOAD_ERROR";

export const getNielsenEntityAction = callApi({
  apiCall: (p) => getNielsenEntityApi(p),
  secured: true,
  startAction: NIELSEN_LIST_LOADING,
  successAction: NIELSEN_LIST_LOADED,
  errorAction: NIELSEN_LIST_LOAD_ERROR,
});

export const NIELSEN_YEARS_LOADING = "NIELSEN_YEARS_LOADING";
export const NIELSEN_YEARS_LOADED = "NIELSEN_YEARS_LOADED";
export const NIELSEN_YEARS_LOAD_ERROR = "NIELSEN_YEARS_LOAD_ERROR";

export const getNielsenYearsAction = callApi({
  apiCall: (p) => getNielsenYearsApi(p),
  secured: true,
  startAction: NIELSEN_YEARS_LOADING,
  successAction: NIELSEN_YEARS_LOADED,
  errorAction: NIELSEN_YEARS_LOAD_ERROR,
});
