import React, { useEffect, useRef, useState } from "react";
import { List, Row, Tag, Col, Button, Spin, Checkbox } from "antd";
import _, { toUpper } from "lodash";
import { Box, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import PictureOutlined from "@ant-design/icons/es/icons/PictureOutlined";
import StarOutlined from "@ant-design/icons/es/icons/StarOutlined";
import StarFilled from "@ant-design/icons/es/icons/StarFilled";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { searchCompaniesAction } from "../../actions/search.action";
import {
  getGmapStringAddress,
  translateRegistryOnView,
} from "../../libs/utils";
import Localize, { bareLocalize } from "../../libs/localization";
import { deleteAssociatedIdTagAction } from "../../actions/tag.actions";
import { openCompanyDetailAction } from "../../actions/company.actions";
import useListSelection from "../../hooks/useListSelection";

const CompanyList = (props) => {
  const {
    data: companyList,
    pageSize,
    setPageSize,
    title = "",
    onRowClick,
    total,
    filters = {},
    tagMode,
    setSelectedCompany = () => {},
    isTagPage = false,
    loadingTag,
    loadingList,
    associatedTagsIds,
    setCompanyTagPage,
    resultsLimited = false,
    alreadyOpened = [],
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [selection, setSelection] = useState([]);
  const {
    deselectAll,
    toggle,
    addToSelection,
    removeFromSelection,
  } = useListSelection({ selection, setSelection });
  const [selectAll, setSelectAll] = useState(false);

  const dispatch = useDispatch();

  const fetchData = (page, pageSize_) => {
    if (!isTagPage && !loadingList) {
      if (pageSize !== pageSize_) {
        setPageSize(pageSize_);
      }
      let params = {};

      params = {
        ...(filters || {}),
        pageNum: page,
        limit: pageSize_,
      };

      dispatch(
        searchCompaniesAction({
          data: params,
        })
      );
    }
  };

  function openCompanyDetail(companyId) {
    dispatch(openCompanyDetailAction(companyId));
    // history.push(`/companies/${item._id}`)
  }

  const prevFilters = useRef();
  useEffect(() => {
    if (!prevFilters.current || !_.isEqual(prevFilters.current, filters)) {
      setCurrentPage(1);
    }
    prevFilters.current = filters;
  }, [filters]);

  useEffect(() => {
    setSelectedCompany(selection);
  }, [selection]);

  useEffect(() => {
    deselectAll();
    setSelectAll(false);
  }, [tagMode]);

  const renderTag = (item) => {
    let renderedTags = [];
    associatedTagsIds &&
      associatedTagsIds.map((association) => {
        const founded = association._id === item._id;
        if (founded) {
          renderedTags = association.tags.map((tag) => (
            <Tag
              key={tag.tagId}
              color={`#${tag.color}`}
              className="searchPage__item-tag"
              closable
              onClose={() =>
                dispatch(
                  deleteAssociatedIdTagAction({
                    tagId: tag.tagId,
                    associatedId: association._id,
                  })
                )
              }
            >
              {tag.name}
            </Tag>
          ));
        }
      });
    return renderedTags;
  };

  const renderStar = (item) => {
    const selected = selection.includes(item._id);
    const Component = selected ? StarFilled : StarOutlined;
    return <Component style={{ fontSize: 20 }} />;
  };

  console.log("current", currentPage);

  return (
    <>
      {loadingList ? (
        <div className="text-center">
          <Spin className="mt-5" />
        </div>
      ) : (
        <div title={title}>
          {tagMode && (
            <Checkbox
              checked={selectAll}
              onChange={(e) => {
                setSelectAll(e.target.checked);
                const ids = companyList.map((company) => company._id);
                if (e.target.checked) {
                  addToSelection(ids);
                } else {
                  removeFromSelection(ids);
                }
              }}
            >
              {Localize("COMMON.SELECT_ALL")}
            </Checkbox>
          )}
          <List
            className="searchPage__list animation"
            itemLayout="horizontal"
            dataSource={companyList}
            pagination={
              resultsLimited
                ? false
                : {
                    onChange: (page, pageSize_) => {
                      setSelectAll(false);
                      setCurrentPage(page);
                      setPageSize(pageSize_);
                      if (setCompanyTagPage) setCompanyTagPage(page);
                      fetchData(page, pageSize_);
                      console.log("set page size", page, pageSize_);
                    },
                    current: currentPage,
                    pageSize,
                    total,
                    showSizeChanger: !isTagPage,
                  }
            }
            renderItem={(item) => {
              const row = (
                <div className="searchPage__item-more animation fadein-right slow">
                  <List.Item
                    className="searchPage__item"
                    onClick={(e) => {
                      if (!(e.ctrlKey || e.metaKey)) {
                        e.preventDefault();
                        e.stopPropagation();
                        typeof onRowClick === "function" && onRowClick(item);
                        !tagMode && openCompanyDetail(item._id);
                      }
                    }}
                  >
                    <List.Item.Meta
                      avatar={
                        item.logo ? (
                          <div
                            style={{ backgroundImage: `url("${item.logo}")` }}
                            className="searchPage__item-logo"
                          />
                        ) : (
                          <Row align="middle">
                            <PictureOutlined className="searchPage__item-logo--placeholder" />
                          </Row>
                        )
                      }
                      title={
                        <Row align="middle" justify="space-between">
                          <Col>
                            <h3 className="searchPage__item-title">
                              {tagMode && (
                                <Button
                                  type="text"
                                  shape="circle"
                                  size="small"
                                  className="mr-2"
                                  onClick={() => {
                                    toggle(item._id);
                                  }}
                                >
                                  {renderStar(item)}
                                </Button>
                              )}
                              <b className="mr-2">{toUpper(item.name)}</b>
                              {loadingTag ? (
                                <Spin size="small" className="ml-4" />
                              ) : (
                                renderTag(item)
                              )}
                            </h3>
                          </Col>
                          <Box sx={{ marginRight: 2 }}>
                            {alreadyOpened?.includes(item._id) ? (
                              <Tooltip
                                title={bareLocalize(
                                  "COMPANY_LIST.ALREADY_OPENED.TOOLTIP"
                                )}
                              >
                                <FileDownloadDoneIcon />
                              </Tooltip>
                            ) : null}
                          </Box>
                        </Row>
                      }
                      description={
                        <>
                          <p>{getGmapStringAddress(item)}</p>
                          <p>
                            {item.sector &&
                              item.sector.map((s, i) => (
                                <Tag key={i}>
                                  {" "}
                                  {translateRegistryOnView(s._id, "Sector")
                                    ? translateRegistryOnView(s._id, "Sector")
                                    : s.key}{" "}
                                </Tag>
                              ))}
                          </p>
                        </>
                      }
                    />
                  </List.Item>
                </div>
              );
              if (!tagMode)
                return (
                  <Link
                    to={`/companies/${item._id}`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {row}
                  </Link>
                );
              return row;
            }}
          />
        </div>
      )}
    </>
  );
};

export default CompanyList;
