import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  SECTOR_LOADING,
  SECTOR_LOADED,
  SECTOR_LOAD_ERROR,
} from "../../actions/registry/registry.sector.actions";

const reducer = {
  /*
   * SECTOR LIST
   */
  [SECTOR_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [SECTOR_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [SECTOR_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
