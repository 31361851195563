import Localize from "../../libs/localization";

export const statusOptions = [
  {
    key: "ALL",
    value: "Tutti",
    label: Localize("PAGES.PITCH.STATUS.ALL")
  },
  {
    key: "ONGOING",
    value: "In corso",
    label: Localize("PAGES.PITCH.STATUS.ON_GOING")
  },
  {
    key: "CLOSED",
    value: "Chiusi",
    label: Localize("PAGES.PITCH.STATUS.CLOSED")
  },
];
