import { reducerFromMap } from "../libs/helpers/reducers";
import {
  ASSOCIATE_TAG_LOAD_ERROR,
  ASSOCIATE_TAG_LOADED,
  ASSOCIATE_TAG_LOADING,
  CREATE_TAG_LOAD_ERROR,
  CREATE_TAG_LOADED,
  CREATE_TAG_LOADING,
  TAG_LOAD_ERROR,
  TAG_LOADED,
  TAG_LOADING,
  RESET_TAG_DETAIL,
  TAG_COMPANY_DETAIL_LOADING,
  TAG_COMPANY_DETAIL_LOADED,
  TAG_COMPANY_DETAIL_LOAD_ERROR,
  TAG_CONTACT_DETAIL_LOADING,
  TAG_CONTACT_DETAIL_LOADED,
  TAG_CONTACT_DETAIL_LOAD_ERROR,
  DELETE_TAG_LOADING,
  DELETE_TAG_LOADED,
  DELETE_TAG_LOAD_ERROR,
  DELETE_TAG_ASSOCIATED_ID_LOADING,
  DELETE_TAG_ASSOCIATED_ID_LOADED,
  DELETE_TAG_ASSOCIATED_ID_LOAD_ERROR,
  ASSOCIATION_TAG_ID_COMPANY_LOADING,
  ASSOCIATION_TAG_ID_COMPANY_LOADED,
  ASSOCIATION_TAG_ID_COMPANY_LOAD_ERROR,
  ASSOCIATION_TAG_ID_CONTACT_LOADING,
  ASSOCIATION_TAG_ID_CONTACT_LOADED,
  ASSOCIATION_TAG_ID_CONTACT_LOAD_ERROR,
  EXPORT_TAG_CONTACTS_LOADING,
  EXPORT_TAG_CONTACTS_LOADED,
  EXPORT_TAG_CONTACTS_LOAD_ERROR,
  SINGLE_CONTACT_TAGS_LOADING,
  SINGEL_CONTACT_TAGS_LOADED,
  SINGEL_CONTACT_TAGS_LOAD_ERROR,
} from "../actions/tag.actions";

const reducer = {
  /*
   * TAG LIST
   */
  [TAG_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [TAG_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    tagsList: data.data,
  }),
  [TAG_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * TAG COMPANY DETAIL
   */
  [TAG_COMPANY_DETAIL_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [TAG_COMPANY_DETAIL_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    companiesTagDetailData: data,
    companiesTagDetail: data.data.companies,
  }),
  [TAG_COMPANY_DETAIL_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * TAG CONTACT DETAIL
   */
  [TAG_CONTACT_DETAIL_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [TAG_CONTACT_DETAIL_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    contactsTagDetailData: data,
    contactsTagDetail: data.data.contacts,
  }),
  [TAG_CONTACT_DETAIL_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * TAG CREATION
   */
  [CREATE_TAG_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [CREATE_TAG_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [CREATE_TAG_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * ASSOCIATE TAG
   */
  [ASSOCIATE_TAG_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [ASSOCIATE_TAG_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [ASSOCIATE_TAG_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  [RESET_TAG_DETAIL]: (state) => ({
    ...state,
    contactsTagDetail: [],
    companiesTagDetail: [],
    contactsTagDetailData: [],
    companiesTagDetailData: [],
  }),
  /*
   * ASSOCIATIONS TAG - ID COMPANY
   */
  [ASSOCIATION_TAG_ID_COMPANY_LOADING]: (state) => ({
    ...state,
    tagsForIdsCompany: {
      loading: true,
    },
  }),
  [ASSOCIATION_TAG_ID_COMPANY_LOADED]: (state, { data }) => ({
    ...state,
    tagsForIdsCompany: {
      loading: false,
      data: data.data,
    },
  }),
  [ASSOCIATION_TAG_ID_COMPANY_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    tagsForIdsCompany: {
      loading: false,
    },
    error: data,
  }),
  /*
   * ASSOCIATIONS TAG - ID CONTACT
   */
  [ASSOCIATION_TAG_ID_CONTACT_LOADING]: (state) => ({
    ...state,
    tagsForIdsContact: {
      loading: true,
    },
  }),
  [ASSOCIATION_TAG_ID_CONTACT_LOADED]: (state, { data }) => ({
    ...state,
    tagsForIdsContact: {
      loading: false,
      data: data.data,
    },
  }),
  [ASSOCIATION_TAG_ID_CONTACT_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    tagsForIdsContact: {
      loading: false,
    },
    error: data,
  }),
  /*
   * GET SINGLE CONTACT TAGS
   */
  [SINGLE_CONTACT_TAGS_LOADING]: (state) => ({
    ...state,
    singleContactTags: {
      loading: true,
    },
  }),
  [SINGEL_CONTACT_TAGS_LOADED]: (state, { data }) => ({
    ...state,
    singleContactTags: {
      loading: false,
      data: data.data,
    },
  }),
  [SINGEL_CONTACT_TAGS_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    singleContactTags: {
      loading: false,
    },
    error: data,
  }),
  /*
   * DELETE TAG
   */
  [DELETE_TAG_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [DELETE_TAG_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [DELETE_TAG_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * DELETE TAG ASSOCIATED ID
   */
  [DELETE_TAG_ASSOCIATED_ID_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [DELETE_TAG_ASSOCIATED_ID_LOADED]: (state) => ({
    ...state,
    loading: false,
  }),
  [DELETE_TAG_ASSOCIATED_ID_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
  /*
   * EXPORT TAG CONTACTS
   */
  [EXPORT_TAG_CONTACTS_LOADING]: (state) => ({
    ...state,
    exportContactList: { loading: true },
  }),
  [EXPORT_TAG_CONTACTS_LOADED]: (state) => ({
    ...state,
    exportContactList: {
      loading: false,
    },
  }),
  [EXPORT_TAG_CONTACTS_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    exportContactList: {
      loading: false,
    },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  tagsList: [],
  contactsTagDetail: [],
  companiesTagDetail: [],
  tagsForIdsCompany: {},
  tagsForIdsContact: {},
  exportContactList: {},
  singleContactTags: {},
});
