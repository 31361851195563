import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import browserHistory from "./history";

import reducers from "../reducers";
import appMiddlewares from "../middlewares";

import { register } from "../libs/helpers/ioc";
import { APP_START } from "../actions/app.actions";
import { createReduxHistoryContext } from "redux-first-history";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: browserHistory,
  });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const middlewares = [...appMiddlewares, thunk];

  const store = createStore(
    combineReducers({
      router: routerReducer,
      ...reducers,
    }),
    composeEnhancers(applyMiddleware(...middlewares, routerMiddleware)),
  );

  if (module.hot) {
    module.hot.accept("../reducers/", () => {
      const updatedRootReducer = require("../reducers").default;
      store.replaceReducer(updatedRootReducer);
    });
  }

  store.dispatch({ type: APP_START });

  register("store", store);

  return store;
};

export default configureStore;
export { createReduxHistory };
