import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, useTheme } from "@mui/material";
import {
  getFromLocalStorage,
  setOnLocalStorage,
} from "../../libs/helpers/localstorage";
import LocalizedFlag from "../Localized/LocalizedFlag";
import { getTenantListAction } from "../../actions/user.actions";
import { flagMap } from "../../libs/utils";
import Select from "../../design-library/Select/Select.component";
import MenuItem from "../../design-library/MenuItem/MenuItem.component";
import InputLabel from "../../design-library/TextField/InputLabel.component";
import { l } from "../../libs/localization";

export default function SelectTenant(props) {
  const {
    onSelect = null,
    id = "selectTenant",
    currentTenant,
    setCurrentTenant,
    filterTenants = () => true,
  } = props;
  const theme = useTheme();

  const dispatch = useDispatch();

  const tenantList = useSelector((state) => state.user.tenant);
  const user = useSelector((state) => state.auth.user);

  function handleChange(e) {
    console.log(e.target.value);
    setOnLocalStorage(e.target.value || "", "lefacWebTenant");
    if (typeof onSelect === "function") {
      onSelect(e.target.value);
    }
    setCurrentTenant(e.target.value);
  }

  useEffect(() => {
    console.log({ currentTenant });
    if (
      tenantList?.data &&
      filterTenants &&
      currentTenant &&
      currentTenant !== ""
    ) {
      console.log("about to check tenant");
      const tenantObj = tenantList.data.find((t) => t._id === currentTenant);
      console.log({ tenantObj });
      if (tenantObj && !filterTenants(tenantObj)) {
        console.log("check tenant, set null");
        handleChange({ target: { value: null } });
      }
    }
  }, [tenantList, currentTenant]);

  useEffect(() => {
    const tenant = getFromLocalStorage("lefacWebTenant");
    if (tenant && tenant !== currentTenant) {
      setCurrentTenant(tenant.data);
    }
  });

  useEffect(() => {
    if (tenantList.data.length === 0) {
      dispatch(getTenantListAction());
    }
  }, []);

  return (
    <>
      <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor={id}>{l("LOGIN.CHOOSE_TENANT")}</InputLabel>
        <Select
          id={id}
          sx={{
            "& .MuiInputBase-input": {
              marginTop: 4,
            },
            "& .MuiSelect-icon": {
              top: `calc(50% - .5em + ${theme.spacing(4)} / 2)`,
            },
          }}
          value={currentTenant}
          style={{ width: "100%" }}
          onChange={handleChange}
          renderValue={(v) =>
            !tenantList || !v.length ? (
              l("LOGIN.CHOOSE_TENANT")
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <LocalizedFlag locale={flagMap[v]} square />
                &nbsp;{tenantList.data.find((t) => t._id === v)?.name}
              </div>
            )
          }
          placeholder=""
        >
          {tenantList &&
            tenantList.data.length > 0 &&
            tenantList.data
              .filter(
                (t) =>
                  t.hidden !== true ||
                  user?.group === "ADMIN" ||
                  user?.group === "SUPER-ADMIN"
              )
              .filter((t) => filterTenants(t))
              .map((t) => (
                <MenuItem
                  key={t._id}
                  value={t._id}
                  label={t.name}
                  icon={<LocalizedFlag locale={flagMap[t._id]} square />}
                />
              ))}
        </Select>
      </FormControl>
    </>
  );
}
