import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, orderBy, remove, isEqual } from "lodash";
import { Button, Col, InputNumber, Modal, Row, Select } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import {
  getNielsenEntityAction,
  getNielsenYearsAction,
} from "../../actions/nielsen.action";

import Label from "../../components/Label/Label";
import Localize from "../../libs/localization";

const InvestmentForm = (props) => {
  const { dataForm, onChange } = props;

  const entity = useSelector((state) => state.nielsen.list);
  const years = useSelector((state) => state.nielsen.years);

  const [filter, setFilter] = useState(dataForm.nielsen_filters || []);
  const [selectedEntity, setSelectedEntity] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(entity)) {
      dispatch(getNielsenEntityAction());
    }
  }, [entity]);

  useEffect(() => {
    setFilter(dataForm.nielsen_filters || []);
  }, [dataForm.nielsen_filters]);

  useEffect(() => {
    if (isEmpty(years)) {
      dispatch(getNielsenYearsAction());
    }
  }, [years]);

  const clear = () => {
    setSelectedEntity([]);
    setSelectedYear(null);
    setMin(null);
    setMax(null);
  };

  const generateFilter = () => {
    const filterArray = [];
    selectedEntity.forEach((e) => {
      const f = {
        year: selectedYear,
        key: e,
      };
      if (min) {
        f.min = min;
      }
      if (max) {
        f.max = max;
      }
      filterArray.push(f);
    });
    const total = [...filter, ...filterArray];
    setFilter(total);
    clear();
    onChange("nielsen_filters", total);
  };

  const removeFromFilterList = (f) => {
    remove(filter, (c) => {
      return isEqual(f, c);
    });
    setFilter(filter);
    onChange("nielsen_filters", filter);
  };

  return (
    <>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {filter.map((f, i) => (
          <li key={i}>
            {/*<Logo style={{ height: "10px" }} />*/}
            <CloseOutlined onClick={() => removeFromFilterList(f)} />
            <b className="secondary">
              {" "}
              {Localize(`SCHEMA.DEFAULT.NIELSEN.${f.key}`)
                ? Localize(`SCHEMA.DEFAULT.NIELSEN.${f.key}`)
                : f.key}
            </b>{" "}
            | {f.year}{" "}
            <>
              | <span className="mr-2">{Localize("COMMON.FROM")}</span>
              {f.min ? f.min : 0}
            </>
            {f.max && (
              <>
                <span className="mr-2"> {Localize("COMMON.TO")}</span>
                {f.max}
              </>
            )}
          </li>
        ))}
      </ul>
      <Row gutter={[16, 16]}>
        <Col md={7} xs={24}>
          <Label className="filter-color" value={Localize("COMMON.TYPE")} />
          <Select
            mode="multiple"
            allowClear={true}
            style={{ width: "100%" }}
            value={selectedEntity}
            onChange={(value) => setSelectedEntity(value)}
            showSearch
            options={
              entity.data &&
              orderBy(entity.data, "key")
                .filter((e) => !e.key.includes("NIELSEN"))
                .map((e, j) => {
                  return {
                    label: Localize(`SCHEMA.DEFAULT.NIELSEN.${e.key}`)
                      ? Localize(`SCHEMA.DEFAULT.NIELSEN.${e.key}`)
                      : e.key,
                    value: e.key,
                    key: e.key,
                  };
                })
            }
          />
        </Col>
        <Col md={7} xs={24}>
          <Label className="filter-color" value={Localize("COMMON.YEAR")} />
          <Select
            allowClear={true}
            style={{ width: "100%" }}
            value={selectedYear}
            onChange={(value) => setSelectedYear(value)}
            onClear={() => setSelectedYear(null)}
            showSearch
            options={
              years.data &&
              years.data.allYears &&
              years.data.allYears.map((e, j) => {
                return {
                  value: e,
                  key: e,
                };
              })
            }
          />
        </Col>
        <Col xs={24} sm={5} lg={3}>
          <Label className="filter-color" value={Localize("COMMON.FROM")} />
          <Row>
            <InputNumber
              style={{ flex: 1 }}
              min={0}
              value={min}
              onChange={(value) => setMin(value)}
            />
          </Row>
        </Col>
        <Col xs={24} sm={5} lg={3}>
          <Label className="filter-color" value={Localize("COMMON.TO")} />
          <Row>
            <InputNumber
              style={{ flex: 1 }}
              min={0}
              value={max}
              onChange={(value) => setMax(value)}
            />
          </Row>
        </Col>
        <Col xs={24} sm={14} md={24} lg={4}>
          <Row style={{ height: "100%" }} align="bottom" justify="end">
            <Button
              disabled={
                selectedYear === null ||
                selectedEntity.length === 0 ||
                (min === null && max === null)
              }
              type="primary"
              onClick={() => generateFilter()}
            >
              <PlusOutlined />
              {Localize("COMMON.ADD")}
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default InvestmentForm;
