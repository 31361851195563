export default function useListSelection({ selection, setSelection }) {
  const self = {
    deselectAll() {
      setSelection([]);
    },
    addToSelection(items) {
      setSelection([
        ...selection,
        ...items.filter((item) => !selection.includes(item)),
      ]);
    },
    removeFromSelection(items) {
      setSelection(selection.filter((item) => !items.includes(item)));
    },
    toggle(item) {
      if (selection.includes(item)) {
        self.removeFromSelection([item]);
      } else {
        self.addToSelection([item]);
      }
    },
  };
  return self;
}
