import {
  FUNDING_TYPE_LOADED,
  FUNDING_TYPE_LOADING,
  FUNDING_TYPE_LOAD_ERROR,
} from "../../actions/registry/registry.funding_type.actions";
import { reducerFromMap } from "../../libs/helpers/reducers";

const reducer = {
  /*
   * FUNDING_TYPE LIST
   */
  [FUNDING_TYPE_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [FUNDING_TYPE_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data,
  }),
  [FUNDING_TYPE_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
