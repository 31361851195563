import React from "react";
import { ListItemText, MenuItem as MUIMenuItem } from "@mui/material";

export default function MenuItem({ value, label, icon, ...props }) {
  return (
    <MUIMenuItem value={value} {...props}>
      {icon}
      {icon ? <>&nbsp;</> : null}
      <ListItemText primary={label} />
    </MUIMenuItem>
  );
}
