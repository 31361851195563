import { createTheme, responsiveFontSizes } from "@mui/material";

const primaryColor = {
  main: "#1A53CB",
  light: "#F1F7FF",
  contrastText: "#fff",
};

const secondaryColor = {
  main: "#FFAC2A",
  light: "#ECEAE0",
  contrastText: "#fff",
};
const theme = responsiveFontSizes(
  createTheme({
    shape: {
      borderRadius: 12,
    },
    palette: {
      primary: primaryColor,
      secondary: secondaryColor,
      error: {
        main: "#FF502A",
      },
    },
    typography: {
      fontFamily: ["Lato", "sans-serif"].join(","),
      h3: {
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: 1.25,
        color: "#FFAC2A",
      },
    },
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            color: primaryColor.main,
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontSize: "18px",
          },
        },
      },
    },
  })
);

export default theme;
