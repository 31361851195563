import React from "react";
import { Layout, Row, Col, Dropdown, Menu, Button } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import LogoutOutlined from "@ant-design/icons/es/icons/LogoutOutlined";

import LocalizedFlag from "../Localized/LocalizedFlag";

import Logo from "../../assets/images/blinko-azul.png";

import "./Header.scss";
import Localize, { getCurrentLocale } from "../../libs/localization";
import Config from "../../Config";
import { goTo, setLanguageAction } from "../../actions";
import { logoutApi } from "../../api/auth.api";

const { Header } = Layout;

export default ({ collapsed, onToggleClick, username }) => {
  const locale = useSelector((state) => state.app.language);
  const dispatch = useDispatch();

  return (
    <>
      <Header className="header">
        <Row justify="space-between">
          <Col span="auto">
            <div className="logo">
              <img className="siteLayout__header-logo" alt="logo" src={Logo} />
            </div>
          </Col>
          <Col span="auto">
            <Dropdown
              className="dropdown-user"
              overlay={
                <Menu>
                  {(Config.locales || []).map((l, i) => (
                    <Menu.Item key={i}>
                      <Button
                        type="link"
                        onClick={() => {
                          dispatch(setLanguageAction({ language: l }));
                        }}
                      >
                        {LocalizedFlag({
                          locale: getCurrentLocale(),
                          style: { height: 10 },
                        })}
                      </Button>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={["click"]}
              placement="bottomCenter"
              arrow
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                style={{ marginRight: 30 }}
              >
                {LocalizedFlag({
                  locale: getCurrentLocale(),
                  style: { height: 10 },
                })}
              </a>
            </Dropdown>
            <Dropdown
              className="dropdown-user"
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    onClick={async () => {
                      await logoutApi();
                      dispatch(goTo("/logout/do"));
                    }}
                  >
                    <LogoutOutlined /> {Localize("LOGIN.LOGOUT")}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
              placement="bottomRight"
              arrow
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <UserOutlined />
                {username || "User"}
                <DownOutlined />
              </a>
            </Dropdown>
          </Col>
        </Row>
      </Header>
    </>
  );
};
