import React, { useEffect } from "react";
import { Card } from "antd";
import { getWikipediaSummary } from "../../api/common.api";

const CardWiki = (props) => {
  const { data } = props;
  const [wikiContent, setWikiContent] = React.useState("");
  const [wikiLink, setWikiLink] = React.useState(null);
  /* Fix dismount before async call finished. Cancelling the subscription to useEffect optimizes the app.*/
  const mountedRef = React.useRef(true);

  useEffect(() => {
    const wikiLinkData =
      data?.external_links?.[
        data?.external_links?.findIndex((elem) => elem.label === "wikipedia")
      ]?.link;
    if (wikiLinkData) {
      getWiki(wikiLinkData);
      setWikiLink(wikiLinkData);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [data]);

  const getWiki = async (link) => {
    getWikipediaSummary(link).then((res) => {
      if (!mountedRef.current) return null;
      setWikiContent(res.extract);
    });
  };

  if (wikiContent) {
    return (
      <Card
        title={
          <a href={wikiLink} target="_blank">
            <h2>
              <b>Wikipedia</b>
            </h2>
          </a>
        }
        bordered={false}
        className="companyPage__body-card companyPage__body-card--custom"
      >
        <p>{wikiContent}</p>
      </Card>
    );
  }

  return null;
};

export default CardWiki;
