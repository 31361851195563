import { useEffect, useState, useCallback } from "react";
import { getValuesApi } from "../api/search.api";

export default function useFieldValues({ entity, field }) {
  const [values, setValues] = useState(null);
  const [loading, setLoading] = useState(false);

  const load = useCallback(() => {
    setLoading(true);
    getValuesApi({ entity, field })
      .then((res) => {
        setValues(res);
      })
      .catch((error) => {
        window.setError(error);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [entity, field]);

  useEffect(() => {
    if (!values) {
      load();
    }
  }, [values, load]);
  return [values, loading, load];
}
