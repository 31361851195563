import React, { Fragment, useState, useEffect } from "react";
import { Tabs as AntdTabs, Affix, Spin } from "antd";
import "./Tabs.scss";

export default ({
  children,
  options = {
    renderTabBar: (props, DefaultTabBar) => (
      <Affix offsetTop={64}>
        <DefaultTabBar {...props} />
      </Affix>
    ),
    onTabClick: () => {
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: 0,
      });
    },
    animated: true,
  },
  containerClass = "",
}) => {
  return (
    <div className={containerClass}>
      <AntdTabs {...options}>
        {children &&
          (Array.isArray(children) ? children : [children])
            .map((child, key) => {
              return child?.props ? (
                <AntdTabs.TabPane
                  tab={
                    <>
                      {child.props.title || `Tab ${child.props?.k || key}`}
                      {child.props.loading && (
                        <Spin size="small" style={{ marginLeft: 5 }} />
                      )}
                    </>
                  }
                  key={child.props?.k || key}
                >
                  {child}
                </AntdTabs.TabPane>
              ) : null;
            })
            .filter((e) => !!e)}
      </AntdTabs>
    </div>
  );
};
