import axios from "axios";
import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";
import { getFromLocalStorage } from "../libs/helpers/localstorage";
import Localize from "../libs/localization";

export const getCompanyListApi_old = async ({
  headers,
  page,
  params = null,
}) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.list}?pageNum=${page || 1}${
      params ? `&${params.join("&")}` : ""
    }`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

const abortChannels = {};

export async function getCompanyListApi({
  headers,
  page,
  params = null,
  abortChannel = null,
}) {
  if (abortChannel !== null) {
    if (abortChannels[abortChannel]) {
      abortChannels[abortChannel].abort();
    }
    abortChannels[abortChannel] = new AbortController();
  }
  const ls = getFromLocalStorage();
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const token = ls && ls.token;
  try {
    const res = await axios.get(
      `${Config.apiBaseurl}/${Config.apis.company.list}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-tenant": tenant,
          ...headers,
        },
        params: {
          pageNum: page || 1,
          ...params,
        },
        signal:
          abortChannel !== null ? abortChannels[abortChannel].signal : null,
      }
    );
    return res.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      return { canceled: true };
    }
    throw e;
  }
}

export const getCompanyDetailApi = async ({
  headers,
  id,
  countView = false,
}) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}?stats=${
      countView ? "yes" : "no"
    }`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getCompanyContactsApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/contacts`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getCompanyContactsSummaryApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.contactSummary}/${id}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getCompanyBrandsApi = async ({
  headers,
  id,
  sectors = null,
  isAgency,
}) => {
  let url = `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/${
    isAgency ? "workingBrands" : "brands"
  }`;
  if (sectors && sectors.length > 0) {
    url += `?sort_key=name&sort_direction=desc`;
    sectors.forEach((sector) => (url += `&sectors[]=${sector.id}`));
  }
  const res = await fetch(url, {
    method: "GET",
    headers,
  });

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getCompanyNielsenApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/nielsen`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getCompanyPitchApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/company/${id}/pitchs`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getCompanyCreaApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${
      Config.apis.company.detail
    }/${id}/creas?${new URLSearchParams({
      hide_inactive: true,
    })}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getCompanySocialMediaApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/socialMedia`,
    {
      method: "GET",
      headers,
    }
  );
  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getCompanyGroupApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.detail}/${id}/ownershipGraph`,
    {
      method: "GET",
      headers,
    }
  );
  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getFinancialsYearsApi = async ({ headers, key }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.financials.api}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getInvestmentRoundApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.investmentRound}/${id}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;

  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getCompanyEntityApi = async ({ headers, page }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.list}/entity`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }

  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const exportCompanyListApi = async ({ headers, data }) => {
  console.log(
    `[MM] exportCompanyListApi: url calcolato = ${Config.apiBaseurl}/${Config.apis.company.list}/export`
  );

  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.company.list}/export`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.blob();

  if (res.ok) {
    return response;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    status !== 403 ? description : Localize("ERROR.ERROR_EXPORT_COMPANIES"),
    httpStatus,
    status !== 403 ? status : null,
    clientType
  );
};
