import callApi from "../libs/helpers/callApi";
import {
  createUserApi,
  editUserApi,
  getUserDetailApi,
  getUserListApi,
  getUserProfilesApi,
  getTenantListApi,
  deleteUserApi,
  getPluginListApi,
} from "../api/user.api";
import { goTo } from "./app.actions";
import { refreshPage } from "../libs/utils";

export const TENANT_LIST_LOADING = "TENANT_LIST_LOADING";
export const TENANT_LIST_LOADED = "TENANT_LIST_LOADED";
export const TENANT_LIST_LOAD_ERROR = "TENANT_LIST_LOAD_ERROR";

// ADMIN PROFILES
export const getTenantListAction = callApi({
  apiCall: (p) => getTenantListApi(p),
  secured: true,
  startAction: TENANT_LIST_LOADING,
  successAction: TENANT_LIST_LOADED,
  errorAction: TENANT_LIST_LOAD_ERROR,
});

export const ADMIN_DETAIL_LOADING = "ADMIN_DETAIL_LOADING";
export const ADMIN_DETAIL_LOADED = "ADMIN_DETAIL_LOADED";
export const ADMIN_DETAIL_LOAD_ERROR = "ADMIN_DETAIL_LOAD_ERROR";

// ADMIN_DETAIL
export const getUserDetailAction = callApi({
  apiCall: (p) => getUserDetailApi(p),
  secured: true,
  startAction: ADMIN_DETAIL_LOADING,
  successAction: ADMIN_DETAIL_LOADED,
  errorAction: ADMIN_DETAIL_LOAD_ERROR,
});
