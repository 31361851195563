import { Helmet } from "react-helmet";
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Tag, Modal, Button, Spin, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TeamOutlined from "@ant-design/icons/es/icons/TeamOutlined";
import FlagOutlined from "@ant-design/icons/es/icons/FlagOutlined";
import EuroOutlined from "@ant-design/icons/es/icons/EuroOutlined";
import { groupBy } from "lodash";
import {
  BellFilled,
  BellOutlined,
  BellTwoTone,
  DownOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import queryString from "query-string";
import EnvironmentOutlined from "@ant-design/icons/es/icons/EnvironmentOutlined";
import ShopOutlined from "@ant-design/icons/es/icons/ShopOutlined";
import ScheduleOutlined from "@ant-design/icons/es/icons/ScheduleOutlined";
import TableOutlined from "@ant-design/icons/es/icons/TableOutlined";
import ClockCircleOutlined from "@ant-design/icons/es/icons/ClockCircleOutlined";
import Localize from "../../components/Localized/Localize.comp";
import CardAddress from "../../components/Cards/Card.address";
import Tabs from "../../components/Tabs/Tabs";
import CardContacts from "../../components/Cards/Card.contacts";
import CardWiki from "../../components/Cards/CardWiki";
import BannerTop from "../../components/BannerTop/BannerTop";
import CompanyInvestments from "./company.investments";
import {
  getCompanyContactsSummaryAction,
  getCompanyDetailAction,
} from "../../actions/company.actions";
import Label from "../../components/Label/Label";
import CardField from "../../components/Cards/CardField";
import { getEmailHelp, label, translateRegistryOnView } from "../../libs/utils";
import { OtherSocial, Social } from "../../components/Cards/Social";
import CompanyContacts from "./company.contacts";
import CompanyBrands from "./company.brands";
import CompanyPitch from "./company.pitch";
import CompanyCrea from "./company.crea";
import _Localize, { l } from "../../libs/localization";
import CompanyFinancial from "./company.financial";
import { getTreeSectorAction } from "../../actions/registry/registry.sector.actions";
import { get } from "../../libs/helpers/ioc";
import CompanyGroup from "./company.group";
import { headersBuilder } from "../../libs/helpers/callApi";
import {
  getCompanyBrandsApi,
  getCompanyCreaApi,
  getCompanyGroupApi,
  getCompanyNielsenApi,
  getCompanyPitchApi,
  getInvestmentRoundApi,
} from "../../api/company.api";
import CardComment from "../../components/Cards/CardComment";
import { associationsTagIdCompanyAction } from "../../actions/tag.actions";
import TagManage from "../../components/Tag/TagManage";
import CompanyInvestmentRound from "./company.investment_round";
import PersonalComments from "../../components/Cards/PersonalComments";
import { getFeatures } from "../../libs/features";
import "./company.scss";
import usePermissions from "../../hooks/usePermissions";
import { getEntityNewsForEntityApi } from "../../api/entityNews.api";
import EntityNews from "../../components/EntityNews/EntityNews.component";
import CompanyJobOffers from "./company.jobOffers";
import usePitchScores from "../../hooks/usePitchScores";
import PitchScoreList from "../../components/List/PitchScore.list";
import {
  createSettingApi,
  deleteSettingApi,
  editSettingApi,
  getSettingsApi,
  upsertSettingApi,
} from "../../api/settings.api";
import {
  FROM_EMAIL_ALERTS_TYPES_MAP,
  TO_EMAIL_ALERTS_TYPES_MAP,
} from "../../libs/emailAlerts";

const CompanyDetail = (props) => {
  const { match } = props;

  const features = getFeatures();

  const [showDetailTagModal, setShowDetailTagModal] = useState(false);
  const [showInvestmentRound, setShowInvestmentRound] = useState(false);
  const [showNielsen, setShowNielsen] = useState(false);
  const [showBrands, setShowBrands] = useState(false);
  const [showPitch, setShowPitch] = useState(false);
  const [showCrea, setShowCrea] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [entityNews, setEntityNews] = useState(null);

  const [selectedAlerts, setSelectedAlerts] = useState([]);

  const [companySettings, setCompanySettings] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const permissions = usePermissions(user);

  const tabKeys = {
    detail: "_0",
    brands: "_1",
    pitch: "_2",
    crea: "_3",
    group: "_4",
    investmentRounds: "_5",
    financials: "_6",
    nielsen: "_7",
    contacts: "_8",
    jobOffers: "_9",
    pitchScores: "_10",
  };

  const {
    data: pitchScores,
    loading: pitchScoresLoading,
    options: pitchScoresOptions,
    setOptions: setPitchScoresOptions,
    setFilters: setPitchScoresFilters,
  } = usePitchScores({
    failSilently: true,
  });

  useEffect(() => {
    setPitchScoresFilters({
      companyId: [match.params.id],
      minPitchScore: 1,
    });
  }, [match.params.id]);

  useEffect(() => {
    async function load() {
      const res = await getSettingsApi({
        entityType: "company",
        entityId: match.params.id,
      });
      setCompanySettings(res.data);
    }
    if (permissions.emailAlerts && !companySettings) {
      load();
    }
  }, [match.params.id, companySettings, permissions]);

  useEffect(() => {
    if (Array.isArray(companySettings)) {
      const alertsSettings = companySettings.find(
        (s) => s.key === "email-alerts.company",
      )?.data;
      if (!alertsSettings?.events) setSelectedAlerts([]);
      else {
        const arr = [];
        Object.keys(alertsSettings?.events).forEach((key) => {
          if (alertsSettings?.events[key])
            arr.push(FROM_EMAIL_ALERTS_TYPES_MAP[key]);
        });
        setSelectedAlerts(arr);
      }
    }
  }, [companySettings]);

  const prevSelectedAlertsLength = useRef(selectedAlerts.length);

  useEffect(() => {
    function toSettingsObj(alertsArray) {
      const obj = {};
      alertsArray.forEach((a) => {
        obj[TO_EMAIL_ALERTS_TYPES_MAP[a]] = true;
      });
      return obj;
    }
    if (Array.isArray(companySettings)) {
      const alertsSettings = companySettings.find(
        (s) => s.key === "email-alerts.company",
      );
      const arr = [];
      if (alertsSettings?.data) {
        Object.keys(alertsSettings?.data?.events).forEach((key) => {
          if (alertsSettings?.data?.events?.[key])
            arr.push(FROM_EMAIL_ALERTS_TYPES_MAP[key]);
        });
        if (arr.sort().join(",") !== selectedAlerts.sort().join(",")) {
          if (selectedAlerts.length === 0) {
            if (prevSelectedAlertsLength.current > 0) {
              deleteSettingApi({ id: alertsSettings._id });
              setCompanySettings(null);
            }
          } else {
            editSettingApi({
              id: alertsSettings._id,
              data: {
                data: { events: toSettingsObj(selectedAlerts) },
              },
            });
          }
        }
      } else if (selectedAlerts.length > 0) {
        upsertSettingApi({
          key: "email-alerts.company",
          entityType: "company",
          entityId: match.params.id,
          data: { events: toSettingsObj(selectedAlerts) },
        });
      }
    }
    prevSelectedAlertsLength.current = selectedAlerts.length;
  }, [selectedAlerts, match.params.id, companySettings]);

  const [activeTab, setActiveTab] = useState(tabKeys.detail);

  const params = queryString.parse(window.location.search);

  const data = useSelector((state) => state.company.detail.data);
  const schema = useSelector((state) => state.company.detail.schema);
  const sector = useSelector((state) => state.sector.data);
  const contactSummary = useSelector(
    (state) => state.company.contactSummary.data,
  );
  const associatedTagsIdsCompany = useSelector(
    (state) => state.tag.tagsForIdsCompany,
  );
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const entity = "company";

  const isAgency = ["AG", "AC"].includes(data?.type);

  async function loadEntityNews() {
    const res = await getEntityNewsForEntityApi({
      entityType: "company",
      entityId: match.params.id,
    });
    setEntityNews(res.data);
  }

  useEffect(() => {
    if (match.params.id && match.params.id !== data?._id) {
      dispatch(
        getCompanyDetailAction({ id: match.params.id, countView: true }),
      );
      dispatch(getTreeSectorAction());
      console.log(permissions);
    }
  }, [match.params.id]);

  useEffect(() => {
    if (match.params.id && data) {
      dispatch(getCompanyContactsSummaryAction({ id: match.params.id }));
      resetTabView();
      setShowTabs();
      if (permissions.favourites) {
        dispatch(
          associationsTagIdCompanyAction({ data: { id: [match.params.id] } }),
        );
      }
      if (permissions.entityNews && features.entityNews) {
        loadEntityNews();
      }
    }
  }, [data, permissions.favourites, permissions.entityNews]);

  const setShowTabs = async () => {
    let gotBrands = false;
    await Promise.all([
      getInvestmentRoundApi({
        headers: {
          ...headersBuilder(),
          Authorization: `Bearer ${token}`,
        },
        id: match.params.id,
      })
        .then((investment) => {
          if (investment?.length > 0) setShowInvestmentRound(true);
        })
        .catch(() => null),

      getCompanyNielsenApi({
        headers: {
          ...headersBuilder(),
          Authorization: `Bearer ${token}`,
        },
        id: match.params.id,
      })
        .then((nielsen) => {
          if (nielsen?.data?.history) setShowNielsen(true);
        })
        .catch(() => null),

      getCompanyBrandsApi({
        headers: {
          ...headersBuilder(),
          Authorization: `Bearer ${token}`,
        },
        id: match.params.id,
        isAgency,
      })
        .then((brands) => {
          if (brands?.data?.length > 0) {
            setShowBrands(true);
            gotBrands = true;
          }
        })
        .catch(() => null),

      getCompanyCreaApi({
        headers: {
          ...headersBuilder(),
          Authorization: `Bearer ${token}`,
        },
        id: match.params.id,
      })
        .then((crea) => {
          if (crea?.data?.length > 0) setShowCrea(true);
        })
        .catch(() => null),

      getCompanyGroupApi({
        headers: {
          ...headersBuilder(),
          Authorization: `Bearer ${token}`,
        },
        id: match.params.id,
      })
        .then((group) => {
          if (
            group?.children?.length > 0 ||
            group?.parent?.children?.length > 0
          )
            setShowGroup(true);
        })
        .catch(() => null),

      getCompanyPitchApi({
        headers: {
          ...headersBuilder(),
          Authorization: `Bearer ${token}`,
        },
        id: match.params.id,
      })
        .then((pitch) => {
          if (pitch?.data?.length > 0) setShowPitch(true);
        })
        .catch(() => null),
    ]);
    if (params.brand && gotBrands && activeTab !== tabKeys.brands) {
      setActiveTab(tabKeys.brands);
    }
  };

  const resetTabView = () => {
    setShowInvestmentRound(false);
    setShowNielsen(false);
    setShowBrands(false);
    setShowCrea(false);
    setShowGroup(false);
    setShowPitch(false);
  };

  function sectorModal(title) {
    data.sector.map((companySector, companySectorIndex) => {
      sector.map((sectorTree, sectorIndex) => {
        if (sectorTree.children) {
          sectorTree.children.map((childrenSector) => {
            if (childrenSector.children) {
              childrenSector.children.map((c) => {
                if (c._id === companySector._id)
                  data.sector[companySectorIndex].parent =
                    sector[sectorIndex].key;
              });
            }
            if (childrenSector._id === companySector._id)
              data.sector[companySectorIndex].parent = sector[sectorIndex].key;
          });
        }
      });
    });
    const groupedSector = groupBy(data.sector, "parent");
    Modal.info({
      title,
      content: (
        <>
          {Object.keys(groupedSector).map((parent) => {
            const parentObj = sector.find((s) => {
              return s.key === parent;
            });
            return (
              <>
                {parent !== "undefined" && (
                  <p>
                    {parentObj
                      ? translateRegistryOnView(parentObj._id, "Sector") ||
                        parent
                      : parent}
                  </p>
                )}
                {parent !== "undefined" ? (
                  <ul>
                    {groupedSector[parent].map((s) => (
                      <li className="m-1" key={s._id}>
                        {translateRegistryOnView(s._id, "Sector") || s.key}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <>
                    {groupedSector[parent].map((s) => (
                      <li className="m-1" key={s._id}>
                        {translateRegistryOnView(s.key, "Sector")
                          ? translateRegistryOnView(s.key, "Sector")
                          : s.key}
                      </li>
                    ))}
                  </>
                )}
              </>
            );
          })}
        </>
      ),
      width: "800px",
      onOk: () => setShowDetailTagModal(false),
    });
  }

  function info(title, content) {
    Modal.info({
      title,
      content,
      width: "800px",
      onOk: () => setShowDetailTagModal(false),
    });
  }

  const tabBarExtraContent = (
    <a href={`mailto:${getEmailHelp()}?subject=Company ${data?.name}`}>
      <Button type="text" className="help-btn">
        {Localize("COMMON.HELP")}
      </Button>
    </a>
  );

  const permissionScopes = get("scopes");
  return (
    <div className="companyPage">
      {data && (
        <>
          <Helmet defer={false}>
            <title>{data.name} - Blinko</title>
          </Helmet>
          <div className="companyPage__head profile__container">
            <BannerTop>
              <Row className="profile__container--left" align="middle">
                {data.logo && (
                  <div className="profile__avatar">
                    <img
                      src={data.logo}
                      className="companyPage__head-logo"
                      alt={`logo-${data.name}`}
                    />
                  </div>
                )}
                <div className="profile__data">
                  <div className="grey-light-color">
                    {translateRegistryOnView(
                      data.main_category,
                      "main_category",
                      true,
                    )
                      ? translateRegistryOnView(
                          data.main_category,
                          "main_category",
                          true,
                        )
                      : data.main_category}
                  </div>
                  <h2 className="grey-light-color">{data.name}</h2>
                  {data.parent_firm_name && (
                    <div className="grey-light-color">
                      {Localize("COMMON.GROUP", [data.parent_firm_name])}
                    </div>
                  )}
                  <div>
                    {associatedTagsIdsCompany?.loading ? (
                      <Spin size="small" />
                    ) : (
                      <TagManage
                        tagInfo={associatedTagsIdsCompany}
                        entityId={match.params.id}
                        onAssociateTag={() => {
                          dispatch(
                            associationsTagIdCompanyAction({
                              data: { id: [match.params.id] },
                            }),
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              </Row>
              <Row className="profile__container--right" align="middle">
                {permissions.emailAlerts ? (
                  <div>
                    <Dropdown.Button
                      icon={
                        <DownOutlined
                          style={{ color: "rgba(0, 0, 0, 0.65)" }}
                        />
                      }
                      loading={false}
                      menu={{
                        items: Object.values(TO_EMAIL_ALERTS_TYPES_MAP).map(
                          (la, i) => ({
                            label: Localize(
                              `PAGES.SEARCH.COMPANY.ALERTS.EVENTS.${la}`,
                            ),
                            key: i,
                          }),
                        ),
                        selectable: true,
                        selectedKeys: selectedAlerts,
                        onSelect: (obj) => {
                          console.log(obj);
                          setSelectedAlerts([...selectedAlerts, obj.key]);
                        },
                        onDeselect: (obj) => {
                          console.log(obj);
                          setSelectedAlerts(
                            selectedAlerts.filter((s) => s !== obj.key),
                          );
                        },
                      }}
                      onClick={() => {
                        console.log("click", selectedAlerts.length);
                        if (selectedAlerts.length < 6) {
                          setSelectedAlerts(["0", "1", "2", "3", "4", "5"]);
                        } else {
                          setSelectedAlerts([]);
                        }
                      }}
                    >
                      {{
                        0: (
                          <BellOutlined
                            style={{
                              color: "rgba(0, 0, 0, 0.65)",
                              fontSize: 14,
                            }}
                          />
                        ),
                        6: (
                          <BellFilled
                            style={{
                              color: "rgba(0, 0, 0, 0.65)",
                              fontSize: 14,
                            }}
                          />
                        ),
                      }[selectedAlerts.length] || (
                        <BellTwoTone
                          style={{ color: "rgba(0, 0, 0, 0.65)", fontSize: 14 }}
                          twoToneColor="#777777"
                        />
                      )}{" "}
                      {Localize("PAGES.SEARCH.COMPANY.ALERTS.FOLLOW")}
                    </Dropdown.Button>
                  </div>
                ) : null}

                <Social external={data.external_links} />
              </Row>
            </BannerTop>
          </div>
          <Col span={24} className="companyPage__body">
            <Tabs
              options={{
                activeKey: activeTab,
                defaultActiveKey: tabKeys.detail,
                onChange: (key) => setActiveTab(key),
                onTabClick: () => {
                  window.scrollTo({
                    behavior: "smooth",
                    left: 0,
                    top: 0,
                  });
                },
                animated: true,
                tabBarExtraContent,
              }}
            >
              <div
                k={tabKeys.detail}
                className="grey-light-color"
                title={Localize(
                  "PAGES.COMPANIES.DETAIL.SECTIONS.GENERAL.TITLE",
                )}
              >
                <Row gutter={16} className="animation fadein-up slow">
                  {/*left col*/}
                  <Col span={16}>
                    <Card
                      title={
                        <h2>
                          <b>
                            {Localize(
                              "PAGES.COMPANIES.DETAIL.SECTIONS.GENERAL.OVERVIEW",
                            )}
                          </b>
                        </h2>
                      }
                      bordered={false}
                      className="companyPage__body-card companyPage__body-card--custom"
                    >
                      <Row gutter={16}>
                        <CardField
                          entity={entity}
                          data={data}
                          schema={schema}
                          val="creation_year"
                          icon={
                            <ClockCircleOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        <CardField
                          entity={entity}
                          data={data}
                          schema={schema}
                          val="nation_orig"
                          icon={
                            <FlagOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        <CardField
                          entity={entity}
                          data={data}
                          schema={schema}
                          val="store_number"
                          icon={
                            <ShopOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        <CardField
                          entity={entity}
                          data={data}
                          schema={schema}
                          val="medium_planning"
                          icon={
                            <ScheduleOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        <CardField
                          entity={entity}
                          data={data}
                          schema={schema}
                          val="income_range"
                          icon={
                            <EuroOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        <CardField
                          entity={entity}
                          data={data}
                          schema={schema}
                          val="employer_range"
                          icon={
                            <TeamOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        <CardField
                          entity={entity}
                          data={data}
                          schema={schema}
                          val="ateco_code"
                          icon={
                            <SnippetsOutlined
                              style={{ fontSize: 20 }}
                              className="mr-2"
                            />
                          }
                        />
                        {data.sector && data.sector.length > 0 && (
                          <Col span={24} className="mb-3">
                            <Row>
                              <TableOutlined
                                style={{ fontSize: 20 }}
                                className="mr-2"
                              />
                              <Label value={label(schema, "sector", entity)} />
                            </Row>
                            {data.sector.slice(0, 5).map((s) => (
                              <Tag className="m-1" key={s._id}>
                                {translateRegistryOnView(s._id, "Sector") ||
                                  s.key}
                              </Tag>
                            ))}
                            {data.sector.length > 5 && (
                              <Tag
                                className="pointer"
                                onClick={() =>
                                  sectorModal(
                                    <Label
                                      value={_Localize("COMMON.SECTORS")}
                                    />,
                                  )
                                }
                              >
                                + {data.sector.length - 5}{" "}
                                {Localize("COMMON.SECTORS")}
                              </Tag>
                            )}
                          </Col>
                        )}
                        {data.international_invest &&
                          data.international_invest.length > 0 && (
                            <Col span={24} className="mb-3">
                              <Row>
                                <EnvironmentOutlined
                                  style={{ fontSize: 20 }}
                                  className="mr-2"
                                />
                                <Label
                                  value={label(
                                    schema,
                                    "international_invest",
                                    entity,
                                  )}
                                />
                              </Row>
                              {data.international_invest
                                .slice(0, 5)
                                .map((s) => (
                                  <Tag className="m-1" key={s}>
                                    {translateRegistryOnView(
                                      s,
                                      "international_invest",
                                      true,
                                    )
                                      ? translateRegistryOnView(
                                          s,
                                          "international_invest",
                                          true,
                                        )
                                      : s}
                                  </Tag>
                                ))}
                              {data.international_invest.length > 5 && (
                                <Tag
                                  className="pointer"
                                  onClick={() =>
                                    info(
                                      <Label
                                        value={label(
                                          schema,
                                          "international_invest",
                                        )}
                                      />,
                                      <ul
                                        style={{
                                          columnCount: parseInt(
                                            data.international_invest.length /
                                              15,
                                          ),
                                        }}
                                      >
                                        {data.international_invest.map((s) => (
                                          <li className="m-1" key={s._id}>
                                            {translateRegistryOnView(
                                              s,
                                              "international_invest",
                                              true,
                                            )
                                              ? translateRegistryOnView(
                                                  s,
                                                  "international_invest",
                                                  true,
                                                )
                                              : s}
                                          </li>
                                        ))}
                                      </ul>,
                                    )
                                  }
                                >
                                  + {data.international_invest.length - 5}{" "}
                                  {Localize("COMMON.COUNTRIES")}
                                </Tag>
                              )}
                            </Col>
                          )}
                      </Row>
                    </Card>
                    <CardWiki data={data} />
                    {data.public_comment && (
                      <CardComment comment={data.public_comment} />
                    )}
                    {features.collectiveAgreements &&
                      permissions.collectiveAgreements &&
                      data.collectiveAgreements?.length && (
                        <Card
                          title={
                            <h2>
                              <b>
                                {Localize(
                                  "PAGES.COMPANIES.DETAIL.SECTIONS.COLLECTIVE_AGREEMENTS.TITLE",
                                )}
                              </b>
                            </h2>
                          }
                          bordered={false}
                          className="companyPage__body-card companyPage__body-card--custom mt-3"
                        >
                          {data.collectiveAgreements
                            ? data.collectiveAgreements.map((ca) => (
                                <p>
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {ca.identifier}
                                  </span>{" "}
                                  - {ca.key}
                                </p>
                              ))
                            : null}
                        </Card>
                      )}
                    {features.entityNews && entityNews?.length ? (
                      <Card
                        title={
                          <h2>
                            <b>
                              {l(
                                "PAGES.COMPANIES.DETAIL.SECTIONS.ENTITY_NEWS.TITLE",
                              )}
                            </b>
                          </h2>
                        }
                        bordered={false}
                        className="companyPage__body-card companyPage__body-card--custom"
                      >
                        {entityNews.map((news) => (
                          <EntityNews news={news} />
                        ))}
                      </Card>
                    ) : null}
                    {features.comments && permissions.comments && (
                      <PersonalComments
                        entityType="company"
                        entityId={match.params.id}
                        className="companyPage__body-card companyPage__body-card--custom"
                        style={{
                          borderRadius: "10px",
                          overflow: "hidden",
                        }}
                      />
                    )}
                    <Card
                      title={
                        <h2>
                          <b>
                            {Localize(
                              "PAGES.COMPANIES.DETAIL.SECTIONS.GENERAL.LINKS",
                            )}
                          </b>
                        </h2>
                      }
                      bordered={false}
                      className="companyPage__body-card companyPage__body-card--custom"
                    >
                      <OtherSocial external={data.external_links} />
                    </Card>
                  </Col>
                  <Col span={8} className="animation fadein-up slow ">
                    <CardContacts data={data} />
                    {data.address && <CardAddress data={data} />}
                  </Col>
                </Row>
              </div>
              {contactSummary?.length > 0 && (
                <div
                  k={tabKeys.contacts}
                  className="grey-light-color"
                  title={Localize("PAGES.COMPANIES.DETAIL.SECTIONS.CONTACTS")}
                >
                  <Row gutter={24} className="animation ">
                    <Col
                      span={24}
                      style={{ marginBottom: "30px" }}
                      className=""
                    >
                      <CompanyContacts companyId={match.params.id} />
                    </Col>
                  </Row>
                </div>
              )}
              {data.financial_history && (
                <div
                  k={tabKeys.financials}
                  className="grey-light-color"
                  title={Localize("PAGES.COMPANIES.DETAIL.SECTIONS.FINANCIALS")}
                >
                  <Row gutter={24} className="animation">
                    <Col span={24}>
                      <CompanyFinancial data={data.financial_history} />
                    </Col>
                  </Row>
                </div>
              )}
              {permissions.jobOffers && data.jobOffers?.length && (
                <div
                  k={tabKeys.jobOffers}
                  className="grey-light-color"
                  title={Localize("PAGES.COMPANIES.DETAIL.SECTIONS.JOB_OFFERS")}
                >
                  <Row gutter={24} className="animation">
                    <Col span={24}>
                      <CompanyJobOffers
                        data={data.jobOffers}
                        updatedAt={data.jobOffersUpdatedAt}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {showInvestmentRound && (
                <div
                  k={tabKeys.investmentRounds}
                  className="grey-light-color"
                  title={Localize(
                    "PAGES.COMPANIES.DETAIL.SECTIONS.INVESTMENT_ROUND",
                  )}
                >
                  <Row gutter={24} className="animation">
                    <Col span={24}>
                      <CompanyInvestmentRound companyId={match.params.id} />
                    </Col>
                  </Row>
                </div>
              )}
              {Object.keys(permissionScopes).some(
                (p) => p === "companyInvestment",
              ) &&
                showNielsen && (
                  <div
                    k={tabKeys.nielsen}
                    className="grey-light-color"
                    title={Localize(
                      "PAGES.COMPANIES.DETAIL.SECTIONS.INVESTMENTS",
                    )}
                  >
                    <Row gutter={24} className="animation">
                      <Col
                        span={24}
                        style={{ marginBottom: "30px" }}
                        className="animation"
                      >
                        <CompanyInvestments companyId={match.params.id} />
                      </Col>
                    </Row>
                  </div>
                )}
              {Object.keys(permissionScopes).some(
                (p) => p === "companyBrand",
              ) &&
                showBrands && (
                  <div
                    k={tabKeys.brands}
                    className="grey-light-color"
                    title={Localize("PAGES.COMPANIES.DETAIL.SECTIONS.BRANDS")}
                  >
                    <Row gutter={24}>
                      <Col span={24} style={{ marginBottom: "30px" }}>
                        <CompanyBrands
                          companyId={match.params.id}
                          sectors={isAgency ? sector : data.sector}
                          isAgency={isAgency}
                          // disableDetail={
                          //   data.type !== "ADV" &&
                          //   data.type !== "ME" &&
                          //   data.type !== "AG" &&
                          //   data.type !== "CP"
                          // }
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              {data &&
                Object.keys(permissionScopes).some(
                  (p) => p === "companyPitch",
                ) &&
                (data.type === "ADV" ||
                  data.type === "ME" ||
                  data.type === "AG" ||
                  data.type === "CP") &&
                showPitch && (
                  <div
                    className="grey-light-color"
                    title="Pitch"
                    k={tabKeys.pitch}
                  >
                    <Row gutter={24}>
                      <CompanyPitch
                        companyId={match.params.id}
                        companyName={data.name}
                      />
                    </Row>
                  </div>
                )}
              {data &&
                Object.keys(permissionScopes).some(
                  (p) => p === "companyCrea",
                ) &&
                (data.type === "ADV" ||
                  data.type === "ME" ||
                  data.type === "AG" ||
                  data.type === "CP") &&
                showCrea && (
                  <div
                    k={tabKeys.crea}
                    className="grey-light-color"
                    title={Localize(
                      "PAGES.COMPANIES.DETAIL.SECTIONS.CREATIVITY",
                    )}
                  >
                    <Row gutter={24}>
                      <CompanyCrea companyId={match.params.id} />
                    </Row>
                  </div>
                )}
              {showGroup && (
                <div
                  className="grey-light-color"
                  title={Localize("PAGES.COMPANIES.DETAIL.SECTIONS.GROUP")}
                  k={tabKeys.group}
                >
                  <Row gutter={24} className="animation ">
                    <Col
                      span={24}
                      style={{ marginBottom: "30px" }}
                      className=""
                    >
                      <CompanyGroup
                        companyId={match.params.id}
                        companyName={data.name}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {pitchScores && pitchScores?.results?.length > 0 && (
                <div
                  className="grey-light-color"
                  title={Localize("PAGES.PITCH_SCORES.TITLE")}
                  k={tabKeys.pitchScores}
                  key={tabKeys.pitchScores}
                >
                  <Row gutter={24} className="animation ">
                    <Col
                      span={24}
                      style={{ marginBottom: "30px" }}
                      className=""
                    >
                      <PitchScoreList
                        data={pitchScores?.results || []}
                        total={pitchScores?.pageInfo?.totalResults || 0}
                        loadingList={pitchScoresLoading}
                        options={pitchScoresOptions}
                        setOptions={setPitchScoresOptions}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </Tabs>
          </Col>
        </>
      )}
    </div>
  );
};

export default (props) => {
  return (
    <CompanyDetail
      key={`companyDetails_${props.match?.params?.id}`}
      {...props}
    />
  );
};
