import { reducerFromMap } from "../libs/helpers/reducers";
import {
  FLATV2_LIST_LOADED,
  FLATV2_LIST_LOADING,
  FLATV2_LIST_LOAD_ERROR,
} from "../actions/flatV2.actions";

const reducer = {
  /*
   * FUNCTION LIST
   */
  [FLATV2_LIST_LOADING]: (state, { entity }) => ({
    entities: {
      ...state.entities,
      [entity]: {
        loading: true,
        error: null,
      },
    },
  }),
  [FLATV2_LIST_LOADED]: (state, { data, entity }) => ({
    entities: {
      ...state.entities,
      [entity]: {
        loading: false,
        data,
      },
    },
  }),
  [FLATV2_LIST_LOAD_ERROR]: (state, { data, entity }) => ({
    ...state,
    entities: {
      ...state.entities,
      [entity]: {
        loading: false,
        error: data,
      },
    },
  }),
};

export default reducerFromMap(reducer, {
  entities: [],
});
