import callApi from "../../libs/helpers/callApi";
import { getFlatEntityListApi } from "../../api/registry.api";

export const EMPLOYER_RANGE_LOADING = "EMPLOYER_RANGE_LOADING";
export const EMPLOYER_RANGE_LOADED = "EMPLOYER_RANGE_LOADED";
export const EMPLOYER_RANGE_LOAD_ERROR = "EMPLOYER_RANGE_LOAD_ERROR";

// EMPLOYER RANGE GET LIST
export const getEmployerRangeAction = callApi({
  apiCall: (p) => getFlatEntityListApi({ ...p, entity: "employer_range" }),
  secured: true,
  startAction: EMPLOYER_RANGE_LOADING,
  successAction: EMPLOYER_RANGE_LOADED,
  errorAction: EMPLOYER_RANGE_LOAD_ERROR,
});
