import { reducerFromMap } from "../libs/helpers/reducers";

import {
  ADMIN_DETAIL_LOAD_ERROR,
  ADMIN_DETAIL_LOADED,
  ADMIN_DETAIL_LOADING,
  TENANT_LIST_LOADING,
  TENANT_LIST_LOAD_ERROR,
  TENANT_LIST_LOADED,
} from "../actions/user.actions";
import {
  getDetailAndSchemaProps,
  getPaginatedProps,
} from "../libs/helpers/callApi";

const reducer = {
  /*
   * ADMIN _ENTITY
   */
  [ADMIN_DETAIL_LOADING]: (state) => ({
    ...state,
    detail: { data: {}, loading: true },
  }),
  [ADMIN_DETAIL_LOADED]: (state, { data }) => ({
    ...state,
    detail: {
      loading: false,
      ...getDetailAndSchemaProps(data),
    },
  }),
  [ADMIN_DETAIL_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    detail: { loading: false },
    error: data,
  }),
  /*
   * TENANT_LIST
   */
  [TENANT_LIST_LOADING]: (state) => ({
    ...state,
    tenant: { ...state.tenant, loading: true },
  }),
  [TENANT_LIST_LOADED]: (state, { data }) => ({
    ...state,
    tenant: { loading: false, ...getDetailAndSchemaProps(data) },
  }),
  [TENANT_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    tenant: { ...state.tenant, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  tenant: { data: [] },
  detail: { data: {} },
});
