import React, { useState } from "react";
import { Card, Row, List, Button, Collapse } from "antd";
import "./Card.scss";
import { orderBy, slice } from "lodash";
import Logo from "../../assets/images/logo.png";
import Localize from "../../libs/localization";
import Pitch from "../Pitch/Pitch";

const CardPitch = (props) => {
  const { data, elementToShow = 3, onMoreBrandClick } = props;
  const [max, setMax] = useState(elementToShow);

  return (
    <Card
      title={
        <h2>
          <b>{Localize("PAGES.COMPANIES.DETAIL.SECTIONS.PITCH")}</b>
        </h2>
      }
      bordered={false}
      className="companyPage__body-card companyPage__body-card--custom customCard"
    >
      <List
        itemLayout="horizontal"
        dataSource={slice(orderBy(data, "start_date", "desc"), 0, max)}
        renderItem={(item) => (
          <List.Item
            style={{ padding: 0 }}
            className="customCard__pitchList animated"
          >
            <List.Item.Meta
              size="small"
              avatar={<img style={{ width: "15px" }} src={Logo} />}
              // title={}
              description={
                <Pitch item={item} onMoreBrandClick={onMoreBrandClick} />
              }
            />
          </List.Item>
        )}
      />
      {max <= data.length && data.length > elementToShow && (
        <Row justify="center">
          <Button onClick={() => setMax(max + elementToShow)}>
            {Localize("COMMON.SHOW_MORE")}
          </Button>
        </Row>
      )}
    </Card>
  );
};

export default CardPitch;
