import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, Statistic, Switch, Button, Checkbox } from "antd";
import _ from "lodash";
import { ResponsivePie } from "@nivo/pie";
import Tabs from "../../components/Tabs/Tabs";

import "./investments.scss";
import { ResponsiveLine } from "@nivo/line";
import LineChartOutlined from "@ant-design/icons/es/icons/LineChartOutlined";
import TableOutlined from "@ant-design/icons/es/icons/TableOutlined";

const Investments = ({ data }) => {
  const [dataSet, setDataSet] = useState(null);
  const [showData, setShowData] = useState([]);
  const [mode, setMode] = useState("stat");

  useEffect(() => {
    if (_.isNil(dataSet) && data) {
      setDataSet(getData(data));
    }
  });

  const getData = (data) => {
    const lineObj = [];
    _.forEach(data, (values, year) => {
      _.forEach(values, (val, key) => {
        if (
          key !== "ID NIELSEN" &&
          key !== "NIELSEN" &&
          key !== "GRAND TOTAL"
        ) {
          const find = _.find(lineObj, (o) => o.id == key);
          if (!find) {
            lineObj.push({
              id: key,
              data: [
                {
                  x: year,
                  y: typeof val === "number" ? val.toFixed(2) : val,
                  k: key,
                },
              ],
            });
          } else {
            find.data.push({
              x: year,
              y: typeof val === "number" ? val.toFixed(2) : val,
              k: key,
            });
          }
        }
      });
    });
    return lineObj;
  };

  const getStat = (dataInv) => {
    const stat = [];
    const pieValue = [];
    _.forEach(dataInv, (value, key) => {
      if (key !== "ID NIELSEN" && key !== "NIELSEN") {
        stat.push(
          <Col
            span={key !== "GRAND TOTAL" ? 12 : 24}
            style={{ margin: "5px 0" }}
            className={`animation fadein-left faster 
               ${key == "GRAND TOTAL" && "investments-total"}`}
          >
            <Statistic
              title={`${_.capitalize(key)} (K) €`}
              value={value}
              precision={0}
            />
          </Col>
        );

        if (key !== "GRAND TOTAL") {
          pieValue.push({
            id: key,
            label: key,
            value: value,
          });
        }
      }
    });

    return { stat, pieValue };
  };

  const inputEl = useRef();

  const toggleMode = () => {
    const val = mode === "graph" ? "stat" : "graph";
    // setShowData([]);
    defaultSelection();
    setMode(val);
  };

  const defaultSelection = () => {
    if (dataSet) {
      const mock = Object.keys(data).map((year) => {
        return { x: year, y: 0, k: dataSet[0].id };
      });

      // merge props data with 'mock' for correct graph render
      const n_data = _.unionWith(dataSet[0].data, mock, (e, f) => e.x === f.x);

      //sorting for year
      dataSet[0].data = _.sortBy(n_data, [(o) => o.x]);
      setShowData([dataSet[0]]);
    }
  };

  useEffect(() => {
    {
      console.log(">>>>>>>>>>>>>>>>>>>>>>> show data", showData);
    }
  }, [showData]);

  return (
    <>
      <Row justify="end">
        <Button
          type="primary"
          onClick={() => toggleMode()}
          className="position-absolute"
          style={{ zIndex: 3 }}
        >
          {mode === "stat" ? <LineChartOutlined /> : <TableOutlined />}
        </Button>
      </Row>
      {mode === "graph" ? (
        <div>
          {dataSet && (
            <Row>
              <Col span={4}>
                {dataSet.map((yearData, index) => {
                  return (
                    <p style={{ marginBottom: "4px" }}>
                      <Checkbox
                        name={yearData.id}
                        key={index}
                        defaultChecked={index === 0}
                        onChange={({ target }) => {
                          const value = target.checked;

                          const mock = Object.keys(data).map((year) => {
                            return { x: year, y: 0, k: target.name };
                          });

                          // merge props data with 'mock' for correct graph render
                          const n_data = _.unionWith(
                            yearData.data,
                            mock,
                            (e, f) => e.x === f.x
                          );

                          //sorting for year
                          yearData.data = _.sortBy(n_data, [(o) => o.x]);

                          if (value) {
                            setShowData([yearData, ...showData]);
                          } else {
                            const newD = showData;
                            _.remove(
                              newD,
                              (current) => current.id == yearData.id
                            );
                            setShowData([...newD]);
                          }
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        {yearData.id}
                      </Checkbox>
                    </p>
                  );
                })}
              </Col>
              <Col span={20} style={{ height: "600px" }}>
                <ResponsiveLine
                  data={showData}
                  margin={{ top: 50, right: 160, bottom: 50, left: 160 }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: "bottom",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "year",
                    legendOffset: 36,
                    legendPosition: "middle",
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 0,
                    tickPadding: 50,
                    tickRotation: 0,
                    legend: "k €",
                    legendOffset: -110,
                    legendPosition: "middle",
                  }}
                  colors={{ scheme: "spectral" }}
                  pointSize={10}
                  pointColor={{ theme: "background" }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: "serieColor" }}
                  pointLabel={(e, f, g) => {
                    return `${e.k} ${e.y}`;
                  }}
                  enablePointLabel={true}
                  pointLabelYOffset={-12}
                />
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <>
          {data && (
            <Tabs
              containerClass="investments__tab"
              options={{ tabPosition: "top", animated: true }}
            >
              {Object.entries(data)
                .sort((a, b) => b[0].localeCompare(a[0]))
                .map((value) => {
                  const [title, _data] = value;
                  const { stat, pieValue } = getStat(_data);
                  return (
                    <Row title={title}>
                      <Col span={6}>
                        <Row gutter={16}>{stat}</Row>
                      </Col>
                      <Col span={18} style={{ height: "550px" }}>
                        <ResponsivePie
                          data={pieValue}
                          margin={{ top: 0, right: 120, bottom: 0, left: 80 }}
                          innerRadius={0.5}
                          padAngle={0.9}
                          cornerRadius={3}
                          colors={{ scheme: "spectral" }}
                          borderWidth={1}
                          borderColor={{
                            from: "color",
                            modifiers: [["darker", 0.2]],
                          }}
                          enableRadialLabels={false}
                          radialLabelsSkipAngle={10}
                          radialLabelsTextXOffset={6}
                          radialLabelsTextColor="#333333"
                          radialLabelsLinkOffset={0}
                          radialLabelsLinkDiagonalLength={16}
                          radialLabelsLinkHorizontalLength={24}
                          radialLabelsLinkStrokeWidth={1}
                          radialLabelsLinkColor={{ from: "color" }}
                          slicesLabelsSkipAngle={10}
                          slicesLabelsTextColor="#333333"
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                          defs={[
                            {
                              id: "dots",
                              type: "patternDots",
                              background: "inherit",
                              color: "rgba(255, 255, 255, 0.3)",
                              size: 4,
                              padding: 1,
                              stagger: true,
                            },
                            {
                              id: "lines",
                              type: "patternLines",
                              background: "inherit",
                              color: "rgba(255, 255, 255, 0.3)",
                              rotation: -45,
                              lineWidth: 6,
                              spacing: 10,
                            },
                          ]}
                          fill={[
                            {
                              match: {
                                id: "tv",
                              },
                              id: "lines",
                            },
                            {
                              match: {
                                id: "radio",
                              },
                              id: "lines",
                            },
                            {
                              match: {
                                id: "magazines",
                              },
                              id: "lines",
                            },
                            {
                              match: {
                                id: "newspapers",
                              },
                              id: "lines",
                            },
                            {
                              match: {
                                id: "freepress",
                              },
                              id: "lines",
                            },
                            {
                              match: {
                                id: "outdoor",
                              },
                              id: "lines",
                            },
                          ]}
                          legends={[
                            {
                              anchor: "bottom-right",
                              direction: "column",
                              itemWidth: 10,
                              itemHeight: 20,
                              itemTextColor: "#999",
                              symbolSize: 18,
                              symbolShape: "circle",
                              effects: [
                                {
                                  on: "hover",
                                  style: {
                                    itemTextColor: "#000",
                                  },
                                },
                              ],
                            },
                          ]}
                        />
                      </Col>
                    </Row>
                  );
                })}
            </Tabs>
          )}
        </>
      )}
    </>
  );
};

export default Investments;
