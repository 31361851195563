import axios from "axios";
import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";
import { getFromLocalStorage } from "../libs/helpers/localstorage";

export const getTreeDataApi = async ({ headers, entity }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.tree.list}?entity=${entity}`,
    {
      method: "GET",
      headers,
    },
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType,
  );
};

export const getTreeDataV2Api = async ({ headers, entity }) => {
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const res = await axios.get(`${Config.apiBaseurl}/${Config.apis.tree.list}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-tenant": tenant,
      ...headers,
    },
    params: {
      entity,
    },
  });
  return res.data?.data;
};

export const getFlatEntityListApi = async ({ headers, entity }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.flat.list}?entity=${entity}`,
    {
      method: "GET",
      headers,
    },
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType,
  );
};

export const getFlatV2EntityListApi = async ({ headers, entity }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.flatV2.api}?entity=${entity}`,
    {
      method: "GET",
      headers,
    },
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType,
  );
};
