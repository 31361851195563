import { reducerFromMap } from "../libs/helpers/reducers";
import {
  RESET_NEWS_SEARCH,
  SEARCH_NEWS_LOAD_ERROR,
  SEARCH_NEWS_LOADED,
  SEARCH_NEWS_LOADING,
  CREA_LIST_LOADING,
  CREA_LIST_LOADED,
  CREA_LIST_LOAD_ERROR,
  PITCH_LIST_LOADING,
  PITCH_LIST_LOADED,
  PITCH_LIST_LOAD_ERROR,
  CONTACTS_LIST_LOADING,
  CONTACTS_LIST_LOADED,
  CONTACTS_LIST_LOAD_ERROR,
} from "../actions/home.actions";

const reducer = {
  /*
   * SEARCH_NEWS
   */
  [SEARCH_NEWS_LOADING]: (state) => ({
    ...state,
    news: { loading: true },
  }),
  [SEARCH_NEWS_LOADED]: (state, { data }) => ({
    ...state,
    news: {
      data: data.data,
      loading: false,
    },
  }),
  [SEARCH_NEWS_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    news: { ...state.news, loading: false },
    error: data,
  }),
  [RESET_NEWS_SEARCH]: (state) => ({
    ...state,
    news: {},
  }),
  /*
   * CREA_LIST
   */
  [CREA_LIST_LOADING]: (state) => ({
    ...state,
    crea: { ...state.crea, loading: true },
  }),
  [CREA_LIST_LOADED]: (state, { data }) => ({
    ...state,
    crea: {
      data: data.data,
      loading: false,
    },
  }),
  [CREA_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    crea: { ...state.crea, loading: false },
    error: data,
  }),
  /*
   * PITCH_LIST
   */
  [PITCH_LIST_LOADING]: (state) => ({
    ...state,
    pitch: { ...state.pitch, loading: true },
  }),
  [PITCH_LIST_LOADED]: (state, { data }) => ({
    ...state,
    pitch: { data: data.data, loading: false },
  }),
  [PITCH_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    pitch: { ...state.pitch, loading: false },
    error: data,
  }),
  /*
   * CONTACTS_LIST
   */
  [CONTACTS_LIST_LOADING]: (state) => ({
    ...state,
    contacts: { ...state.contacts, loading: true },
  }),
  [CONTACTS_LIST_LOADED]: (state, { data }) => ({
    ...state,
    contacts: { data: data.data, loading: false },
  }),
  [CONTACTS_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    contacts: { ...state.contacts, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  news: {},
  crea: {},
  pitch: {},
  contacts: {},
});
