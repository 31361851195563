import _ from "lodash";
import en from "./en.json";
import es from "./es.json";
import it from "./it.json";
import fr from "./fr.json";
// import { getLocalesFromServer } from "../api/app.api.js";

// getLocalesFromServer();

const locales = {
  en,
  it,
  fr,
  es,
  loaded: false,
};

export async function loadDictionaries() {
  const { dictionaries } = window.config;
  await Promise.all(
    Object.keys(dictionaries).map(async (key) => {
      const uri = dictionaries[key];
      const obj = await (await fetch(uri)).json();
      _.merge(locales[key], obj);
    })
  );
  locales.loaded = true;
}

if (!locales.loaded) {
  loadDictionaries();
}

export function getLocales() {
  return locales;
}

export default locales;
