import _ from "lodash";
import moment from "moment";
import { push } from "redux-first-history";
import log from "./logger";
import { get } from "./ioc";
import appInfo from "./appInfo";
import { getFromLocalStorage, removeFromLocalStorage } from "./localstorage";
import { setErrorAction } from "../../actions/app.actions";
import _Localize, { getCurrentLocale } from "../localization";
import { userLogout } from "../../actions";

export const API_ERROR = "API_ERROR";
export const API_LOADING = "API_LOADING";

const defaultErrorProcessor = (error, dispatch, errorAction) => {
  /* Default error processor, clears the user if the API response status is 401 */
  log.error(error.stack, error);

  console.log({ error });

  const { name, status, httpStatus, message, id, date, details, code } = error;

  const { label } = details || {};

  const finalMessage = label || message;
  if (status === 403) {
    dispatch(push("/forbidden"));
    return;
  }

  if (status === 401) {
    removeFromLocalStorage(null, "general");
    dispatch(userLogout());
  } else if (status === 400 && id === "USER_ALREADY_LOGGED") {
    removeFromLocalStorage(null, "general");
    window.location.reload();
  } else {
    dispatch(
      setErrorAction({
        name,
        status: status || httpStatus,
        message: _Localize(`ERROR.${status || httpStatus}`)
          ? _Localize(`ERROR.${status || httpStatus}`)
          : finalMessage,
        id,
        date,
        code,
      }),
    );
  }
};

export const headersBuilder = () => {
  const tenant = getFromLocalStorage("lefacWebTenant");
  return {
    // "Accept-Language": getCurrentLocaleExtended(),
    "Accept-Language": getCurrentLocale(),
    "App-Launch-Count": _.get(getFromLocalStorage(), "app-launch-count", 1), //How many times the app was launched
    "App-Unique-Run-Id": _.get(getFromLocalStorage(), "app-run-id", null), //Unique Id to this concrete execution
    "App-Id": appInfo.name, //Packagename/Bundle Identifier
    "App-Build-Type": appInfo.env, //Debug/Release
    "App-Version-Code": appInfo.version, //Version Code
    "Content-Type": "application/json",
    "Device-OS": navigator.platform, //Operating System ios/android
    "Device-OS-Version": navigator.appVersion || navigator.userAgent, //Operating System Version
    "Device-Screen-Width": window.screen.availWidth, //Screen width in pixels
    "Device-Screen-Height": window.screen.availHeight, //Screen height in pixels
    "x-tenant": tenant ? tenant.data : "italy",
    // "Device-Push-Notifications-Enabled":
    //   _.get(Notification, "permission") === "granted" ? true : false, //true/false
    "Device-Screen-DPR": `${window.devicePixelRatio}x`, //DPR zoom !== DPI
    TimeOffset: moment().format("ZZ"), //Offset from UTC in Format +-HHMM (See ISO8601 Specs)
  };
};
/**
 * Handles API calls and manages actions and errors
 *
 * @param {Function} apiCall
 * @param {Boolean} secured Injects access_token (default: false)
 * @param {Object} permissions Checks for user permissions (schema: { section: ['create', 'read', 'update', 'delete']})
 * @param {String} startAction The first action to be called (default: API_LOADING)
 * @param {String} successAction The success action to be called
 * @param {String} errorAction The error action to be called (default: API_ERROR)
 * @param {Function} processResult The optional function to be called for result processing {result, dispatch, getState}
 * @param {Function} processError The optional function to be called for error processing {error, dispatch, errorAction, getState} (default: defaultErrorProcessor)
 * @param {Function} headers The optional function to be called to build headers {user} (default: headersBuilder)
 * @param {String} queryAuth The optional choice to put token as a queryParam (default: false)
 */
export default ({
  apiCall,
  secured = false,
  permissions = null,
  startAction = API_LOADING,
  successAction,
  extraActionParams = () => ({}),
  errorAction = API_ERROR,
  processResult = null,
  processError = defaultErrorProcessor,
  headers = headersBuilder,
  queryAuth = false,
  afterSuccessAction = null,
}) => {
  return (params = {}) =>
    async (dispatch, getState) => {
      const { token } = getState().auth;
      params.headers = headers();
      if (secured && token) {
        /* If secured, injects access_token in the apiCall function */
        if (queryAuth) {
          params[queryAuth] = token;
        } else {
          params.headers.Authorization = `Bearer ${token}`;
        }
      }

      const found = {};

      if (permissions) {
        const userPerm = get("scopes") || {};
        Object.keys(permissions).forEach((sect) => {
          found[sect] = permissions[sect].filter(
            (p) => _.get(userPerm, `${sect}`, []).indexOf(p) > -1,
          );
        });
      }

      if (!permissions || _.isEqual(found, permissions)) {
        dispatch({ type: startAction, params, ...extraActionParams(params) });

        try {
          let result = await apiCall(params);
          if (processResult) {
            result = processResult(result, dispatch, getState, params);
          }

          if (successAction) {
            dispatch({
              type: successAction,
              data: result,
              ...extraActionParams(params),
            });
          }
          if (afterSuccessAction) {
            await afterSuccessAction({
              dispatch,
              result,
              params,
              getState,
            });
          }
        } catch (error) {
          if (error.name === "AbortError") {
            return;
          }
          if (processError)
            processError(error, dispatch, errorAction, getState, params);
          dispatch({
            type: errorAction,
            error,
            ...extraActionParams(params),
          });
        }
      }
    };
};

/**
 *  Sets or resets pagination props
 *
 * @param {Number} currentPage
 * @param {Number} totalPages
 * @param {Number} totalResults
 * @param {Array} data
 * @param {Array} schema
 */
export const getPaginatedProps = ({
  currentPage = 0,
  totalPages = 0,
  totalResults = 0,
  data,
  schema,
}) => ({ currentPage, totalPages, totalResults, data, schema });

/**
 *  Sets or resets pagination props
 *
 * @param {Object} data
 * @param {Array} schema
 */
export const getDetailAndSchemaProps = ({ data, schema }) => ({ data, schema });

/**
 *  Sets or resets pagination props
 *
 * @param {Object} data
 */
export const getDetailProps = ({ data }) => ({ data });
/**
 *  Sets or resets pagination props
 *
 * @param {Array} schema
 */
export const getSchemaProps = ({ schema }) => ({ schema });
