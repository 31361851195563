import React from "react";
import { orderBy, capitalize, isEmpty } from "lodash";
import { Input, Space, Button } from "antd";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { turnKeyIntoLabel } from "../../libs/utils";
import Localize from "../../components/Localized/Localize.comp";

import { ReactComponent as EmptyIcon } from "./empty.svg";
import EmptyImg from "./empty.png";

export const generateColumns = ({ entity, data = [], parent }) =>
  entity && Array.isArray(entity)
    ? orderBy(entity, "order", "asc").map((k) => ({
        title: <b>{turnKeyIntoLabel(k.label || k.key)}</b>,
        dataIndex: k.key,
        key: k.key,
        ...(k.enum
          ? generateFilterFromEnum(k.enum)
          : generateFilterFromField(k.key, parent, k.type)),
        sorter: true,
        width: "fit-content",
        // width: "100%",
      }))
    : data.length > 0 &&
      Object.keys(data[0]).map((k) => ({
        title: turnKeyIntoLabel(k),
        dataIndex: k,
        key: k,
      }));

const generateFilterFromEnum = (enumSet) =>
  enumSet && {
    filterMultiple: true,
    filters: enumSet.map((e) => ({ text: e, value: e })),
  };

const generateFilterFromField = (dataIndex, parent, type) =>
  type === "String" && {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            parent.searchInput = node;
          }}
          placeholder={Localize("COMMON.SEARCH")}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys[0], confirm, dataIndex, parent)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space style={{ borderTop: "1px solid #f0f0f0", paddingTop: 7 }}>
          <Button
            type="link"
            onClick={() => handleReset(clearFilters, parent)}
            disabled={isEmpty(selectedKeys)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {isEmpty(selectedKeys) ? (
            <Button
              onClick={() =>
                // handleSearch(selectedKeys[0], confirm, dataIndex, parent)
                handleReset(clearFilters, parent)
              }
              size="small"
              style={{ width: 90 }}
            >
              {Localize("COMMON.CANCEL")}
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys[0], confirm, dataIndex, parent)
              }
              size="small"
              style={{ width: 90 }}
            >
              OK
            </Button>
          )}
        </Space>
      </div>
    ),
    filterMultiple: true,
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => parent.searchInput.select(), 200);
      }
    },
  };

const handleSearch = (selectedKeys, confirm, dataIndex, parent) => {
  confirm();
  parent.setState({ filtering: true });
};

const handleReset = (clearFilters, parent) => {
  clearFilters();
  parent.setState({ filtering: false });
};

export const EmptySet = () => (
  <>
    {/* <EmptyIcon className="empty-img" /> */}
    <img src={EmptyImg} className="empty-img" />
    <p style={{ opacity: 0.6 }}>{Localize("COMMON.NO_DATA")}</p>
  </>
);
