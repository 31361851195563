import React from "react";
import { FormControl } from "@mui/material";
import InputBase from "./InputBase.component";
import InputLabel from "./InputLabel.component";

export default function TextField({
  id,
  sx,
  label,
  hideLabel = false,
  ...props
}) {
  return (
    <FormControl variant="standard" fullWidth sx={sx}>
      {!hideLabel && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <InputBase
        id={id}
        {...props}
        sx={{
          "& .MuiInputBase-input": {
            marginTop: hideLabel ? 0 : 4,
          },
        }}
      />
    </FormControl>
  );
}
