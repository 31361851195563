import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, List, Row, Tag, Collapse, Spin } from "antd";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { searchWorkRelationAction } from "../../actions/search.action";
import Localize from "../../libs/localization";
import { get } from "../../libs/helpers/ioc";
import { toUpper } from "lodash";
import { translateRegistryOnView } from "../../libs/utils";

const { Panel } = Collapse;

const WorkRelationList = (props) => {
  const { data, pageSize = 5, total, loadingList, filters = "" } = props;
  const permissions = get("scopes");

  const dispatch = useDispatch();
  const location = useLocation();

  const [pSize, setPSize] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = (page, pageSize) => {
    if (!loadingList) {
      if (pSize !== pageSize) {
        setPSize(pageSize);
      }
      const query = queryString.parse(location.search);
      let params = "";

      if (filters) {
        params = {
          ...filters,
          pageNum: page,
          limit: pageSize,
        };
      }

      if (query.key && !params.name) {
        params = {
          name: query.key,
          pageNum: page,
          limit: pageSize,
        };
      }
      dispatch(
        searchWorkRelationAction({
          data: params,
        })
      );
    }
  };

  useEffect(() => {
    fetchData(1, pageSize);
  }, [location.key]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const renderListItem = (item) => {
    if (item) {
      return (
        <List.Item.Meta
          description={
            <>
              <Col className="align-content-center mx-2">
                <Row align="top">
                  <h3 className="searchPage__item-title py-2">
                    <b>{toUpper(item.name)}</b>
                  </h3>
                </Row>
              </Col>
              <>
                {item.brands.map((brand, index) =>
                  brand.agencies.length > 0 ? (
                    <Collapse key={brand._id} className="mb-3 mr-3 ml-2">
                      <Panel
                        key={index}
                        header={
                          <Col className="m-0 p-0 align-content-center">
                            <Row align="top">
                              <h4 className="m-0 p-0">{toUpper(brand.name)}</h4>
                              {brand.sector.map((s) => (
                                <Tag key={s.key} className="ml-3">
                                  {translateRegistryOnView(s.key, "Sector")
                                    ? translateRegistryOnView(s.key, "Sector")
                                    : s.key}
                                </Tag>
                              ))}
                            </Row>
                          </Col>
                        }
                      >
                        <>
                          <Col className="m-0 p-0" key={item._id}>
                            {brand.agencies.map((agency) =>
                              agency.categories.map((c) => {
                                return (
                                  <div className="m-0 p-0">
                                    <p className="m-0">
                                      <b>
                                        {translateRegistryOnView(
                                          c.categoryId,
                                          "Category"
                                        )
                                          ? translateRegistryOnView(
                                              c.categoryId,
                                              "Category"
                                            )
                                          : c.categoryName}
                                      </b>
                                      <span className="mx-1">:</span>
                                      <Link
                                        style={{
                                          pointerEvents: Object.keys(
                                            permissions
                                          )?.includes("company")
                                            ? ""
                                            : "none",
                                        }}
                                        to={`/companies/${agency.id}`}
                                      >
                                        {toUpper(agency.name)}
                                      </Link>
                                    </p>
                                  </div>
                                );
                              })
                            )}
                          </Col>
                        </>
                      </Panel>
                    </Collapse>
                  ) : (
                    <Collapse key={brand._id} className="mr-3 mb-3 ml-2">
                      <Panel
                        key={1}
                        header={
                          <Row align="top">
                            <h4 className="m-0 p-0">{toUpper(brand.name)}</h4>
                            {brand.sector.map((s) => (
                              <Tag key={s.key} className="ml-3">
                                {translateRegistryOnView(s.key, "Sector")
                                  ? translateRegistryOnView(s.key, "Sector")
                                  : s.key}
                              </Tag>
                            ))}
                          </Row>
                        }
                      >
                        <p className="p-0 m-0">
                          {Localize("PAGES.SEARCH.NO_DATA")}
                          <span>.</span>
                        </p>
                      </Panel>
                    </Collapse>
                  )
                )}
              </>
            </>
          }
        />
      );
    }
    return (
      <List.Item.Meta
        title={
          <Col className="searchPage__item-clc align-content-center">
            <Row align="top">
              <h3 className="searchPage__item-title mr-3">
                <b>{item.name}</b>
              </h3>
              {item.sector.map((s) => (
                <Tag>
                  {translateRegistryOnView(s.key, "Sector")
                    ? translateRegistryOnView(s.key, "Sector")
                    : s.key}
                </Tag>
              ))}
            </Row>
            <p className="m-0">{item.company.name}</p>
          </Col>
        }
      />
    );
  };

  return (
    <>
      {loadingList ? (
        <div className="text-center">
          <Spin className="mt-5" />
        </div>
      ) : (
        <List
          className="searchPage__list animation"
          itemLayout="horizontal"
          dataSource={data}
          pagination={{
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              fetchData(page, pageSize);
            },
            current: currentPage,
            pageSize: pSize,
            total,
          }}
          renderItem={(item) => (
            <div className="searchPage__item-more animation fadein-right slow">
              <List.Item className="searchPage__item pl-3">
                {renderListItem(item)}
              </List.Item>
            </div>
          )}
        />
      )}
    </>
  );
};
export default WorkRelationList;
