import React, { useEffect, useState } from "react";
import { Card, Typography, useTheme, Box } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import BannerTop from "../../components/BannerTop/BannerTop";
import { l } from "../../libs/localization";
import "./profile.style.scss";
import Button from "../../design-library/Button/Button.component";
import {
  getActivePlansApi,
  getConsumptionApi,
  getCustomerPortalUrlApi,
} from "../../api/ecommerce.api";
import { getPlanConfigurations } from "../../libs/ecommerce";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";

export const pricingPageUrls = {
  italy: "https://blinkogroup.com/it/piani-e-prezzi/",
  uk: "https://blinkogroup.com/en/plans-pricing/",
  france: "https://blinkogroup.com/fr/forfaits-et-tarifs/",
};
function ProfileSubscriptionInfoPage({}) {
  const theme = useTheme();
  const [activePlans, setActivePlans] = useState(null);
  const [consumption, setConsumption] = useState(null);
  const profile = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);

  const tenant = getFromLocalStorage("lefacWebTenant")?.data;

  const pricingUrl = pricingPageUrls[tenant];

  const plan = Object.values(getPlanConfigurations(tenant)).find(
    (c) => c.priceId === activePlans?.plans[0]?.priceId
  ) || {
    id: "trial",
    planName: "Trial",
    price: 0,
    companyCredits: 5,
    contactCredits: 5,
  };

  async function openCustomerPortal() {
    const res = await getCustomerPortalUrlApi({
      returnTo: "/profile/subscription-info",
    });
    window.location.href = res.data?.url;
  }

  async function loadInfo() {
    setLoading(true);
    let res = [getActivePlansApi(), getConsumptionApi()];
    res = await Promise.all(res);
    setActivePlans(res[0].data);
    setConsumption(res[1].data?.consumption);
    setLoading(false);
  }

  useEffect(() => {
    loadInfo();
  }, []);

  return (
    <div className="profilePage">
      <BannerTop small variant="profile">
        <h1 className="mt-4 title">{l("ECOMMERCE.SUBSCRIPTION_PLAN")}</h1>
      </BannerTop>
      <Card
        sx={{
          p: 8,
          mt: -6,
          position: "relative",
          boxShadow: "0px 4px 10px 0px #00000026",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
        }}
      >
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 4 }}>
          <Box>
            <Typography
              sx={{
                display: "inline",
                fontWeight: "600",
                fontSize: 20,
              }}
            >
              {l("ECOMMERCE.SUBSCRIPTION")}{" "}
            </Typography>
            <Typography
              sx={{
                color: theme.palette.secondary.main,
                display: "inline",
                fontWeight: "400",
                fontSize: 20,
                textTransform: "uppercase",
              }}
            >
              {plan.planName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                {l("ECOMMERCE.REMAINING_COMPANY_CREDITS")}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                <Typography
                  sx={{
                    display: "inline",
                    fontSize: 24,
                    color: theme.palette.primary.main,
                  }}
                >
                  {typeof consumption?.companies?.count === "number"
                    ? (plan.companyCredits +
                        profile?.ecommerce?.extraCompanyCredits || 0) -
                      consumption?.companies?.count
                    : "..."}
                </Typography>
                /
                {plan.companyCredits +
                  profile?.ecommerce?.extraCompanyCredits || 0}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                {l("ECOMMERCE.REMAINING_CONTACT_CREDITS")}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                <Typography
                  sx={{
                    display: "inline",
                    fontSize: 24,
                    color: theme.palette.primary.main,
                  }}
                >
                  {typeof consumption?.contacts?.count === "number"
                    ? (plan.contactCredits +
                        profile?.ecommerce?.extraContactCredits || 0) -
                      consumption?.contacts?.count
                    : "..."}
                </Typography>
                /
                {plan.contactCredits +
                  profile?.ecommerce?.extraContactCredits || 0}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                {l("ECOMMERCE.PLAN_EXPIRY_DATE")}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  display: "inline",
                  fontSize: 24,
                  color: theme.palette.primary.main,
                }}
              >
                {activePlans?.plans?.[0]?.periodEndDate &&
                  moment(activePlans?.plans?.[0]?.periodEndDate * 1000).format(
                    "DD-MM-YYYY"
                  )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ flex: { xs: 0, md: 0.15, lg: 0.5 } }} />
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "stretch",
            gap: 1,
          }}
        >
          <a
            href={pricingUrl}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Button>{l("ECOMMERCE.CHANGE_PLAN")}</Button>
          </a>
          {plan?.price > 0 ? (
            <Button onClick={openCustomerPortal}>
              {l("ECOMMERCE.MANAGE_SUBSCRIPTION")}
            </Button>
          ) : null}
        </Box>
      </Card>
    </div>
  );
}

export default ProfileSubscriptionInfoPage;
