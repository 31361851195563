import React from "react";

const LocalizedFlag = ({ locale, square = false, style = { height: 15 } }) => {
  let flag = null;
  try {
    if (locale) {
      flag = require(`./flags/${square ? "1x1" : "4x3"}/${locale}.svg`);
    }
  } catch (e) {
    console.error(`LocalizedFlag: Flag ${locale} not found`);
  }
  return <img className="localized-flag" src={flag} style={style} alt="" />;
};

export default LocalizedFlag;
