import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfoAction } from "../../actions";
import { checkoutApi } from "../../api/ecommerce.api";
import { editProfileApi } from "../../api/user.api";
import BlinkoLogo from "../../design-library/BlinkoLogo";
import Button from "../../design-library/Button/Button.component";
import InputLabel from "../../design-library/TextField/InputLabel.component";
import { getPlanConfigurations } from "../../libs/ecommerce";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import { l } from "../../libs/localization";
import history from "../../store/history";
import { pricingPageUrls } from "../profile/subscriptionInfo.page";

function Row({ ...props }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "col", sm: "row" },
        alignSelf: "stretch",
      }}
      {...props}
    />
  );
}

function Cell({ ...props }) {
  return <Typography sx={{ flex: 1, textAlign: "start" }} {...props} />;
}

function Price({ plan }) {
  if (plan.price === 0) {
    return l("ECOMMERCE.FREE");
  }
  let periodLabel = "mese";
  if (plan.period === "year") periodLabel = "anno";
  return (
    <>
      {plan.price} €/{periodLabel} ( + IVA )
    </>
  );
}

function CompanyBillingInfo({ user }) {
  return (
    <>
      <Row>
        <Cell>{l("BILLING.ITALY.CODICE_FISCALE_P_IVA")} </Cell>
        <Cell>
          {user?.billingInfo?.countrySpecificInfo?.italy?.codiceFiscale}
        </Cell>
      </Row>
      <Row>
        <Cell>{l("BILLING.COMPANY_ADMINISTRATION_EMAIL")} </Cell>
        <Cell>{user?.billingInfo?.administrationEmail}</Cell>
      </Row>
      <Row>
        <Cell>{l("BILLING.FUNCTION")} </Cell>
        <Cell>{user?.billingInfo?.function}</Cell>
      </Row>
      <Row>
        <Cell>{l("BILLING.REGISTERED_OFFICE_AT")} </Cell>
        <Cell>
          {user?.billingInfo?.address?.street},{" "}
          {user?.billingInfo?.address?.city},{" "}
          {user?.billingInfo?.address?.province},{" "}
          {user?.billingInfo?.address?.region},{" "}
          {user?.billingInfo?.address?.country}
        </Cell>
      </Row>
    </>
  );
}

function IndividualBillingInfo({ user }) {
  return (
    <>
      {/* <Row>
        <Cell>
          {l("BILLING.ITALY.CODICE_FISCALE")}{" "}
          {user?.billingInfo?.countrySpecificInfo?.italy?.codiceFiscale}
        </Cell>
      </Row>
      <Row>
        <Cell>
          {l("BILLING.ITALY.PEC")}{" "}
          {user?.billingInfo?.countrySpecificInfo?.italy?.pec}
        </Cell>
      </Row> */}
      <Row>
        <Cell>
          {l("BILLING.RESIDING_AT")} {user?.billingInfo?.address?.street},{" "}
          {user?.billingInfo?.address?.city},{" "}
          {user?.billingInfo?.address?.province},{" "}
          {user?.billingInfo?.address?.region},{" "}
          {user?.billingInfo?.address?.country}
        </Cell>
      </Row>
    </>
  );
}

export default function ConfirmPlanPage() {
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const planObj = getPlanConfigurations(tenant)[
    getFromLocalStorage("blinko_requested_plan")?.data
  ];

  const pricingUrl = pricingPageUrls[tenant];

  const plan =
    planObj && !planObj.archived
      ? planObj
      : {
          id: "trial",
          planName: "Trial",
          price: 0,
          companyCredits: 5,
          contactCredits: 5,
        };

  useEffect(() => {
    if (
      plan.id === "trial" &&
      user?.tags?.includes("regFlow_confirmed_trial")
    ) {
      history.push("/");
    }
  }, [user]);

  async function submit(e) {
    e.preventDefault();
    if (plan.id === "trial") {
      setLoading(true);
      try {
        await editProfileApi({
          tags: [...user.tags, "regFlow_confirmed_trial"],
        });
        dispatch(getUserInfoAction());
      } catch (err) {
        console.error(err);
        if (err.response?.data?.clientType) {
          setError(err.response.data.clientType);
        } else {
          setError(err.message);
        }
      }
      setLoading(false);
    } else if (plan.price > 0) {
      setLoading(true);
      try {
        await editProfileApi({
          tags: [...user.tags, "regFlow_confirmed_trial"],
        });
        const res = await checkoutApi({ priceId: plan.priceId });
        setLoading(false);
        if (res.data?.done) {
          if (res.data?.success) {
            history.push("/paymentSuccessful");
          } else {
            history.push("/paymentFailed");
          }
        } else if (res.data.url) {
          window.location.href = res.data.url;
        } else {
          history.push("/paymentFailed");
        }
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
        py: { xs: 0, sm: 8 },
        width: "100%",
        maxWidth: "none !important",
        backgroundColor: theme.palette.primary.light,
        minHeight: "100vh",
      }}
    >
      <Card
        sx={{
          width: {
            xs: "100%",
            sm: "550px",
            md: "680px",
          },
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: 3,
            px: { xs: 2, sm: 5.5 },
            py: 7.5,
            position: "relative",
          }}
        >
          <BlinkoLogo style={{ maxWidth: 180, alignSelf: "center" }} />
          <Typography sx={{ mt: 2.5, textAlign: "center" }} variant="h3">
            {l("REGISTER.COMPLETE_REGISTRATION")}
          </Typography>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              width: `calc(100% + ${theme.spacing(5.5 * 2)})`,
              mx: -5.5,
              mt: 1,
              p: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                textTransform: "uppercase",
                fontWeight: 700,
                color: theme.palette.primary.contrastText,
              }}
            >
              {l("REGISTER.RECAP")}
            </Typography>
          </Box>
          {error ? (
            <Alert severity="error" sx={{ alignSelf: "stretch" }}>
              {error}
            </Alert>
          ) : null}
          <InputLabel sx={{ alignSelf: "stretch" }}>
            {l("REGISTER.PERSONAL_INFO")}
          </InputLabel>
          <Row>
            <Cell>
              {user?.billingInfo?.firstName} {user?.billingInfo?.lastName}
            </Cell>
            <Cell>{user?.username}</Cell>
          </Row>
          <Row>
            <Cell>
              {l("REGISTER.BORN_ON")}{" "}
              {moment(user?.billingInfo?.birthDate).format("DD/MM/YYYY")}
            </Cell>
            <Cell>{user?.billingInfo?.phoneNumber}</Cell>
          </Row>
          <InputLabel sx={{ alignSelf: "stretch", mt: 4 }}>
            {l("REGISTER.BILLING_INFO")}
          </InputLabel>
          {user?.billingInfo?.isCompany ? (
            <CompanyBillingInfo user={user} />
          ) : (
            <IndividualBillingInfo user={user} />
          )}
          <InputLabel sx={{ alignSelf: "stretch", mt: 4 }}>
            {l("ECOMMERCE.SUBSCRIPTION_PLAN")}
          </InputLabel>
          <Row>
            <Cell>
              {l("ECOMMERCE.SUBSCRIPTION")}{" "}
              <Typography
                sx={{
                  color: theme.palette.secondary.main,
                  display: "inline",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {plan.planName}
              </Typography>
            </Cell>
            <Cell>
              <Price plan={plan} />
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Typography
                sx={{ color: theme.palette.secondary.main, display: "inline" }}
              >
                {plan.companyCredits}
              </Typography>{" "}
              {l("ECOMMERCE.COMPANY_CREDITS")}
            </Cell>
            <Cell>
              {plan.id === "trial" ? l("ECOMMERCE.LIMITED_SEARCHES") : ""}
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Typography
                sx={{ color: theme.palette.secondary.main, display: "inline" }}
              >
                {plan.contactCredits}
              </Typography>{" "}
              {l("ECOMMERCE.COMPANY_CREDITS")}
            </Cell>
          </Row>
          <Link
            href={pricingUrl}
            sx={{ color: "#C0BEB3", alignSelf: "stretch" }}
            underline="hover"
          >
            {l("ECOMMERCE.WANNA_CHANGE_PLAN_CLICK_HERE")}
          </Link>
          <Link
            href="/completeBillingInfo"
            sx={{
              color: "#565551",
              alignSelf: "stretch",
              textAlign: "center",
              mt: 16,
            }}
            underline="hover"
          >
            {l("ECOMMERCE.CLICK_TO_EDIT_INFO")}
          </Link>
          <Button onClick={submit}>
            {plan.id === "trial"
              ? l("ECOMMERCE.START_TRIAL")
              : l("ECOMMERCE.GO_PAY")}
          </Button>
          {user?.tags?.includes("regFlow_confirmed_trial") ? (
            <Button
              onClick={() => {
                localStorage.removeItem("blinko_requested_plan");
                history.push("/");
              }}
              thin
              color="gray"
            >
              {l("COMMON.CANCEL")}
            </Button>
          ) : null}
          {loading ? (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.7)",

                zIndex: 5,
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
        </CardContent>
        <div />
      </Card>
    </Container>
  );
}
