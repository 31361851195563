import { combineReducers } from "redux";

/**
 * Creates reducer from a JSON map
 *
 * @author marco.denittis
 *
 * @param {Object} map the reducer map ({ACTION: callback(state, action), ...})
 * @param {Object} initialState initial state (optional)
 *
 * @returns {Function} to be used passing state and action
 */
export const reducerFromMap = (map, initialState = {}) => {
  return (...args) => {
    if (args.length > 0 && !args[0] && initialState) {
      args[0] = initialState;
    }
    const action = args[1];
    const reducer = map[action.type];
    let newState = args[0];

    if (reducer) newState = reducer(...args);

    return newState;
  };
};

/**
 *
 * @author davide.mantovani
 *
 * @param {[Object]} reducers array of reducers maps
 * @param {Object} state Redux state
 *
 * @returns {Function} to be used passing state and action
 */
export const createRootReducer = (reducers = {}, state) => {
  let root = {};

  Object.keys(reducers).map((key) => {
    return (root[key] = reducerFromMap(reducers[key], state[key]));
  });

  return combineReducers(root);
};
