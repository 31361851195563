import { reducerFromMap } from "../libs/helpers/reducers";

import {
  GET_ACTIVE_PLANS_LOADING,
  GET_ACTIVE_PLANS_LOADED,
  GET_ACTIVE_PLANS_LOAD_ERROR,
} from "../actions/ecommerce.actions";
const initialState = {
  activePlans: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = {
  [GET_ACTIVE_PLANS_LOADING]: (state, action) => {
    return {
      ...state,
      activePlans: {
        ...state.activePlans,
        loading: true,
        error: null,
      },
    };
  },
  [GET_ACTIVE_PLANS_LOADED]: (state, action) => ({
    ...state,
    activePlans: {
      ...state.activePlans,
      loading: false,
      data: action.data.data.plans,
    },
  }),
  [GET_ACTIVE_PLANS_LOAD_ERROR]: (state, action) => ({
    ...state,
    activePlans: {
      ...state.activePlans,
      loading: false,
      error: action.params,
    },
  }),
};

export default reducerFromMap(reducer, initialState);
