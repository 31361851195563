import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Collapse,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Localize from "../../libs/localization";
import {
  createCommentAction,
  deleteCommentAction,
  editCommentAction,
  getCommentListAction,
} from "../../actions/comment.actions";
import { ReactComponent as EmptyIcon } from "../DetailPane/empty.svg";

const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;

const PersonalComments = ({ entityType, entityId, ...props }) => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState();
  const [commentValue, setCommentValue] = useState();
  const [type, setType] = useState();
  const [editId, setEditId] = useState();

  const user = useSelector((state) => state.auth.user);
  const commentList = useSelector((state) => state.comment.commentList);

  useEffect(() => {
    dispatch(
      getCommentListAction({
        params: { entityId, entityType },
      })
    );
  }, []);

  const saveComment = () => {
    const data = {
      commentType: type,
      content: commentValue,
      userId: user._id,
      entityId,
      entityType,
    };
    editId
      ? dispatch(editCommentAction({ id: editId, data }))
      : dispatch(createCommentAction({ data }));
    resetInfo();
  };
  const onDeleteComment = (id) =>
    dispatch(deleteCommentAction({ id, entityId, entityType }));

  const resetInfo = () => {
    setEditId(null);
    setModalVisible(false);
    setCommentValue(null);
    setType(null);
  };

  return (
    <>
      <Collapse bordered={false} defaultActiveKey={["comments"]} {...props}>
        <Panel
          key="comments"
          showArrow={false}
          header={
            <Row justify="space-between" align="middle">
              <Col>
                <h2>
                  <b>
                    {Localize(
                      "PAGES.COMPANIES.DETAIL.SECTIONS.COMMENTS.PERSONAL_COMMENTS"
                    )}
                  </b>
                </h2>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <span
                  style={{ fontSize: 12 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setModalVisible(true);
                  }}
                >
                  {Localize("COMMON.CREATE_NEW")}
                </span>
              </Col>
            </Row>
          }
          className="personalComment"
        >
          {commentList?.length > 0 ? (
            commentList.map((comment) => (
              <div key={comment._id}>
                <Row justify="space-between" align="middle">
                  <Col>
                    {comment.userId === user._id ? (
                      <span className="person-commented">
                        {Localize(
                          `PAGES.COMPANIES.DETAIL.SECTIONS.COMMENTS.${
                            comment.commentType === "private"
                              ? "PERSONAL_PRIVATE_COMMENT"
                              : "PERSONAL_PUBLIC_COMMENT"
                          }`
                        )}
                      </span>
                    ) : (
                      <span className="person-commented">
                        {comment.userName} {comment.userSurname}
                      </span>
                    )}{" "}
                    <span className="date-commented">
                      {moment(comment.created_at).format("DD/MM/YYYY")}
                    </span>
                  </Col>
                  {comment.userId === user._id && (
                    <Col className="icons">
                      <EditOutlined
                        className="pr-2 pointer"
                        onClick={() => {
                          setType(comment.commentType);
                          setCommentValue(comment.content);
                          setEditId(comment._id);
                          setModalVisible(true);
                        }}
                      />
                      <Popconfirm
                        title={Localize("CONFIRM.DELETE")}
                        onConfirm={() => onDeleteComment(comment._id)}
                        okText={Localize("COMMON.YES")}
                        cancelText={Localize("COMMON.NO")}
                      >
                        <DeleteOutlined className="pointer" />
                      </Popconfirm>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>{comment.content}</Col>
                </Row>
                <Divider className="my-2" />
              </div>
            ))
          ) : (
            <Col span={24}>
              <div style={{ textAlign: "center", opacity: 0.8 }}>
                <EmptyIcon />
                <p style={{ opacity: 0.6 }}>{Localize("COMMON.NO_DATA")}</p>
              </div>
            </Col>
          )}
        </Panel>
      </Collapse>
      <Modal
        visible={modalVisible}
        onCancel={resetInfo}
        title={Localize("PAGES.COMPANIES.DETAIL.SECTIONS.PUBLIC_COMMENT")}
        cancelText={Localize("COMMON.CANCEL")}
        okText={Localize(`COMMON.${editId ? "SAVE" : "CREATE_NEW"}`)}
        onOk={saveComment}
        okButtonProps={{ disabled: !type || !commentValue }}
      >
        <Row align="bottom" justify="start">
          <Col span={24}>
            <TextArea
              value={commentValue}
              placeholder={Localize(
                "PAGES.COMPANIES.DETAIL.SECTIONS.COMMENTS.ADD_COMMENT_PLACEHOLDER"
              )}
              style={{ height: 120 }}
              onChange={(e) => setCommentValue(e.target.value)}
            />
          </Col>
          <Col span={24} className="pt-3">
            <Select
              value={type}
              placeholder={Localize(
                "PAGES.COMPANIES.DETAIL.SECTIONS.COMMENTS.TYPE_PLACEHOLDER"
              )}
              style={{ width: "100%" }}
              onChange={(value) => setType(value)}
            >
              <Option value="private">
                {Localize(
                  "PAGES.COMPANIES.DETAIL.SECTIONS.COMMENTS.TYPES.PRIVATE"
                )}
              </Option>
              <Option value="public">
                {Localize(
                  "PAGES.COMPANIES.DETAIL.SECTIONS.COMMENTS.TYPES.PUBLIC"
                )}
              </Option>
            </Select>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default PersonalComments;
