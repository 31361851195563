import React from "react";
import { Card } from "antd";
import Localize from "../Localized/Localize.comp";
import "./Card.scss";
import moment from "moment";

const CardNomina = (props) => {
  const { data } = props;

  return (
    <Card
      title={
        <h2>
          <b>{Localize("PAGES.COMPANIES.DETAIL.SECTIONS.MODAL.NOMINA")}</b>
        </h2>
      }
      bordered={false}
      className="companyPage__body-card companyPage__body-card--custom customCard"
    >
      {data.nomination_date && (
        <p style={{ fontSize: "0.75rem", opacity: "0.7" }}>
          {moment(data.nomination_date).format("DD/MM/YYYY")}
        </p>
      )}
      {data.nomination_comment && <p>{data.nomination_comment}</p>}
    </Card>
  );
};

export default CardNomina;
