import { TreeSelect } from "antd";
import { orderBy } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFunctionsContactsSummaryAction } from "../../actions/contact.actions";
import { getFunctionAction } from "../../actions/registry/registry.function.actions";
import { translateRegistryOnView } from "../../libs/utils";

export default function FunctionPicker({ value, onChange }) {
  const dispatch = useDispatch();
  const functions = useSelector((state) => state.functionReg.data);
  const functionsContactSummary = useSelector(
    (state) => state.contact.functionsContactSummary.data
  );

  const functionGroups = {};

  if (functionsContactSummary) {
    functionsContactSummary.forEach((group) => {
      if (group.functionParent && group.numberOfElements > 0) {
        functionGroups[group.functionParent] = {
          title: (
            <b>
              {translateRegistryOnView(group.functionParent, "function", true)
                ? translateRegistryOnView(
                    group.functionParent,
                    "function",
                    true
                  )
                : group.functionParent}
            </b>
          ),
          value: group.functionParent,
          key: group.functionParent,
          children: [],
        };
      }
    });
  }

  if (functions?.enum) {
    functions.enum.forEach((functionItem) => {
      if (
        functionItem.active &&
        functionItem.parent &&
        functionItem.id &&
        functionGroups[functionItem.parent]
      ) {
        functionGroups[functionItem.parent].children.push({
          title: translateRegistryOnView(functionItem.id, "function", true)
            ? translateRegistryOnView(functionItem.id, "function", true)
            : functionItem.id,
          value: functionItem.id,
          key: functionItem.id,
        });
      }
    });
  }

  console.log({ functionGroups });

  useEffect(() => {
    if (functions.length === 0) {
      dispatch(getFunctionAction());
    }
    if (!functionsContactSummary) {
      dispatch(getFunctionsContactsSummaryAction());
    }
  }, [functions, functionsContactSummary]);

  return (
    <TreeSelect
      treeData={orderBy(Object.values(functionGroups), ["key"], ["asc"])}
      value={value}
      onChange={(v) => onChange(v)}
      treeCheckable
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      style={{ width: "100%" }}
    />
  );
}
