import React, { useEffect, useState } from "react";
import _ from "lodash";
import { ResponsiveLine } from "@nivo/line";
import { Col, Row } from "antd";
import Localize from "../../libs/localization";
import moment from "moment";

var SI_SYMBOL = ["€", "k€", "M€", "B€", "T€", "P€", "E€"];

function abbreviateNumber(number) {
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  // if (tier == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1).replace(".", ",") + suffix;
}

const InvestmentRoundGraph = ({ data }) => {
  const [dataSet, setDataSet] = useState();

  useEffect(() => {
    let investmentData = [];
    data &&
      data.forEach((element) => {
        investmentData.push({
          x: moment(element.fundingDate).format("DD/MM/YY"),
          y: element.totalAmountInvested,
        });
      });
    setDataSet([
      {
        id: Localize("PAGES.COMPANIES.DETAIL.SECTIONS.INVESTMENT_ROUND_GRAPH"),
        data: investmentData,
      },
    ]);
  }, [data]);

  const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
    <g>
      <circle
        fill="#fff"
        r={size / 2}
        strokeWidth={borderWidth}
        stroke={borderColor}
      />
      <circle
        r={size / 5}
        strokeWidth={borderWidth}
        stroke={borderColor}
        fill={color}
        fillOpacity={0.35}
      />
    </g>
  );

  return (
    <>
      <Row justify="center" style={{ marginTop: 24 }}>
        <Col>
          <h3 style={{ fontWeight: "bold" }}>
            {Localize("PAGES.COMPANIES.DETAIL.SECTIONS.INVESTMENT_ROUND_GRAPH")}
          </h3>
        </Col>
      </Row>
      <Row style={{ height: "300px", width: "100%" }}>
        {dataSet && (
          <ResponsiveLine
            data={dataSet}
            enableArea={true}
            pointSymbol={CustomSymbol}
            margin={{ top: 10, right: 100, bottom: 50, left: 100 }}
            xScale={{ type: "point" }}
            yScale={{ type: "linear" }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: Localize("COMMON.DATE"),
              legendOffset: 40,
              legendPosition: "middle",
            }}
            // yFormat={(e) => abbreviateNumber(e)}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: Localize("COMMON.MONEY_RAISED"),
              legendOffset: -80,
              legendPosition: "middle",
              format: (e) => abbreviateNumber(e),
            }}
            useMesh={true}
            colors={["rgb(97, 205, 187)", "rgb(244, 117, 96)"]}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            animate
          />
        )}
      </Row>
    </>
  );
};

export default InvestmentRoundGraph;
