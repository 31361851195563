import React from "react";
import { InputLabel as MUIInputLabel, useTheme } from "@mui/material";

export default function InputLabel({ children, sx, ...props }) {
  const theme = useTheme();
  return (
    <MUIInputLabel
      shrink
      sx={{
        fontWeight: 500,
        fontSize: "1.5rem",
        fontHeight: 1.2,
        // paddingLeft: 2,
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        ...sx,
      }}
      {...props}
    >
      {children}
    </MUIInputLabel>
  );
}
