import React from "react";
import { FormControl, Select as MUISelect } from "@mui/material";
import InputBase from "../TextField/InputBase.component";
import MenuItem from "../MenuItem/MenuItem.component";

export default function Select({ children, placeholder, ...props }) {
  return (
    <MUISelect
      sx={{
        "& .MuiSelect-icon": {
          // top: "calc(66.6% - .5em)",
        },
        position: "relative",
      }}
      input={<InputBase placeholder={placeholder} />}
      {...props}
    >
      {children}
    </MUISelect>
  );
}
