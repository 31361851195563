import { reducerFromMap } from "../../libs/helpers/reducers";

import {
  MEDIUM_PLANNING_LOADING,
  MEDIUM_PLANNING_LOADED,
  MEDIUM_PLANNING_LOAD_ERROR,
  MEDIUM_PLANNING_CREATE_LOADING,
  MEDIUM_PLANNING_CREATE_LOAD_ERROR,
  MEDIUM_PLANNING_EDIT_LOADING,
  MEDIUM_PLANNING_EDIT_COMPLETE,
  MEDIUM_PLANNING_EDIT_LOAD_ERROR,
  MEDIUM_PLANNING_DELETE_LOADING,
  MEDIUM_PLANNING_DELETE_COMPLETE,
  MEDIUM_PLANNING_DELETE_LOAD_ERROR,
  MEDIUM_PLANNING_CREATE_COMPLETE,
} from "../../actions/registry/registry.medium_planning.actions";

const reducer = {
  /*
   * MEDIUM_PLANNING LIST
   */
  [MEDIUM_PLANNING_LOADING]: (state) => ({
    ...state,
    loading: true,
  }),
  [MEDIUM_PLANNING_LOADED]: (state, { data }) => ({
    ...state,
    loading: false,
    data: data.data,
  }),
  [MEDIUM_PLANNING_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    loading: false,
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  data: [],
});
