import callApi from "../libs/helpers/callApi";
import {
  getCreaListApi,
  getPitchListApi,
  searchNewsApi,
} from "../api/home.api";
import { getContactListApi } from "../api/contact.api";

export const SEARCH_NEWS_LOADING = "SEARCH_NEWS_LOADING";
export const SEARCH_NEWS_LOADED = "SEARCH_NEWS_LOADED";
export const SEARCH_NEWS_LOAD_ERROR = "SEARCH_NEWS_LOAD_ERROR";

export const searchNewsAction = callApi({
  apiCall: (p) => searchNewsApi(p),
  secured: true,
  startAction: SEARCH_NEWS_LOADING,
  successAction: SEARCH_NEWS_LOADED,
  errorAction: SEARCH_NEWS_LOAD_ERROR,
});

export const RESET_NEWS_SEARCH = "RESET_NEWS_SEARCH";

export const resetNewsSearchAction = () => ({
  type: RESET_NEWS_SEARCH,
});

export const CREA_LIST_LOADING = "CREA_LIST_LOADING";
export const CREA_LIST_LOADED = "CREA_LIST_LOADED";
export const CREA_LIST_LOAD_ERROR = "CREAT_LIST_LOAD_ERROR";

export const getCreaListAction = callApi({
  apiCall: (p) => getCreaListApi(p),
  secured: true,
  startAction: CREA_LIST_LOADING,
  successAction: CREA_LIST_LOADED,
  errorAction: CREA_LIST_LOAD_ERROR,
});

export const PITCH_LIST_LOADING = "PITCH_LIST_LOADING";
export const PITCH_LIST_LOADED = "PITCH_LIST_LOADED";
export const PITCH_LIST_LOAD_ERROR = "PITCH_LIST_LOAD_ERROR";

export const getPitchListAction = callApi({
  apiCall: (p) => getPitchListApi(p),
  secured: true,
  startAction: PITCH_LIST_LOADING,
  successAction: PITCH_LIST_LOADED,
  errorAction: PITCH_LIST_LOAD_ERROR,
});

export const CONTACTS_LIST_LOADING = "CONTACTS_LIST_LOADING";
export const CONTACTS_LIST_LOADED = "CONTACTS_LIST_LOADED";
export const CONTACTS_LIST_LOAD_ERROR = "CONTACTS_LIST_LOAD_ERROR";

export const getContactsListAction = callApi({
  apiCall: (p) => getContactListApi(p),
  secured: true,
  startAction: CONTACTS_LIST_LOADING,
  successAction: CONTACTS_LIST_LOADED,
  errorAction: CONTACTS_LIST_LOAD_ERROR,
});
