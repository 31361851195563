import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Tooltip,
} from "antd";
import queryString from "query-string";
import { useLocation } from "react-router";
import { DownloadOutlined, SaveOutlined } from "@ant-design/icons";
import { orderBy } from "lodash";
import { FilterOutlined } from "@ant-design/icons/es/icons";
import { connect, useDispatch, useSelector } from "react-redux";
import DownOutlined from "@ant-design/icons/es/icons/DownOutlined";
import UpOutlined from "@ant-design/icons/es/icons/UpOutlined";
import StarOutlined from "@ant-design/icons/es/icons/StarOutlined";
import StarFilled from "@ant-design/icons/es/icons/StarFilled";
import BannerTop from "../../components/BannerTop/BannerTop";
import Localize, { l } from "../../libs/localization";
import Form from "../../components/Form/Form";
import Tree from "../../components/Tree/Tree";
import { getMainCategoryAction } from "../../actions/registry/registry.main_category.actions";
import Label from "../../components/Label/Label";
import CompanyAutocomplete from "../../components/CompanyAutocomplete/CompanyAutocomplete";
import { getTreeSectorAction } from "../../actions/registry/registry.sector.actions";
import {
  createSearchAction,
  deleteSearchAction,
  getCitiesListAction,
  getProvincesListAction,
  getRegionsListAction,
  getSearchListAction,
  resetSearchAction,
  searchCompaniesAction,
} from "../../actions/search.action";
import CompanyList from "../../components/List/Company.list";
import InfoForm from "../form/InfoForm";
import InvestmentForm from "../form/InvestmentForm";
import TagOptions from "../../components/Tag/TagOptions";
import CreateTagModal from "../../components/CreateTagModal/CreateTagModal";
import {
  associateTagAction,
  associationsTagIdCompanyAction,
  createTagAction,
  getTagAction,
} from "../../actions/tag.actions";
import FinancialForm from "../form/FinancialForm";
import SearchManagement from "../../components/SearchManagement/SearchManagement";
import { translateRegistryOnView } from "../../libs/utils";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import PlaceAutocomplete from "../../components/PlaceAutocomplete/PlaceAutocomplete";
import { exportCompaniesAction } from "../../actions/company.actions";
import { getOtherCategoriesAction } from "../../actions/registry/registry.other_categories.actions";
import { EXPORT_COMPANIES_PLUGIN } from "../../api/user.api";
import usePermissions from "../../hooks/usePermissions";
import { getFeatures } from "../../libs/features";
import InvestmentRoundsForm from "../form/InvestmentRoundsForm";
import FlatV2Picker from "../../components/FlatV2Picker";
import LimitedFeatureBanner from "../../design-library/LimitedFeatureBanner/LimitedFeatureBanner.component";
import ContactSalesModal from "../../design-library/ContactSalesModal/ContactSalesModal.component";
import { getConsumptionApi } from "../../api/ecommerce.api";
import JobOffersFiltersForm from "../../components/JobOffersFiltersForm/JobOffersFiltersForm";
import { attachTagToCompanySearchApi } from "../../api/tag.api";
import getPluginConsumption from "../../libs/getPluginConsumption";
import getUserPlugins from "../../libs/getUserPlugins";

const { Panel } = Collapse;

const PAGE_SIZE = 20;
const { confirm } = Modal;

const CompanySearch = (props) => {
  const features = getFeatures();

  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const location = useLocation();
  const permissions = usePermissions(props.user);

  const [open, setOpen] = useState([1]);
  const [dataForm, setDataForm] = useState({});
  const [showOther, setShowOther] = useState(false);
  const [moveSearchButton, setMoveSearchButton] = useState(false);
  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState("name");
  const [orderDir, setOrderDir] = useState("asc");
  const [city, setCity] = useState("");
  const [isHR, setIsHR] = useState(false);

  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  // TAG
  const [tagMode, setTagMode] = useState(false);
  const [tagAddModal, setAddTagModal] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [colorValue, setColorValue] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedSearchElement, setSelectedSearchElement] = useState(null);
  const [searchName, setSearchName] = useState(null);
  const [showPopoverSearch, setShowPopoverSearch] = useState(false);

  const resultSearch = useSelector((state) => state.search.results.data);
  const mainCategory = useSelector((state) => state.main_category.data);
  const token = useSelector((state) => state.auth.token);
  const sector = useSelector((state) => state.sector.data);
  const tags = useSelector((state) => state.tag.tagsList);
  const [consumption, setConsumption] = useState(null);
  const loadingTagsIdsCompany = useSelector(
    (state) => state.tag.tagsForIdsCompany.loading
  );
  const associatedTagsIdsCompany = useSelector(
    (state) => state.tag.tagsForIdsCompany.data
  );
  const otherCategories = useSelector((state) => state.other_categories.data);
  const searchList = useSelector((state) => state.search.searchList.data);
  const searchCreated = useSelector((state) => state.search.searchCreated.data);
  const citiesList = useSelector((state) => state.search.places.cities);
  const regionsList = useSelector((state) => state.search.places.regions);
  const provincesList = useSelector((state) => state.search.places.provinces);
  const loadingSearch = useSelector((state) => state.search.results.loading);
  const loadingExport = useSelector(
    (state) => state.company.exportList.loading
  );

  const [exportLimitedModal, setExportLimitedModal] = useState(false);

  const consumptionPlugin =
    props.user.licence.tenants[tenant]?.plugins?.["6538ca30695c4015d3daeb7c"];

  const ids = [];

  const dispatch = useDispatch();

  async function loadConsumption() {
    try {
      if ((permissions.ecommerce || !!consumptionPlugin) && !consumption) {
        let periodStartDate = null;
        if (consumptionPlugin) {
          const period = consumptionPlugin.data.period === "month" ? 1 : 12;
          const firstDate = consumptionPlugin.data.periodStartDateTime;
          periodStartDate = moment(firstDate);
          while (
            periodStartDate.isBefore(moment().subtract(period, "months"))
          ) {
            periodStartDate.add(period, "months");
          }
          periodStartDate = periodStartDate.valueOf();
        }
        console.log("calling consumption api", { periodStartDate });
        const res = await getConsumptionApi({ periodStartDate });
        setConsumption(res.data?.consumption);
      }
    } catch (e) {}
  }

  useEffect(() => {
    loadConsumption();
  }, [permissions.ecommerce]);

  useEffect(() => {
    if (mainCategory.length === 0) {
      dispatch(getMainCategoryAction());
    }
  }, [mainCategory]);

  useEffect(() => {
    if (otherCategories.length == 0) {
      dispatch(getOtherCategoriesAction());
    }
  }, [otherCategories]);

  useEffect(() => {
    if (sector.length === 0) {
      dispatch(getTreeSectorAction());
    }
  }, [sector]);

  useEffect(() => {
    if (permissions.favourites) {
      dispatch(getTagAction());
    }
  }, [tagAddModal, permissions.favourites]);

  const callback = (key) => {
    setOpen(key);
  };

  const checkIfIsHrProfile = () => {
    const userProfiles = props.user?.licence?.tenants?.[tenant]?.profiles;
    if (userProfiles) {
      Object.keys(userProfiles).map((profile) => {
        if (userProfiles[profile].name === "hr_database") setIsHR(true);
      });
    }
  };

  useEffect(() => {
    dispatch(resetSearchAction());
    checkIfIsHrProfile();
  }, []);

  useEffect(() => {
    if (permissions.savedSearch) {
      dispatch(getSearchListAction({ params: "entity=company" }));
    }
  }, [permissions.savedSearch]);

  const getPlaces = () => {
    dispatch(getCitiesListAction({ type: "cities" }));
    dispatch(getRegionsListAction({ type: "regions" }));
    dispatch(getProvincesListAction({ type: "provinces" }));
  };

  const onExportCompanies = async () => {
    const dataParams = { ...filters };
    delete dataParams.limit;
    delete dataParams.pageNum;
    delete dataParams.sort_direction;
    delete dataParams.sort_key;
    if (props.user.group === "SUPER-ADMIN" || props.user.group === "ADMIN")
      dispatch(exportCompaniesAction({ data: dataParams }));
    else {
      let creditsLeft = 0;
      if (permissions.exportCompaniesV2) {
        const pluginName = "ExportCompaniesV2";
        const plugin = getUserPlugins(props.user).find(
          (p) => p.name === pluginName
        );
        creditsLeft =
          (plugin?.data?.companyLimit || 0) -
          (
            await getPluginConsumption({
              user: props.user,
              pluginName,
            })
          )?.companyExport?.count;
      } else {
        const creditData = Object.values(
          props?.user?.licence?.tenants[tenant]?.plugins
        )?.find((plugin) => plugin.name === EXPORT_COMPANIES_PLUGIN)?.data;
        creditsLeft =
          creditData?.contactLimit - parseInt(creditData?.exportedCount, 10);
      }

      const content =
        creditsLeft > 0
          ? `${Localize(
              "TAG.EXPORT_MODAL_DESCRIPTION"
            )} ${creditsLeft}. ${Localize("TAG.EXPORT_CONTINUE")}`
          : Localize("ERROR.ERROR_EXPORT_CONTACTS");
      return confirm({
        title: Localize("TAG.EXPORT_MODAL_TITLE"),
        content,
        okButtonProps: { disabled: creditsLeft <= 0 },
        cancelText: Localize("COMMON.CANCEL"),
        onOk() {
          dispatch(exportCompaniesAction({ data: dataParams }));
        },
      });
    }
  };

  function showExportLimitedModal() {
    setExportLimitedModal(true);
  }

  function getParsedDataForm() {
    const parsedDataForm = {};
    Object.keys(dataForm).forEach((p) => {
      if (Array.isArray(dataForm[p])) {
        if (dataForm[p].length > 0) {
          parsedDataForm[p] = dataForm[p];
        }
      } else if (typeof dataForm[p] === "number") {
        parsedDataForm[p] = dataForm[p];
      } else if (dataForm[p] && dataForm[p].length > 0) {
        parsedDataForm[p] = dataForm[p];
      } else if (dataForm[p] === true) {
        parsedDataForm[p] = dataForm[p];
      }
    });
    if (city) parsedDataForm.city = city;
    return parsedDataForm;
  }

  const searchRequest = () => {
    let dataParams = {};
    const query = queryString.parse(location.search);
    if (query.key && !dataParams.name) dataParams.name = query.key;

    const parsedDataForm = getParsedDataForm();
    dataParams = { ...dataParams, ...parsedDataForm };

    if (order) {
      dataParams.sort_key = order;
      dataParams.sort_direction = orderDir;
    }

    setFilters(dataParams);

    console.log(dataParams);
    // setShowOther(false);
    // setMoveSearchButton(false);
    dataParams.pageNum = 1;
    dataParams.limit = pageSize;
    dispatch(searchCompaniesAction({ data: dataParams }));
  };

  useEffect(() => {
    searchRequest();
  }, [
    dataForm,
    city,
    selectedSearchElement,
    searchCreated,
    orderDir,
    order,
    location.key,
  ]);

  const getCompanyPageIds = () => {
    if (resultSearch.companies && resultSearch.companies.data) {
      resultSearch.companies.data.forEach((company) => {
        ids.push(company._id);
      });
      dispatch(associationsTagIdCompanyAction({ data: { id: ids } }));
    }
  };

  useEffect(() => {
    if (permissions.favourites) {
      getCompanyPageIds();
    }
  }, [resultSearch.companies.data, tagMode, permissions.favourites]);

  const handleOrder = (val) => {
    if (val === order) {
      setOrderDir(orderDir == "asc" ? "desc" : "asc");
    } else {
      setOrderDir("asc");
    }
    setOrder(val);
  };

  const clearTagFields = () => {
    setTagValue("");
    setColorValue("");
    setErrorMessage("");
  };

  const createTag = () => {
    const params = {
      name: tagValue,
      color: colorValue.hex.replace("#", "").toUpperCase(),
    };

    dispatch(createTagAction({ data: params }));
    clearTagFields();
  };

  const associateTagCompany = () => {
    const dataParams = {};
    let id = "";

    tags.forEach((tag) => {
      if (tag.name === selectedTag) {
        id = tag._id;
      }
    });

    dataParams.name = selectedTag;
    dataParams.companies = selectedCompany;
    dispatch(associateTagAction({ id, data: dataParams }));
    setTagMode(false);
    setSelectedTag("");
  };

  async function associateAll(e) {
    let id;

    tags.forEach((tag) => {
      if (tag.name === selectedTag) {
        id = tag._id;
      }
    });
    console.log("id", id);
    if (id) {
      const params = getParsedDataForm();
      await attachTagToCompanySearchApi({ data: params, tagId: id });
      setTagMode(false); // chiude la sezione dei tag
      setSelectedTag(""); // svuota i contatti selezionati
    }
  }

  const orderLabel = (value, key) => {
    return (
      <Button
        type="link"
        className={`${
          order === value ? "selected" : ""
        } searchPage__orders-button`}
        onClick={() => handleOrder(value)}
      >
        {key}
        {order === value && (
          <span className="searchPage__orders-icon">
            {orderDir == "asc" ? <UpOutlined /> : <DownOutlined />}
          </span>
        )}
      </Button>
    );
  };

  useEffect(() => {
    setSelectedCompany([]);
  }, [tagMode]);

  const renderSearchButton = () => (
    <Row justify="end" align="bottom" style={{ height: "100%" }}>
      {permissions.savedSearch ? (
        <>
          <SearchManagement
            type={"company"}
            searchList={orderBy(searchList, "created_at", "desc")}
            value={selectedSearchElement}
            onDeleteSearch={() => {
              dispatch(
                deleteSearchAction({
                  id: selectedSearchElement,
                  type: "company",
                })
              );
              setSelectedSearchElement(null);
              setCity(null);
              setDataForm({ company_filters: {}, city: null });
            }}
            onSearchElementChange={(id, data) => {
              setSelectedSearchElement(id);
              setCity(data?.city);
              setDataForm(data ? data : { company_filters: {}, city: null });
            }}
          />
          <Popover
            placement="topLeft"
            title={Localize("PAGES.SEARCH.SEARCH_NAME")}
            content={searchNameContent}
            trigger="click"
            visible={showPopoverSearch}
            onVisibleChange={(visibility) => setShowPopoverSearch(visibility)}
          >
            <Button
              type="primary"
              // className="mr-3"
              onClick={() => setShowPopoverSearch(true)}
            >
              <SaveOutlined />
              {Localize("PAGES.SEARCH.SAVE_SEARCH")}
            </Button>
          </Popover>
        </>
      ) : null}
      {/* <Button type="primary" onClick={() => searchRequest()}>
        <SearchOutlined />
        {Localize("COMMON.SEARCH")}
      </Button> */}
    </Row>
  );

  const searchNameContent = () => (
    <Row>
      <Col span={16}>
        <Input
          value={searchName}
          onChange={(e) => {
            setSearchName(e.target.value);
          }}
        />
      </Col>
      <Col align="right" span={8}>
        <Button
          type="primary"
          disabled={!searchName}
          onClick={() => {
            const dataParams = { ...filters };
            delete dataParams.pageNum;
            delete dataParams.sort_direction;
            delete dataParams.sort_key;
            dispatch(
              createSearchAction({
                body: { data: dataParams, name: searchName, type: "company" },
              })
            );
            setShowPopoverSearch(false);
            setSearchName(null);
          }}
        >
          {Localize("COMMON.SAVE")}
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <BannerTop small>
        <h1 className="mt-4 orange-title">
          {Localize("PAGES.COMPANIES.TITLE")}
        </h1>
      </BannerTop>
      <div className="searchPage__filters animation fadein-up slow \">
        <Card
          bordered={false}
          style={{ borderRadius: "10px" }}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          <Form
            name="form-create"
            initialValues={dataForm}
            onChange={({ values, errors, completed }) => {
              setDataForm({
                ...dataForm,
                ...values,
                ...errors,
              });
            }}
          >
            {({ values, errors, completed, updateValue }) => (
              <>
                <Row gutter={16}>
                  <Col span={8}>
                    <Label
                      className="filter-color"
                      value={Localize("PAGES.SEARCH.ORDER.NAME")}
                    />
                    <CompanyAutocomplete
                      token={token}
                      onChange={(value) => {
                        updateValue({
                          key: "id",
                          value: value.map((e) => e?.id),
                        });
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <div>
                      <Label
                        className="filter-color"
                        value={Localize("PAGES.SEARCH.ORDER.CATEGORY")}
                      />
                      <Select
                        mode="multiple"
                        allowClear={true}
                        style={{ width: "100%" }}
                        value={dataForm.main_category}
                        onChange={(value) =>
                          updateValue({ key: "main_category", value })
                        }
                        showSearch
                        options={
                          mainCategory.enum &&
                          orderBy(mainCategory.enum, ["id"], ["asc"])
                            .filter((o) => o.active)
                            .map((e, j) => {
                              return {
                                value: e.id,
                                label: translateRegistryOnView(
                                  e.id,
                                  "main_category",
                                  true
                                )
                                  ? translateRegistryOnView(
                                      e.id,
                                      "main_category",
                                      true
                                    )
                                  : e.id,
                                key: e.id,
                              };
                            })
                        }
                      />
                    </div>
                  </Col>
                  {features.comments && permissions.comments && (
                    <Col span={8} className="mt-3">
                      <Row align="bottom" style={{ height: "100%" }}>
                        <Checkbox
                          checked={dataForm.commentsExists}
                          onChange={(value) => {
                            updateValue({
                              key: "commentsExists",
                              value: value.target.checked,
                            });
                          }}
                        >
                          <span className="filter-color">
                            {Localize("PAGES.SEARCH.ONLY_WITH_COMMENTS")}
                          </span>
                        </Checkbox>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Button
                      type="link"
                      onClick={() => {
                        getPlaces();
                        setShowOther(!showOther);
                        setMoveSearchButton(!moveSearchButton);
                      }}
                      className="secondary mt-3 pl-2"
                    >
                      <FilterOutlined className="mr-1" />
                      {Localize(
                        `COMMON.${!showOther ? "OTHER_FILTERS" : "CLOSE"}`
                      )}
                    </Button>
                  </Col>
                  <Col>{!moveSearchButton && renderSearchButton()}</Col>
                </Row>
                {showOther && (
                  <>
                    <div
                      className="animation fadein-right slow "
                      style={{ marginLeft: "-15px" }}
                    >
                      <Collapse
                        onChange={callback}
                        ghost={true}
                        activeKey={open}
                      >
                        <Panel
                          header={Localize("COMMON.COMPANY")}
                          key="1"
                          className="pt-3"
                        >
                          {" "}
                          <Row gutter={16}>
                            {/* <Col span={8}>
                              <Label
                                className="filter-color"
                                value={Localize("PAGES.SEARCH.ORDER.CITY")}
                              />
                              <SearchPlaces
                                value={dataForm.city}
                                placeholder={Localize("COMMON.FIND_ADDRESS")}
                                type={["(cities)"]}
                                onChange={(place) => {
                                  if (place && place.address_components) {
                                    const city =
                                      place.address_components[0].long_name;
                                    setCity(city);
                                  } else {
                                    setCity("");
                                  }
                                }}
                              />
                            </Col> */}
                            <Col span={8}>
                              <Label
                                className="filter-color"
                                value={Localize("COMMON.CITY")}
                              />
                              <PlaceAutocomplete
                                disableOptions={loadingSearch}
                                data={citiesList
                                  ?.sort()
                                  .filter((el) => el !== "")}
                                value={dataForm.city}
                                onChange={(value) => {
                                  updateValue({ key: "city", value });
                                }}
                              />
                            </Col>
                            <Col span={8}>
                              <Label
                                className="filter-color"
                                value={Localize("COMMON.PROVINCE")}
                              />
                              <PlaceAutocomplete
                                disableOptions={loadingSearch}
                                data={provincesList
                                  ?.sort()
                                  .filter((el) => el !== "")}
                                value={dataForm.province}
                                onChange={(value) => {
                                  updateValue({ key: "province", value });
                                }}
                              />
                            </Col>
                            <Col span={8}>
                              <Label
                                className="filter-color"
                                value={Localize("COMMON.REGION")}
                              />
                              <PlaceAutocomplete
                                disableOptions={loadingSearch}
                                data={regionsList
                                  ?.sort()
                                  .filter((el) => el !== "")}
                                value={dataForm.region}
                                onChange={(value) => {
                                  updateValue({ key: "region", value });
                                }}
                              />
                            </Col>
                            <Col span={8} className="mt-3">
                              <Label
                                className="filter-color"
                                value={Localize("PAGES.SEARCH.ORDER.SECTOR")}
                              />
                              <Tree
                                translateEntity="Sector"
                                valueData={dataForm.sector}
                                datatree={orderBy(sector, ["key"], ["asc"])}
                                onChange={(value) => {
                                  updateValue({ key: "sector", value });
                                }}
                                onSelect={(value, e) => {
                                  updateValue({ key: "sector", value });
                                }}
                              />
                            </Col>
                            {tenant !== "spain" && (
                              <Col span={8} className="mt-3">
                                <Label
                                  className="filter-color"
                                  value={Localize(
                                    "PAGES.SEARCH.ORDER.OTHER_CAT"
                                  )}
                                />
                                <Select
                                  mode="multiple"
                                  allowClear={true}
                                  style={{ width: "100%" }}
                                  value={dataForm.other_categories}
                                  onChange={(value) =>
                                    updateValue({
                                      key: "other_categories",
                                      value,
                                    })
                                  }
                                  showSearch
                                  options={
                                    otherCategories.set &&
                                    orderBy(otherCategories.set, "id")
                                      .filter((e) => e.id.length > 0)
                                      .map((e, j) => {
                                        return {
                                          value: e.id,
                                          label: translateRegistryOnView(
                                            e.id,
                                            "other_categories",
                                            true
                                          )
                                            ? translateRegistryOnView(
                                                e.id,
                                                "other_categories",
                                                true
                                              )
                                            : e.id,
                                          key: e.id,
                                        };
                                      })
                                  }
                                />
                              </Col>
                            )}
                            {features.atecoCode && (
                              <Col span={8} className="mt-3">
                                <Label
                                  className="filter-color"
                                  value={Localize(
                                    "PAGES.SEARCH.ORDER.ATECO_CODE"
                                  )}
                                />
                                <Input
                                  value={dataForm.ateco_code}
                                  onChange={(e) => {
                                    updateValue({
                                      key: "ateco_code",
                                      value: e.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            )}
                          </Row>
                        </Panel>
                        {permissions.companyFinancials &&
                          features.companyFinancials && (
                            <Panel
                              header={Localize(
                                "PAGES.SEARCH.ORDER.FINANCIAL_DATA"
                              )}
                              key="2"
                              className="pt-3"
                            >
                              <FinancialForm
                                onChange={(key, value) =>
                                  updateValue({ key, value })
                                }
                                dataForm={dataForm}
                              />
                            </Panel>
                          )}
                        <Panel
                          header={Localize("PAGES.SEARCH.ORDER.ADDITION_INFO")}
                          key="3"
                          className="pt-3"
                        >
                          <InfoForm
                            onChange={(key, value) =>
                              updateValue({ key, value })
                            }
                            dataForm={dataForm}
                            isHR={isHR}
                          />
                        </Panel>
                        {features.companyInvestments &&
                          permissions.companyInvestments && (
                            <Panel
                              header={Localize(
                                "PAGES.SEARCH.ORDER.ADEVERTISING_INVESTMENTS"
                              )}
                              key="4"
                              className="pt-3"
                            >
                              <InvestmentForm
                                onChange={(key, value) =>
                                  updateValue({ key, value })
                                }
                                dataForm={dataForm}
                              />
                            </Panel>
                          )}
                        {permissions.jobOffers && features.jobOffers ? (
                          <Panel
                            header={Localize("PAGES.SEARCH.COMPANY.JOB_OFFERS")}
                            key="12"
                            className="pt-3"
                          >
                            <JobOffersFiltersForm
                              formData={dataForm}
                              updateValue={updateValue}
                            />
                          </Panel>
                        ) : null}
                        {permissions.entityNews && features.entityNews ? (
                          <Panel
                            header={Localize(
                              "PAGES.SEARCH.COMPANY.ACQUISITIONS"
                            )}
                            key="13"
                            className="pt-3"
                          >
                            <Row gutter={16}>
                              <Col span={16}>
                                <Label
                                  className="filter-color"
                                  value={Localize(
                                    "PAGES.SEARCH.COMPANY.ACQUISITION_DATE"
                                  )}
                                />
                                <DatePicker.RangePicker
                                  style={{ width: "100%" }}
                                  format="DD-MM-YYYY"
                                  placeholder={[
                                    Localize("COMMON.FROM"),
                                    Localize("COMMON.TO"),
                                  ]}
                                  className="mr-5"
                                  allowEmpty={[false, true]}
                                  defaultValue={[
                                    dataForm.acquisition_after &&
                                      moment(dataForm.acquisition_after),
                                    dataForm.acquisition_before &&
                                      moment(dataForm.acquisition_before),
                                  ]}
                                  onChange={(dates) => {
                                    const [dateStart, dateEnd] = dates || [];
                                    if (dateStart) {
                                      updateValue({
                                        key: "acquisition_after",
                                        value: dateStart.toISOString(),
                                      });
                                    } else {
                                      updateValue({
                                        key: "acquisition_after",
                                        value: null,
                                      });
                                    }
                                    if (dateEnd) {
                                      updateValue({
                                        key: "acquisition_before",
                                        value: dateEnd.toISOString(),
                                      });
                                    } else {
                                      updateValue({
                                        key: "acquisition_before",
                                        value: null,
                                      });
                                    }
                                  }}
                                />
                              </Col>
                              <Col span={8}>
                                <Label
                                  className="filter-color"
                                  value={Localize(
                                    "PAGES.SEARCH.COMPANY.ACQUISITION_ROLE"
                                  )}
                                />
                                <Select
                                  allowClear={true}
                                  style={{ width: "100%" }}
                                  value={dataForm.acquisition_role}
                                  onChange={(value) =>
                                    updateValue({
                                      key: "acquisition_role",
                                      value,
                                    })
                                  }
                                  onClear={() =>
                                    updateValue("acquisition_role", null)
                                  }
                                  showSearch
                                  options={[
                                    {
                                      value: "buyer",
                                      label: l(
                                        "PAGES.SEARCH.COMPANY.ACQUISITION_BUYER"
                                      ),
                                    },
                                    {
                                      value: "seller",
                                      label: l(
                                        "PAGES.SEARCH.COMPANY.ACQUISITION_SELLER"
                                      ),
                                    },
                                  ]}
                                />
                              </Col>
                            </Row>
                          </Panel>
                        ) : null}
                        {permissions.investmentRounds &&
                          features.investmentRounds && (
                            <Panel
                              header={Localize(
                                "PAGES.SEARCH.ORDER.INVESTMENT_ROUNDS"
                              )}
                              key="6"
                              className="pt-3"
                            >
                              <InvestmentRoundsForm
                                onChange={(key, value) =>
                                  updateValue({ key, value })
                                }
                                dataForm={dataForm}
                              />
                            </Panel>
                          )}
                        {features.collectiveAgreements &&
                          permissions.collectiveAgreements && (
                            <Panel
                              header={Localize(
                                "PAGES.SEARCH.COLLECTIVE_AGREEMENTS"
                              )}
                              key="7"
                              className="pt-3"
                            >
                              <FlatV2Picker
                                entityName="CollectiveAgreement"
                                placeholder={Localize(
                                  "PAGES.SEARCH.COLLECTIVE_AGREEMENTS"
                                )}
                                onChange={(value) => {
                                  console.log(value);
                                  updateValue({
                                    key: "collectiveAgreements",
                                    value: value.map((obj) => obj.id),
                                  });
                                }}
                                selectedIds={dataForm.collectiveAgreements}
                                displayExtraField="identifier"
                                multi
                              />
                            </Panel>
                          )}
                      </Collapse>
                    </div>
                    {moveSearchButton && renderSearchButton()}
                  </>
                )}
              </>
            )}
          </Form>
        </Card>
      </div>
      <div>
        <Row align="middle" className="searchPage__orders">
          {permissions.favourites ? (
            <Tooltip title="Associa Tag">
              <Button
                type="primary"
                shape="circle"
                onClick={() => setTagMode(!tagMode)}
                icon={
                  tagMode ? (
                    <StarFilled
                      className="mr-2 orange-title"
                      style={{ fontSize: 20, fontWeight: "normal" }}
                    />
                  ) : (
                    <StarOutlined
                      className="mr-2 orange-title"
                      style={{ fontSize: 20, fontWeight: "normal" }}
                    />
                  )
                }
              />
            </Tooltip>
          ) : null}
          <Col className="orange-title" style={{ fontWeight: "normal" }}>
            {Localize("COMMON.ORDER_BY")}
          </Col>
          <Col flex="none" className="grey-light-color">
            {orderLabel("name", Localize("PAGES.SEARCH.ORDER.NAME"))}
            {orderLabel("sector", Localize("PAGES.SEARCH.ORDER.SECTOR"))}
            {orderLabel("city", Localize("PAGES.SEARCH.ORDER.CITY"))}
          </Col>
          <Col flex="auto">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <h2
                style={{ fontWeight: "bold" }}
                className="p-0 m-0 searchPage__orders-button orange-title"
              >
                {Localize("PAGES.SEARCH.COMPANIES_FOUND")}:{" "}
                <span className="orange-title">
                  {resultSearch.companies && resultSearch.companies.totalResults
                    ? resultSearch.companies.totalResults
                    : 0}
                </span>
              </h2>
              {resultSearch?.companies?.totalResults > 0 &&
                (permissions.downloadCompanies || permissions.ecommerce) && (
                  <Button
                    type="primary"
                    className="ml-3 orange-title"
                    onClick={
                      permissions.downloadCompanies
                        ? onExportCompanies
                        : showExportLimitedModal
                    }
                    loading={loadingExport}
                  >
                    <DownloadOutlined className="mr-1" />
                    {Localize("TAG.EXPORT_COMPANY")}
                  </Button>
                )}
            </div>
          </Col>
        </Row>
        <div className="animation fadein-right slow">
          <CreateTagModal
            visible={tagAddModal}
            onCancel={() => {
              clearTagFields();
              setAddTagModal(false);
            }}
            onOk={() => {
              createTag();
              setAddTagModal(false);
            }}
            okDisabled={!tagValue || !colorValue || errorMessage}
            tagValue={tagValue}
            colorValue={colorValue}
            onTagChange={(e) => {
              const foundedTag = tags.find(
                (tag) => tag.name.toLowerCase() === e.target.value.toLowerCase()
              );
              if (foundedTag) {
                setErrorMessage(Localize("TAG.ERROR_MESSAGE"));
              } else {
                setErrorMessage("");
              }
              setTagValue(e.target.value);
            }}
            onColorChange={(color) => setColorValue(color)}
            errorMessage={errorMessage}
          />
          {tagMode && (
            <TagOptions
              onAddTagClick={() => setAddTagModal(true)}
              tagList={tags}
              value={selectedTag}
              onTagChange={(value) => setSelectedTag(value)}
              onAssociateClick={associateTagCompany}
              associateDisabled={!selectedTag || selectedCompany.length === 0}
              associateAllDisabled={!selectedTag}
              tooManyResultsToAssociateAll={
                !selectedTag || resultSearch?.companies?.totalResults > 3000
              }
              onAssociateAllClick={associateAll}
            />
          )}
        </div>
        <div>
          <CompanyList
            data={(resultSearch.companies && resultSearch.companies.data) || []}
            resultsLimited={resultSearch?.companies?.resultsLimited}
            pageSize={pageSize}
            setPageSize={setPageSize}
            total={
              resultSearch.companies && resultSearch.companies.totalResults
            }
            title={
              <>
                {Localize("PAGES.COMPANIES.TITLE")} (
                {resultSearch.companies && resultSearch.companies.totalResults})
              </>
            }
            filters={filters}
            tagMode={tagMode}
            setSelectedCompany={(id) => setSelectedCompany(id)}
            loadingList={loadingSearch}
            loadingTag={loadingTagsIdsCompany}
            associatedTagsIds={
              associatedTagsIdsCompany && associatedTagsIdsCompany
            }
            alreadyOpened={
              permissions.ecommerce || consumptionPlugin
                ? consumption?.companies?.ids
                : []
            }
          />
          {resultSearch?.companies?.resultsLimited ? (
            <LimitedFeatureBanner
              message={l("ECOMMERCE.SEARCH_RESULTS_LIMIT_TEXT")}
            />
          ) : null}
        </div>
      </div>
      <ContactSalesModal
        open={exportLimitedModal}
        dismiss={() => setExportLimitedModal(false)}
        mainMessage={l("ECOMMERCE.EXPORT_COMPANIES_LIMIT_TEXT")}
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, {})(CompanySearch);
