import { reducerFromMap } from "../libs/helpers/reducers";

import {
  getDetailAndSchemaProps,
  getPaginatedProps,
} from "../libs/helpers/callApi";

import {
  NIELSEN_LIST_LOADED,
  NIELSEN_LIST_LOADING,
  NIELSEN_LIST_LOAD_ERROR,
  NIELSEN_YEARS_LOADING,
  NIELSEN_YEARS_LOADED,
  NIELSEN_YEARS_LOAD_ERROR,
} from "../actions/nielsen.action";

const reducer = {
  /*
   * NIELSEN_LIST
   */
  [NIELSEN_LIST_LOADING]: (state) => ({
    ...state,
    list: { ...state.list, loading: true },
  }),
  [NIELSEN_LIST_LOADED]: (state, { data }) => {
    return {
      ...state,
      list: {
        loading: false,
        data,
      },
    };
  },
  [NIELSEN_LIST_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    list: { ...state.list, loading: false },
    error: data,
  }),
  /*
   * NIELSEN_YEARS
   */
  [NIELSEN_YEARS_LOADING]: (state) => ({
    ...state,
    years: { ...state.years, loading: true },
  }),
  [NIELSEN_YEARS_LOADED]: (state, { data }) => ({
    ...state,
    years: {
      loading: false,
      ...getPaginatedProps(data),
    },
  }),
  [NIELSEN_YEARS_LOAD_ERROR]: (state, { data }) => ({
    ...state,
    years: { ...state.years, loading: false },
    error: data,
  }),
};

export default reducerFromMap(reducer, {
  list: {},
  years: {},
});
