import { Typography } from "@mui/material";
import React from "react";
import Button from "../Button/Button.component";
import SadIcon from "../icons/SadIcon.component";
import Modal from "../Modal/Modal.component";
import { l } from "../../libs/localization";
import { pricingPageUrls } from "../../pages/profile/subscriptionInfo.page";
import usePermissions from "../../hooks/usePermissions";
import { helpdeskEmails } from "../ContactSalesModal/ContactSalesModal.component";

export default function OutOfQuotaModal({ dismiss, children, ...props }) {
  const tenant = localStorage.getItem("lefacWebTenant")?.data;
  const permissions = usePermissions();
  return (
    <Modal open={true} onClose={dismiss} {...props}>
      <SadIcon sx={{ fontSize: 50 }} />
      <Typography sx={{ mt: 2.5 }} variant="h3">
        {l("ECOMMERCE.OUT_OF_QUOTA_MODAL.TITLE")}
      </Typography>
      <Typography sx={{ alignSelf: "stretch", mb: 2.5 }}>
        {permissions.ecommerce
          ? l("ECOMMERCE.OUT_OF_QUOTA_MODAL.BODY_ECOM")
          : l("ECOMMERCE.OUT_OF_QUOTA_MODAL.BODY")}
      </Typography>
      <a
        href={
          permissions.ecommerce
            ? pricingPageUrls[tenant]
            : `mailto:${helpdeskEmails[tenant]}`
        }
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          width: "100%",
        }}
      >
        <Button sx={{ flex: 1 }}>
          {permissions.ecommerce
            ? l("ECOMMERCE.OUT_OF_QUOTA_MODAL.CHOSE_PLAN")
            : l("ECOMMERCE.CONTACT")}
        </Button>
      </a>
      <Button color="gray" onClick={dismiss}>
        Chiudi
      </Button>
    </Modal>
  );
}
