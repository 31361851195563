import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Input,
  Row,
  Select,
  Tooltip,
  DatePicker,
  Popover,
  TreeSelect,
  Modal as AntModal,
} from "antd";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { DownloadOutlined, SaveOutlined } from "@ant-design/icons";
import moment from "moment";
import { FilterOutlined } from "@ant-design/icons/es/icons";
import queryString from "query-string";
import { useLocation } from "react-router";
import { find, forEach, groupBy, isEmpty, orderBy } from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import DownOutlined from "@ant-design/icons/es/icons/DownOutlined";
import UpOutlined from "@ant-design/icons/es/icons/UpOutlined";
import StarOutlined from "@ant-design/icons/es/icons/StarOutlined";
import StarFilled from "@ant-design/icons/es/icons/StarFilled";
import BannerTop from "../../components/BannerTop/BannerTop";
import Localize, { l } from "../../libs/localization";
import Form from "../../components/Form/Form";
import Tree from "../../components/Tree/Tree";
import { getMainCategoryAction } from "../../actions/registry/registry.main_category.actions";
import Label from "../../components/Label/Label";
import CompanyAutocomplete from "../../components/CompanyAutocomplete/CompanyAutocomplete";
import { getTreeSectorAction } from "../../actions/registry/registry.sector.actions";
import {
  createSearchAction,
  deleteSearchAction,
  getCitiesListAction,
  getProvincesListAction,
  getRegionsListAction,
  getSearchListAction,
  resetSearchAction,
  searchContactsAction,
} from "../../actions/search.action";
import InfoForm from "../form/InfoForm";
import InvestmentForm from "../form/InvestmentForm";
import ContactList from "../../components/List/Contact.list";
import { getFunctionAction } from "../../actions/registry/registry.function.actions";
import CreateTagModal from "../../components/CreateTagModal/CreateTagModal";
import TagOptions from "../../components/Tag/TagOptions";
import {
  associateTagAction,
  associationsTagIdContactAction,
  createTagAction,
  getTagAction,
} from "../../actions/tag.actions";
import { exportContactsAction } from "../../actions/contact.actions";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";
import FinancialForm from "../form/FinancialForm";
import SearchManagement from "../../components/SearchManagement/SearchManagement";
import { translateRegistryOnView } from "../../libs/utils";
import PlaceAutocomplete from "../../components/PlaceAutocomplete/PlaceAutocomplete";
import { getPlaceListApi, getSingleSavedSearch } from "../../api/search.api";
import { headersBuilder } from "../../libs/helpers/callApi";
import { getOtherCategoriesAction } from "../../actions/registry/registry.other_categories.actions";
import {
  EXPORT_CONTACTS_MAILER_PLUGIN,
  EXPORT_CONTACTS_PLUGIN,
} from "../../api/user.api";
import usePermissions from "../../hooks/usePermissions";
import { getFeatures } from "../../libs/features";
import InvestmentRoundsForm from "../form/InvestmentRoundsForm";
import FlatV2Picker from "../../components/FlatV2Picker";
import LimitedFeatureBanner from "../../design-library/LimitedFeatureBanner/LimitedFeatureBanner.component";
import ContactSalesModal from "../../design-library/ContactSalesModal/ContactSalesModal.component";
import { getConsumptionApi } from "../../api/ecommerce.api";
import FunctionPicker from "../../components/FunctionPicker/FunctionPicker";
import { exportContactSearchToMailerApi } from "../../api/contact.api";
import { getUserInfoAction } from "../../actions";
import { attachTagToContactSearchApi } from "../../api/tag.api";
import getPluginConsumption from "../../libs/getPluginConsumption";
import getUserPlugins from "../../libs/getUserPlugins";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const PAGE_SIZE = 20;
const { confirm } = AntModal;

const ContactSearch = (props) => {
  const features = getFeatures();

  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const dispatch = useDispatch();
  const location = useLocation();
  const permissions = usePermissions();

  const [open, setOpen] = useState([1]);
  const [dataForm, setDataForm] = useState({ company_filters: {} });
  const [companyFilters, setCompanyFilters] = useState({});
  const [showOther, setShowOther] = useState(false);
  const [moveSearchButton, setMoveSearchButton] = useState(false);
  const [filters, setFilters] = useState({});
  const [order, setOrder] = useState("modified_at");
  const [orderDir, setOrderDir] = useState("desc");
  const [city, setCity] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [isHR, setIsHR] = useState(false);

  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  // TAG
  const [tagMode, setTagMode] = useState(false);
  const [tagAddModal, setAddTagModal] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [colorValue, setColorValue] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedContact, setSelectedContact] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [mailerExportLoading, setMailerExportLoading] = useState(false);

  const [selectedSearchElement, setSelectedSearchElement] = useState(null);
  const [searchName, setSearchName] = useState(null);
  const [showPopoverSearch, setShowPopoverSearch] = useState(false);
  // const [searchListWithCache, setSearchListWithCache] = useState([]);
  const [refetchedSearch, setRefetchedSearch] = useState(false);
  const [consumption, setConsumption] = useState(null);

  const [exportLimitedModal, setExportLimitedModal] = useState(false);

  const resultSearch = useSelector((state) => state.search.results.data);
  const mainCategory = useSelector((state) => state.main_category.data);
  const token = useSelector((state) => state.auth.token);
  const sector = useSelector((state) => state.sector.data);
  const functions = useSelector((state) => state.functionReg.data);
  const tags = useSelector((state) => state.tag.tagsList);
  const searchList = useSelector((state) => state.search.searchList.data);
  const searchCreated = useSelector((state) => state.search.searchCreated.data);
  const citiesList = useSelector((state) => state.search.places.cities);
  const regionsList = useSelector((state) => state.search.places.regions);
  const provincesList = useSelector((state) => state.search.places.provinces);
  const otherCategories = useSelector((state) => state.other_categories.data);
  const loadingExport = useSelector(
    (state) => state.contact.exportList.loading
  );

  const consumptionPlugin =
    props.user.licence.tenants[tenant]?.plugins?.["6538ca30695c4015d3daeb7c"];

  const [companyCitiesList, setCompanyCitiesList] = useState(null);

  async function loadCompanyCities() {
    const cities = await getPlaceListApi({
      type: "cities",
      isContactsList: false,
    });
    setCompanyCitiesList(cities);
  }

  useEffect(() => {
    if (companyCitiesList === null) {
      loadCompanyCities();
    }
  }, [companyCitiesList]);

  const ids = [];

  const loadingTagsIdsContact = useSelector(
    (state) => state.tag.tagsForIdsContact.loading
  );
  const loadingSearch = useSelector((state) => state.search.results.loading);
  const associatedTagsIdsContact = useSelector(
    (state) => state.tag.tagsForIdsContact.data
  );

  async function loadConsumption() {
    try {
      if ((permissions.ecommerce || !!consumptionPlugin) && !consumption) {
        let periodStartDate = null;
        if (consumptionPlugin) {
          const period = consumptionPlugin.data.period === "month" ? 1 : 12;
          const firstDate = consumptionPlugin.data.periodStartDateTime;
          periodStartDate = moment(firstDate);
          while (
            periodStartDate.isBefore(moment().subtract(period, "months"))
          ) {
            periodStartDate.add(period, "months");
          }
          periodStartDate = periodStartDate.valueOf();
        }
        const res = await getConsumptionApi({ periodStartDate });
        setConsumption(res.data?.consumption);
      }
    } catch (e) {}
  }
  useEffect(() => {
    loadConsumption();
  }, [permissions.ecommerce]);

  useEffect(() => {
    if (mainCategory.length == 0) {
      dispatch(getMainCategoryAction());
    }
  }, [mainCategory]);

  useEffect(() => {
    if (otherCategories.length == 0) {
      dispatch(getOtherCategoriesAction());
    }
  }, [otherCategories]);

  useEffect(() => {
    if (sector.length == 0) {
      dispatch(getTreeSectorAction());
    }
  }, [sector]);

  useEffect(() => {
    // lista dei tag
    if (permissions.favourites) {
      dispatch(getTagAction());
    }
  }, [tagAddModal, permissions.favourites]);

  const callback = (key) => {
    setOpen(key);
  };

  const checkIfIsHrProfile = () => {
    const userProfiles = props.user?.licence?.tenants?.[tenant]?.profiles;
    if (userProfiles) {
      Object.keys(userProfiles).map((profile) => {
        if (userProfiles[profile].name === "hr_database") setIsHR(true);
      });
    }
  };

  useEffect(() => {
    if (permissions.savedSearch) {
      dispatch(getSearchListAction({ params: "entity=contact" }));
    }
  }, [permissions.savedSearch]);

  useEffect(() => {
    dispatch(resetSearchAction());
    checkIfIsHrProfile();
    dispatch(getCitiesListAction({ type: "cities", isContactsList: true }));
    dispatch(getRegionsListAction({ type: "regions", isContactsList: true }));
    dispatch(
      getProvincesListAction({ type: "provinces", isContactsList: true })
    );
  }, []);

  const getSearchElements = async () => {
    let refetch;
    // let tempList = [...searchList];
    for (const search of searchList) {
      if (!search.cache) {
        refetch = true;
        const resp = await getSingleSavedSearch({
          headers: {
            ...headersBuilder(),
            Authorization: `Bearer ${token}`,
          },
          id: search._id,
        });
        // tempList[searchList.findIndex((x) => x._id === search._id)] = resp;
      }
    }
    // setSearchListWithCache(tempList);
    if (refetch && !refetchedSearch) {
      dispatch(getSearchListAction({ params: "entity=contact" }));
      setRefetchedSearch(true);
    }
  };
  useEffect(() => {
    if (searchList && searchList.length > 0) getSearchElements();
  }, [searchList]);

  const renderFunctionChild = (array) => {
    const result = [];
    forEach(array, (el, k) => {
      if (el.active) {
        result.push({
          title: translateRegistryOnView(el.id, "function", true)
            ? translateRegistryOnView(el.id, "function", true)
            : el.id,
          value: el.id,
          key: el.id,
        });
      }
    });
    return result;
  };

  useEffect(() => {
    if (functions.length === 0) {
      dispatch(getFunctionAction());
    }
  }, [functions]);

  function showExportLimitedModal() {
    setExportLimitedModal(true);
  }

  function buildSearchParams() {
    const dataParams = { function_parent: [], function: [] };
    const query = queryString.parse(location.search);
    if (query.key && !dataParams.surname) dataParams.surname = query.key;
    Object.keys(dataForm).forEach((p) => {
      if (Array.isArray(dataForm[p])) {
        if (dataForm[p].length > 0) {
          dataParams[p] = dataForm[p];
        }
      } else if (dataForm[p] && dataForm[p].length > 0) {
        dataParams[p] = dataForm[p];
      } else if (dataForm[p] === true) {
        dataParams[p] = dataForm[p];
      }
    });

    if (dataParams.function?.length) {
      const parentToSend = [];
      const childToSend = [];
      dataParams.function.forEach((val) => {
        if (functions?.enum.find((fnc) => fnc.id === val))
          childToSend.push(val);
        else parentToSend.push(val);
      });
      dataParams.function_parent = parentToSend;
      dataParams.function = childToSend;
    }

    if (order) {
      dataParams.sort_key = order;
      dataParams.sort_direction = orderDir;
    }

    if (city) dataParams.city = city;

    dataParams.company_filters = {};
    if (companyCity) dataParams.company_filters.city = companyCity;
    if (!isEmpty(companyFilters)) {
      const compFilt = {};
      Object.keys(companyFilters).forEach((p) => {
        if (Array.isArray(companyFilters[p])) {
          if (companyFilters[p].length > 0) {
            compFilt[p] = companyFilters[p];
          }
        } else if (typeof companyFilters[p] === "number") {
          compFilt[p] = companyFilters[p];
        } else if (companyFilters[p] && companyFilters[p].length > 0) {
          compFilt[p] = companyFilters[p];
        }
      });
      if (companyCity) compFilt.city = companyCity;

      if (compFilt.financial_filters && compFilt.financial_filters?.length) {
        dataParams.financial_filters = [...compFilt.financial_filters];
        delete compFilt.financial_filters;
      } else dataParams.company_filters = { ...compFilt };
      // setDataForm({
      //   ...dataForm,
      //   function: dataParams.function.concat(dataParams.function_parent),
      //   company_filters: { ...compFilt },
      //   financial_filters: dataParams.financial_filters
      //     ? dataParams.financial_filters
      //     : [],
      // });
    }

    setFilters(dataParams);
    // setShowOther(false);
    // setMoveSearchButton(false);
    dataParams.pageNum = 1;
    dataParams.limit = PAGE_SIZE;
    if (!dataParams.function?.length) delete dataParams.function;
    if (!dataParams.function_parent?.length) delete dataParams.function_parent;
    return dataParams;
  }

  const searchRequest = () => {
    const params = buildSearchParams();
    dispatch(searchContactsAction({ data: params }));
  };

  useEffect(() => {
    searchRequest();
  }, [
    dataForm,
    city,
    companyCity,
    selectedSearchElement,
    searchCreated,
    orderDir,
    order,
    location.key,
  ]);

  const handleOrder = (val) => {
    if (val === order) {
      setOrderDir(orderDir == "asc" ? "desc" : "asc");
    } else {
      setOrderDir("asc");
    }
    setOrder(val);
  };

  const onExportContacts = async () => {
    const dataParams = { ...filters };
    delete dataParams.pageNum;
    delete dataParams.sort_direction;
    delete dataParams.sort_key;
    delete dataParams.limit;
    if (props.user.group === "SUPER-ADMIN" || props.user.group === "ADMIN")
      dispatch(exportContactsAction({ data: dataParams }));
    else {
      let creditsLeft = 0;
      if (permissions.exportContactsV2) {
        const pluginName = "ExportContactsV2";
        const plugin = getUserPlugins(props.user).find(
          (p) => p.name === pluginName
        );
        creditsLeft =
          (plugin?.data?.contactLimit || 0) -
          (
            await getPluginConsumption({
              user: props.user,
              pluginName,
            })
          )?.contactExport?.count;
      } else {
        const creditData = Object.values(
          props?.user?.licence?.tenants[tenant]?.plugins
        )?.find((plugin) => plugin.name === EXPORT_CONTACTS_PLUGIN)?.data;
        creditsLeft =
          creditData?.contactLimit - parseInt(creditData?.exportedCount, 10);
      }
      const content =
        creditsLeft > 0
          ? `${Localize(
              "TAG.EXPORT_MODAL_DESCRIPTION"
            )} ${creditsLeft}. ${Localize("TAG.EXPORT_CONTINUE")}`
          : Localize("ERROR.ERROR_EXPORT_CONTACTS");
      return confirm({
        title: Localize("TAG.EXPORT_MODAL_TITLE"),
        content,
        okButtonProps: { disabled: creditsLeft <= 0 },
        cancelText: Localize("COMMON.CANCEL"),
        onOk() {
          dispatch(exportContactsAction({ data: dataParams }));
        },
      });
    }
  };

  function onExportContactsToMailer() {
    const params = buildSearchParams();
    async function doExport() {
      setMailerExportLoading(true);
      try {
        await exportContactSearchToMailerApi(params);
        dispatch(getUserInfoAction());
      } catch (e) {
        console.error(e);
        let errorText;
        if (e.response?.data?.clientType)
          errorText =
            l(`MAILER_INTEGRATION.ERROR.${e.response?.data?.clientType}`) ||
            l("MAILER_INTEGRATION.ERROR.UNKNOWN");
        AntModal.info({
          title: l("MAILER_INTEGRATION.ERROR"),
          content: errorText,
        });
      }
      setMailerExportLoading(false);
    }
    if (props.user.group === "SUPER-ADMIN" || props.user.group === "ADMIN")
      doExport();
    else {
      const creditData = Object.values(
        props?.user?.licence?.tenants[tenant]?.plugins
      )?.find((plugin) => plugin.name === EXPORT_CONTACTS_MAILER_PLUGIN)?.data;
      const numberOfCredits =
        creditData?.contactLimit - parseInt(creditData?.exportedCount, 10);
      const content =
        numberOfCredits > 0
          ? `${Localize(
              "TAG.EXPORT_MODAL_DESCRIPTION"
            )} ${numberOfCredits}. ${Localize("TAG.EXPORT_CONTINUE")}`
          : Localize("ERROR.ERROR_EXPORT_CONTACTS");
      confirm({
        title: Localize("TAG.EXPORT_MODAL_TITLE"),
        content,
        okButtonProps: { disabled: numberOfCredits <= 0 },
        cancelText: Localize("COMMON.CANCEL"),
        onOk() {
          doExport();
        },
      });
    }
  }

  const orderLabel = (value, key) => {
    return (
      <Button
        type="link"
        className={`${
          order === value ? "selected" : ""
        } searchPage__orders-button`}
        onClick={() => handleOrder(value)}
      >
        {key}
        {order === value && (
          <span className="searchPage__orders-icon">
            {orderDir === "asc" ? <UpOutlined /> : <DownOutlined />}
          </span>
        )}
      </Button>
    );
  };

  const getContactPageIds = () => {
    // ottengo gli id della pagina per capire quali sono i tag associati per i singoli contatti
    if (resultSearch.contacts && resultSearch.contacts.data) {
      resultSearch.contacts.data.forEach((contact) => {
        ids.push(contact._id);
      });
    }
    dispatch(associationsTagIdContactAction({ data: { id: ids } }));
  };

  useEffect(() => {
    // prendo gli id ogni volta che cambia la lista contatti e quando apro/chiudo la tendina tag
    if (permissions.favourites) {
      getContactPageIds();
    }
  }, [resultSearch?.contacts?.data, tagMode, permissions.favourites]);

  const clearTagFields = () => {
    // svuoto i campi della modale di creazione tag
    setTagValue("");
    setColorValue("");
    setErrorMessage("");
  };

  const createTag = () => {
    // params e action per creare il tag dalla modale
    const params = {
      name: tagValue,
      color: colorValue.hex.replace("#", "").toUpperCase(),
    };

    dispatch(createTagAction({ data: params }));
    clearTagFields();
  };

  const associateTagContact = () => {
    // params e action per associare il tag ai contatti selezionati
    const dataParams = {};
    let id = "";

    tags.forEach((tag) => {
      if (tag.name === selectedTag) {
        id = tag._id;
      }
    });

    dataParams.name = selectedTag; // tag
    dataParams.contacts = selectedContact; // lista contatti
    dispatch(associateTagAction({ id, data: dataParams }));
    setTagMode(false); // chiude la sezione dei tag
    setSelectedTag(""); // svuota i contatti selezionati
  };

  async function associateAll(e) {
    let id;

    tags.forEach((tag) => {
      if (tag.name === selectedTag) {
        id = tag._id;
      }
    });
    console.log("id", id);
    if (id) {
      const params = buildSearchParams();
      await attachTagToContactSearchApi({ data: params, tagId: id });
      setTagMode(false); // chiude la sezione dei tag
      setSelectedTag(""); // svuota i contatti selezionati
    }
  }

  const renderSearchButton = () => (
    <Row justify="end" align="bottom" style={{ height: "100%" }}>
      {permissions.savedSearch ? (
        <>
          <SearchManagement
            type={"contacts"}
            searchList={orderBy(searchList, "created_at", "desc")}
            value={selectedSearchElement}
            onDeleteSearch={() => {
              dispatch(
                deleteSearchAction({
                  id: selectedSearchElement,
                  type: "contact",
                })
              );
              setSelectedSearchElement(null);
              setCity(null);
              setCompanyCity(null);
              setDataForm({ company_filters: {}, city: null });
              setCompanyFilters({ city: null });
            }}
            onSearchElementChange={(id, data) => {
              setSelectedSearchElement(id);
              let dataForm = { ...data };
              if (dataForm.function || dataForm.function_parent) {
                if (!dataForm.function) dataForm.function = [];
                if (dataForm.function_parent) {
                  dataForm.function = dataForm.function.concat(
                    dataForm.function_parent
                  );
                  delete dataForm.function_parent;
                }
              }
              if (data?.company_filters?.city)
                setCompanyCity(data?.company_filters?.city);
              setCity(data?.city);
              setDataForm(
                dataForm ? dataForm : { company_filters: {}, city: null }
              );
              setCompanyFilters(
                data?.company_filters ? data?.company_filters : { city: null }
              );
              setCompanyCity(data?.company_filters?.city);
            }}
          />
          <Popover
            placement="topLeft"
            title={Localize("PAGES.SEARCH.SEARCH_NAME")}
            content={searchNameContent}
            trigger="click"
            visible={showPopoverSearch}
            onVisibleChange={(visibility) => setShowPopoverSearch(visibility)}
          >
            <Button
              type="primary"
              // className="mr-3"
              onClick={() => setShowPopoverSearch(true)}
            >
              <SaveOutlined />
              {Localize("PAGES.SEARCH.SAVE_SEARCH")}
            </Button>
          </Popover>
        </>
      ) : null}
      {/* <Button type="primary" onClick={() => searchRequest()}>
        <SearchOutlined />
        {Localize("COMMON.SEARCH")}
      </Button> */}
    </Row>
  );

  const searchNameContent = () => (
    <Row>
      <Col span={16}>
        <Input
          value={searchName}
          onChange={(e) => {
            setSearchName(e.target.value);
          }}
        />
      </Col>
      <Col align="right" span={8}>
        <Button
          disabled={!searchName}
          type="primary"
          onClick={() => {
            const dataParams = { ...filters };
            delete dataParams.pageNum;
            delete dataParams.sort_direction;
            delete dataParams.sort_key;
            dispatch(
              createSearchAction({
                body: { data: dataParams, name: searchName, type: "contact" },
              })
            );
            setShowPopoverSearch(false);
            setSearchName(null);
            setRefetchedSearch(false);
          }}
        >
          {Localize("COMMON.SAVE")}
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <BannerTop small>
        <h1 className="mt-4 orange-title">
          {Localize("PAGES.CONTACTS.TITLE")}
        </h1>
      </BannerTop>
      <div className="searchPage__filters animation fadein-up slow \">
        <Card
          bordered={false}
          style={{ borderRadius: "10px" }}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          <Form
            name="form-contact"
            initialValues={dataForm}
            onChange={({ values, errors, completed }) => {
              setDataForm({
                ...dataForm,
                ...values,
                ...errors,
              });
            }}
          >
            {({
              values,
              errors,
              completed,
              updateValue: updateValueParent,
            }) => (
              <>
                <Row gutter={16}>
                  <Col span={8}>
                    <Label
                      className="filter-color"
                      value={Localize("SCHEMA.DEFAULT.CONTACT.SURNAME")}
                    />
                    <Input
                      value={dataForm.surname}
                      onChange={(e) => {
                        updateValueParent({
                          key: "surname",
                          value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Label
                      className="filter-color"
                      value={Localize("SCHEMA.DEFAULT.CONTACT.TITLE")}
                    />
                    <Input
                      value={dataForm.title}
                      onChange={(e) => {
                        updateValueParent({
                          key: "title",
                          value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Label
                      className="filter-color"
                      value={Localize("SCHEMA.DEFAULT.CONTACT.FUNCTION")}
                    />
                    <FunctionPicker
                      value={dataForm.function}
                      onChange={(value) =>
                        updateValueParent({ key: "function", value })
                      }
                    />
                  </Col>
                  <Col span={8} className="mt-3">
                    <Label
                      className="filter-color"
                      value={Localize("COMMON.CITY")}
                    />
                    <PlaceAutocomplete
                      disableOptions={loadingSearch}
                      data={citiesList?.sort().filter((el) => el !== "")}
                      value={dataForm.city}
                      onChange={(value) => {
                        updateValueParent({ key: "city", value });
                      }}
                    />
                  </Col>
                  <Col span={8} className="mt-3">
                    <Label
                      className="filter-color"
                      value={Localize("COMMON.PROVINCE")}
                    />
                    <PlaceAutocomplete
                      disableOptions={loadingSearch}
                      data={provincesList?.sort().filter((el) => el !== "")}
                      value={dataForm.province}
                      onChange={(value) => {
                        updateValueParent({ key: "province", value });
                      }}
                    />
                  </Col>
                  <Col span={8} className="mt-3">
                    <Label
                      className="filter-color"
                      value={Localize("COMMON.REGION")}
                    />
                    <PlaceAutocomplete
                      disableOptions={loadingSearch}
                      data={regionsList?.sort().filter((el) => el !== "")}
                      value={dataForm.region}
                      onChange={(value) => {
                        updateValueParent({ key: "region", value });
                      }}
                    />
                  </Col>
                  <Col span={8} className="mt-3">
                    <Row align="bottom" style={{ height: "100%" }}>
                      <Checkbox
                        checked={dataForm.emailExists}
                        onChange={(value) => {
                          updateValueParent({
                            key: "emailExists",
                            value: value.target.checked,
                          });
                        }}
                      >
                        <span className="filter-color">
                          {Localize("PAGES.SEARCH.ONLY_WITH_MAIL")}
                        </span>
                      </Checkbox>
                    </Row>
                  </Col>
                  {features.comments && permissions.comments && (
                    <Col span={8} className="mt-3">
                      <Row align="bottom" style={{ height: "100%" }}>
                        <Checkbox
                          checked={dataForm.commentsExists}
                          onChange={(value) => {
                            updateValueParent({
                              key: "commentsExists",
                              value: value.target.checked,
                            });
                          }}
                        >
                          <span className="filter-color">
                            {Localize("PAGES.SEARCH.ONLY_WITH_COMMENTS")}
                          </span>
                        </Checkbox>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row justify="space-between">
                  <Col>
                    <Button
                      type="link"
                      onClick={() => {
                        setMoveSearchButton(!moveSearchButton);
                        setShowOther(!showOther);
                      }}
                      className="secondary mt-3 pl-2"
                    >
                      <FilterOutlined className="mr-1" />
                      {Localize(
                        `COMMON.${!showOther ? "OTHER_FILTERS" : "CLOSE"}`
                      )}
                    </Button>
                  </Col>
                  <Col>{!moveSearchButton && renderSearchButton()}</Col>
                </Row>
                {showOther && (
                  <>
                    <div className="animation fadein-right slow">
                      <Form
                        name="form-contact-company"
                        className="animation fadein-right slow "
                        initialValues={companyFilters}
                        onChange={({ values, errors, completed }) => {
                          const newCompanyFilters = {
                            ...companyFilters,
                            ...values,
                            ...errors,
                          };
                          setCompanyFilters(newCompanyFilters);
                          setDataForm({
                            ...dataForm,
                            company_filters: newCompanyFilters,
                          });
                        }}
                      >
                        {({ values, errors, completed, updateValue }) => (
                          <>
                            <Collapse
                              style={{ marginLeft: "-15px" }}
                              onChange={callback}
                              ghost={true}
                              activeKey={open}
                            >
                              <Panel
                                header={Localize(
                                  "PAGES.SEARCH.ORDER.NOMINATION_AND_DATE"
                                )}
                                key="1"
                                className="pt-3"
                              >
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Label
                                      tooltip={Localize(
                                        "PAGES.SEARCH.ORDER.NEW_CONTACTS_DATE_RANGE_INFO"
                                      )}
                                      className="filter-color"
                                      value={Localize(
                                        "PAGES.SEARCH.ORDER.NEW_CONTACTS_DATE_RANGE"
                                      )}
                                    />
                                    <RangePicker
                                      style={{ width: "100%" }}
                                      format="DD-MM-YYYY"
                                      placeholder={[
                                        Localize("COMMON.START"),
                                        Localize("COMMON.END"),
                                      ]}
                                      className="mr-5"
                                      allowEmpty={[false, true]}
                                      defaultValue={[
                                        dataForm.newContactAfter &&
                                          moment(dataForm.newContactAfter),
                                        dataForm.newContactBefore &&
                                          moment(dataForm.newContactBefore),
                                      ]}
                                      onChange={(dates) => {
                                        const [dateStart, dateEnd] =
                                          dates || [];
                                        if (dateStart) {
                                          updateValueParent({
                                            key: "newContactAfter",
                                            value: dateStart.format(
                                              "YYYY-MM-DD"
                                            ),
                                          });
                                        } else {
                                          updateValueParent({
                                            key: "newContactAfter",
                                            value: "",
                                          });
                                        }
                                        if (dateEnd) {
                                          updateValueParent({
                                            key: "newContactBefore",
                                            value: dateEnd.format("YYYY-MM-DD"),
                                          });
                                        } else {
                                          updateValueParent({
                                            key: "newContactBefore",
                                            value: "",
                                          });
                                        }
                                      }}
                                    />
                                  </Col>
                                  <Col span={8}>
                                    <Label
                                      tooltip={Localize(
                                        "PAGES.SEARCH.ORDER.MODIFY_CONTACTS_DATE_RANGE_INFO"
                                      )}
                                      className="filter-color"
                                      value={Localize(
                                        "PAGES.SEARCH.ORDER.MODIFY_CONTACTS_DATE_RANGE"
                                      )}
                                    />
                                    <RangePicker
                                      style={{ width: "100%" }}
                                      format="DD-MM-YYYY"
                                      placeholder={[
                                        Localize("COMMON.START"),
                                        Localize("COMMON.END"),
                                      ]}
                                      className="mr-5"
                                      allowEmpty={[false, true]}
                                      defaultValue={[
                                        dataForm.modifiedContactAfter &&
                                          moment(dataForm.modifiedContactAfter),
                                        dataForm.modifiedContactBefore &&
                                          moment(
                                            dataForm.modifiedContactBefore
                                          ),
                                      ]}
                                      onChange={(dates) => {
                                        const [dateStart, dateEnd] =
                                          dates || [];
                                        if (dateStart) {
                                          updateValueParent({
                                            key: "modifiedContactAfter",
                                            value: dateStart.format(
                                              "YYYY-MM-DD"
                                            ),
                                          });
                                        } else {
                                          updateValueParent({
                                            key: "modifiedContactAfter",
                                            value: "",
                                          });
                                        }
                                        if (dateEnd) {
                                          updateValueParent({
                                            key: "modifiedContactBefore",
                                            value: dateEnd.format("YYYY-MM-DD"),
                                          });
                                        } else {
                                          updateValueParent({
                                            key: "modifiedContactBefore",
                                            value: "",
                                          });
                                        }
                                      }}
                                    />
                                  </Col>
                                  {permissions.contactNomination ? (
                                    <Col span={8}>
                                      <Row
                                        align="bottom"
                                        style={{ height: "100%" }}
                                      >
                                        <Checkbox
                                          checked={dataForm.nominationExists}
                                          onChange={(value) => {
                                            updateValueParent({
                                              key: "nominationExists",
                                              value: value.target.checked,
                                            });
                                          }}
                                        >
                                          <span className="filter-color">
                                            {Localize(
                                              "PAGES.SEARCH.ONLY_WITH_NOMINATION"
                                            )}
                                          </span>
                                        </Checkbox>
                                      </Row>
                                    </Col>
                                  ) : null}
                                </Row>
                              </Panel>
                              <Panel
                                header={Localize("COMMON.COMPANY")}
                                key="2"
                                className="pt-3"
                              >
                                <Row gutter={16}>
                                  <Col span={8}>
                                    <Label
                                      className="filter-color"
                                      value={Localize(
                                        "PAGES.SEARCH.ORDER.NAME"
                                      )}
                                    />
                                    <CompanyAutocomplete
                                      token={token}
                                      onChange={(value) => {
                                        updateValue({
                                          key: "id",
                                          value: value.map((e) => e?.id),
                                        });
                                      }}
                                    />
                                  </Col>
                                  <Col span={8}>
                                    <div>
                                      <Label
                                        className="filter-color"
                                        value={Localize(
                                          "PAGES.SEARCH.ORDER.CATEGORY"
                                        )}
                                      />
                                      <Select
                                        mode="multiple"
                                        allowClear={true}
                                        style={{ width: "100%" }}
                                        value={companyFilters.main_category}
                                        onChange={(value) =>
                                          updateValue({
                                            key: "main_category",
                                            value,
                                          })
                                        }
                                        showSearch
                                        options={
                                          mainCategory.enum &&
                                          orderBy(
                                            mainCategory.enum,
                                            ["id"],
                                            ["asc"]
                                          )
                                            .filter((o) => o.active)
                                            .map((e) => {
                                              return {
                                                value: e.id,
                                                label: translateRegistryOnView(
                                                  e.id,
                                                  "main_category",
                                                  true
                                                )
                                                  ? translateRegistryOnView(
                                                      e.id,
                                                      "main_category",
                                                      true
                                                    )
                                                  : e.id,
                                                key: e.id,
                                              };
                                            })
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col span={8}>
                                    <Label
                                      className="filter-color"
                                      value={Localize(
                                        "PAGES.SEARCH.ORDER.CITY"
                                      )}
                                    />
                                    <PlaceAutocomplete
                                      disableOptions={!companyCitiesList}
                                      data={companyCitiesList
                                        ?.sort()
                                        .filter((el) => el !== "")}
                                      value={companyFilters.city}
                                      onChange={(value) => {
                                        setCompanyCity(value);
                                      }}
                                      showSearch={true}
                                      singleValue={true}
                                    />
                                  </Col>
                                  <Col span={8} className="mt-3">
                                    <Label
                                      className="filter-color"
                                      value={Localize(
                                        "PAGES.SEARCH.ORDER.SECTOR"
                                      )}
                                    />
                                    <Tree
                                      translateEntity="Sector"
                                      valueData={companyFilters.sector}
                                      datatree={orderBy(
                                        sector,
                                        ["key"],
                                        ["asc"]
                                      )}
                                      onChange={(value) => {
                                        updateValue({ key: "sector", value });
                                      }}
                                      onSelect={(value, e) => {
                                        updateValue({ key: "sector", value });
                                      }}
                                    />
                                  </Col>
                                  {tenant !== "spain" && (
                                    <Col span={8} className="mt-3">
                                      <Label
                                        className="filter-color"
                                        value={Localize(
                                          "PAGES.SEARCH.ORDER.OTHER_CAT"
                                        )}
                                      />
                                      <Select
                                        mode="multiple"
                                        allowClear={true}
                                        style={{ width: "100%" }}
                                        value={companyFilters.other_categories}
                                        onChange={(value) =>
                                          updateValue({
                                            key: "other_categories",
                                            value,
                                          })
                                        }
                                        showSearch
                                        options={
                                          otherCategories.set &&
                                          orderBy(otherCategories.set, "id")
                                            .filter((e) => e.id.length > 0)
                                            .map((e, j) => {
                                              return {
                                                value: e.id,
                                                label: translateRegistryOnView(
                                                  e.id,
                                                  "other_categories",
                                                  true
                                                )
                                                  ? translateRegistryOnView(
                                                      e.id,
                                                      "other_categories",
                                                      true
                                                    )
                                                  : e.id,
                                                key: e.id,
                                              };
                                            })
                                        }
                                      />
                                    </Col>
                                  )}
                                  {features.atecoCode && (
                                    <Col span={8} className="mt-3">
                                      <Label
                                        className="filter-color"
                                        value={Localize(
                                          "PAGES.SEARCH.ORDER.ATECO_CODE"
                                        )}
                                      />
                                      <Input
                                        value={companyFilters.ateco_code}
                                        onChange={(e) => {
                                          updateValue({
                                            key: "ateco_code",
                                            value: e.target.value,
                                          });
                                        }}
                                      />
                                    </Col>
                                  )}
                                </Row>
                              </Panel>
                              {permissions.companyFinancials &&
                                features.companyFinancials && (
                                  <Panel
                                    header={Localize(
                                      "PAGES.SEARCH.ORDER.FINANCIAL_DATA"
                                    )}
                                    key="3"
                                    className="pt-3"
                                  >
                                    <FinancialForm
                                      onChange={(key, value) =>
                                        updateValueParent({ key, value })
                                      }
                                      dataForm={dataForm}
                                    />
                                  </Panel>
                                )}
                              <Panel
                                header={Localize(
                                  "PAGES.SEARCH.ORDER.ADDITION_INFO"
                                )}
                                key="3.5"
                                className="pt-3"
                              >
                                <InfoForm
                                  onChange={(key, value) =>
                                    updateValue({ key, value })
                                  }
                                  dataForm={companyFilters}
                                  isHR={isHR}
                                />
                              </Panel>
                              {features.companyInvestments &&
                                permissions.companyInvestments && (
                                  <Panel
                                    header={Localize(
                                      "PAGES.SEARCH.ORDER.ADEVERTISING_INVESTMENTS"
                                    )}
                                    key="5"
                                    className="pt-3"
                                  >
                                    <InvestmentForm
                                      onChange={(key, value) =>
                                        updateValueParent({ key, value })
                                      }
                                      dataForm={dataForm}
                                    />
                                  </Panel>
                                )}
                              {permissions.entityNews && features.entityNews ? (
                                <Panel
                                  header={Localize(
                                    "PAGES.SEARCH.COMPANY.ACQUISITIONS"
                                  )}
                                  key="12"
                                  className="pt-3"
                                >
                                  <Row gutter={16}>
                                    <Col span={16}>
                                      <Label
                                        className="filter-color"
                                        value={Localize(
                                          "PAGES.SEARCH.COMPANY.ACQUISITION_DATE"
                                        )}
                                      />
                                      <DatePicker.RangePicker
                                        style={{ width: "100%" }}
                                        format="DD-MM-YYYY"
                                        placeholder={[
                                          Localize("COMMON.FROM"),
                                          Localize("COMMON.TO"),
                                        ]}
                                        className="mr-5"
                                        allowEmpty={[false, true]}
                                        defaultValue={[
                                          companyFilters.acquisition_after &&
                                            moment(
                                              companyFilters.acquisition_after
                                            ),
                                          companyFilters.acquisition_before &&
                                            moment(
                                              companyFilters.acquisition_before
                                            ),
                                        ]}
                                        onChange={(dates) => {
                                          const [dateStart, dateEnd] =
                                            dates || [];
                                          if (dateStart) {
                                            updateValue({
                                              key: "acquisition_after",
                                              value: dateStart.toISOString(),
                                            });
                                          } else {
                                            updateValue({
                                              key: "acquisition_after",
                                              value: null,
                                            });
                                          }
                                          if (dateEnd) {
                                            updateValue({
                                              key: "acquisition_before",
                                              value: dateEnd.toISOString(),
                                            });
                                          } else {
                                            updateValue({
                                              key: "acquisition_before",
                                              value: null,
                                            });
                                          }
                                        }}
                                      />
                                    </Col>
                                    <Col span={8}>
                                      <Label
                                        className="filter-color"
                                        value={Localize(
                                          "PAGES.SEARCH.COMPANY.ACQUISITION_ROLE"
                                        )}
                                      />
                                      <Select
                                        allowClear={true}
                                        style={{ width: "100%" }}
                                        value={companyFilters.acquisition_role}
                                        onChange={(value) =>
                                          updateValue({
                                            key: "acquisition_role",
                                            value,
                                          })
                                        }
                                        onClear={() =>
                                          updateValue("acquisition_role", null)
                                        }
                                        showSearch
                                        options={[
                                          {
                                            value: "buyer",
                                            label: l(
                                              "PAGES.SEARCH.COMPANY.ACQUISITION_BUYER"
                                            ),
                                          },
                                          {
                                            value: "seller",
                                            label: l(
                                              "PAGES.SEARCH.COMPANY.ACQUISITION_SELLER"
                                            ),
                                          },
                                        ]}
                                      />
                                    </Col>
                                  </Row>
                                </Panel>
                              ) : null}
                              {permissions.investmentRounds &&
                                features.investmentRounds && (
                                  <Panel
                                    header={Localize(
                                      "PAGES.SEARCH.ORDER.INVESTMENT_ROUNDS"
                                    )}
                                    key="6"
                                    className="pt-3"
                                  >
                                    <InvestmentRoundsForm
                                      onChange={(key, value) =>
                                        updateValueParent({ key, value })
                                      }
                                      dataForm={dataForm}
                                    />
                                  </Panel>
                                )}
                              {features.collectiveAgreements &&
                                permissions.collectiveAgreements && (
                                  <Panel
                                    header={Localize(
                                      "PAGES.SEARCH.COLLECTIVE_AGREEMENTS"
                                    )}
                                    key="7"
                                    className="pt-3"
                                  >
                                    <FlatV2Picker
                                      entityName="CollectiveAgreement"
                                      placeholder={Localize(
                                        "PAGES.SEARCH.COLLECTIVE_AGREEMENTS"
                                      )}
                                      onChange={(value) => {
                                        console.log(value);
                                        updateValue({
                                          key: "collectiveAgreements",
                                          value: value.map((obj) => obj.id),
                                        });
                                      }}
                                      displayExtraField="identifier"
                                      selectedIds={
                                        companyFilters.collectiveAgreements
                                      }
                                      multi
                                    />
                                  </Panel>
                                )}
                            </Collapse>
                          </>
                        )}
                      </Form>
                    </div>
                    {moveSearchButton && renderSearchButton()}
                  </>
                )}
              </>
            )}
          </Form>
        </Card>
      </div>
      <div>
        <Row align="middle" className="searchPage__orders">
          {permissions.favourites ? (
            <Tooltip title={Localize("TAG.ASSOCIATE")}>
              <Button
                type="primary"
                shape="circle"
                onClick={() => setTagMode(!tagMode)}
                icon={
                  tagMode ? (
                    <StarFilled
                      className="mr-2 orange-title"
                      style={{ fontSize: 20, fontWeight: "normal" }}
                    />
                  ) : (
                    <StarOutlined
                      className="mr-2 orange-title"
                      style={{ fontSize: 20, fontWeight: "normal" }}
                    />
                  )
                }
              />
            </Tooltip>
          ) : null}
          <Col className="orange-title" style={{ fontWeight: "normal" }}>
            {Localize("COMMON.ORDER_BY")}
          </Col>
          <Col flex="none" className="grey-light-color">
            {orderLabel(
              "modified_at",
              Localize("PAGES.SEARCH.ORDER.LAST_MODIFY")
            )}
            {orderLabel("surname", Localize("PAGES.SEARCH.ORDER.NAME"))}
            {orderLabel(
              "function",
              Localize("PAGES.REGISTRY.SECTIONS.FUNCTION")
            )}
            {orderLabel(
              "company.name",
              Localize("PAGES.SEARCH.ORDER.COMPANY_NAME")
            )}
          </Col>
          <Col flex="auto">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <h2
                style={{ fontWeight: "bold" }}
                className="p-0 m-0 searchPage__orders-button orange-title"
              >
                {Localize("PAGES.SEARCH.CONTACTS_FOUND")}:{" "}
                <span className="orange-title">
                  {resultSearch.contacts && resultSearch.contacts.totalResults
                    ? resultSearch.contacts.totalResults
                    : 0}
                </span>
              </h2>
              {resultSearch?.contacts?.data?.length > 0 &&
                (permissions.downloadContacts || permissions.ecommerce) && (
                  <Button
                    type="primary"
                    className="ml-3 orange-title"
                    onClick={
                      permissions.downloadContacts
                        ? onExportContacts
                        : showExportLimitedModal
                    }
                    loading={loadingExport}
                  >
                    <DownloadOutlined className="mr-1" />
                    {Localize("TAG.EXPORT_CONTACTS")}
                  </Button>
                )}
              {resultSearch?.contacts?.data?.length > 0 &&
                permissions.activemailer && (
                  <Button
                    type="primary"
                    className="ml-3 orange-title"
                    onClick={onExportContactsToMailer}
                    loading={mailerExportLoading}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <ForwardToInboxIcon
                      fontSize="10"
                      sx={{ alignSelf: "center" }}
                      className="mr-1"
                    />
                    {Localize("MAILER_INTEGRATION.EXPORT_CONTACTS")}
                  </Button>
                )}
            </div>
          </Col>
        </Row>
        <div className="animation fadein-right slow">
          <CreateTagModal
            visible={tagAddModal}
            onCancel={() => {
              clearTagFields();
              setAddTagModal(false);
            }}
            okDisabled={!tagValue || !colorValue || errorMessage}
            onOk={() => {
              createTag();
              setAddTagModal(false);
            }}
            tagValue={tagValue}
            colorValue={colorValue}
            onTagChange={(e) => {
              const foundTag = tags.find(
                (tag) => tag.name.toLowerCase() === e.target.value.toLowerCase()
              );
              if (foundTag) {
                setErrorMessage(Localize("TAG.ERROR_MESSAGE"));
              } else {
                setErrorMessage("");
              }
              setTagValue(e.target.value);
            }}
            onColorChange={(color) => setColorValue(color)}
            errorMessage={errorMessage}
          />
          {tagMode && (
            <TagOptions
              onAddTagClick={() => setAddTagModal(true)} // apro modale
              tagList={tags} // lista dei tag nella select
              value={selectedTag} // tag selezionato dalla lista
              onTagChange={(value) => setSelectedTag(value)} // cambio tag selezionato
              onAssociateClick={associateTagContact} // azione al bottone "ASSOCIA"
              onAssociateAllClick={associateAll}
              associateDisabled={!selectedTag || selectedContact.length === 0} // bottone disabilitato se non c'ÃƒÆ’Ã‚Â¨ il tag E i non ci sono contatti da associare
              associateAllDisabled={!selectedTag}
              tooManyResultsToAssociateAll={
                !selectedTag || resultSearch?.contacts?.totalResults > 3000
              }
            />
          )}
        </div>
        <div>
          <ContactList
            data={(resultSearch.contacts && resultSearch.contacts.data) || []}
            resultsLimited={resultSearch?.contacts?.resultsLimited}
            pageSize={pageSize}
            setPageSize={setPageSize}
            total={resultSearch.contacts && resultSearch.contacts.totalResults}
            title={
              <>
                {Localize("PAGES.COMPANIES.TITLE")} (
                {resultSearch.contacts && resultSearch.contacts.totalResults})
              </>
            }
            onAssociateTag={() => getContactPageIds()}
            onDeleteTag={() => getContactPageIds()}
            filters={filters}
            tagMode={tagMode} // mostra stelle, seleziona tutto, blocca l'apertura della modale di dettaglio
            setSelectedContact={(id) => setSelectedContact(id)} // callback per comporre l'array di contatti selezionati
            loadingList={loadingSearch}
            loadingTag={loadingTagsIdsContact} // loader per le tag label
            associatedTagsIds={
              associatedTagsIdsContact && associatedTagsIdsContact
            } // risultato api per l'associazione contatto - tag per fare le label
            alreadyOpened={
              permissions.ecommerce || consumptionPlugin
                ? consumption?.contacts?.ids
                : []
            }
            onContactOpen={
              permissions.ecommerce || consumptionPlugin
                ? (id) => {
                    if (
                      consumption &&
                      !consumption?.contacts?.ids?.includes(id)
                    ) {
                      setConsumption({
                        ...(consumption || {}),
                        contacts: {
                          ...(consumption?.contacts || {}),
                          ids: [...(consumption?.contacts?.ids || []), id],
                        },
                      });
                    }
                  }
                : () => {}
            }
          />
          {resultSearch?.contacts?.resultsLimited ? (
            <LimitedFeatureBanner
              message={l("ECOMMERCE.SEARCH_RESULTS_LIMIT_TEXT")}
            />
          ) : null}
        </div>
      </div>
      <ContactSalesModal
        open={exportLimitedModal}
        dismiss={() => setExportLimitedModal(false)}
        mainMessage={l("ECOMMERCE.EXPORT_CONTACTS_LIMIT_TEXT")}
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps, {})(ContactSearch);
