import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Layout,
  Menu,
  Drawer,
  Button,
  Avatar,
  Dropdown,
  Modal,
} from "antd";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuOutlined from "@ant-design/icons/es/icons/MenuOutlined";
import LogoutOutlined from "@ant-design/icons/es/icons/LogoutOutlined";
import GlobalOutlined from "@ant-design/icons/es/icons/GlobalOutlined";
import Localize, { getCurrentLocale } from "../../libs/localization";
import Logo from "../../assets/images/blinko-azul.png";
import LogoHR from "../../assets/images/blinko-black.png";

import routes from "../../routes";
import "./profile.layout.scss";
import { get } from "../../libs/helpers/ioc";
import SearchBar from "../../pages/search/Searchbar/SearchBar";
import { goTo, setLanguageAction, updateAuth } from "../../actions";
import LocalizedFlag from "../../components/Localized/LocalizedFlag";
import Config from "../../Config";
import SmallTenantSelector from "../../components/SmallTenantSelector/SmallTenantSelector";
import { refreshPage } from "../../libs/utils";
import { logoutApi } from "../../api/auth.api";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
} from "../../libs/helpers/localstorage";
import { MenuUnfoldOutlined } from "@ant-design/icons";

const { Header, Content, Footer } = Layout;

const BOX_WIDTH = "1200px";

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

const ProfileLayout = (props) => {
  const {
    // user,
    title,
    children,
    history,
    // path,
    id,
    parent_id,
    sidebarCollapsed,
    setSidebarCollapsed,
    boxShadow = true,
    noPadding = false,
    showInMenu = false,
  } = props;

  const [isMobile, setIsMobile] = useState(null);
  const [visible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isHR, setIsHR] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const tenant = getFromLocalStorage("lefacWebTenant");

  const dispatch = useDispatch();

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const size = useWindowSize();

  const handleMenuChange = (_size) => {
    if (_size.width <= 575) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleMenuChange(size);
  }, [size]);

  useEffect(() => {
    const userProfiles = user?.licence?.tenants?.[tenant?.data]?.profiles;
    if (userProfiles) {
      Object.keys(userProfiles).map((profile) => {
        if (
          userProfiles[profile].name === "external_contact" ||
          userProfiles[profile].name === "hr_database"
        )
          setIsHR(true);
      });
    }
  }, [user]);

  const menu = routes.filter((r) => r.profile);
  const permissions = get("scopes");

  console.log(permissions, menu);

  const filteredMenu = menu.filter((m) =>
    permissions && m.scopes && m.scopes.length > 0
      ? (m.scopes || []).some((s) =>
          Object.keys(permissions || {}).some((p) => p === s)
        )
      : true
  );

  const drawMenuItem = (i) =>
    i.children ? (
      <Menu.SubMenu key={i.id} icon={i.icon} title={i.title}>
        {i.children.map((j) => drawMenuItem(j))}
      </Menu.SubMenu>
    ) : (
      <Menu.Item key={i.id} icon={i.icon}>
        <Link to={i.menuPath || i.path}>{i.title}</Link>
      </Menu.Item>
    );

  return (
    <Layout className="profileLayout">
      {isMobile && (
        <Button
          type="primary"
          className="profileLayout__mobileMenuButton"
          onClick={() => showDrawer()}
        >
          <MenuOutlined />
        </Button>
      )}
      <Header className="profileLayout__header">
        <Row type="flex" justify="center">
          <Col span={24} style={{ maxWidth: BOX_WIDTH }}>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="profileLayout__header-content"
            >
              <Col span={12}>
                <Row type="flex" justify="start" align="middle">
                  <img
                    className="profileLayout__header-logo"
                    alt="logo"
                    src={!isHR ? Logo : LogoHR}
                    onClick={() => dispatch(goTo("/"))}
                  />
                  <SearchBar />
                </Row>
              </Col>
              <Col span={12}>
                <Row type="flex" justify="end" align="middle">
                  <Dropdown
                    className="dropdown-language"
                    overlay={
                      <Menu>
                        {(Config.locales || []).map((l, i) => (
                          <Menu.Item key={i}>
                            <Button
                              type="link"
                              onClick={() => {
                                dispatch(setLanguageAction({ language: l }));
                                window.location.reload();
                              }}
                            >
                              {LocalizedFlag({
                                locale: l,
                                style: { height: 10 },
                              })}
                            </Button>
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    trigger={["click"]}
                    placement="bottomCenter"
                    arrow
                    style={{ top: "100px" }}
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                      style={{ marginRight: 30 }}
                    >
                      {LocalizedFlag({
                        locale: getCurrentLocale(),
                      })}
                    </a>
                  </Dropdown>
                  <Dropdown
                    className="dropdown-language"
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="0"
                          style={{
                            textAlign: "center",
                            borderBottom: "1px solid #CCC",
                          }}
                        >
                          <b className="no-pointer">
                            {user && `${user.name} ${user.surname}`}
                          </b>
                        </Menu.Item>
                        <Menu.Item
                          key="1"
                          onClick={() => {
                            setShowModal(!showModal);
                          }}
                        >
                          <GlobalOutlined /> {Localize("LOGIN.CHANGE_TENANT")}
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          onClick={async () => {
                            await logoutApi();
                            dispatch(goTo("/logout/do"));
                          }}
                        >
                          <LogoutOutlined /> {Localize("LOGIN.LOGOUT")}
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                    placement="bottomCenter"
                    arrow
                    style={{ top: "100px" }}
                  >
                    <Avatar
                      size="large"
                      icon={<MenuUnfoldOutlined className="pointer" />}
                    />
                  </Dropdown>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        {!isMobile && (
          <Row type="flex" justify="center" className="profileLayout__menu">
            <Col span={24} style={{ maxWidth: BOX_WIDTH }}>
              <Menu
                mode="horizontal"
                defaultSelectedKeys={[id]}
                selectedKeys={[id]}
              >
                {filteredMenu.map((i) => drawMenuItem(i))}
              </Menu>
            </Col>
          </Row>
        )}
      </Header>

      <Drawer
        title="Basic Drawer"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={size.width}
      >
        <Link to="/login" onClick={onClose}>
          {Localize("COMMON.LOGIN")}
        </Link>
        <Menu mode="vertical" className="profileLayout__menu">
          {filteredMenu.map((i) => drawMenuItem(i))}
        </Menu>
      </Drawer>

      <Content className="">
        <Row type="flex" justify="center">
          <Col
            span={24}
            style={{ maxWidth: BOX_WIDTH }}
            className="profileLayout__content"
          >
            {children}
          </Col>
        </Row>
      </Content>
      <Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="profileLayout__footer"
      >
        <Row
          type="flex"
          justify="space-between"
          style={{ maxWidth: BOX_WIDTH, width: "100%" }}
        >
          &copy; BLINKO {new Date().getFullYear().toString()}
          <a style={{}} href="https://synesthesia.it" target="_blank">
            <img
              src="/madewithlove-syn_white.svg"
              alt="Made with heart in Synesthesia"
              height="15"
            />
          </a>
        </Row>
      </Footer>
      <Modal
        title={
          <>
            <GlobalOutlined /> {Localize("LOGIN.CHANGE_TENANT")}
          </>
        }
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <SmallTenantSelector
          onSelect={() => {
            setShowModal(false);
            refreshPage();
          }}
        />
      </Modal>
    </Layout>
  );
};

export default ProfileLayout;
