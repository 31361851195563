import React from "react";
import { Input, Modal } from "antd";
import { CirclePicker } from "react-color";
import Localize from "../Localized/Localize.comp";
import Label from "../Label/Label";

const CreateTagModal = ({
  visible,
  onCancel,
  onOk,
  okDisabled,
  tagValue,
  colorValue,
  onTagChange,
  onColorChange,
  errorMessage,
}) => {
  return (
    <Modal
      title={Localize("TAG.ADD_TAG")}
      visible={visible}
      cancelText={Localize("COMMON.CANCEL")}
      okText={Localize("COMMON.CREATE_NEW")}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{ disabled: okDisabled }}
    >
      <Label value={Localize("TAG.INSERT_NEW_TAG")} />
      <Input value={tagValue} onChange={onTagChange} />
      <p className="mt-1" style={{ color: "red" }}>
        {errorMessage}
      </p>
      <Label value={Localize("TAG.SELECT_COLOR")} className="mt-3" />
      <CirclePicker color={colorValue} onChange={onColorChange} />
    </Modal>
  );
};

export default CreateTagModal;
