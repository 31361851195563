export default class ErrorMessage extends Error {
  constructor(name, message, status, code, id, details, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(details, ...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorMessage);
    }

    this.name = name;
    this.message = message;
    this.details = details;
    this.status = status;
    this.code = code;
    this.id = id;
    this.date = new Date();
  }
}
