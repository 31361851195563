import axios from "axios";
import ErrorMessage from "../libs/helpers/ErrorMessage";
import Config from "../Config";
import { getFromLocalStorage } from "../libs/helpers/localstorage";

export const getPitchGraphApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/pitch/graph`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }

  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getPitchScoresApi = async ({ headers, data = {} }) => {
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  try {
    const res = await axios.post(
      `${Config.apiBaseurl}/api/v1/pitch/scores`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-tenant": tenant,
          ...headers,
        },
      }
    );

    return res.data;
  } catch (e) {
    if (e.response) {
      const { statusText, status } = e.response;
      const { httpStatus, clientType, description } = e.response.data;
      throw new ErrorMessage(
        statusText,
        description,
        httpStatus,
        status,
        clientType
      );
    } else throw e;
  }
};
