import React from "react";
import { Col, Row, List } from "antd";
import { Link } from "react-router-dom";
import Localize from "../../libs/localization";

const TagWidget = ({ tags }) => {
  const renderedTag = (item) => (
    <Row align="top" className=" p-0 mx-2" key={item._id}>
      <div
        className="homePage__tagsContainer__tagsCard-circle mr-3"
        style={{
          backgroundColor: `#${item.color}`,
        }}
      />
      <div>
        <Link to={{ pathname: "/tag", data: item }}>
          <h3 className="pt-1">{item.name}</h3>
          <div className="p-0 m-0" style={{ fontSize: 12 }}>
            <p>
              {Localize("PAGES.COMPANIES.TITLE")}: {item.companies}
            </p>
            <p>
              {Localize("PAGES.CONTACTS.TITLE")}: {item.contacts}
            </p>
          </div>
        </Link>
      </div>
    </Row>
  );
  return (
    <div>
      <div className="homePage__titleContainer">
        <h1 className="orange-title">Tags</h1>
      </div>
      <div className="homePage__tagsContainer__tagsCard">
        <List
          itemLayout="horizontal"
          dataSource={tags}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta description={renderedTag(item)} />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default TagWidget;
