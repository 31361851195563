import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfoAction } from "../../actions";
import { editProfileApi } from "../../api/user.api";
import BlinkoLogo from "../../design-library/BlinkoLogo";
import Button from "../../design-library/Button/Button.component";
import Radio from "../../design-library/Radio/Radio.component";
import InputLabel from "../../design-library/TextField/InputLabel.component";
import TextField from "../../design-library/TextField/TextField.component";
import { l } from "../../libs/localization";
import history from "../../store/history";

export function ProfileStep1({
  data,
  setData,
  disabled = false,
  fieldProps = {},
}) {
  return (
    <>
      <TextField
        placeholder={l("PROFILE.NAME")}
        label={l("PROFILE.NAME")}
        type="text"
        required
        value={data.firstName}
        onChange={(e) => setData({ ...data, firstName: e.target.value })}
        disabled={disabled}
        {...fieldProps}
      />
      <TextField
        placeholder={l("PROFILE.SURNAME")}
        label={l("PROFILE.SURNAME")}
        type="text"
        required
        value={data.lastName}
        onChange={(e) => setData({ ...data, lastName: e.target.value })}
        disabled={disabled}
        {...fieldProps}
      />
      <TextField
        placeholder={l("PROFILE.DATE_OF_BIRTH")}
        label={l("PROFILE.DATE_OF_BIRTH")}
        type="date"
        required
        value={data.birthDate}
        onChange={(e) => setData({ ...data, birthDate: e.target.value })}
        disabled={disabled}
        {...fieldProps}
      />
      <TextField
        placeholder={l("PROFILE.TELEPHONE")}
        label={l("PROFILE.TELEPHONE")}
        type="text"
        required
        value={data.phoneNumber}
        onChange={(e) => setData({ ...data, phoneNumber: e.target.value })}
        disabled={disabled}
        {...fieldProps}
      />
    </>
  );
}

function AddressFields({
  data,
  setData,
  label,
  required,
  disabled = false,
  fieldProps = {},
}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignSelf: "stretch",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          gap: 1,
        }}
      >
        <TextField
          placeholder={l("ADDRESS.STREET")}
          label={label}
          type="text"
          required={required}
          value={data.address.street}
          onChange={(e) =>
            setData({
              ...data,
              address: { ...(data.address || {}), street: e.target.value },
            })
          }
          disabled={disabled}
          {...fieldProps}
        />
        <TextField
          placeholder={l("ADDRESS.CITY")}
          hideLabel
          type="text"
          required={required}
          value={data.address.city}
          onChange={(e) =>
            setData({
              ...data,
              address: { ...(data.address || {}), city: e.target.value },
            })
          }
          disabled={disabled}
          {...fieldProps}
        />
        <TextField
          placeholder={l("ADDRESS.PROVINCE")}
          hideLabel
          type="text"
          required={required}
          value={data.address.province}
          onChange={(e) =>
            setData({
              ...data,
              address: { ...(data.address || {}), province: e.target.value },
            })
          }
          disabled={disabled}
          {...fieldProps}
        />
        <TextField
          placeholder={l("ADDRESS.REGION")}
          hideLabel
          type="text"
          required={required}
          value={data.address.region}
          onChange={(e) =>
            setData({
              ...data,
              address: { ...(data.address || {}), region: e.target.value },
            })
          }
          disabled={disabled}
          {...fieldProps}
        />
        <TextField
          placeholder={l("ADDRESS.COUNTRY")}
          hideLabel
          type="text"
          required={required}
          value={data.address.country}
          onChange={(e) =>
            setData({
              ...data,
              address: { ...(data.address || {}), country: e.target.value },
            })
          }
          disabled={disabled}
          {...fieldProps}
        />
      </Box>
    </>
  );
}

function IndividualFields({ data, setData, disabled = true, fieldProps = {} }) {
  return (
    <>
      <AddressFields
        data={data}
        setData={setData}
        label={l("BILLING.RESIDENTIAL_ADDRESS")}
        disabled={disabled}
        fieldProps={fieldProps}
      />
    </>
  );
}

function CompanyFields({ data, setData, disabled = false, fieldProps = {} }) {
  return (
    <>
      <TextField
        placeholder={l("BILLING.COMPANY_NAME")}
        label={l("BILLING.COMPANY_NAME")}
        type="text"
        required
        value={data.companyName}
        onChange={(e) => setData({ ...data, companyName: e.target.value })}
        disabled={disabled}
        {...fieldProps}
      />
      <TextField
        placeholder={l("BILLING.COMPANY_ADMINISTRATION_EMAIL")}
        label={l("BILLING.COMPANY_ADMINISTRATION_EMAIL")}
        type="email"
        required
        value={data.administrationEmail}
        onChange={(e) =>
          setData({ ...data, administrationEmail: e.target.value })
        }
        disabled={disabled}
        {...fieldProps}
      />
      <TextField
        placeholder={l("BILLING.ITALY.CODICE_FISCALE_P_IVA")}
        label={l("BILLING.ITALY.CODICE_FISCALE_P_IVA")}
        type="text"
        required
        value={data.countrySpecificInfo?.italy?.codiceFiscale || ""}
        onChange={(e) =>
          setData({
            ...data,
            countrySpecificInfo: {
              ...(data.countrySpecificInfo || {}),
              italy: {
                ...(data.countrySpecificInfo?.italy || {}),
                codiceFiscale: e.target.value,
              },
            },
          })
        }
        disabled={disabled}
        {...fieldProps}
      />
      <TextField
        placeholder={l("BILLING.FUNCTION")}
        label={l("BILLING.FUNCTION")}
        type="text"
        required
        value={data.function}
        onChange={(e) => setData({ ...data, function: e.target.value })}
        disabled={disabled}
        {...fieldProps}
      />
      <AddressFields
        data={data}
        setData={setData}
        label={l("BILLING.REGISTERED_OFFICE")}
        disabled={disabled}
        required
        fieldProps={fieldProps}
      />
    </>
  );
}

export function ProfileStep2({
  data,
  setData,
  disabled = false,
  fieldProps = {},
}) {
  return (
    <>
      <FormControl variant="standard" fullWidth {...fieldProps}>
        <InputLabel htmlFor="private-or-company">
          {l("BILLING.PERSON")}
        </InputLabel>
        <RadioGroup
          sx={{
            marginTop: 4,
          }}
          row
          name="row-radio-buttons-group"
          value={data?.isCompany}
          onChange={(e) =>
            setData({ ...data, isCompany: e.target.value === "true" })
          }
        >
          {!disabled || data?.isCompany === false ? (
            <FormControlLabel
              value="false"
              control={<Radio />}
              label={l("BILLING.PHYSICAL_PERSON")}
              disabled={disabled}
            />
          ) : null}
          {!disabled || data?.isCompany === true ? (
            <FormControlLabel
              value="true"
              control={<Radio />}
              label={l("BILLING.JURIDICAL_PERSON")}
            />
          ) : null}
        </RadioGroup>
      </FormControl>
      {data?.isCompany ? (
        <CompanyFields
          data={data}
          setData={setData}
          disabled={disabled}
          fieldProps={fieldProps}
        />
      ) : (
        <IndividualFields
          data={data}
          setData={setData}
          disabled={disabled}
          fieldProps={fieldProps}
        />
      )}
    </>
  );
}
const steps = [ProfileStep1, ProfileStep2];

export default function CompleteBillingInfoPage() {
  const theme = useTheme();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const [step, setStep] = useState(0);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [billingInfo, setBillingInfo] = useState({
    isCompany: false,
    address: {},
    countrySpecificInfo: {
      italy: {},
    },
  });
  useEffect(() => {
    if (user.billingInfo) {
      setBillingInfo({
        ...billingInfo,
        ...user.billingInfo,
        birthDate: user.billingInfo?.birthDate
          ? moment(user.billingInfo.birthDate).format("YYYY-MM-DD")
          : "",
      });
    }
  }, [user]);

  const CurrentStep = steps[step];

  function forward() {
    setStep(step + 1);
  }
  async function submit(e) {
    e.preventDefault();
    if (step < steps.length - 1) {
      forward();
      return;
    }
    setLoading(true);
    setError(null);
    try {
      await editProfileApi({
        name: billingInfo.firstName,
        surname: billingInfo.lastName,
        billingInfo,
      });
      dispatch(getUserInfoAction());
      setLoading(false);
      history.push("/confirmPlan");
    } catch (err) {
      setLoading(false);
      console.error(err);
      if (err.response?.data?.clientType) {
        setError(
          `${err.response.data.clientType}: ${err.response.data.description}`
        );
      } else {
        setError(err.message);
      }
    }
  }

  function back() {
    setStep(step - 1);
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 8,
        py: 8,
        width: "100%",
        maxWidth: "none !important",
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <Card sx={{ width: { xs: "100%", sm: "600px", md: "680px" } }}>
        <form onSubmit={submit}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              px: 5.5,
              py: 7.5,
              alignItems: "center",
              position: "relative",
            }}
          >
            <BlinkoLogo style={{ maxWidth: 220, alignSelf: "center" }} />
            <Typography sx={{ mt: 2.5, position: "relative" }} variant="h3">
              {l("REGISTER.COMPLETE_REGISTRATION")}
            </Typography>
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                width: `calc(100% + ${theme.spacing(5.5 * 2)})`,
                mx: -5.5,
                mt: 1,
                p: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontWeight: 700,
                  color: theme.palette.primary.contrastText,
                }}
              >
                {l("REGISTER.PERSONAL_INFO")}
              </Typography>
            </Box>
            {error ? (
              <Alert severity="error" sx={{ alignSelf: "stretch" }}>
                {error}
              </Alert>
            ) : null}
            <CurrentStep data={billingInfo} setData={setBillingInfo} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "stretch",
                flexWrap: "wrap",
                gap: 2,
                mt: 5.5,
              }}
            >
              {/* <Box sx={{ flex: 1, px: 2.75 }} /> */}
              <Button
                sx={{ flex: 1, visibility: step > 0 ? "visible" : "hidden" }}
                color="gray"
                onClick={back}
              >
                {l("REGISTER.PREV")}
              </Button>
              <Button sx={{ flex: 1 }} type={"submit"}>
                {l("REGISTER.NEXT")}
              </Button>
            </Box>
            {loading ? (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",

                  zIndex: 5,
                }}
              >
                <CircularProgress />
              </Box>
            ) : null}
          </CardContent>
          <div />
        </form>
      </Card>
    </Container>
  );
}
