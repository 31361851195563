import React, { useEffect, useState } from "react";
import { Card, Row, Col, Statistic, Spin } from "antd";
import { forEach, capitalize, isEmpty } from "lodash";
import { ResponsivePie } from "@nivo/pie";
import Tabs from "../../components/Tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyNielsenAction } from "../../actions/company.actions";
import Investments from "../../components/Investments/Investments";
import Localize from "../../components/Localized/Localize.comp";
import LogoArcemedia from "../../assets/images/arcemedia_logo.png";
import { getFromLocalStorage } from "../../libs/helpers/localstorage";

const CompanyInvestments = (props) => {
  const { companyId } = props;

  const nielsen = useSelector((state) => state.company.nielsen);
  const dispatch = useDispatch();
  const tenant = getFromLocalStorage("lefacWebTenant").data;

  useEffect(() => {
    dispatch(getCompanyNielsenAction({ id: companyId }));
  }, []);

  return (
    <>
      {nielsen.loading ? (
        <div className="text-center mt-5">
          <Spin className="mt-5" />
        </div>
      ) : (
        <Card
          title={
            <Row>
              <Col span="12">
                <h2 className={tenant === "spain" ? "m-0" : ""}>
                  <b>
                    {Localize("PAGES.COMPANIES.DETAIL.SECTIONS.INVESTMENTS")}
                  </b>
                </h2>
                {tenant === "spain" && (
                  <p style={{ fontSize: "0.9rem" }}>
                    {Localize("PAGES.REGISTRY.SECTIONS.SOURCE")}: Arce Media
                  </p>
                )}
              </Col>
              {tenant === "spain" && (
                <Col span="12" className="text-right my-auto">
                  <a href="http://www.arcemedia.es/" target="_blank">
                    <img
                      className="arcemedia-logo"
                      alt="logo-arcemedia"
                      src={LogoArcemedia}
                    />
                  </a>
                </Col>
              )}
            </Row>
          }
          bordered={false}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          {nielsen && nielsen.data && nielsen.data.history && (
            <Investments data={nielsen.data.history} />
          )}
        </Card>
      )}
    </>
  );
};

export default CompanyInvestments;
