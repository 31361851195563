import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import jwt from "jsonwebtoken";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AUTHORIZE_DONE, goTo, setLanguageAction } from "../../actions";
import { loginApi } from "../../api/auth.api";
import SelectTenant from "../../components/SelectTenant/SelectTenant.component";
import BlinkoLogo from "../../design-library/BlinkoLogo";
import Button from "../../design-library/Button/Button.component";
import TextField from "../../design-library/TextField/TextField.component";
import { bareLocalize, l } from "../../libs/localization";

const TENANT_TO_LANG = {
  italy: "it",
  spain: "es",
  france: "fr",
  portugal: "en",
};

export default function LoginPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tenant, setTenant] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  const query = new URLSearchParams(window.location.search);
  const redirectUri = query.get("redirectUri");

  useEffect(() => {
    if (user) {
      history.push("/");
    }
  }, [user]);

  async function submit(e) {
    e.preventDefault();
    if (!tenant) {
      setError(bareLocalize(`ERROR.NO_TENANT_SELECTED`));
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const res = await loginApi({ email, password });
      const { user, token, licence, scopes, error: error_ = true } = res || {};
      const decodedLicence = jwt.decode(licence);
      if (!error_) {
        if (
          !decodedLicence?.tenants?.[tenant]?.exp ||
          moment(decodedLicence.tenants[tenant].exp * 1000).isBefore(moment())
        ) {
          setError(bareLocalize(`ERROR.NO_RIGHTS_TO_TENANT`));
          setLoading(false);
          return;
        }
        dispatch({
          type: AUTHORIZE_DONE,
          data: {
            token,
            licence: decodedLicence,
            user,
            scopes,
          },
        });
        dispatch(setLanguageAction({ language: TENANT_TO_LANG[tenant] }));
        dispatch(goTo(redirectUri || "/"));
      } else {
        setError(bareLocalize(`ERROR.${res.clientType}`));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      if (err.response?.data?.clientType) {
        setError(err.response.data.clientType);
      } else {
        setError(err.message);
      }
    }
  }
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 8,
        py: 8,
        width: "100%",
        maxWidth: "none !important",
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <Card sx={{ width: { xs: "100%", sm: "600px", md: "680px" } }}>
        <form onSubmit={submit}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              px: 5.5,
              py: 7.5,
              alignItems: "center",
              position: "relative",
            }}
          >
            <BlinkoLogo style={{ maxWidth: 220, alignSelf: "center" }} />
            <Typography sx={{ mt: 2.5 }} variant="h3">
              {l("LOGIN.LOGIN")}
            </Typography>
            {error ? (
              <Alert severity="error" sx={{ alignSelf: "stretch" }}>
                {error}
              </Alert>
            ) : null}
            <TextField
              placeholder={l("LOGIN.EMAIL")}
              label={l("LOGIN.EMAIL")}
              type="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              placeholder={l("LOGIN.PASSWORD")}
              label={l("LOGIN.PASSWORD")}
              type="password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <SelectTenant currentTenant={tenant} setCurrentTenant={setTenant} />
            <Link
              sx={{
                color: "#565551",
                textAlign: "left",
                alignSelf: "stretch",
              }}
              underline="hover"
              href="/forgotPassword"
            >
              {l("LOGIN.FORGOT_PASSWORD")}
            </Link>
            <Button sx={{ mt: 12 }} type="submit">
              {l("LOGIN.LOGIN")}
            </Button>
            {/* <Typography variant="body1">
              {l("REGISTER.HAVE_NO_ACCOUNT_YET")}{" "}
              <Link href="/register" alt="register">
                {l("REGISTER.REGISTER")}
              </Link>
            </Typography> */}
            {loading ? (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",

                  zIndex: 5,
                }}
              >
                <CircularProgress />
              </Box>
            ) : null}
          </CardContent>
          <div />
        </form>
      </Card>
    </Container>
  );
}
