import React from "react";
import { Link } from "react-router-dom";
import { toUpper } from "lodash";
import Column from "antd/lib/table/Column";
import moment from "moment";
import { Table } from "antd";
import Localize from "../../libs/localization";

const PitchScoreList = (props) => {
  const { data, total, loadingList, options, setOptions } = props;

  const { page, pageSize } = options;

  const sdMap = {
    asc: "ascend",
    desc: "descend",
  };

  return (
    <>
      <Table
        loading={loadingList}
        scroll={{ x: 1000 }}
        onChange={(pagination, filters, sorter, extra) => {
          if (extra.action === "sort") {
            if (sorter.order) {
              setOptions({
                ...options,
                sortBy: sorter.columnKey,
                sortDirection: sorter.order === "ascend" ? "asc" : "desc",
              });
            } else {
              setOptions({
                ...options,
                sortBy: undefined,
                sortDirection: undefined,
              });
            }
          }
        }}
        dataSource={data.map((o) => {
          return {
            company: o.company,
            category: o.category?.key || o._id?.category?.id,
            endDate: moment(o.latestEndDate).format("DD/MM/YYYY"),
            scope: o.scope,
            currentAgency: o.latestPitch.winnerCompany,
            contractScore: `${o.score}%`,
          };
        })}
        pagination={{
          total,
          pageSize,
          current: page,
          onChange: (page_, pageSize_) => {
            setOptions({ ...options, page: page_, pageSize: pageSize_ });
          },
        }}
      >
        <Column
          title={
            <span style={{ fontWeight: "bold" }}>
              {Localize("PAGES.PITCH_SCORES.FILTERS.COMPANY")}
            </span>
          }
          dataIndex="company"
          render={(company) => {
            return (
              <Link to={`/companies/${company?.id}`}>
                {toUpper(company.name || company.id)}
              </Link>
            );
          }}
          key="company"
          sortDirections={["ascend", "descend"]}
          sorter
          sortOrder={
            options.sortBy === "company" && sdMap[options.sortDirection]
          }
        />
        <Column
          title={
            <span style={{ fontWeight: "bold" }}>
              {Localize("PAGES.PITCH_SCORES.FILTERS.CATEGORY")}
            </span>
          }
          dataIndex="category"
          key="category"
          sortDirections={["ascend", "descend"]}
          sorter
          sortOrder={
            options.sortBy === "category" && sdMap[options.sortDirection]
          }
        />
        <Column
          title={
            <span style={{ fontWeight: "bold" }}>
              {Localize("PAGES.PITCH_SCORES.FILTERS.END_DATE")}
            </span>
          }
          dataIndex="endDate"
          key="endDate"
          sortDirections={["ascend", "descend"]}
          sorter
          sortOrder={
            options.sortBy === "endDate" && sdMap[options.sortDirection]
          }
        />
        <Column
          title={
            <span style={{ fontWeight: "bold" }}>
              {Localize("PAGES.PITCH_SCORES.FILTERS.GEO_AREA")}
            </span>
          }
          dataIndex="scope"
          key="scope"
          sortDirections={["ascend", "descend"]}
          sorter
          sortOrder={options.sortBy === "scope" && sdMap[options.sortDirection]}
        />
        <Column
          title={
            <span style={{ fontWeight: "bold" }}>
              {Localize("PAGES.PITCH_SCORES.FILTERS.AGENCY")}
            </span>
          }
          dataIndex="currentAgency"
          render={(currentAgency) => {
            if (currentAgency?.name) {
              return (
                <Link to={`/companies/${currentAgency?.id}`}>
                  {toUpper(currentAgency?.name || currentAgency?.id)}
                </Link>
              );
            }
            return null;
          }}
          key="currentAgency"
        />
        <Column
          title={
            <span style={{ fontWeight: "bold" }}>
              {Localize("PAGES.PITCH_SCORES.FILTERS.CONTRACT_SCORE")}
            </span>
          }
          dataIndex="contractScore"
          key="score"
          sortDirections={["ascend", "descend"]}
          sorter
          sortOrder={options.sortBy === "score" && sdMap[options.sortDirection]}
        />
      </Table>
    </>
  );
};

export default PitchScoreList;
