const registry = {};

/**
 *
 * @author marco.denittis
 *
 * @param {String} name identifier
 * @param {*} service service to be registered for inversion of control
 */
const register = (name, service) => {
  registry[name] = service;
  return registry[name];
};

/**
 *
 * @author marco.denittis
 *
 * @param {String} name identifier of service to be retrieved
 */
const get = (name) => {
  return registry[name];
};

export { register, get };
