import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, DatePicker, Modal, Row, Select, Spin } from "antd";
import { getCompanyPitchAction } from "../../actions/company.actions";
import CardPitch from "../../components/Cards/Card.pitch";
import { getPitchGraphAction } from "../../actions/pitch.actions";
import PitchGraph from "../../components/PitchGraph/PitchGraph";
import Form from "antd/lib/form/Form";
import Localize from "../../libs/localization";
import moment from "moment";
import Label from "../../components/Label/Label";
import { isEmpty, orderBy } from "lodash";
import SearchOutlined from "@ant-design/icons/es/icons/SearchOutlined";
import Tree from "../../components/Tree/Tree";
import { getTreeCategoryAction } from "../../actions/registry/registry.category.actions";

const { RangePicker } = DatePicker;

const CompanyPitch = (props) => {
  const { companyId, companyName } = props;

  const [showBrandModal, setShowBrandModal] = useState(false);
  const [searchForm, setSearchForm] = useState({
    company_filters: { name: companyName },
  });
  const [selectedCategory, setSelectedCategory] = useState([]);

  const pitch = useSelector((state) => state.company.pitch);
  const pitchGraph = useSelector((state) => state.pitch.graph);
  const category = useSelector((state) => state.pitch_category.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(category) || category.length === 0) {
      dispatch(getTreeCategoryAction());
    }
    searchRequest();
  }, []);

  useEffect(() => {
    setSearchForm({
      ...searchForm,
      category: selectedCategory,
    });
  }, [selectedCategory]);

  const searchRequest = () => {
    let searchData = { ...searchForm };
    if (searchData.category?.length === 0) delete searchData.category;
    dispatch(getCompanyPitchAction({ data: { ...searchData, pageNum: 1 } }));
    dispatch(getPitchGraphAction({ data: searchData }));
  };

  const onMoreBrandClick = (title, content) => {
    Modal.info({
      title,
      content,
      width: "800px",
      onOk: () => setShowBrandModal(false),
    });
  };

  const onDateChange = (date) => {
    setSearchForm({
      ...searchForm,
      min_start_date: date?.[0],
      max_start_date: date?.[1],
    });
  };

  return (
    <>
      <div className="animation fadein-up slow \" style={{ width: "100%" }}>
        <Card
          bordered={false}
          style={{ borderRadius: "10px" }}
          className="companyPage__body-card companyPage__body-card--custom customCard"
        >
          <Form className="pitch_search" initialValues={searchForm}>
            <Row gutter={16} align="bottom">
              <Col span={8}>
                <Label
                  className="filter-color"
                  value={Localize("PAGES.SEARCH.ORDER.CATEGORY")}
                />
                <Tree
                  translateEntity="Category"
                  datatree={orderBy(category, ["key"], ["asc"])}
                  onChange={(value) => setSelectedCategory(value)}
                  onSelect={(value) => setSelectedCategory(value)}
                />
              </Col>
              <Col span={8}>
                <Label
                  className="filter-color"
                  value={Localize("COMMON.DATE")}
                />
                <RangePicker
                  format="DD-MM-YYYY"
                  placeholder={[
                    Localize("COMMON.START"),
                    Localize("COMMON.END"),
                  ]}
                  className="mr-5"
                  allowEmpty={[false, true]}
                  defaultValue={[
                    searchForm.min_start_date &&
                      moment(searchForm.min_start_date),
                    searchForm.max_start_date &&
                      moment(searchForm.max_start_date),
                  ]}
                  onChange={onDateChange}
                />
              </Col>
            </Row>
            <Row justify="end" align="bottom" style={{ height: "100%" }}>
              <Button type="primary" onClick={() => searchRequest()}>
                <SearchOutlined className="search-icn" />
                {Localize("COMMON.SEARCH")}
              </Button>
            </Row>
          </Form>
        </Card>
      </div>
      {pitchGraph && pitchGraph.length > 0 ? (
        <PitchGraph data={pitchGraph} />
      ) : null}
      {pitch.loading ? (
        <div className="text-center mt-5" style={{ width: "100%" }}>
          <Spin className="mt-5" />
        </div>
      ) : (
        <Col span={24} className="animation fadein-right slow">
          {pitch?.data && (
            <CardPitch
              data={pitch.data}
              elementToShow={10}
              onMoreBrandClick={onMoreBrandClick}
            />
          )}
        </Col>
      )}
    </>
  );
};

export default CompanyPitch;
