import React from "react";
import { alpha, InputBase as MUIInputBase, useTheme } from "@mui/material";

export default function InputBase({ ...props }) {
  const theme = useTheme();
  return (
    <MUIInputBase
      {...props}
      sx={{
        ...props.sx,
        "& .MuiInputBase-input": {
          border: "1px solid #ced4da",
          borderRadius: "4px",
          padding: "20px 22px",
          transition: theme.transitions.create([
            "border-color",
            "background-color",
            "box-shadow",
          ]),
          "&:focus": {
            boxShadow: `${alpha(
              theme.palette.primary.main,
              0.25
            )} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
            borderRadius: "4px",
          },
          ...props.sx["& .MuiInputBase-input"],
        },
      }}
    />
  );
}
