import axios from "axios";
import ErrorMessage from "../libs/helpers/ErrorMessage";
import Config from "../Config";
import { getFromLocalStorage } from "../libs/helpers/localstorage";

export const getTagApi = async ({ headers }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/tag`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getTagCompanyDetailApi = async ({ headers, id, pageNum }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/tag/${id}?entity=company&pageNum=${pageNum}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getTagContactDetailApi = async ({ headers, id, pageNum }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/tag/${id}?entity=contact&pageNum=${pageNum}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const createTagApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/tag`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  if (res.ok) {
    return;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const associateTagApi = async ({ headers, id, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/tag/${id}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    }
  );

  if (res.ok) {
    return;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const associationsTagIdCompanyApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/tag/associations?entity=company`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const associationsTagIdContactApi = async ({ headers, data }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/tag/associations?entity=contact`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const deleteTagApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/tag/${id}`,
    {
      method: "DELETE",
      headers,
    }
  );

  if (res.ok) {
    return;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const exportTagContactsApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/tag/${id}/contacts/export`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.blob();

  if (res.ok) {
    return response;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const deleteAssociatedIdTagApi = async ({
  headers,
  tagId,
  associatedId,
}) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.frontend.api}/tag/${tagId}/${associatedId}`,
    {
      method: "DELETE",
      headers,
    }
  );

  if (res.ok) {
    return;
  }

  const { status, statusText, httpStatus, clientType, description } = res;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const attachTagToCompanySearchApi = async ({ headers, tagId, data }) => {
  if (data.creation_year) data.creation_year = parseInt(data.creation_year, 10);
  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  try {
    const res = await axios.put(
      `${Config.apiBaseurl}/${Config.apis.frontend.api}/tag/${tagId}/company-search`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-tenant": tenant,
          ...headers,
        },
      }
    );

    return res.data;
  } catch (e) {
    if (e.response) {
      const { statusText, status } = e.response;
      const { httpStatus, clientType, description } = e.response.data;
      throw new ErrorMessage(
        statusText,
        description,
        httpStatus,
        status,
        clientType
      );
    } else throw e;
  }
};

export const attachTagToContactSearchApi = async ({ headers, tagId, data }) => {
  if (data.company_filters?.creation_year)
    data.company_filters.creation_year = parseInt(
      data.company_filters.creation_year,
      10
    );

  const tenant = getFromLocalStorage("lefacWebTenant")?.data;
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  try {
    const res = await axios.put(
      `${Config.apiBaseurl}/${Config.apis.frontend.api}/tag/${tagId}/contact-search`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-tenant": tenant,
          ...headers,
        },
      }
    );

    return res.data;
  } catch (e) {
    if (e.response) {
      const { statusText, status } = e.response;
      const { httpStatus, clientType, description } = e.response.data;
      throw new ErrorMessage(
        statusText,
        description,
        httpStatus,
        status,
        clientType
      );
    } else throw e;
  }
};
