import React from "react";
import { AutoComplete, Select } from "antd";
import { toUpper } from "lodash";

const { Option } = AutoComplete;

const PlaceAutocomplete = ({
  data,
  onChange,
  disabled,
  disableOptions,
  value,
  singleValue = false,
  showSearch,
  showArrow,
}) => {
  return (
    <Select
      mode={singleValue ? "" : "multiple"}
      showSearch={showSearch}
      showArrow={showArrow}
      value={value}
      style={{ width: "100%" }}
      disabled={disabled}
      allowClear
      onChange={onChange}
      // filterOption={(input, option) =>
      //   (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      // }
      placeholder={disabled ? "—" : ""}
    >
      {data?.map((place) => (
        <Option
          key={place}
          value={place}
          obj={{ value: place, label: place }}
          disabled={disableOptions}
        >
          {toUpper(place)}
        </Option>
      ))}
    </Select>
  );
};

export default PlaceAutocomplete;
