import axios from "axios";
import Config from "../Config";
import ErrorMessage from "../libs/helpers/ErrorMessage";
import { getFromLocalStorage } from "../libs/helpers/localstorage";

export const EXPORT_CONTACTS_PLUGIN = "Esporta contatti";
export const EXPORT_CONTACTS_MAILER_PLUGIN = "Emailing";
export const EXPORT_COMPANIES_PLUGIN = "Esporta aziende";
export const EXPORT_WORKRELATION_PLUGIN = "Esporta collaborazioni";

export const getTenantListApi = async ({ headers }) => {
  const res = await fetch(`${Config.apiBaseurl}/api/v1/tenant`, {
    method: "GET",
    headers,
  });

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export const getUserDetailApi = async ({ headers, id }) => {
  const res = await fetch(
    `${Config.apiBaseurl}/${Config.apis.admin.detail}/user/${id}`,
    {
      method: "GET",
      headers,
    }
  );

  const response = await res.json();

  if (res.ok) {
    return response;
  }
  const { statusText, status } = res;
  const { httpStatus, clientType, description } = response;
  throw new ErrorMessage(
    statusText,
    description,
    httpStatus,
    status,
    clientType
  );
};

export async function editProfileApi({ ...data }) {
  const ls = getFromLocalStorage();
  const token = ls && ls.token;
  return axios.patch(
    `${Config.apiBaseurl}/${Config.apis.user.profile}`,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
