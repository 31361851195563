import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";

const Label = (props) => {
  const { value, className = "", tooltip } = props;

  return (
    <p className={className}>
      <b>{value} </b>
      {tooltip && tooltip.length && (
        <Tooltip placement="top" title={tooltip}>
          <InfoCircleOutlined
            className="filter-color"
            style={{ marginLeft: 6 }}
          />
        </Tooltip>
      )}
    </p>
  );
};

export default Label;
