import React, { useEffect, useState } from "react";
import { Card, Box, Alert, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import BannerTop from "../../components/BannerTop/BannerTop";
import { l } from "../../libs/localization";
import "./profile.style.scss";
import Button from "../../design-library/Button/Button.component";
import TextField from "../../design-library/TextField/TextField.component";
import { editProfileApi } from "../../api/user.api";

function ProfileMailerIntegrationPage({}) {
  const profile = useSelector((state) => state.auth.user);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!username) {
      setUsername(profile.mailerIntegration?.username);
    }
  }, [profile]);

  async function submit(e) {
    e.preventDefault();
    setLoading(true);
    setError(false);
    try {
      await editProfileApi({
        mailerIntegration: {
          username,
          password,
        },
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      if (err.response?.data?.clientType) {
        setError(
          l(
            `PROFILE.MAILER_INTEGRATION.ERROR.${err.response.data.clientType}`
          ) || err.response.data.clientType
        );
      } else {
        setError(err.message);
      }
    }
  }

  return (
    <div className="profilePage">
      <BannerTop small variant="profile">
        <h1 className="mt-4 title">{l("PROFILE.MAILER_INTEGRATION")}</h1>
      </BannerTop>
      <form onSubmit={submit}>
        <Card
          sx={{
            p: 8,
            mt: -6,
            position: "relative",
            boxShadow: "0px 4px 10px 0px #00000026",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "stretch", md: "flex-end" },
            gap: 4,
          }}
        >
          {error ? (
            <Alert severity="error" sx={{ alignSelf: "stretch" }}>
              {error}
            </Alert>
          ) : null}
          <TextField
            placeholder={l("PROFILE.MAILER_INTEGRATION.USERNAME")}
            label={l("PROFILE.MAILER_INTEGRATION.USERNAME")}
            type="text"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            placeholder={l("PROFILE.MAILER_INTEGRATION.PASSWORD")}
            label={l("PROFILE.MAILER_INTEGRATION.PASSWORD")}
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button sx={{ alignSelf: "flex-end" }} type="submit">
            {l("PROFILE.SAVE")}
          </Button>
          {loading ? (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.7)",

                zIndex: 5,
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
        </Card>
      </form>
    </div>
  );
}

export default ProfileMailerIntegrationPage;
