import callApi from "../libs/helpers/callApi";

import {
  getBrandEntityApi,
  getBrandListApi,
  getBrandDetailApi,
  editBrandDetailApi,
  createBrandDetailApi,
  getBrandCompanyApi,
} from "../api/brand.api";

export const BRAND_LIST_LOADING = "BRAND_LIST_LOADING";
export const BRAND_LIST_LOADED = "BRAND_LIST_LOADED";
export const BRAND_LIST_LOAD_ERROR = "BRAND_LIST_LOAD_ERROR";

export const getBrandListAction = callApi({
  apiCall: (p) => getBrandListApi(p),
  secured: true,
  startAction: BRAND_LIST_LOADING,
  successAction: BRAND_LIST_LOADED,
  errorAction: BRAND_LIST_LOAD_ERROR,
});

export const BRAND_DETAIL_LOADING = "BRAND_DETAIL_LOADING";
export const BRAND_DETAIL_LOADED = "BRAND_DETAIL_LOADED";
export const BRAND_DETAIL_LOAD_ERROR = "BRAND_DETAIL_LOAD_ERROR";

export const getBrandDetailAction = callApi({
  apiCall: (p) => getBrandDetailApi(p),
  secured: true,
  startAction: BRAND_DETAIL_LOADING,
  successAction: BRAND_DETAIL_LOADED,
  errorAction: BRAND_DETAIL_LOAD_ERROR,
});

export const BRAND_COMPANY_LOADING = "BRAND_COMPANY_LOADING";
export const BRAND_COMPANY_LOADED = "BRAND_COMPANY_LOADED";
export const BRAND_COMPANY_LOAD_ERROR = "BRAND_COMPANY_LOAD_ERROR";

export const getBrandCompanyAction = callApi({
  apiCall: (p) => getBrandCompanyApi(p),
  secured: true,
  startAction: BRAND_COMPANY_LOADING,
  successAction: BRAND_COMPANY_LOADED,
  errorAction: BRAND_COMPANY_LOAD_ERROR,
});
