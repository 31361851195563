import React, { useState } from "react";
import { Button, Card, Col, List, Row, Tag } from "antd";
import "../../pages/home/home.styles.scss";
import Localize from "../../libs/localization";
import { orderBy, slice } from "lodash";
import { toTitleCase, translateRegistryOnView } from "../../libs/utils";
import ContactModal from "../../pages/contact/contact.modal";
import { get } from "../../libs/helpers/ioc";

const ContactsBrandList = ({ data, elementToShow = 3 }) => {
  const permissions = get("scopes");
  const [max, setMax] = useState(elementToShow);
  const [detailId, setDetailId] = useState(null);

  return (
    <>
      <Card
        title={
          <h2>
            <b>{Localize("PAGES.COMPANIES.DETAIL.SECTIONS.GENERAL.CONTACT")}</b>
          </h2>
        }
        bordered={false}
        className="companyPage__body-card companyPage__body-card--custom customCard"
      >
        <List
          dataSource={slice(orderBy(data, "start_date", "desc"), 0, max)}
          renderItem={(item) => {
            return (
              <List.Item key={item._id}>
                <List.Item.Meta
                  title={
                    <Row align="middle">
                      <h3
                        className="mb-0 pb-0"
                        onClick={() =>
                          Object.keys(permissions || {})?.includes("contact") &&
                          setDetailId(item._id)
                        }
                      >
                        <a>
                          {item.prefix} {toTitleCase(item.name)}{" "}
                          {toTitleCase(item.surname)}
                        </a>
                      </h3>
                    </Row>
                  }
                  description={
                    <>
                      <Row>
                        <p className="p-0 m-0" style={{ fontSize: 12 }}>
                          <b>
                            {Localize("COMMON.FUNCTION")}:{" "}
                            {translateRegistryOnView(
                              item.function,
                              "function",
                              true
                            )
                              ? translateRegistryOnView(
                                  item.function,
                                  "function",
                                  true
                                )
                              : item.function}
                          </b>
                        </p>
                      </Row>
                    </>
                  }
                />
              </List.Item>
            );
          }}
        />
        {max <= data.length && data.length > elementToShow && (
          <Row justify="center">
            <Button onClick={() => setMax(max + elementToShow)}>
              {Localize("COMMON.SHOW_MORE")}
            </Button>
          </Row>
        )}
      </Card>
      {detailId && (
        <ContactModal
          showAssociateTag={false}
          id={detailId}
          visible={detailId}
          close={() => setDetailId(null)}
        />
      )}
    </>
  );
};

export default ContactsBrandList;
